import { BorderBottom } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
// import { fontGrid } from "@mui/material/styles/cssUtils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: "20px 30px 20px 30px !important ",
    backgroundColor: "#fff",
    // boxShadow: theme.shadows[3],
    borderRadius: "10px",
    // height: "75vh !important ",
    // overflow: "auto",
    // "&::-webkit-scrollbar": {
    //   display: "none !important",
    // },
    // scrollbarWidth: "none !important",
  },
  title: {
    color: "black !important",
    fontSize: "1.3rem!important",
    marginTop: "10px !important",
    fontWeight: "bold !important",
  },
  clearIcon: {
    marginTop: "5px !important",
    color: "#d5d5d5 !important",
    cursor: "pointer !important",
    fontSize: "2rem !important",
  },
  divider: {
    marginTop: "5px !important",
  },
  PayCountry: {
    display: "flex !important",
    aliginItems: "center !important ",
    gap: "15px !important ",
  },
  submit: {
    backgroundColor: "#51459E !important",
    color: "#FFFF !important",
    borderRadius: "20px !important",
    fontSize: " 14px !important",
    padding: " 0 16px 0 16px !important",
    height: "32px !important",
    textTransform: "none !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    backgroundColor: " #FFFFFF !important",
    height: "32px !important",
    padding: " 0 16px 0 16px !important",
    fontSize: "14px",
    color: "#87269B !important",
    fontWeight: "600 !important",
    borderRadius: "20px !important",
    outline: "none !important",
    textTransform: "none !important",
  },
  countryFlag: {
    width: "25px !important ",
    height: "18px !important",
    // borderRadius: "50% !important",
    objectFit: "cover !important ",
  },
  textstyle: {
    marginTop: "10px !important ",
    fontWeight: "600 !important ",
    fontSize: "14px !important",
  },
  contentPadding: {
    padding: "2% !important",
  },
  inputLable: {
    margin: "8px 0 0px 0px !important ",
    fontSize: "14px !important",
    color: "#222222 !important",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "180px !important",
  },
}));
export default useStyles;
