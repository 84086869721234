import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    justifyContent: "end",
  },
  searchIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "var(--primary-color)",
  },
  closeIcon: {
    position: "absolute",
    right: "10px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "var(--primary-color)",
  },
  circleIcon: {
    fill: "#51459E !important",
  },
  addtxt: {
    fontSize: "1rem !important ",
    fontWeight: "bold !important ",
  },
  headerFont: {
    margin: "0 !important ",
    fontSize: "1.12rem !important ",
    color: "var(--primary-color) !important ",
  },
  inputInput: {
    width: "100%",
    padding: "10px !important ",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    fontSize: "1rem !important",
    //background: "#f5f7ff",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  inputLable: {
    margin: "-3px 0 0px 0px !important ",
    fontSize: "14px !important",
    color: "#222222 !important",
  },
  cell: {
    whiteSpace: "nowrap",
    color: "var(--primary-color)",
  },
  title: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "var(--primary-color)",
    fontSize: "16px",
    fontWeight: "bold",
    borderBottom: "1.4px solid #dddd",
  },
  b_heading: {
    color: "var(--primary-color) ",
    padding: "10px  ",
    // margin: "10px 0px",
    fontSize: "1rem !important",
    fontWeight: "bold !important ",
    backgroundColor: "#dddd",
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "20px",
    height: "20px",
    marginLeft: "25px",
  },
  avatar: {
    marginRight: "15px",
  },
  flag: {
    marginTop: "-1px",
    display: "flex",
    justifyContent: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  accountCard: {
    border: "1px solid #DDDDDD  ",
    borderRadius: "16px !important ",
    marginTop: "10px !important ",
    padding: "4px !important ",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
      borderRadius: "16px",
    },
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  },
  sepearator: {
    paddingBottom: "10px !important",
    borderBottom: "1px solid #c7c2c2 !important ",
  },
  marginTopSearch: {
    marginTop: "20px !important ",
  },
  searchBar: {
    position: "relative !important ",
    display: "flex !important ",
    justifyContent: "center !important ",
  },
  addNewBene: {
    display: "flex !important ",
    gap: "10px !important",
    margin: "20px 0px !important ",
  },
  paddingStyle: {
    padding: "2px !important ",
  },
  cardContent: {
    display: "flex !important",
    width: "80% !important ",
  },
  avatarFullName: {
    color: "#222 !important ",
    fontWeight: "600 !important",
    fontSize: "14px !important ",
  },
  accNumber: {
    color: "var(--primary-color) !important ",
    fontSize: "13px !important ",
    fontWeight: "400 !important ",
  },
  LaunchOutlinedIcon: {
    color: "var(--primary-color) !important",
    marginRight: "14px !important",
    cursor: "pointer !important",
  },
  DeleteOutlineIcon: {
    color: "var(--primary-color) !important",
    cursor: "pointer !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "grey !important",
    paddingLeft: "8px !important",
    marginTop: "6px !important ",
  },
  /* styles for dialog */
  Dialog: {
    "& .MuiDialog-paper": {
      width: "600px",
      borderRadius: "25px !important",
    },
    "& .MuiDialog-container": {
      height: "90vh !important",
    },
  },
  dialogTitle: {
    background: "#51459E !important ",
    color: "#FCFBFA",
    borderRadius: "20px",
    margin: "10px !important",
    height: "2vh !important",
    display: "flex",
  },
  popup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: " 10px 5px",
    width: "100%",
  },
  popupTitle: {
    fontFamily: " OpenSans, sans-serif !important ",
    fontSize: "16px !important ",
    color: "inherit !important",
    fontWeight: "bold !important",
    //textDecoration: "inherit !important",
    textTransform: "none !important",
    paddingLeft: "10px !important",
  },
  main: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    gap: "10px !important",
  },
  editIcon: {
    fill: "#fff !important",
    background: "#52439E",
    borderRadius: "7px",
    padding: "3px",
    cursor: "pointer",
    height: "15px",
    width: " 18px",
  },
  submit: {
    backgroundColor: "#51459E !important",
    color: "#FFFF !important",
    borderRadius: "20px !important",
    fontSize: " 14px !important",
    padding: " 0 16px 0 16px !important",
    height: "32px !important",
    textTransform: "none !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    backgroundColor: " #FFFFFF !important",
    height: "32px !important",
    padding: " 0 16px 0 16px !important",
    fontSize: "14px",
    color: "#87269B !important",
    fontWeight: "600 !important",
    borderRadius: "20px !important",
    outline: "none !important",
    textTransform: "none !important",
  },
  formselect: {
    minWidth: "35vw !important ",
  },
  payToCountry: {
    marginTop: "5px !important",
    fontSize: "0.8rem !important",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    Dialog: {
      "& .MuiDialog-paper": {
        width: "600px",
        borderRadius: "25px !important",
      },
    },
    headerFont: {
      margin: "0 !important ",
      fontSize: "0.8rem !important ",
      color: "var(--primary-color) !important ",
    },
    inputInput: {
      width: "100%",
      padding: "10px !important ",
      border: "1px solid #dcdcdc",
      borderRadius: "12px",
      fontSize: "0.79rem !important",
      //background: "#f5f7ff",
      "&:focus": {
        border: "1px solid #dcdcdc",
        outline: "none",
      },
    },
    addtxt: {
      fontSize: "0.8rem !important ",
      fontWeight: "bold !important ",
    },
    b_heading: {
      color: "var(--primary-color) ",
      padding: "10px  ",
      // margin: "10px 0px",
      fontSize: "0.8rem !important",
      fontWeight: "bold !important ",
      backgroundColor: "#dddd",
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      marginRight: "15px",
      width: "30px !important ",
      height: "30px !important ",
    },
    avatarInitial: {
      fontSize: "0.78rem !important ",
    },
    avatarFullName: {
      color: "#222 !important ",
      fontWeight: "600 !important",
      fontSize: "0.78rem !important ",
    },
    accNumber: {
      color: "var(--primary-color) !important ",
      fontSize: "0.77rem !important ",
      fontWeight: "400 !important ",
    },
    LaunchOutlinedIcon: {
      color: "var(--primary-color) !important",
      marginRight: "14px !important",
      cursor: "pointer !important",
      fontSize: "1.1rem !important ",
    },
    DeleteOutlineIcon: {
      color: "var(--primary-color) !important",
      cursor: "pointer !important",
      fontSize: "1.1rem !important ",
    },
    circleIcon: {
      fill: "#51459E !important",
      fontSize: "1.1rem !important ",
    },
  },
}));

export default useStyles;
