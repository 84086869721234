import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";

const PhoneNumberFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    handleChange,
    checkErrors,
    handleBlurChange,
    isEditable,
    disableFlag,
    country,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const phoneNumberValidationRule = {
    8: ["Guatemala"],
    9: ["Chile"],
    10: ["Argentina", "Bangladesh", "Colombia"],
    11: ["China"],
    13: ["Indonesia"],
  };

  const getMaxIBANLength = (country) => {
    for (const [maxLength, countries] of Object.entries(
      phoneNumberValidationRule
    )) {
      if (countries.includes(country)) {
        return parseInt(maxLength, 10);
      }
    }
    return 10; // Default max length if country not listed
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    const maxLength = getMaxIBANLength(beneDetails?.Country);

    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: `Mobile number should be exactly ${maxLength} digits.`,
      }));
      return; // Prevent further input
    } else {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    handleChange(event); // Pass changes to the parent component
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    // handleBlurChange(event);
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          {country === "Ghana"
            ? "Beneficiary Telephone Number"
            : "Phone Number"}
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={beneDetails?.PhoneNumber}
            //onChange={handleChange}
            //onBlur={handleBlurChange}
            name="PhoneNumber"
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(
              localErrors.PhoneNumber || checkErrors("PhoneNumber")
            )}
            helperText={localErrors.PhoneNumber || checkErrors("PhoneNumber")}
            // error={Boolean(checkErrors("PhoneNumber"))}
            // helperText={checkErrors("PhoneNumber")}
            disabled={disableFlag}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    {country === "Chile" ? (
                      <Typography>+56</Typography>
                    ) : country === "Bangladesh" ? (
                      <Typography>+880</Typography>
                    ) : country === "Argentina" ? (
                      <Typography>+54</Typography>
                    ) : country === "Guatemala" ? (
                      <Typography>+502</Typography>
                    ) : country === "China" ? (
                      <Typography>+86</Typography>
                    ) : country === "Indonesia" ? (
                      <Typography>+62</Typography>
                    ) : country === "Colombia" ? (
                      <Typography>+57</Typography>
                    ) : country === "Ghana" ? (
                      <Typography>+233</Typography>
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                ),
              },
            }}
          />
        ) : (
          <span className={classes.phoneNumber}>
            {country === "Chile" ? (
              <Typography className={classes.contentstyle}>+56</Typography>
            ) : country === "Bangladesh" ? (
              <Typography className={classes.contentstyle}>+880</Typography>
            ) : country === "Argentina" ? (
              <Typography className={classes.contentstyle}>+54</Typography>
            ) : country === "Guatemala" ? (
              <Typography className={classes.contentstyle}>+502</Typography>
            ) : country === "China" ? (
              <Typography className={classes.contentstyle}>+86</Typography>
            ) : country === "Indonesia" ? (
              <Typography className={classes.contentstyle}>+62</Typography>
            ) : country === "Colombia" ? (
              <Typography className={classes.contentstyle}>+57</Typography>
            ) : country === "Ghana" ? (
              <Typography className={classes.contentstyle}>+233</Typography>
            ) : (
              ""
            )}
            &nbsp;{beneDetails?.PhoneNumber ? beneDetails?.PhoneNumber : "NA"}
          </span>
        )}
      </Grid>
    </>
  );
};

export default PhoneNumberFeild;
