import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Loader from "../Loader";
import CurrencyFlag from "react-currency-flags";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useStyles from "./styles";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  fetchNewBeneficiary,
  fetchCountries,
  submitBeneficiaryDetails,
  validateBeneficiary,
  initiateBeneficiary,
  setCountryNameFor,
  setFormState,
  setError,
  setSubmitFlag,
} from "../../../Redux/Reducers/addNewBeneficiaryReducer";
import CountrySpecificComponent from "../MobileSendPayment/CountrySpecificComponent";

const Modalpopup = (props) => {
  const { open, close, rowdata, newFormFlag } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const addNewBeneficiaryData = useSelector((data) => data?.addNewBeneficiary);
  const navigate = useNavigate();
  const [getCountries, setGetCountries] = useState([]);
  const [getAssignmentID, setAssignmentID] = useState();
  const [formFlag, setFormFlag] = useState(addNewBeneficiaryData?.formFlag);
  const [etag, setEtag] = useState();
  // const [submitFlag, setSubmitFlag] = useState(false);
  const [countryName, setCountryName] = useState(
    addNewBeneficiaryData?.countryName
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [loading, setLoading] = useState(false);

  // const [formState, setFormState] = useState({
  //   BICFI: "",
  //   NickName: "",
  //   FullName: "",
  //   IsValidBeneNameURL: "",
  //   CdtrAcctId: "",
  //   IsValidBeneBICCodeURL: "",
  //   IsValidABANumberURL: "",
  //   BeneBankAddress: "",
  //   IsValidBeneAddressURL: "",
  // });
  const handleChange = (evt) => {
    // setFormState({ ...formState, [evt.target.name]: evt.target.value });
    dispatch(
      setFormState({
        [evt.target.name]: evt.target.value,
      })
    );
  };

  useEffect(() => {
    if (open != true) return;
    dispatch(fetchNewBeneficiary());
    dispatch(fetchCountries());
    setGetCountries(addNewBeneficiaryData?.countries);
    setFormFlag(false);
  }, [open]);

  const handleCountries = (evt) => {
    const value = evt.target.value;
    setSelectedCountry(value);
    navigate("/addnewbeneficiary", { state: { country: value } });
    dispatch(setCountryNameFor(value));
    dispatch(
      initiateBeneficiary({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        etag: addNewBeneficiaryData?.etag,
        countryName: value,
      })
    );
  };
  const handleRefreshInputBlur = (event) => {
    const { name, value } = event.target;

    const bicfiRefreshCountries = [
      "Argentina",
      "Canada",
      "Ghana",
      "Hong Kong",
      "Australia",
      "Bangladesh",
      "India",
      "China",
      "Malaysia",
      "Mexico",
      "Morocco",
      "Singapore",
      "Philippines",
      "South Africa",
      "Sri Lanka",
      "Japan",
    ];

    let refreshFor;

    if (
      addNewBeneficiaryData?.countryName === "Brazil" ||
      addNewBeneficiaryData?.countryName === "United Arab Emirates"
    ) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "CdtrAcctIdIBAN") {
        refreshFor = ".NewBeneDetails.CdtrAcctIdIBAN";
      }
    } else if (addNewBeneficiaryData?.countryName === "United States") {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
      }
    } else {
      refreshFor = bicfiRefreshCountries.includes(
        addNewBeneficiaryData?.countryName
      )
        ? ".NewBeneDetails.BICFI"
        : ".NewBeneDetails.CdtrAcctIdIBAN";
    }

    dispatch(
      validateBeneficiary({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        formState: addNewBeneficiaryData?.formState,
        etag: addNewBeneficiaryData?.etag,
        refreshFor: refreshFor,
      })
    );
  };
  const handleValidate = () => {
    const { Edit, ...state } = addNewBeneficiaryData?.formState;
    const refreshFor = ".NewBeneDetails.Validate";
    setLoading(true);

    dispatch(
      validateBeneficiary({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        formState: addNewBeneficiaryData?.formState,
        etag: addNewBeneficiaryData?.etag,
        refreshFor: refreshFor,
      })
    )
      .then((res) => {
        if (res.payload && res?.meta.requestStatus == "fulfilled") {
          dispatch(setSubmitFlag(true));
          dispatch(setError(""));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    const { Edit, message, pyImage, Country, classID, ...state } =
      addNewBeneficiaryData?.formState;
    setLoading(true);

    dispatch(
      submitBeneficiaryDetails({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        formState: state,
        etag: addNewBeneficiaryData?.etag,
      })
    )
      .then((res) => {
        if (res.payload?.confirmationNote) {
          close();
          navigate("/addnewbene");
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    setSelectedCountry("");
    close();
  };
  return (
    <>
      <Dialog
        onclose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
        className={classes.Dialog}
      >
        {newFormFlag ? (
          <>
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              <div className={classes.popup}>
                <Typography variant="body1" className={classes.popupTitle}>
                  Add New Beneficiary
                </Typography>
                <ClearIcon
                  className={classes.ClearIcon}
                  onClick={handleClose}
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid size={{ md: 4 }}>
                  <Typography className={classes.payToCountry}>
                    <b>Pay-to Country :</b>
                  </Typography>
                </Grid>
                <Grid size={{ md: 6, xs: 6 }}>
                  <Box className={classes.formselect}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel className={classes.inputLable}>
                        Select
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleCountries}
                        /*  disabled={
                          !!selectedCountry || addNewBeneficiaryData?.submitFlag
                        } */
                        size="small"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflow: "auto",
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {addNewBeneficiaryData?.countries &&
                          addNewBeneficiaryData?.countries.map((item) => (
                            <MenuItem
                              value={item?.Country}
                              key={item?.CountryCode}
                            >
                              {item?.Country}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid container spacing={2}>
                {loading && <Loader />}
                <Grid md={6} xs={12}>
                  <Button className={classes.cancel} onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              <div className={classes.popup}>
                <Typography variant="body1" className={classes.popupTitle}>
                  Modify Beneficiary
                </Typography>
              </div>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }} style={{ display: "flex" }}>
                  <Grid container spacing={2}>
                    <Grid size={{ md: 6 }} style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ fontWeight: "600" }}>
                        Pay-to Country:
                      </Typography>
                      <Typography variant="body2">
                        &nbsp;{rowdata?.Country}
                      </Typography>
                      <CurrencyFlag
                        currency={rowdata?.CountryCode}
                        style={{
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Grid>
                    <Grid size={{ md: 3 }}></Grid>
                    <Grid size={{ md: 3 }}>
                      <div className={classes.main}>
                        <EditIcon className={classes.editIcon} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Nickname (Optional)
                  </Typography>
                  <Typography variant="body2">NA</Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12 }}>
                      <Typography variant="body2" style={{ fontWeight: "600" }}>
                        Beneficiary Name
                      </Typography>
                      <Typography variant="body2">
                        {rowdata?.FullName}
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Typography variant="body2" style={{ fontWeight: "600" }}>
                        Account Number
                      </Typography>
                      <Typography variant="body2">
                        {rowdata?.CdtrAcctId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {rowdata?.Country === "United States" && (
                  <Grid
                    size={{ xs: 12 }}
                    spacing={2}
                    sx={{ display: "flex", gap: "3px" }}
                  >
                    <InfoOutlinedIcon
                      style={{ fill: "#52439E", height: "20px" }}
                    />
                    <Typography variant="body2">
                      Enter BIC Code or ABA Number
                    </Typography>
                  </Grid>
                )}
                <Grid size={{ xs: 12 }}>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12 }}>
                      <Typography variant="body2" style={{ fontWeight: "600" }}>
                        Beneficiary Bank BIC Code
                      </Typography>
                      <Typography variant="body2">{rowdata?.BICFI}</Typography>
                    </Grid>
                    {rowdata?.Country === "United States" && (
                      <Grid size={{ md: 6 }}>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "600" }}
                        >
                          ABA Routing Number
                        </Typography>
                        <Typography variant="body2">--</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    Beneficiary Bank Address
                  </Typography>{" "}
                  <Typography variant="body2">
                    {rowdata?.BeneBankAddress}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between ",
                  width: "100%",
                }}
              >
                <Grid
                  size={{ xs: 12 }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end  ",
                    gap: "50%",
                  }}
                >
                  <Button className={classes.cancel} onClick={handleClose}>
                    Cancel
                  </Button>

                  <Button className={classes.submit}>Submit</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Modalpopup;
