import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, Box, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

const BeneficiaryBankBICCodeField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    flag,
    handleChange,
    handleBlurChange,
    disableFlag,
    country,
    checkErrors = () => {},
    addEditBene,
  } = props;
  const [localErrors, setLocalErrors] = useState({});
  const maxLength = 11;
  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: ` Bank Identifier Code should not  exceed ${maxLength} Characters. `,
      }));
      return;
    } else {
      setLocalErrors((prev) => ({ ...prev, [name]: "" }));
    }
    handleChange(event);
  };
  const handleFieldBlur = (event) => {
    const { name } = event.target;

    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    handleBlurChange(event);
  };
  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary BIC
        </Typography>

        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={beneDetails?.BICFI}
            //onChange={handleChange}
            //onBlur={handleBlurChange}
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={localErrors.BICFI || Boolean(checkErrors("BICFI"))}
            helperText={localErrors.BICFI || checkErrors("BICFI")}
            name="BICFI"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneBICCodeURL && (
                      <img
                        src={beneDetails?.IsValidBeneBICCodeURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
            disabled={
              /* disableFlag */ country === "United States"
                ? beneDetails?.submitFlag || beneDetails?.ClrSysMmbId
                : disableFlag
            }
            className={classes.conToUppercase}
          />
        ) : addEditBene ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={beneDetails?.BICFI}
            name="BICFI"
            disabled
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneBICCodeURL && (
                      <img
                        src={beneDetails?.IsValidBeneBICCodeURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.BICFI ? beneDetails?.BICFI : "NA"} &nbsp;
            {flag &&
              beneDetails?.IsValidBeneBICCodeURL &&
              beneDetails?.BICFI && (
                <img
                  src={beneDetails?.IsValidBeneBICCodeURL}
                  className={classes.checkMark}
                />
              )}
          </span>
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryBankBICCodeField;
