import React, { useState, useEffect } from "react";
import flagsCode from "../../../CommonService/flagServies";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Loader from "../Loader";
import {
  getDataForFromAccount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataAfterFirstContinue,
  getDataForReceiversCountryName,
  debouncedGetDataForReceiverAmount,
  debouncedGetDataForSendAmount,
} from "../../../CommonService/sendPaymentService";
import ReactFlagsSelect from "react-flags-select";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import EuroCountries from "../../../CommonService/EuroCountries";
import waringICon from "../../../../assets/images/warningicon.svg";
import countryList from "react-select-country-list";
import Checkbox from "@mui/material/Checkbox";
import {
  getPurposeCodeList,
  countryCurrencyList,
} from "../../../CommonUtils/CommonUtils";
// import { checkErrors } from "../../../CommonUtils/CommonUtils";

const AmountSection = ({
  onContinue,
  accounts,
  eTag,
  nextAssignmentId,
  avaiableToPayCurrencyList,
  availableToPayCountryNamesList,
  setEtag,
  data,
  setReceiversCountryName,
  previousData,
  setIsFirstAccordionComplete,
  setExpanded,
  amountPreviousData,
}) => {
  const classes = useStyles();
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [validateCurrency, setValidateCurrency] = useState("");
  const [showAmountFields, setShowAmountFields] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagCode, setFlagCode] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const actionName = "ActionInitiatePayment";
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [checkBox, setCheckBox] = useState(true);
  const [selectedOption, setSelectedOption] = useState("SendAmount");
  const [receiverCurrency, setReceiverCurrency] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  useEffect(() => {
    if (amountPreviousData) {
      const fmAccount = amountPreviousData.caseInfo.content.FromAccount;
      const countyCode =
        amountPreviousData?.caseInfo?.content?.PaymentInfo?.CountryCode;
      const pxresults = getAccountsList(
        amountPreviousData?.shared?.D_GetAccountList
      );
      const maskNumber = pxresults.find(
        (item) => item.AccountNumber === fmAccount
      );
      if (maskNumber) {
        setAccount(maskNumber.AccountNumber);
      }
      setSelectedCountryName(
        amountPreviousData.caseInfo.content.ReceiversCountryName
      );
      setFlagCode(
        flagsCode[amountPreviousData?.caseInfo?.content?.ReceiversCountryName]
      );
      /* setflagselect(amountPreviousData?.caseInfo?.content?.PaymentInfo?.CountryCode); */
      const Options = {
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`https://flagcdn.com/w40/${countyCode?.toLowerCase()}.png`}
              alt=""
              style={{
                marginRight: "10px",
                width: "25px",
                height: "20px",

                objectFit: "cover",
              }}
            />
            {amountPreviousData?.caseInfo?.content?.ReceiversCountryName}
          </div>
        ),
        value: countyCode,
      };
      setSelectedCountry(Options);
      setSelectedCountryCode(countyCode);

      const selectedCurrencyInfo = countryCurrencyList.find((c) => {
        return c.countryCode === countyCode;
      });
      if (
        selectedCurrencyInfo.currencyCode !==
        amountPreviousData?.caseInfo?.content?.ReceiveCurrency
      ) {
        setCheckBox(false);
      }

      if (
        amountPreviousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode
      ) {
        const list = getPurposeCodeList(amountPreviousData?.shared);
        setPurposeCodeList(list);
        setPurposeCode(
          amountPreviousData?.caseInfo?.content?.PaymentInfo
            ?.PaymentsPurposeCode
        );
      }
      if (amountPreviousData?.shared) {
        const list = getPurposeCodeList(amountPreviousData?.shared);
        setPurposeCodeList(list);
        setPurposeCode(
          amountPreviousData?.caseInfo?.content?.PaymentInfo
            ?.PaymentsPurposeCode
        );
      }
      const option = {
        label: amountPreviousData?.caseInfo?.content?.ReceiveCurrency,
        value: amountPreviousData?.caseInfo?.content?.ReceiveCurrency,
      };
      setSelectedCurrency(option);
      setRemarks(amountPreviousData?.caseInfo?.content?.Remarks);
      const newDetails = amountPreviousData.caseInfo.content;
      setShowAmountFields(true);
      setShowExchangeFields(true);
      setAccountDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    }
  }, [amountPreviousData]);

  // useEffect(() => {
  //   if (accounts && accounts.length === 1) {
  //     setAccount(accounts[0].AccountNumber);
  //   }
  // }, [accounts]);

  function getAccountsList(list) {
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }
  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (/* flagselect */ selectedCountry && selectedvalue) {
      if (selectedvalue === "EU") {
        setShowWarning(
          !EuroCountries.includes(/* flagselect */ selectedCountry?.value)
        );
      } else {
        const isMismatch =
          /* flagselect */ selectedCountry?.value !== selectedvalue;
        setShowWarning(isMismatch);
      }
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (selectedCountry && selectedvalue) {
      if (selectedvalue === "EU") {
        setShowWarning(!EuroCountries.includes(selectedCountry?.value));
      } else {
        const isMismatch = selectedCountry?.value !== selectedvalue;
        setShowWarning(isMismatch);
      }
    } else {
      setShowWarning(false);
    }
  }, [selectedCountry, selectedCurrency]);

  const checkErrors = (value) => {
    if (!validate) return null;

    const errorDetail = validate.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    if (accountValue) {
      setError((prev) => ({
        ...prev,
        accountError: "",
        sendCountry: "",
        accountCurrency: "",
        amountYouSendError: " ",
        amountReceiveError: " ",
        purposeCodeError: " ",
        remarksError: "",
      }));
    }
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag, false)
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        if (resData?.AccountStatus === "false") {
          setError((prev) => ({
            ...prev,
            accountError:
              "The selected account is either on hold or not authorized for payment.",
          }));
        }
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
          POPVisibility: resData.POPVisibility,
          AccountType: resData?.AccountType,
          FullName: resData?.FullName,
          DebtorCountry: resData?.DebtorCountry,
          // CalculationInitiatedField:resData.CalculationInitiatedField,
        });
        /* setflagselect(""); */
        setSelectedCountry(null);
        setSelectedCurrency(null);
        setSelectedOption("SendAmount");
        setRemarks("");
        setCheckBox(true);
        setAccountDetails((prev) => ({
          ...prev,
          ReceiversCountryName: "",
          SendAmount: "",
          ReceiveAmount: "",
          CurrencyConvDesc: "",
          FXRateGoodForText: "",
          TransactionDesc: "",
          ReceiveCurrency: "",
        }));
      })
      .catch((error) => {
        if (error.status !== "401") {
        } else {
          navigate("/login");
        }
      });
  };
  const Options = countryList().getData();
  const newOptions = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();
      let countryObj = Options.find((item) => {
        const isoCountryName = item.label.trim().toLowerCase();
        if (
          normalizedCountry === "czech republic" &&
          isoCountryName === "czechia"
        ) {
          return true;
        } else if (
          normalizedCountry === "tanzania" &&
          isoCountryName === "tanzania, united republic of"
        ) {
          return true;
        } else if (
          normalizedCountry === "vietnam" &&
          isoCountryName === "viet nam"
        ) {
          return true;
        }
        return isoCountryName === normalizedCountry;
      });
      if (!countryObj) {
        countryObj = Options.find((item) => {
          const isoCountryName = item.label.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }
      if (!countryObj) {
        return null;
      }
      if (countryObj.label.toLowerCase() === "czechia") {
        countryObj.label = "Czech Republic";
      } else if (
        countryObj.label.toLowerCase() === "tanzania, united republic of"
      ) {
        countryObj.label = "Tanzania";
      } else if (countryObj.label.toLowerCase() === "viet nam") {
        countryObj.label = "Vietnam";
      }
      return countryObj;
    });
  const formattedOptions = newOptions.map((country) => ({
    value: country?.value,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={`https://flagcdn.com/w40/${country?.value.toLowerCase()}.png`}
          alt=""
          style={{
            marginRight: "10px",
            width: "25px",
            height: "20px",
            // borderRadius: "5px",
            objectFit: "cover",
          }}
        />
        {country?.label}
      </div>
    ),
  }));

  const customFilterOption = (option, searchText) => {
    const labelText = option.label.props.children[1];
    return labelText?.toLowerCase().includes(searchText?.toLowerCase());
  };
  const handleReceiverCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowExchangeFields(false);
    setRemarks("");
    setPurposeCode("");
    setSelectedCountryCode(country?.value);
    const countryName = country?.label?.props?.children[1];
    const selectedCurrencyInfo = countryCurrencyList.find(
      (c) => c.countryCode === country?.value
    );
    const selectedCurencyOption = {
      label: selectedCurrencyInfo?.currencyCode,
      value: selectedCurrencyInfo?.currencyCode,
    };
    setSelectedCurrency(selectedCurencyOption);
    setReceiverCurrency(selectedCurrencyInfo?.currencyCode);
    setError((prev) => ({
      amountYouSendError: "",
      remarksError: "",
      amountReceiveError: "",
      purposeCodeError: "",
      sendCountry: "",
      accountCurrency: "",
    }));
    setAccountDetails((prev) => ({
      ...prev,
      ReceiversCountryName: countryName,
      SendAmount: "",
      ReceiveAmount: "",
      CurrencyConvDesc: "",
      FXRateGoodForText: "",
      TransactionDesc: "",
      ReceiveCurrency: selectedCurrencyInfo?.currencyCode,
    }));
    // Fetch data for the selected country
    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      countryName,
      country?.value,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        // Update account details with fetched data
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency:
            resData?.ReceiveCurrency || selectedCurrencyInfo?.currencyCode, //here selectedCurrencyInfo?.currencyCode added to proceed with default currency if we don't get from api
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData?.POPVisibility,
        });
        // Handle purpose code list
        if (response?.data?.data?.shared?.D_PurposeCodeList) {
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        } else {
          setPurposeCodeList("");
        }
      })
      .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };
  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setCheckBox(checked);

    if (checked) {
      const selectedCurrencyInfo = countryCurrencyList.find((c) => {
        return c.countryCode === selectedCountryCode;
      });

      const selectedCurencyOption = {
        label: selectedCurrencyInfo?.currencyCode,
        value: selectedCurrencyInfo?.currencyCode,
      };
      setSelectedCurrency(selectedCurencyOption);
      setReceiverCurrency(selectedCurrencyInfo?.currencyCode);

      handleReceiverCurrencyChange(selectedCurencyOption);
    }
  };
  const handleRadioChange = (evt) => {
    setSelectedOption(evt.target.value);
    if (evt.target.value === "SendAmount") {
      setError((prevState) => ({
        ...prevState,
        amountReceiveError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
      }));
    }
  };
  const handleAmountInputChange = (event) => {
    const value = event.target.value;
// const format=parseFloat(value).toFixed(2);
// console.log(format)
    // Allow only numeric input and a single decimal point
    const regex = /^[0-9]*\.?[0-9]*$/; // Allow digits and a single decimal point
    if (!regex.test(value)) {
      return; // Prevent any invalid characters from being entered
    }

    // let formattedValue ;
    // if (value && !value.includes('.')) {
    //    formattedValue = `${value}.00`;
    // }
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
        remarksError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
        ReceiveCurrency: receiverCurrency,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Selected Account holds insufficient Funds for the selected transaction amount, we are unable to proceed with the payment at this time and that they should call Customer Service.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoading(true);
    let formattedValue;
    if (value && !value?.includes(".")) {
      formattedValue = `${value}.00`;
    }
    const amount = formattedValue || value;
    setLoading(true);
    if (!isNaN(amount) && !error?.amountYouSendError) {
      debouncedGetDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          setLoading(false);
          setShowExchangeFields(true);

          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
            POPVisibility: resData.POPVisibility,
          });

          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;

    // Allow only numeric input and a single decimal point
    const regex = /^[0-9]*\.?[0-9]*$/; // Allow digits and a single decimal point
    if (!regex.test(value)) {
      return; // Prevent any invalid characters from being entered
    }

    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
        amountReceiveError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not greater than available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoading(true);
    if (parsedValue === 0 || value === 0) {
      setLoading(false);
    }
    if ((!isNaN(value) && parsedValue) || value === "") {
      debouncedGetDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        value,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            ReceiveAmount: resData.ReceiveAmount,
            SendAmount: resData.SendAmount,
            POPVisibility: resData.POPVisibility,
          });
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          console.error("Error fetching receiver amount:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  function updatePurposeCodeListForJapan(
    resData,
    setPurposeCodeList,
    getPurposeCodeList,
    response
  ) {
    if ("Japan" === resData.ReceiversCountryName && resData.POPVisibility) {
      // setPurposeCodeList(
      //   resData.ReceiveAmount > 1000000
      //     ? getPurposeCodeList(response?.data?.data?.shared)
      //     : ""
      // );
      setPurposeCodeList(getPurposeCodeList(response?.data?.data?.shared));
    }
  }
  const handleReceiverCurrencyChange = (option) => {
    setLoading(true);
    const newCurrency = option.value;
    setError((prev) => ({
      ...prev,
      accountCurrency: " ",
      remarksError: "",
    }));
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setReceiverCurrency(resData.ReceiveCurrency);
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData.POPVisibility,
        });
        updatePurposeCodeListForJapan(
          resData,
          setPurposeCodeList,
          getPurposeCodeList,
          response
        );
        setShowExchangeFields(true);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const uniqueCurrencies = avaiableToPayCurrencyList.filter(
    (currency, index, self) =>
      index ===
      self.findIndex(
        (element) => element.CurrencyName === currency.CurrencyName
      )
  );
  const options = uniqueCurrencies.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && (
        <CurrencyFlag currency={value} className={classes.CurrencyFlag} />
      )}
      <span className={classes.optionValue}>&nbsp;&nbsp;{value}</span>
    </div>
  );

  const handleFirstAccordionComplete = () => {
    if (accountDetails.account && accountDetails.amount) {
      setIsFirstAccordionComplete(true);
      setExpanded("panel2");
    }
  };

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
    if (purposeCode) {
      setError((prevState) => ({
        ...prevState,
        purposeCodeError: "",
      }));
    }
  };

  const handleRemarksChange = (event) => {
    //setRemarks(event.target.value);
    if (event.target.value.length <= 140) {
      setRemarks(event.target.value);
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Value should be less than 140 characters.",
      }));
    }
  };
  const [debouncedRemarks, setDebouncedRemarks] = useState("");

  const validateCountry = () => {
    if (selectedCountry === null) {
      return selectedCountry === null ? "Select a country" : "";
    } else {
      return false;
    }
  };

  const validateBeneficiary = () => {
    if (selectedCurrency === null) {
      return selectedCurrency === null ? "Select a Currency" : "";
    } else {
      return false;
    }
  };

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    let amountError = "";
    if (sendAmount <= 0) {
      amountError = " Amount entered must be greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };
  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount entered must be greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
      // } else if (receiveAmount > accountDetails?.AccountBalance) {
      //   amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const validatePurposeCode = () => {
    if (accountDetails?.POPVisibility) {
      return purposeCode === "" ? "Value cannot be blank." : "";
    } else {
      return false;
    }
  };
  const validateAccount = () => {
    if (account === "") {
      return account === "" ? "Select an account to continue." : "";
    } else {
      return false;
    }
  };

  const validateForm = () => {
    const validations = [
      { error: validateAccount(), field: "accountError" },
      { error: validateCountry(), field: "sendCountry" },
      { error: validateBeneficiary(), field: "accountCurrency" },
      selectedOption === "SendAmount"
        ? { error: validateAmount(), field: "amountYouSendError" }
        : { error: validateReceiveAmount(), field: "amountReceiveError" },
      { error: validatePurposeCode(), field: "purposeCodeError" },
      { error: validateRemarks(), field: "remarksError" },
    ];

    for (let validation of validations) {
      if (validation.error) {
        setError({ [validation.field]: validation.error });
        return false;
      }
    }

    handleContinue();
    return true;
  };

  const handleContinue = async () => {
    try {
      setLoading(true);
      const response = await getDataAfterFirstContinue(
        accountDetails.FromAccount,
        accountDetails.SendAmount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiveAmount,
        accountDetails.ReceiversCountryName,
        /* flagselect, */
        selectedCountry?.value,
        purposeCode,
        remarks,
        nextAssignmentId,
        eTag,
        accountDetails?.AccountType,
        accountDetails?.FullName,
        accountDetails?.DebtorCountry,
        selectedOption
      );
      if (response.status === 200) {
        setIsFirstAccordionComplete(true);
        setExpanded("panel2");
      }

      setEtag(response.headers["etag"]);
      const receiversCountryName = accountDetails.ReceiversCountryName;
      setReceiversCountryName(receiversCountryName);
      onContinue(nextAssignmentId, eTag, setEtag);
    } catch (error) {
      setValidate(error.response?.data?.errorDetails);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <Grid container spacing={2}>
        <Grid item size={{ md: 7, xl: 7 }}>
          <Typography className={classes.Typo2} sx={{ pb: "10px" }}>
            Select account to make payment from
          </Typography>
          <Box
            sx={{
              border: "1px solid #c0c0c0",
              borderRadius: "10px",
              p: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item size={{ md: 6, xl: 6 }}>
                <Typography variant="body2">
                  {accountDetails?.FullName ? accountDetails?.FullName : "Name"}{" "}
                  &nbsp;
                </Typography>
              </Grid>
              <Grid item size={{ md: 6, xl: 6 }}>
                <Typography variant="body2">
                  {accountDetails?.DebtorCountry
                    ? accountDetails?.DebtorCountry
                    : " Debitor Country"}{" "}
                  &nbsp;
                </Typography>
              </Grid>
              <Grid item size={{ md: 12, xl: 12 }}>
                {" "}
                <FormControl
                  fullWidth
                  error={Boolean(checkErrors(".FromAccount"))}
                >
                  {/* <InputLabel className={classes.inputLable}>
                            Select an account
                          </InputLabel> */}
                  <Select
                    id="Account"
                    variant="standard"
                    value={account}
                    onChange={handleAccountChange}
                    inputProps={{ className: classes.acctInput }}
                    label="Select an account"
                    error={Boolean(checkErrors(".FromAccount"))}
                    helperText={checkErrors(".FromAccount")}
                    disableUnderline
                    displayEmpty
                    required
                  >
                    <MenuItem value="" disabled className={classes.placeholder}>
                      Select an account
                    </MenuItem>
                    {accounts &&
                      accounts.map((acc) => (
                        <MenuItem
                          key={acc.AccountNumber}
                          value={acc.AccountNumber}
                        >
                          {acc.AccountNumber}{" "}
                          {/* Masked account number shown */}
                        </MenuItem>
                      ))}
                  </Select>
                  {checkErrors(".FromAccount") && (
                    <FormHelperText>
                      {checkErrors(".FromAccount")}
                    </FormHelperText>
                  )}
                  {error?.accountError && (
                    <span className={classes.errorMsg}>
                      {error.accountError}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item size={{ md: 6, xl: 6 }}>
                <Typography variant="body2">
                  {accountDetails?.AccountType
                    ? accountDetails?.AccountType
                    : "Account type"}{" "}
                  &nbsp;
                </Typography>
              </Grid>
              <Grid
                item
                size={{ md: 6, xl: 6 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2">Balance</Typography>
                <Typography variant="body2">
                  &nbsp;{accountDetails.AccountBalance.toLocaleString()} &nbsp;
                  {accountDetails?.SendCurrency && (
                    <CurrencyFlag currency={accountDetails?.SendCurrency} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item size={{ md: 5, xl: 5 }}>
          <Typography variant="body2" className={classes.Typo2}>
            What country are the funds being sent to ?&nbsp;
          </Typography>
          <SelectFeild
            options={formattedOptions}
            value={selectedCountry}
            isDisabled={account === "" || error?.accountError}
            onChange={handleReceiverCountrySelect}
            placeholder="Search by country name"
            isSearchable={true}
            filterOption={customFilterOption}
            className={classes.countryCodeSelect}
            classNamePrefix="custom"
          />
          {error?.sendCountry && (
            <span className={classes.errorMsg}>{error?.sendCountry}</span>
          )}{" "}
          <Box sx={{ display: "flex", pt: "8px" }}>
            <Checkbox
              style={{ color: "var(--primary-color)" }}
              name="checkBox"
              checked={checkBox}
              onChange={handleCheckbox}
            />
            <Box>
              <Typography className={classes.Typo2}>
                Default beneficiary currency type as per country is selected
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                You can change beneficiary currency type by uncheck
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item size={{ md: 7, xl: 7 }}>
          <Grid container>
            <Grid size={{ md: 12, xl: 12 }}>
              {" "}
              <Typography className={classes.Typo2}>
                Please select send money equal to
              </Typography>
              {/* <Box> */}
              {/* <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className={classes.radioCSS}
                  value={selectedOption}
                  onChange={radioSelect}
                > */}
              <Grid container spacing={2} sx={{ fontSize: "12px" }}>
                <Grid item size={{ md: 6, xl: 6 }} className={classes.radioCSS}>
                  <FormControlLabel
                    value="SendAmount"
                    control={<Radio />}
                    label="Amount you want to send"
                    checked={selectedOption === "SendAmount"}
                    onChange={handleRadioChange}
                  />
                </Grid>
                <Grid item size={{ md: 6, xl: 6 }} className={classes.radioCSS}>
                  <FormControlLabel
                    value="ReceiveAmount"
                    control={<Radio />}
                    label="Amount beneficiary receive"
                    checked={selectedOption === "ReceiveAmount"}
                    onChange={handleRadioChange}
                  />
                </Grid>
              </Grid>
              {/* </RadioGroup> */}
              {/* </Box> */}
            </Grid>
            <Grid size={{ md: 12, xl: 12 }}>
              <Grid container spacing={2}>
                <Grid size={{ md: 5, xl: 5 }}>
                  <Typography className={classes.Typo2} sx={{ pb: "5px" }}>
                    Amount you want to send
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    // error={error?.amountYouSendError}
                    // helperText={error?.amountYouSendError}
                    disabled={
                      selectedOption != "SendAmount" || selectedCountry === null || error?.accountError
                    }
                    sx={{
                      background:
                        selectedOption !== "SendAmount"
                          ? "rgba(0,0,0,0.12)"
                          : "",
                    }}
                    className={`${classes.sendAmountTextField} ${classes.itemWidth}`}
                    onChange={handleAmountInputChange}
                    // onBlur={handleAmountInputBlur}
                    value={
                      accountDetails.SendCurrency.includes("JPY") ||
                      accountDetails?.SendCurrency?.includes("CLP") ||
                      accountDetails?.SendCurrency?.includes("KRW") ||
                      accountDetails?.SendCurrency?.includes("VND")
                        ? accountDetails.SendAmount &&
                          !isNaN(parseFloat(accountDetails.SendAmount))
                          ? parseFloat(accountDetails.SendAmount).toFixed(0)
                          : ""
                        : accountDetails.SendAmount
                    }
                    // value={accountDetails.SendAmount}
                    slotProps={{
                      input: {
                        className: classes.borderstyle,
                        inputMode: "decimal",
                        pattern: "\\d*([\\.,]\\d{0,7})?",
                        step:
                          accountDetails?.SendCurrency?.includes("JPY") ||
                          accountDetails?.SendCurrency?.includes("CLP") ||
                          accountDetails?.SendCurrency?.includes("KRW") ||
                          accountDetails?.SendCurrency?.includes("VND")
                            ? "1"
                            : "0.0000001",
                        endAdornment: (
                          <InputAdornment position="end">
                            {accountDetails?.SendCurrency && (
                              <CurrencyFlag
                                currency={accountDetails?.SendCurrency}
                                className={classes.CurrencyFlag}
                              />
                            )}
                            &nbsp; {accountDetails?.SendCurrency}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  {error.amountYouSendError && (
                    <div className="error-text">{error.amountYouSendError}</div>
                  )}
                </Grid>
                <Grid size={{ md: 1, xl: 1 }}>
                  <SwapHorizIcon sx={{ mt: "25px", fill: "#44318D" }} />
                </Grid>
                <Grid size={{ md: 6, xl: 6 }}>
                  <Typography className={classes.Typo2} sx={{ pb: "5px" }}>
                    Amount beneficiary receive
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    //error={error?.amountReceiveError}
                    //helperText={error?.amountReceiveError}
                    disabled={
                      selectedOption !== "ReceiveAmount" ||
                      selectedCountry === null || error?.accountError
                    }
                    onChange={handleReceiveAmountInputChange}
                    // onBlur={handleReceiveAmountInputBlur}
                    value={
                      accountDetails?.ReceiveCurrency?.includes("JPY") ||
                      accountDetails?.ReceiveCurrency?.includes("CLP") ||
                      accountDetails?.ReceiveCurrency?.includes("KRW") ||
                      accountDetails?.ReceiveCurrency?.includes("VND")
                        ? accountDetails.ReceiveAmount &&
                          !isNaN(parseFloat(accountDetails?.ReceiveAmount))
                          ? parseFloat(accountDetails.ReceiveAmount).toFixed(0)
                          : ""
                        : accountDetails.ReceiveAmount
                    }
                    // value={accountDetails?.ReceiveAmount}
                    className={`${classes.boxItem} ${
                      checkBox ? classes.disabledTextField : ""
                    }`}
                    sx={{
                      background:
                        selectedOption !== "ReceiveAmount"
                          ? "rgba(0,0,0,0.12)"
                          : "",
                    }}
                    slotProps={{
                      input: {
                        className: classes.borderstyle,
                        inputMode: "decimal",
                        pattern: "\\d*([\\.,]\\d{0,7})?",
                        step:
                          accountDetails?.ReceiveCurrency?.includes("JPY") ||
                          accountDetails?.ReceiveCurrency?.includes("CLP") ||
                          accountDetails?.ReceiveCurrency?.includes("KRW") ||
                          accountDetails?.ReceiveCurrency?.includes("VND")
                            ? "1"
                            : "0.0000001",
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControl fullWidth>
                              <SelectFeild
                                options={options}
                                value={selectedCurrency}
                                onChange={handleReceiverCurrencyChange}
                                formatOptionLabel={formatOptionLabel}
                                isSearchable={true}
                                isDisabled={checkBox}
                                placeholder="Select Currency"
                                error={error?.accountCurrency}
                                helperText={error?.accountCurrency}
                                className={classes.currencyCodeSelect}
                                classNamePrefix="custom"
                              />
                            </FormControl>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  {error.amountReceiveError && (
                    <div className="error-text">{error.amountReceiveError}</div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* {showExchangeFields && ( */}
        <Grid
          item
          size={{ md: 5, xl: 5 }}
          sx={{ border: "2px dashed #44318D", borderRadius: "2px", p: "20px" }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto 1fr",
              gap: "10px",
            }}
          >
            <Typography variant="body2" className={classes.label}>
              Exchange Rate
            </Typography>
            <Typography variant="body2">:</Typography>
            <Typography variant="body2" className={classes.label}>
              {accountDetails.CurrencyConvDesc}
            </Typography>

            <Typography variant="body2" className={classes.label}>
              Exchange Rate Valid For
            </Typography>
            <Typography variant="body2">:</Typography>
            <Typography variant="body2" className={classes.label}>
              {accountDetails.FXRateGoodForText}
            </Typography>

            <Typography variant="body2" className={classes.label}>
              Transaction Fee
            </Typography>
            <Typography variant="body2">:</Typography>
            <Typography variant="body2" className={classes.label}>
              {accountDetails.TransactionDesc}
            </Typography>
          </Box>
        </Grid>

        {/* )} */}
        {purposeCodeList && accountDetails?.POPVisibility && (
          <>
            <Grid
              item
              size={{ xs: 12, md: 7, xl: 7 }}
              className={classes.purpose}
            >
              <Typography
                variant="body2"
                className={classes.Typo1}
                sx={{ pb: "5px" }}
              >
                Purpose of Payment
                {/*  */}
              </Typography>

              <TextField
                id="PurposeCode"
                variant="outlined"
                value={purposeCode}
                size="small"
                onChange={handlePurposeCode}
                slotProps={{
                  input: {
                    className: `${classes.purposeCodeDropdown} ${classes.remarks}`,
                  },
                }}
                //label="Select Pay Purpose"
                select
                fullWidth
              >
                {purposeCodeList &&
                  purposeCodeList.map((pCode) => (
                    <MenuItem
                      key={pCode.PayPurPCodeDesc}
                      value={pCode.PayPurPCodeDesc}
                    >
                      {pCode.PayPurPCode}
                    </MenuItem>
                  ))}
              </TextField>
              {error.purposeCodeError && (
                <div className="error-text">{error.purposeCodeError}</div>
              )}
            </Grid>
            {/* <Grid siz={{ md: 2 }}></Grid> */}
          </>
        )}
        <Grid
          size={{
            md: !purposeCodeList ? 7 : 5,
            xs: 12,
            xl: !purposeCodeList ? 7 : 5,
          }}
          className={classes.purpose}
        >
          <Typography
            variant="body2"
            className={classes.Typo1}
            sx={{ pb: "5px" }}
          >
            Additional Payment Info
            {/*  */}
          </Typography>
          <TextField
            id="remarks"
            //label="Remarks"
            size="small"
            //multiline
            rows={1}
            value={remarks}
            onChange={handleRemarksChange}
            fullWidth
            slotProps={{
              input: {
                maxLength: 140,
                className: classes.borderstyle,
              },
            }}
            className={classes.remarks}
          />
          {error.remarksError && (
            <div className="error-text">{error.remarksError}</div>
          )}
        </Grid>
      </Grid>
      <Grid size={{ md: 12, xs: 12 }} className={classes.spacing}>
        {isShowWarning ? (
          <Box
            className={classes.flexCenterAlign}
            sx={{ gap: "5px", mt: "5px" }}
          >
            <img src={waringICon} className={classes.waringICon} />
            <Typography variant="body2" className={classes.Infotext}>
              Paying funds to a country in a currency other than its domestic
              currency may result in the funds being converted by the
              beneficiary bank before they are applied to the beneficiary's
              account if that account is not held in the currency of the
              payment. Please be sure you want to proceed before continuing.
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        item
        size={{ md: 12, xl: 12 }}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={validateForm}
          variant="contained"
          sx={{
            borderRadius: "27px",
            background: "var(--secondary-color)",
            textTransform: "none",
            color: "#fffff",
            mt: 1,
          }}
          size="small"
        >
          Next &nbsp;
          <ArrowDownwardIcon sx={{ fill: "#fffff", height: "18px" }} />
        </Button>
      </Grid>
    </>
  );
};
export default AmountSection;
