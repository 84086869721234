import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBeneficiaryInSendPaymentCaseFlow,
  validateBeneficiaryRefreshInSendPaymentFlow,
  submitAddBeneficiaryInSendPaymentCaseFlow,
} from "../../components/CommonService/commonAppService";

export const fetchNewBeneficiarySendPaymentFlow = createAsyncThunk(
  "sendPaymentFlow_beneficiary/fetchNewBeneficiary",
  async ({ caseKey, eTag }) => {
    const res = await addBeneficiaryInSendPaymentCaseFlow(caseKey, eTag);
    return {
      etag: res.headers["etag"],
      data: res.data?.data?.caseInfo?.content?.NewBeneficiary,
    };
  }
);
export const submitNewBeneficiarySendPaymentFlow = createAsyncThunk(
  "sendPaymentFlow_beneficiary/submitNewBeneficiary",
  async ({ caseKey, eTag, payload }) => {
    const res = await submitAddBeneficiaryInSendPaymentCaseFlow(
      caseKey,
      eTag,
      payload
    );
    return {
      etag: res.headers["etag"],
      data: res.data,
    };
  }
);
export const validateBeneficiarySendPaymentFlow = createAsyncThunk(
  "sendPaymentFlow_beneficiary/validateBeneficiary",
  async ({ caseKey, formState, etag, refreshFor }, { rejectWithValue }) => {
    try {
      const response = await validateBeneficiaryRefreshInSendPaymentFlow(
        caseKey,
        formState,
        etag,
        refreshFor
      );

      // Check for a valid response (e.g., HTTP 2xx status)
      if (response.status >= 200 && response.status < 300) {
        return response.data?.data?.caseInfo?.content?.NewBeneficiary;
      }
    } catch (error) {
      // Handle unexpected errors
      return rejectWithValue({
        status: error.status,
        message: error,
      });
    }
  }
);
const initialState = {
  caseKey: "",
  enableNewBeneFormFlag: false,
  etag: "",
  assignmentID: "",
  formState: {},
  formFlag: false,
  submitFlag: false,
  loading: false,
  error: null,
};
const sendPaymentAddNewBeneficiarySlice = createSlice({
  name: "sendPaymentFlowAddNewBeneficiary",
  initialState,
  reducers: {
    setFormState: (state, action) => {
      state.formState = { ...state.formState, ...action.payload };
    },
    setCaseKey: (state, action) => {
      state.caseKey = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSubmitFlag: (state, action) => {
      state.submitFlag = action.payload;
    },
    resetInitialState: (state, action) => {
      return initialState;
    },
    setClearBICForSendPaymentFlow:(state,action)=>{
      if (state.formState) {
        state.formState.BICFI = "";
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchNewBeneficiarySendPaymentFlow.fulfilled,
        (state, action) => {
          state.etag = action.payload.etag;
          state.formState = action.payload.data;
          state.enableNewBeneFormFlag = action.payload.data !== "";
          state.formFlag = action.payload.data !== "";
        }
      )
      .addCase(
        validateBeneficiarySendPaymentFlow.fulfilled,
        (state, action) => {
          state.formState = { ...state.formState, ...action.payload };
          state.error = null;
        }
      )
      .addCase(validateBeneficiarySendPaymentFlow.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(
        submitNewBeneficiarySendPaymentFlow.fulfilled,
        (state, action) => {
          state.formState = action.payload;
          state.error = null;
        }
      );
  },
});

export const { setCaseKey, setFormState, resetInitialState, setSubmitFlag,setClearBICForSendPaymentFlow } =
  sendPaymentAddNewBeneficiarySlice.actions;
export default sendPaymentAddNewBeneficiarySlice.reducer;
