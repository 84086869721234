import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAccessToken, setAccessToken, setRefreshToken,fetchUserInfo,setUserName,setAccessTokenForMobile} from '../Services/authService';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import Loader from "../../../Loader";

const Callback = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchToken = async () => {
            let code; 
            const isMobile =  Capacitor.isNativePlatform();
            if (isMobile) {
                const { value } = await Preferences.get({ key: 'code' });
                code = value;
            } else {
                const query = new URLSearchParams(window.location.search);
                 code = query.get('code');
            }
            if (code) {
                try {
                    const data = await fetchAccessToken(code);
                    isMobile ? setAccessTokenForMobile(data.access_token) : setAccessToken(data.access_token);
                    const userInfo = await fetchUserInfo(data.access_token);
                    localStorage.setItem("userIdentifier",userInfo?.sub)
                    setUserName(userInfo)                   
                    setRefreshToken(data.refresh_token);
                    setLoading(false);
                    navigate('/');
                } catch (error) {
                    setLoading(false);
                    console.error('Error during token exchange:', error);
                }
            }
        };
        
        fetchToken();
    }, [navigate]);

    if(loading){
        return <div> <Loader /></div>;
    }
};

export default Callback;
