import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../CommonService/flagServies";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import Flag from "react-world-flags";
import AddressMessage from "./CommonFields/AddressMessage";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import NickNameField from "./CommonFields/NickNameField";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import BeneficiaryIbanField from "./CommonFields/BeneficiaryIbanField";
import PhoneNumberFeild from "./CommonFields/PhoneNumberFeild";
import { useLocation } from "react-router-dom";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CountryFeild from "./CommonFields/CountryFeild";
const Guatemala = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    setInitialDetails,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    addNewBeneSendPaymentFlow,
    //below is for edit beneficiary
    editBene,
  } = props;

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);
  const newBeneData = addNewBeneSendPaymentFlow
    ? state.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      Edit: isEditable,
    }));
    const refereshFor = ".SelectedBeneDetails.CdtrAcctIdIBAN";
    try {
      const payLoad = {
        content: {
          SelectedBeneDetails: {
            [name]: value,
            Edit: isEditable,
          },
        },
        pageInstructions: [],
      };
      const response = await validateEditBeneficiaryFields(
        nextAssignmentId,
        payLoad,
        etag,
        refereshFor
      );
      if (name === "CdtrAcctIdIBAN") {
        setEditableBeneDetails((prevDetails) => ({
          ...prevDetails,
          CdtrAcctIdIBAN:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.CdtrAcctIdIBAN,
          BICFI:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails?.BICFI,
          BeneBankName:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankName,
          BeneBankAddress:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankAddress,
          Edit: isEditable,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <PayToCountryFeild countryCode={SelectedBeneDetails?.CountryCode} />
            <BeneficiaryIbanField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
              review={review}
              flag={false}
            />

            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Phone Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.PhoneNumber
                  ? `+502 ${SelectedBeneDetails?.PhoneNumber}`
                  : "--"}
              </Typography>
            </Grid>

            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />

            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.textstyle}>
                Beneficiary Account Type
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "NA"}
              </Typography>
            </Grid>

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankNameField
              beneDetails={SelectedBeneDetails}
              review={review}
            />

            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>

                <BeneficiaryIbanField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  flag={true}
                />
                <Grid size={{ md: 4 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Beneficiary Account Type
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="CdtrAcctTpPrtry"
                    value={newBeneData?.formState?.CdtrAcctTpPrtry}
                    error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                    helperText={checkErrors("CdtrAcctTpPrtry")}
                    onChange={handleChange}
                    disabled={newBeneData?.submitFlag}
                    size="small"
                    fullWidth
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment>
                            {newBeneData?.formState?.IsValidBeneIBANURL && (
                              <img
                                src={newBeneData?.formState?.IsValidBeneIBANURL}
                                className={classes.checkMark}
                              />
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                  >
                    <MenuItem value={"Savings/General"}>
                      Savings/General
                    </MenuItem>
                    <MenuItem value={"Savings/Deposit"}>
                      Savings/Deposit
                    </MenuItem>

                    <MenuItem value={"Checking/Current"}>
                      Checking/Current
                    </MenuItem>
                  </Select>
                  {checkErrors("CdtrAcctTpPrtry") && (
                    <FormHelperText style={{ color: "red" }}>
                      {checkErrors("CdtrAcctTpPrtry")}
                    </FormHelperText>
                  )}

                  {/* {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )} */}
                </Grid>
                {/* <Grid container size={{ xs: 12, md: 12 }}> */}
                {newBeneData?.formState?.BICFI && (
                  <BeneficiaryBankBICCodeField
                    beneDetails={newBeneData?.formState}
                    isEditable={false}
                    flag={true}
                    addEditBene={true}
                  />
                )}

                {newBeneData?.formState?.BeneBankName && (
                  <BeneficiaryBankNameField
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                )}

                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                    addEditBene={true}
                  />
                )}
                {/* </Grid> */}
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ xs: 12, md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />
                  <NickNameField
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <PhoneNumberFeild
                  beneDetails={newBeneData?.formState}
                  checkErrors={checkErrors}
                  handleChange={handleChange}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                  country={newBeneData?.formState?.Country}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />

              <BeneficiaryIbanField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />
              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              <PhoneNumberFeild
                beneDetails={editableBeneDetails}
                checkErrors={checkErrors}
                handleChange={handleInputChange}
                isEditable={isEditable}
                country={editableBeneDetails?.Country}
                disableFlag={editableBeneDetails?.submitFlag}
              />

              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Account Type
                </Typography>
                {isEditable ? (
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      onChange={handleInputChange}
                      value={editableBeneDetails?.CdtrAcctTpPrtry}
                      // onBlur={handleEditChange}

                      size="small"
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>
                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctTpPrtry
                      ? editableBeneDetails?.CdtrAcctTpPrtry
                      : "NA"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                  <img
                    src={editableBeneDetails?.IsValidAccountTypeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                disableFlag={true}
                flag={flag}
              />

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}
              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
              />
              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Guatemala;
