import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import RoutingCodeField from "./CommonFields/RoutingCodeField";
import Flag from "react-world-flags";
import AddressMessage from "./CommonFields/AddressMessage";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import NickNameField from "./CommonFields/NickNameField";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import companylogo from "../../assets/images/EPP-logo.png";
import AccountNumberFeild from "./CommonFields/AccountNumberFeild";
import CountryFeild from "./CommonFields/CountryFeild";
const Uruguay = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();
  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    setInitialDetails,
    // below is for  add/new beneficiary
    addNewBene,
    handleChange,
    handleRefreshInputBlur,
    //below is for edit beneficiary
    editBene,
    //below is for add beneficiary in send payment flow
    addNewBeneSendPaymentFlow,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const validationRules = {
    TaxID: {
      maxLength: 9,
      errorMessage: `National ID  number should be  exactly 9 characters.  Example format is 1234567-8.`,
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleLocalValidationEdit = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleInputChange(event); // Pass the change to parent
  };

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);

  const newBeneData = addNewBeneSendPaymentFlow
    ? state?.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state?.addNewBeneficiary;

  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    if (name === "BICFI" || name === "ClrSysMmbId") {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        } else if (name === "ClrSysMmbId") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            ClrSysMmbId:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.ClrSysMmbId,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <PayToCountryFeild countryCode={SelectedBeneDetails?.CountryCode} />
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb
                  ? SelectedBeneDetails?.MaskAccNumb
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Account Type
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                National ID Card Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.TaxID ? SelectedBeneDetails?.TaxID : "NA"}
              </Typography>
            </Grid>

            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankNameField beneDetails={SelectedBeneDetails} />

            <BeneficiaryBankAddressField beneDetails={SelectedBeneDetails} />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>
                <AccountNumberFeild
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <FormControl fullWidth>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Beneficiary Account Type
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                      value={newBeneData?.formState?.CdtrAcctTpPrtry}
                      helperText={checkErrors("CdtrAcctTpPrtry")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleChange}
                      size="small"
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>
                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                    {checkErrors("CdtrAcctTpPrtry") && (
                      <FormHelperText style={{ color: "red" }}>
                        {checkErrors("CdtrAcctTpPrtry")}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    National ID Card Number
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newBeneData?.formState?.TaxID}
                    //onChange={handleChange}
                    //error={Boolean(checkErrors("TaxID"))}
                    // helperText={checkErrors("TaxID")}
                    name="TaxID"
                    disabled={newBeneData?.submitFlag}
                    onChange={handleLocalValidation}
                    onBlur={handleFieldBlur}
                    error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                    helperText={localErrors.TaxID || checkErrors("TaxID")}
                  />
                </Grid>

                {/* <Grid container size={{ md: 12 }}> */}
                <BeneficiaryBankBICCodeField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                {newBeneData?.formState?.BeneBankName && (
                  <BeneficiaryBankNameField
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                )}
                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                    addEditBene={true}
                  />
                )}
                {/* </Grid> */}
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />
                  <NickNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <AccountNumberFeild
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                checkErrors={checkErrors}
                flag={flag}
              />
              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />
              <NickNameField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
              <Grid
                size={{ md: 4, xs: 12 }}
                className={`${classes.spacing} ${classes.flagAlignments}`}
              >
                <Box>
                  <FormControl className={classes.widthFeilds}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Beneficiary Account Type
                    </Typography>
                    {isEditable ? (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="CdtrAcctTpPrtry"
                        error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                        value={editableBeneDetails?.CdtrAcctTpPrtry}
                        helperText={checkErrors("CdtrAcctTpPrtry")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                      >
                        <MenuItem value={"Savings/General"}>
                          Savings/General
                        </MenuItem>
                        <MenuItem value={"Savings/Deposit"}>
                          Savings/Deposit
                        </MenuItem>
                        <MenuItem value={"Checking/Current"}>
                          Checking/Current
                        </MenuItem>
                      </Select>
                    ) : (
                      <span className={classes.contentstyle}>
                        {editableBeneDetails?.CdtrAcctTpPrtry
                          ? editableBeneDetails?.CdtrAcctTpPrtry
                          : "NA"}
                      </span>
                    )}
                    {checkErrors("CdtrAcctTpPrtry") && (
                      <FormHelperText style={{ color: "red" }}>
                        {checkErrors("CdtrAcctTpPrtry")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                {newBeneData?.formState?.IsValidAccountTypeURL && (
                  <Box sx={{ pt: 3 }}>
                    <img
                      src={newBeneData?.formState?.IsValidAccountTypeURL}
                      className={classes.checkMark}
                    />
                  </Box>
                )}
              </Grid>

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}
              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  National ID Card Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.TaxID}
                   //onChange={handleInputChange}
                    // error={Boolean(checkErrors("TaxID"))}
                    //  helperText={checkErrors("TaxID")}
                    name="TaxID"
                    disabled={newBeneData?.submitFlag}
                    onChange={handleLocalValidationEdit}
                     onBlur={handleFieldBlur}
                     error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                     helperText={localErrors.TaxID || checkErrors("TaxID")}
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxID
                      ? editableBeneDetails?.TaxID
                      : "NA"}
                  </span>
                )}
              </Grid>

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}

              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                flag={true}
                isEditable={isEditable}
              />

              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Uruguay;
