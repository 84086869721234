import React, { useState } from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../CommonService/flagServies";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFormState as setFormStateForAddNewbene } from "../../Redux/Reducers/addNewBeneficiaryReducer";
import { setFormState as setFormStateForEditBene } from "../../Redux/Reducers/editBeneficiaryReducer";
import { setFormState as setFormStateForSendPaymentFlow } from "../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";
import { TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import NickNameField from "./CommonFields/NickNameField";
import Flag from "react-world-flags";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import AddressMessage from "./CommonFields/AddressMessage";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import PhoneNumberFeild from "./CommonFields/PhoneNumberFeild";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountNumberFeild from "./CommonFields/AccountNumberFeild";
import CountryFeild from "./CommonFields/CountryFeild";

const Argentina = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    setInitialDetails,
    etag,
    // below is for add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    addNewBeneSendPaymentFlow,
    //below is for edit beneficiary
    row,
    editBene,
  } = props;
  const dispatch = useDispatch();
  const [localErrors, setLocalErrors] = useState({});
  const [TaxIDType, setTaxIDType] = useState("");
  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);
  const newBeneData = addNewBeneSendPaymentFlow
    ? state.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (fieldName) => {
    console.log("Checking errors for:", fieldName);
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(fieldName)
    );

    return errorDetail?.message || null;
  };

  const handleTaxIDTypeChange = (event) => {
    const { name, value } = event.target;
    setTaxIDType(value);
    // handleChange(event);
    handleInputChange(event);
    if (editableBeneDetails) {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,

        TaxID: "",
      }));
    }
  };

  const validationRules = {
    TaxID: {
      maxLength: 13,
      errorMessage: `${TaxIDType} number should be exactly 13 characters.  Example format is 20-12345678-9.`,
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleInputChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };
  const handleLocalValidationV1 = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleChange(event); // Pass the change to parent
  };

  const handleFieldBlurV1 = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  // const handleLocalValidationPhoneNumber = (event) => {
  //   const { name, value } = event.target;
  //   const maxLength = 10;

  //   if (value.length > maxLength) {
  //     setLocalErrors((prev) => ({
  //       ...prev,
  //       [name]: `Mobile number should be exactly ${maxLength} digits.`,
  //     }));
  //     return; // Prevent further input
  //   } else {
  //     setLocalErrors((prev) => ({
  //       ...prev,
  //       [name]: "",
  //     }));
  //   }

  //   handleInputChange(event); // Pass changes to the parent component
  // };
  const setFormState = addNewBeneSendPaymentFlow
    ? setFormStateForSendPaymentFlow
    : editBene
    ? setFormStateForEditBene
    : setFormStateForAddNewbene;

  const handleTaxIDTypeChangeV1 = (event) => {
    const { name, value } = event.target;
    setTaxIDType(value);
    if (newBeneData?.formState) {
      dispatch(
        setFormState({
          ["TaxID"]: "",
        })
      );
    }
    handleChange(event);
    //handleInputChange(event);
  };
  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      Edit: isEditable,
    }));

    const refereshFor = ".SelectedBeneDetails.BICFI";
    try {
      const payLoad = {
        content: {
          SelectedBeneDetails: {
            [name]: value,
            Edit: isEditable,
          },
        },
        pageInstructions: [],
      };
      const response = await validateEditBeneficiaryFields(
        nextAssignmentId,
        payLoad,
        etag,
        refereshFor
      );
      if (name === "BICFI") {
        setEditableBeneDetails((prevDetails) => ({
          ...prevDetails,
          BICFI:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails?.BICFI,
          BeneBankAddress:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankAddress,
          BeneBankName:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankName,
          Edit: isEditable,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Nick Name
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.NickName
                  ? SelectedBeneDetails?.NickName
                  : "NA"}
              </Typography>
            </Grid>
            <PayToCountryFeild countryCode={SelectedBeneDetails?.CountryCode} />
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number(CBU)
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb
                  ? SelectedBeneDetails?.MaskAccNumb
                  : "NA"}
              </Typography>
            </Grid>

            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Phone Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.PhoneNumber
                  ? `+54 ${SelectedBeneDetails?.PhoneNumber}`
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Tax ID Type
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxIDType
                  ? SelectedBeneDetails?.TaxIDType
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                {SelectedBeneDetails?.TaxIDType
                  ? SelectedBeneDetails?.TaxIDType
                  : "NA"}
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxID ? SelectedBeneDetails?.TaxID : "NA"}
              </Typography>
            </Grid>
            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />
            <BeneficiaryBankNameField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>
                <AccountNumberFeild
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  flag={flag}
                  disableFlag={newBeneData?.submitFlag}
                />

                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <FormControl className={classes.widthFeilds}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Tax ID Type
                    </Typography>
                    <Select
                      labelId="TaxIDType"
                      id="TaxIDType"
                      name="TaxIDType"
                      error={Boolean(checkErrors("TaxIDType"))}
                      value={newBeneData?.formState?.TaxIDType}
                      helperText={checkErrors("TaxIDType")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleTaxIDTypeChangeV1}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={"CUIT"}>CUIT</MenuItem>
                      <MenuItem value={"CUIL"}>CUIL</MenuItem>
                    </Select>
                    {checkErrors("TaxIDType") && (
                      <FormHelperText style={{ color: "red" }}>
                        {checkErrors("TaxIDType")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {newBeneData?.formState?.TaxIDType && (
                  <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        {newBeneData?.formState?.TaxIDType}
                      </Typography>

                      <TextField
                        variant="outlined"
                        size="small"
                        name="TaxID"
                        value={newBeneData?.formState?.TaxID}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleLocalValidationV1}
                        onBlur={handleFieldBlurV1}
                        error={Boolean(
                          localErrors.TaxID || checkErrors("TaxID")
                        )}
                        helperText={localErrors.TaxID || checkErrors("TaxID")}
                      />
                    </FormControl>
                  </Grid>
                )} 

                <Grid container size={{ xs: 12, md: 12 }}>
                  <BeneficiaryBankBICCodeField
                    beneDetails={newBeneData?.formState}
                    isEditable={true}
                    handleChange={handleChange}
                    handleBlurChange={handleRefreshInputBlur}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />

                  {newBeneData?.formState?.BeneBankName && (
                    <BeneficiaryBankNameField
                      beneDetails={newBeneData?.formState}
                      addEditBene={true}
                    />
                  )}

                  {newBeneData?.formState?.BeneBankAddress && (
                    <BeneficiaryBankAddressField
                      beneDetails={newBeneData?.formState}
                      flag={true}
                      addEditBene={true}
                    />
                  )}
                </Grid>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ xs: 12, md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />
                  <NickNameField
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />

                <PhoneNumberFeild
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  isEditable={true}
                  country={newBeneData?.formState?.Country}
                  disableFlag={newBeneData?.submitFlag}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <AccountNumberFeild
                handleChange={handleInputChange}
                checkErrors={checkErrors}
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
              />
              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />

              <NickNameField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
              <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Tax ID Type
                </Typography>
                {isEditable ? (
                  <FormControl className={classes.widthFeilds}>
                    <Select
                      labelId="TaxIDType"
                      id="TaxIDType"
                      name="TaxIDType"
                      error={Boolean(checkErrors("TaxIDType"))}
                      value={editableBeneDetails?.TaxIDType || ""}
                      helperText={checkErrors("TaxIDType")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleTaxIDTypeChange}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={"CUIT"}>CUIT</MenuItem>
                      <MenuItem value={"CUIL"}>CUIL</MenuItem>
                    </Select>
                    {checkErrors("TaxIDType") && (
                      <FormHelperText style={{ color: "red" }}>
                        {checkErrors("TaxIDType")}
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxIDType
                      ? editableBeneDetails?.TaxIDType
                      : "NA"}
                  </span>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  {editableBeneDetails?.TaxIDType}
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.TaxID}
                    //onChange={handleInputChange}
                    onChange={handleLocalValidation}
                    onBlur={handleFieldBlur}
                    error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                    helperText={localErrors.TaxID || checkErrors("TaxID")}
                    name="TaxID"
                    fullWidth
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxID
                      ? editableBeneDetails?.TaxID
                      : "NA"}
                  </span>
                )}
              </Grid>

              <PhoneNumberFeild
                beneDetails={editableBeneDetails}
                checkErrors={checkErrors}
                handleChange={handleInputChange}
                isEditable={isEditable}
                country={editableBeneDetails?.Country}
                disableFlag={editableBeneDetails?.submitFlag}
              />

              {/* <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Phone Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.PhoneNumber}
                    // onChange={handleInputChange}
                    onChange={handleLocalValidationPhoneNumber}
                    onBlur={handleFieldBlur}
                    error={Boolean(localErrors.PhoneNumber || checkErrors("PhoneNumber"))}
                    helperText={localErrors.PhoneNumber || checkErrors("PhoneNumber")}
                    name="PhoneNumber"
                    fullWidth
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment>
                            <Typography>+54&nbsp;&nbsp;</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.PhoneNumber
                      ? `+54 ${editableBeneDetails?.PhoneNumber}`
                      : "NA"}
                  </span>
                )}
              </Grid> */}

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />
              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}

              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
              />

              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Argentina;
