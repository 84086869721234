import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Grid from "@mui/material/Grid2";
import useStyles from "./styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AmountSection from "./AmountSection";
import Beneficiaries from "./Beneficiaries";
import {
  resetInitialState,
  validateAndSaveDetailsForEditBeneInSendPaymentFlow,
} from "../../../../Redux/Reducers/SendPaymentReducer";
import { fetchAccountsData } from "../../../CommonService/sendPaymentService";
import {
  getAccounts,
  getCountryNamesList,
} from "../../../CommonUtils/CommonUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReviewBeneficiaryDetails from "./ReviewBeneficiaryDetails";
import Review from "./Review";
import { validateBeneficiaryDetailsReviewScreen } from "../../../CommonService/sendPaymentService";
import Loader from "../Loader";
import FinalResponse from "./Finalresponse";
import Payagain from "../PayAgain/index";
import RepeatTransaction from "../../RepeatTransaction";

const steps = ["Payment details", "Review details", "Payment Summary"];

const TransferAmount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [completed, setCompleted] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [data, setData] = useState();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const location = useLocation();
  const { state } = location;

  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [caseKey, setCaseKey] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [receiversCountryName, setReceiversCountryName] = useState("");
  const [selectedBeneDetails, setSelectedBeneDetails] = useState(null);
  const [editableBeneDetails, setEditableBeneDetails] =
    useState(selectedBeneDetails);
  const [previousData, setPreviousData] = useState(null);
  const [beneficiariesPreviousData, setBeneficiariesPreviousData] =
    useState(null);
  const [amountPreviousData, setAmountPreviousData] = useState(null);
  const [purposeCodeList, setPurposeCodeList] = useState([]);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [finalResponse, setFinalResponse] = useState(null);
  const [showReviewBeneficiary, setShowReviewBeneficiary] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState("panel1");
  const [isFirstAccordionComplete, setIsFirstAccordionComplete] =
    useState(false);
  const [isSecondAccordionComplete, setIsSecondAccordionComplete] =
    useState(false);
  const [payagain, setPayagain] = useState(true);
  const [repeatpay, setRepeatpay] = useState(false);

  const actionName = state?.response?.data?.caseInfo?.content?.pyViewName;

  useEffect(() => {
    if (state?.response) {
      if (actionName === "PayAgain") {
        setPayagain(false);
        setRepeatpay(false);
      } else if (actionName === "RepeatTransaction") {
        setPayagain(false);
        setRepeatpay(true);
      }
    }
  }, [state?.response]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAccountsData();
        setData(response.data);
        setEtag(response.headers["etag"]);
        setnextAssignmentId(response.data?.nextAssignmentInfo?.ID);
        setCaseId(response.data.ID.split(" ")[1]);
        setCaseKey(response.data.ID);
        setAvailableToPayCurrencyList(
          response.data.data.shared.D_AvailabletoPayCurrencyListAPIList
            .D_AvailabletoPayCurrencyListAPIList.pxResults
        );
        setAvailableToPayCountryNamesList(
          getCountryNamesList(response?.data?.data)
        );
        let pxResults = getAccounts(response?.data?.data);
        setAccounts(pxResults);
      } catch (error) {
        console.error("Error fetching accounts data:", error.status);
        localStorage.clear();
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleStep = () => {
    //setLoading(true);
    // setActiveStep((prevStep) => prevStep + 1);

    handleContinue();
  };
  const handleStepV1 = () => {
    //setLoading(true);
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleContinue = async () => {
    dispatch(resetInitialState());
    setLoading(true)
    try {
      const response = await validateBeneficiaryDetailsReviewScreen(
        nextAssignmentId,
        eTag,
        editableBeneDetails
      );
      if (response.data) {
        setActiveStep((prevStep) => prevStep + 1);

        setLoading(false);
      }
      setReviewDetails(response.data);
      setEtag(response.headers.etag);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setPayagain(!payagain);
    navigate("/internationaltransfer/transactions");
    setRepeatpay(false);
  };
  const [open, setOpen] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log("handleBeforeUnload")
  //     if (!isLeaving) {
  //       event.preventDefault(); // Cancel the event
  //       event.returnValue = ''; // Required for some browsers
  //       setOpen(true); // Open the modal
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     console.log("clean")
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [isLeaving]);
  const handleStay = () => {
    setOpen(false);
  };

  // const handleLeave = () => {
  //   setIsLeaving(true); // Allow the page to unload
  //   setOpen(false); // Close the modal
  //   window.location.reload(); // Or navigate as needed
  // };

  return (
    <>
      <Box>
        <Typography className={classes.txAmountTitle}>
          Transfer Amount
        </Typography>
      </Box>
      {payagain ? (
        <>
          <Box>
            <Paper elevation={0}>
              <Box className={classes.stepperContainer}>
                {" "}
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  className={classes.steps}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      {/* onClick={handleStep(index)} */}
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              {activeStep === 0 && (
                <>
                  <Box className={classes.accord}>
                    <Accordion
                      // defaultExpanded
                      expanded={expanded === "panel1"}
                      // onChange={handleAccordionChange("panel1")}
                      sx={{
                        borderRadius: 2,
                      }}
                      disabled={expanded !== "panel1"}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          mt: 1,
                          borderRadius: 1,
                          height: "80px",
                          border:
                            expanded === "panel1"
                              ? "1px solid #44318D"
                              : "1px solid #D9D9D9",
                          borderLeft:
                            expanded === "panel1"
                              ? "40px solid #44318D"
                              : "40px solid #D9D9D9",
                        }}
                      >
                        <Grid container>
                          <Grid item md={0.5} sx={{ pt: 4, ml: 2, pr: 2 }}>
                            <AccountBalanceIcon />
                          </Grid>
                          <Grid item md={11} sx={{ pt: 3 }}>
                            <Box sx={{ display: "block" }}>
                              <Typography className={classes.Typo1}>
                                Account and payment details
                              </Typography>
                              <Typography
                                sx={{
                                  marginBottom: 3,
                                  color: "#c0c0c0",
                                  fontSize: "11px",
                                }}
                              >
                                Choose your account and enter 'Amount I want to
                                send' or 'Amount beneficiary receive'. The other
                                field auto-calculates.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AmountSection
                          data={data}
                          accounts={accounts}
                          eTag={eTag}
                          nextAssignmentId={nextAssignmentId}
                          avaiableToPayCurrencyList={avaiableToPayCurrencyList}
                          availableToPayCountryNamesList={
                            availableToPayCountryNamesList
                          }
                          amountPreviousData={amountPreviousData}
                          setIsFirstAccordionComplete={
                            setIsFirstAccordionComplete
                          }
                          setExpanded={setExpanded}
                          caseId={caseId}
                          setEtag={setEtag}
                          setReceiversCountryName={setReceiversCountryName}
                          previousData={previousData}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ border: "1px solid transparent" }}
                      expanded={expanded === "panel2"}
                      // onChange={handleAccordionChange("panel2")}
                      // disabled={!isFirstAccordionComplete}
                      disabled={expanded !== "panel2"}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          mt: 1,
                          borderRadius: 1,
                          height: "80px",
                          border:
                            expanded === "panel2"
                              ? "1px solid #44318D"
                              : "1px solid #D9D9D9",
                          borderLeft:
                            expanded === "panel2"
                              ? "40px solid #44318D"
                              : "40px solid #D9D9D9",
                        }}
                      >
                        <Grid container>
                          <Grid item md={0.5} sx={{ pt: 4, pr: 2 }}>
                            <PersonOutlineOutlinedIcon />
                          </Grid>
                          <Grid item md={11} sx={{ pt: 3 }}>
                            <Box sx={{ display: "block" }}>
                              <Typography className={classes.Typo1}>
                                Beneficiary details
                              </Typography>
                              <Typography
                                sx={{
                                  marginBottom: 3,
                                  color: "#c0c0c0",
                                  fontSize: "11px",
                                }}
                              >
                                Select the beneficiary you wish to transfer
                                funds to.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {nextAssignmentId && (
                          <Beneficiaries
                            receiversCountryName={receiversCountryName}
                            nextAssignmentId={nextAssignmentId}
                            eTag={eTag}
                            setEtag={setEtag}
                            beneficiariesPreviousData={
                              beneficiariesPreviousData
                            }
                            setSelectedBeneDetails={setSelectedBeneDetails}
                            setPurposeCodeList={setPurposeCodeList}
                            setPreviousData={setPreviousData}
                            setAmountPreviousData={setAmountPreviousData}
                            setExpanded={setExpanded}
                            setIsSecondAccordionComplete={
                              setIsSecondAccordionComplete
                            }
                            caseKey={caseKey}
                            previousData={previousData}
                            setEditableBeneDetails={setEditableBeneDetails}
                            editableBeneDetails={editableBeneDetails}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ border: "1px solid transparent" }}
                      expanded={expanded === "panel3"}
                      // onChange={handleAccordionChange("panel3")}
                      // disabled={!isSecondAccordionComplete}
                      disabled={expanded !== "panel3"}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          mt: 1,
                          borderRadius: 1,
                          height: "80px",
                          border:
                            expanded === "panel3"
                              ? "1px solid #44318D"
                              : "1px solid #D9D9D9",
                          borderLeft:
                            expanded === "panel3"
                              ? "40px solid #44318D"
                              : "40px solid #D9D9D9",
                        }}
                      >
                        <Grid container>
                          <Grid item md={0.5} sx={{ pt: 4, pr: 2 }}>
                            <FactCheckOutlinedIcon />
                          </Grid>
                          <Grid item md={11} sx={{ pt: 3 }}>
                            <Box sx={{ display: "block" }}>
                              <Typography className={classes.Typo1}>
                                Review beneficiary details
                              </Typography>
                              <Typography
                                sx={{
                                  marginBottom: 3,
                                  color: "#c0c0c0",
                                  fontSize: "11px",
                                }}
                              >
                                Review the beneficiary which you want to send
                                payment.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {nextAssignmentId && selectedBeneDetails && (
                          <>
                            <ReviewBeneficiaryDetails
                              selectedBeneDetails={selectedBeneDetails}
                              purposeCodeList={purposeCodeList}
                              nextAssignmentId={nextAssignmentId}
                              eTag={eTag}
                              setEtag={setEtag}
                              setReviewDetails={setReviewDetails}
                              setPreviousData={setPreviousData}
                              previousData={previousData}
                              setBeneficiariesPreviousData={
                                setBeneficiariesPreviousData
                              }
                              setExpanded={setExpanded}
                              setAmountPreviousData={setAmountPreviousData}
                              handleStep={handleStep}
                              setPurposeCodeList={setPurposeCodeList}
                              setEditableBeneDetails={setEditableBeneDetails}
                              editableBeneDetails={editableBeneDetails}
                            />
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              )}
              {loading && <Loader />}
              {activeStep === 1 && (
                <>
                  {reviewDetails && Object.keys(reviewDetails).length > 0 && (
                    <Review
                      nextAssignmentId={nextAssignmentId}
                      eTag={eTag}
                      setEtag={setEtag}
                      reviewDetails={reviewDetails}
                      setFinalResponse={setFinalResponse}
                      setPreviousData={setPreviousData}
                      handleStep={handleStepV1}
                      handleBack={handleBack}
                      setBeneficiariesPreviousData={
                        setBeneficiariesPreviousData
                      }
                      setAmountPreviousData={setAmountPreviousData}
                      setPurposeCodeList={setPurposeCodeList}
                    />
                  )}
                </>
              )}
              {activeStep === 2 && (
                <>
                  <FinalResponse finalResponseData={finalResponse} />
                </>
              )}
              <Grid container>
                <Grid size={{ md: 12 }} sx={{ m: "10px" }}></Grid>
              </Grid>
            </Paper>
          </Box>
        </>
      ) : repeatpay ? (
        <RepeatTransaction repeatdata={state} handleCancel={handleCancel} />
      ) : (
        <Payagain paydata={state} handleCancel={handleCancel} />
      )}
    </>
  );
};

export default TransferAmount;
