import React from "react";
import useStyles from "./styles";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Select,
  Avatar,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { getPaymentsListDashboard } from "../../../CommonService/commonAppService";
import { useState, useEffect } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import endPoints, { BASE_URL } from "../../../../config/Api";
import axios from "axios";
import {
  refreshAccessToken,
  getAccessToken,
  isTokenExpired,
} from "../../../Authorization/Services/authService";
import { Link, useNavigate } from "react-router-dom";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  getVisibleIndicators,
  getAccounts,
} from "../../../CommonUtils/CommonUtils";
import { getAccountsLIst } from "../../../CommonService/commonAppService";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Circle as CircleIcon,
} from "@mui/icons-material";
import Loader from "../../Loader";
import { fetchAccountsData } from "../../../CommonService/sendPaymentService";

const Dashboard = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage] = useState(1);
  const [page, setPage] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState("");

  const navigate = useNavigate();
  const [selectedMode, setSelectedMode] = useState("monthly"); // Default to monthly
  const [piechartdata, setPieChartData] = useState([]);

  const cardData = [
    { label: "Transfer amount", icon: <SendOutlinedIcon /> },
    { label: "Beneficiaries", icon: <PersonOutlineOutlinedIcon /> },
    { label: "All transactions ", icon: <PaymentsOutlinedIcon /> },
  ];

  const COLORS = [
    "#8E44AD",
    "#F39C12",
    " #27AE60",
    " #3498DB",
    "#BDC3C7",
    "#E74C3C",
  ];
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [availableMonths, setAvailableMonths] = useState([]);

  const getMonthAbbreviation = (monthNumber, year) => {
    return new Date(year, monthNumber - 1, 1).toLocaleString("default", {
      month: "short",
    });
  };
  useEffect(() => {
    getAccountsLIst(localStorage.getItem("userIdentifier"))
      .then((res) => {
        const data = res?.data?.data;
        setAccounts(data);
        setAccount(data[0]?.AccountNumber);
        accountOnBlur(data[0]?.AccountNumber);
      })
      .catch((err) => console.log(err));

    const fetchData = async () => {
      try {
        const response = await fetchAccountsData();
        let pxResults = getAccounts(response?.data?.data);
        setAccounts(pxResults);

        setAccount(pxResults[0]?.AccountNumber);
        accountOnBlur(pxResults[0]?.AccountNumber);
      } catch (error) {
        console.error("Error fetching accounts data:", error.status);
        localStorage.clear();
        navigate("/login");
      }
    };

    // fetchData();
  }, [navigate]);

  useEffect(() => {
    const getAvailableMonths = (year) => {
      let months = [];

      if (year === currentYear) {
        setSelectedMonth(currentMonth);
        if (currentMonth >= 6) {
          const startMonth = currentMonth - 5;
          for (let i = startMonth; i <= currentMonth; i++) {
            let month = i;
            if (i <= 0) {
              month = 12 + i;
            }
            months.push(i);
          }
        } else {
          for (let i = 1; i <= currentMonth; i++) {
            months.push(i);
          }
        }
        setSelectedMonth(currentMonth);
      } else if (year < currentYear) {
        const startMonth = 12 - 6;
        for (let i = startMonth + 1; i <= 12; i++) {
          const month = i;
          months.push(month);
        }
        const prevMonth = Math.max(...months);
        setSelectedMonth(prevMonth);
        //const prevMonthAbrev = getMonthAbbreviation(prevMonth, selectedYear);
        getPaymentsListDashboard(userName, account, prevMonth, selectedYear)
          .then((response) => {
            setPieChartData(response?.data?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      setAvailableMonths(months);
    };

    getAvailableMonths(selectedYear);
  }, [selectedYear, currentMonth]);

  const handleModeChange = (event) => {
    const newMode = event.target.value;

    setSelectedMode(newMode);
    if (newMode === "yearly") {
      setSelectedMonth(null);
      getPaymentsListDashboard(userName, account, "", selectedYear)
        .then((response) => {
          setPieChartData(response?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectedMonth(currentMonth);
      setSelectedYear(currentYear);

      //const monthName = getMonthAbbreviation(currentMonth, selectedYear);

      getPaymentsListDashboard(userName, account, currentMonth, currentYear)
        .then((response) => {
          setPieChartData(response?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Generate years dynamically (e.g., from 2020 to the current year)
  const years = Array.from({ length: 2 }, (_, index) => currentYear - index);

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    //const monthName = getMonthAbbreviation(newMonth, currentYear);
    setSelectedMonth(newMonth);

    getPaymentsListDashboard(userName, account, newMonth, selectedYear)
      .then((response) => {
        setPieChartData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    //const monthName = getMonthAbbreviation(selectedMonth, newYear);
    if (selectedMode === "yearly") {
      setSelectedMonth(null);
      getPaymentsListDashboard(userName, account, "", newYear)
        .then((response) => {
          setPieChartData(response?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getPaymentsListDashboard(userName, account, selectedMonth, newYear)
        .then((response) => {
          setPieChartData(response?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const fetchData = async () => {
    try {
      let token = getAccessToken();

      if (isTokenExpired(token)) {
        token = await refreshAccessToken();
      }
      setLoading(true);
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.recentPayments.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      localStorage.clear();
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dateConvert = (isoString) => {
    const date = new Date(isoString);
    // Define options for date and time formatting
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    // Format the date and time
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);
    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 1).toUpperCase();
  };

  const totalPages = 6;

  const handleNextPage = () => {
    if ((page + 1) * rowsPerPage < data.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const userName = localStorage.getItem("userIdentifier");
  const accountOnBlur = async (ac = "") => {
    // const monthName = getMonthAbbreviation(selectedMonth, selectedYear);

    getPaymentsListDashboard(userName, ac, selectedMonth, selectedYear)
      .then((response) => {
        setPieChartData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const DashboardList = piechartdata?.map((data) => ({
    label: data?.ReceivedAmountCurrency,
    value: data?.ReceivedAmount,
  }));
  const sortedData = DashboardList?.sort(
    (currentValue, nextValue) =>
      Number(nextValue.value) - Number(currentValue.value)
  );
  const sampleData = sortedData ? sortedData?.slice(0, 6) : [];

  return (
    <>
      <div className={classes.pageHeader}>Dashboard</div>
      <div>
        <Paper className={classes.rootContainer}>
          <Box style={{ marginLeft: "100%" }}>
            <SettingsOutlinedIcon />
          </Box>

          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 5, xl: 5 }}>
              <Typography className={classes.mainHeading}>
                Quick actions
              </Typography>
              {cardData.map((item, index) => (
                <Card
                  key={index}
                  className={classes.quickActionsCard}
                  style={{ marginLeft: "10px" }}
                >
                  <CardContent className={classes.quickActionsCardContent}>
                    <div className={classes.flexCenterGap}>
                      {item.icon}
                      <Typography className={classes.subHeading}>
                        {item.label}
                      </Typography>
                    </div>
                    <Link
                      to={
                        index === 0
                          ? "/internationaltransfer/transfer"
                          : index === 1
                          ? "/internationaltransfer/beneficiaries"
                          : "/internationaltransfer/transactions"
                      }
                    >
                      <div>
                        <ArrowForwardIcon className={classes.icons} />
                      </div>
                    </Link>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            <Grid size={{ xs: 12, md: 7, xl: 7 }}>
              <Typography className={classes.mainHeading}>
                Recent payment status
              </Typography>
              <Grid
                container
                size={{ md: 12, xl: 12 }}
                className={classes.flexAlignCenter}
              >
                {loading ? (
                  <Grid
                    size={{ md: 12, xl: 12 }}
                    className={classes.loader}
                    position={"relative"}
                  >
                    <Loader />
                  </Grid>
                ) : (
                  <Grid
                    size={{ md: 12, xl: 12 }}
                    style={{ position: "relative" }}
                  >
                    <IconButton
                      onClick={handlePreviousPage}
                      disabled={page === 0}
                      className={classes.carouselLeftBtn}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    {data
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        let steps;
                        const stepsRejected = [
                          "Initiated",
                          "In Progress",
                          "To Be Sent",
                          "Payment Delayed",
                          "Rejected",
                        ];

                        const stepsSent = [
                          "Initiated",
                          "In Progress",
                          "To Be Sent",
                          "Payment Delayed",
                          "Rejected",
                          "Sent",
                        ];

                        if (
                          row?.Status === "Resolved-RejectedBank" ||
                          row?.Status === "Resolved-RejectedSWIFT"
                        ) {
                          steps = stepsRejected;
                        } else {
                          steps = stepsSent;
                        }

                        let activeSteps = [];

                        switch (row?.Status) {
                          case "New-InProcess":
                            activeSteps = [0]; // Only Initiated step ticked
                            break;
                          case "Pending-InTransit":
                            activeSteps = [0, 1];
                            break;
                          case "Pending-PastDueBank":
                            activeSteps = [0, 1, 2];
                            break;
                          case "Pending-PastDueSWIFT":
                            activeSteps = [0, 1, 2, 3]; // All steps ticked
                            break;
                          case "Resolved-RejectedBank":
                            activeSteps = [0, 1, 2, 3, 4];
                            break;
                          case "Resolved-RejectedSWIFT":
                            activeSteps = [0, 1, 2, 3, 4];
                            break;
                          case "Resolved-Delivered":
                            activeSteps = [0, 1, 2, 3, 4, 5];
                            break;
                          default:
                            activeSteps = [];
                        }
                        const formatted_Payment_amount = parseFloat(
                          row.PaymentAmount
                        ).toFixed(2);

                        return (
                          <Card
                            key={index}
                            style={{
                              margin: "10px 40px 0px 40px",
                            }}
                            className={classes.recentPayments}
                          >
                            <CardContent className={classes.statusCardContent}>
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6, xl: 6 }}>
                                  <Typography
                                    variant="h6"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row?.BeneficiaryName}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Estimated time {row?.EstimatedDeliveryTime}
                                  </Typography>
                                </Grid>
                                <Grid
                                  size={{ xs: 12, md: 6 }}
                                  style={{ textAlign: "right" }}
                                >
                                  <Typography className={classes.mainHeading}>
                                    {row?.PaymentCurrency?.includes("JPY")
                                      ? parseFloat(row.PaymentAmount).toFixed(0)
                                      : formatted_Payment_amount}
                                    {/* {row?.PaymentAmount} */}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {row?.PaymentCurrency} to{" "}
                                    {row?.ReceivedAmountCurrency}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardContent
                              style={{
                                backgroundColor: "#DDDDDD",
                              }}
                              className={classes.statusCardContent}
                            >
                              <Grid
                                container
                                size={{ xs: 12, md: 12, xl: 12 }}
                                style={{
                                  padding: "10px",
                                  borderRadius: "8px",
                                }}
                              >
                                <Box sx={{ width: "100%" }}>
                                  <Stepper
                                    activeStep={
                                      activeSteps.length
                                        ? activeSteps[activeSteps.length - 1]
                                        : 0
                                    }
                                    className={classes.stepperStyle}
                                    alternativeLabel
                                  >
                                    {steps.map((label, index) => (
                                      <Step
                                        key={label}
                                        completed={activeSteps.includes(index)}
                                      >
                                        <StepLabel>{label}</StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>
                                </Box>
                              </Grid>
                            </CardContent>
                          </Card>
                        );
                      })}
                    {data && data.length > 0 && (
                      <Grid
                        container
                        size={{ md: 12, xl: 12 }}
                        sx={{ marginTop: "15px" }}
                      >
                        <Grid
                          size={{ md: 12, xl: 12 }}
                          className={classes.flexCenter}
                        >
                          {getVisibleIndicators(6, page, totalPages)?.map(
                            (indicator, index) => {
                              return (
                                <CircleIcon
                                  key={index}
                                  sx={{
                                    color: indicator === page ? "#000" : "#ccc",
                                  }}
                                  className={classes.carouselIcons}
                                />
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <IconButton
                      onClick={handleNextPage}
                      className={classes.carouselRightBtn}
                      disabled={page === 5}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.spacing}>
            <Grid size={{ xs: 12, md: 5, xl: 5 }}>
              <Typography className={classes.mainHeading}>
                Payment overview
              </Typography>
              <div className={`${classes.flexCenterGap} ${classes.spacing}`}>
                <Typography className={classes.subHeading}>
                  Select an account
                </Typography>
                <Select
                  value={account || ""}
                  // fullWidth
                  onChange={(e) => {
                    setAccount(e.target.value);
                    accountOnBlur(e.target.value);
                  }}
                  //onBlur={accountOnBlur}

                  displayEmpty
                  sx={{
                    width: "65%",
                    height: "40px",
                  }}
                >
                  <MenuItem value="" disabled>
                    Select an account
                  </MenuItem>

                  {accounts &&
                    accounts.map((acc) => (
                      <MenuItem
                        key={acc.AccountNumber}
                        value={acc.AccountNumber}
                      >
                        {acc.MaskAccountNumber}
                      </MenuItem>
                    ))}
                </Select>
              </div>

              <div className={`${classes.flexCenterGap} ${classes.spacing}`}>
                <RadioGroup
                  row
                  value={selectedMode}
                  onChange={handleModeChange}
                  className={classes.flexCenterGap}
                >
                  <FormControlLabel
                    value="monthly"
                    control={<Radio size="small" />}
                    label={
                      <Typography className={classes.subHeading}>
                        Monthly
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="yearly"
                    control={<Radio size="small" />}
                    label={
                      <Typography className={classes.subHeading}>
                        Yearly
                      </Typography>
                    }
                  />
                </RadioGroup>
                {selectedMode === "monthly" && (
                  <Select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    disabled={selectedMode === "yearly"}
                    placeholder="Month"
                    sx={{
                      height: "40px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 150,
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                        },
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    {availableMonths.map((month) => (
                      <MenuItem key={month} value={month}>
                        {new Date(currentYear, month - 1).toLocaleString(
                          "default",
                          { month: "short" }
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                )}

                <Select
                  value={selectedYear}
                  onChange={handleYearChange}
                  style={{
                    height: "40px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 150,
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                      },

                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    },
                  }}
                  placeholder="Year"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <PieChart
                series={[
                  {
                    data: sampleData,
                    cx: 120,
                    cy: 160,
                    innerRadius: 70,
                    outerRadius: 100,
                  },
                ]}
                colors={COLORS}
                width={650}
                height={300}
                slotProps={{
                  legend: {
                    hidden: false,
                    position: "left", // Position the legend to the right
                    layout: "vertical", // Layout to be vertical
                  },
                }}
              />
            </Grid>

            <Grid size={{ xs: 12, md: 7, xl: 7 }}>
              <Typography
                className={classes.mainHeading}
                sx={{ marginBottom: "20px" }}
              >
                Direct debits
              </Typography>
              {loading ? (
                <Grid size={{ md: 12 }} className={classes.loader}>
                  <Loader />
                </Grid>
              ) : (
                data?.slice(0, 6)?.map((row, index) => {
                  const initials = getInitials(row.BeneficiaryName);
                  const formatted_Payment_amount = parseFloat(
                    row.PaymentAmount
                  ).toFixed(2);
                  return (
                    <Card
                      key={index}
                      style={{ padding: "5px", marginLeft: "10px" }}
                    >
                      <CardContent className={classes.futurePayments}>
                        <Grid container>
                          <Grid
                            size={{ xs: 12, md: 12, xl: 12 }}
                            className={classes.flexCenterGap}
                          >
                            <Grid size={{ xs: 12, md: 0.5, xl: 0.5 }}>
                              <Avatar className={classes.avatar}>
                                <span className={classes.avatarInitial}>
                                  {initials}
                                </span>
                              </Avatar>
                            </Grid>
                            <Grid size={{ xs: 12, md: 3, xl: 3 }}>
                              <Typography className={classes.subHeading}>
                                {row?.BeneficiaryName}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 12, md: 4, xl: 4 }}>
                              <Typography className={classes.subHeading}>
                                {dateConvert(row?.DateOfPayment)}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 12, md: 1.5, xl: 1.5 }}>
                              <Typography className={classes.subHeading}>
                                {row?.PaymentCurrency?.includes("JPY")
                                  ? parseFloat(row.PaymentAmount).toFixed(0)
                                  : formatted_Payment_amount}
                                {/* {row?.PaymentAmount} */}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 12, md: 2.5, xl: 2.5 }}>
                              <Typography className={classes.subHeading}>
                                {row?.PaymentCurrency} to{" "}
                                {row?.ReceivedAmountCurrency}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 12, md: 0.5, xl: 0.5 }}>
                              <ArrowForwardIcon />
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default Dashboard;
