import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  rootContainer: {
    width: "70vw !important ",
    marginTop: "10px",
    padding: "10px 40px 30px 40px",
  },
  tabStyles: {
    display: "flex",
    zIndex: "99999",
    position: "fixed",
    marginTop: "-105px",
    alignItems: "center",
    gap: "15px",
    // margin: "20px 0 0 70px",
  },
  tabs: {
    flexGrow: 1,
    display: "flex !important ",
    justifyContent: "flex-start !important ",
  },
  tabHeading: {
    textTransform: "none !important",
    color: "#000 !important",
    fontSize: "14px !important",
    paddingBottom: "0px !important",
    "&.Mui-selected": {
      color: "var(--primary-color) !important",
    },
  },
  quickActionsHeader: {
    fontSize: "18px !important",
    lineHeight: "1.2 !important",
    fontWeight: "600 !important ",
  },
  quickActionsCard: {
    marginTop: "15px !important",
    width: "90% !important",
  },
  quickActionsCardContent: {
    border: "1px solid #e0e0e0 !important",
    borderRadius: "5px !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    padding: "16px !important",
    "&:hover": {
      border: "2px solid var(--primary-color) !important",
    },
  },
  quickActionsItems: {
    display: "flex !important",
    gap: "10px !important",
  },
  recentPayments: {
    border: "1px solid #e0e0e0 !important",
    borderRadius: "5px !important",
    //padding: "30px !important",
  },
  futurePayments: {
    border: "1px solid #e0e0e0 !important",
    borderRadius: "10px !important",
    padding: "10px !important",
  },
  avatarInitial: {
    fontSize: "14px",
  },
  content: {
    // padding:"20px 0px 0px 0px"
  },
  flexCenter: {
    display: "flex !important ",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "3px !important",
  },
  carouselIcons: {
    width: "12px !important ",
    height: "12px !important ",
  },
}));
export default useStyles;
