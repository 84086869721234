import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import Flag from "react-world-flags";
import { getCountryCode } from "../../CommonUtils/CommonUtils";

const CountryFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();

  const { Country } = props;
  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Country
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          name="Country"
          value={Country ? Country : "NA"}
          disabled
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment>
                  <Flag
                    code={getCountryCode(Country)}
                    className={classes.countryFlag}
                  />
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
    </>
  );
};

export default CountryFeild;
