import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import companyLogo from "../../../assets/images/EPP-logo.png";
const BeneficiaryNameField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    flag,
    handleChange,
    checkErrors,
    disableFlag,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const validationRules = {
    FullName: {
      maxLength: 140,
      errorMessage: "Beneficiary Name cannot exceed 140 characters.",
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;

    const maxLength = validationRules[name].maxLength;

    // Allow one extra character but show error if exceeded
    // if (value.length > maxLength + 1) {
    //   setLocalErrors((prev) => ({
    //     ...prev,
    //     [name]: validationRules[name].errorMessage,
    //   }));
    //   return; // Prevent further input beyond one extra character
    // }

    // Show error if within one extra character
    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    handleChange(event);
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Full Name
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.FullName}
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(localErrors.FullName || checkErrors("FullName"))}
            helperText={localErrors.FullName || checkErrors("FullName")}
            // onChange={handleChange}
            // error={Boolean(checkErrors("FullName"))}
            // helperText={checkErrors("FullName")}
            name="FullName"
            disabled={disableFlag}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneNameURL && (
                      <img
                        src={beneDetails?.IsValidBeneNameURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
            fullWidth
            // className={classes.textWidth}
          />
        ) : beneDetails?.FullName ? (
          <span className={classes.contentstyle}>
            {beneDetails?.FullName}&nbsp;
            {flag &&
              beneDetails?.IsValidBeneNameURL &&
              beneDetails?.FullName && (
                <img
                  src={beneDetails?.IsValidBeneNameURL}
                  className={classes.checkMark}
                />
              )}
          </span>
        ) : (
          "NA"
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryNameField;
