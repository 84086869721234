import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import HelpIcon from "@mui/icons-material/Help";
import Switch from "@mui/material/Switch";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  payAgainProcessSubmit,
  sendPaymentFinalSubmit,
} from "../../CommonService/sendPaymentService";
import { sendPaymentCancelApi } from "../../CommonService/commonAppService";
import { getPurposeCodeListApi } from "../../CommonService/commonAppService";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import warningIcon from "../../../assets/images/warningicon.svg";
import CountrySpecificComponent from "../SendPayment/CountrySpecificComponent";
import Loader from "../InternationTransfer/Loader";
import Flag from "react-world-flags";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import FinalResponse from "../InternationTransfer/TransferAmount/Finalresponse";

const RepeatTransaction = ({ repeatdata, handleCancel }) => {
  const classes = useStyles();
  const location = useLocation();

  // const { state } = location;
  const [accounts, setAccounts] = useState([]);
  const [payAgainData] = useState(repeatdata);
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [setValidateCurrency] = useState("");
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [payAgainEtag] = useState(repeatdata?.etag);
  const [editableBeneDetails] = useState(
    repeatdata?.response?.data.caseInfo?.content?.SelectedBeneDetails
  );
  const [editDetails] = useState(repeatdata?.response?.data.caseInfo?.content);
  const [actionName, setActionName] = useState(
    repeatdata?.response?.data.caseInfo?.content?.pyViewName
  );
  const [finalResponse, setFinalResponse] = useState();
  const [isFinalResponse, setIsFinalResponse] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkbox, setcheckbox] = useState({
    PayChargeCmtIndicator: false,
    ReviewConfirmAck: false,
  });
  const [checkCount, setcheckCount] = useState(0);

  const [radioValue, setRadioValue] = useState("");
  const [cancelPaymentId, setCancelPaymentId] = useState();
  const cancelPayment = async (evt) => {
    setRadioValue(evt.target.value);
  };
  useEffect(() => {
    if ("RepeatTransaction" === actionName) {
      setDisable(true);
    }
  }, [actionName]);

  const [checkbox_Details] = useState(
    repeatdata?.response?.uiResources.resources.views?.[actionName]?.[0]
      ?.children[0].children
  );

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const matchCountries = (countryName) => {
    for (const [code, fullCountryName] of Object.entries(countriesShow)) {
      if (countryName === countriesShow[code]) {
        return code;
      }
    }
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        acc[data.config.value.replace(/^@P \./, "")] = false;
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  function getAccounts(response) {
    const list = response.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const getCountryCode = (countryName) => {
    const normalizedCountry = countryName.trim().toLowerCase();
    let countryObj = iso3166.all().find((item) => {
      const isoCountryName = item.country.toLowerCase();

      return isoCountryName === normalizedCountry;
    });

    if (!countryObj) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.trim().toLowerCase();
        return isoCountryName.includes(normalizedCountry);
      });
    }

    return countryObj.alpha2;
  };

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setcheckbox((check) => ({
      ...check,
      [name]: checked,
    }));
    setcheckCount((prev) => (checked ? prev + 1 : prev - 1));
  };

  useEffect(() => {
    setnextAssignmentId(payAgainData?.response?.nextAssignmentInfo?.ID);
    setCaseId(payAgainData?.response?.data.caseInfo?.ID.split(" ")[1]);
    setAvailableToPayCountryNamesList(
      getCountryNamesList(payAgainData?.response?.data)
    );
    let pxResults = getAccounts(payAgainData?.response?.data);
    setAccounts(pxResults);
    setAvailableToPayCurrencyList(
      payAgainData?.response?.data.shared.D_AvailabletoPayCurrencyListAPIList
        .D_AvailabletoPayCurrencyListAPIList.pxResults
    );
    setEtag(payAgainEtag);

    setAccount(payAgainData?.response?.data.caseInfo?.content?.FromAccount);
    matchCountries(
      payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
    );
    setflagselect(
      matchCountries(
        payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
      )
    );
    const option = {
      label: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
      value: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
    };
    setSelectedCurrency(option);
    setShowExchangeFields(true);
    if (payAgainData?.response?.data?.shared?.D_PurposeCodeList) {
      setPurposeCodeList(
        getPurposeCodeList(payAgainData?.response.data.shared)
      );
    } else {
      setPurposeCodeList("");
    }
    if (payAgainData?.response?.data.caseInfo?.content?.POPVisibility) {
      getPurposeCodeListApi(
        payAgainData?.response?.data.caseInfo?.content?.PaymentInfo?.CountryCode
      ).then((res) => {
        setPurposeCodeList(res?.data?.data);
      });
    }
    const newDetails = payAgainData?.response?.data.caseInfo?.content;
    setCancelPaymentId(payAgainData?.response?.data.caseInfo?.ID);
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.SendAmount);
    if (!isNaN(amount) && !error?.amountYouSendError) {
      getDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
          });
          setShowExchangeFields(true);
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        });
    }
  };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleReceiveAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.ReceiveAmount);
    if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
      getDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          SendAmount: resData.SendAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiverCurrencyChange = (option) => {
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    let amountError = "";
    if (sendAmount <= 0) {
      amountError = "Send Amount must be a valid number greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };
  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount must be a valid number greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
      // } else if (receiveAmount > accountDetails?.AccountBalance) {
      //   amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const handleRemarksChange = (event) => {
    //setRemarks(event.target.value);
    if (event.target.value.length <= 140) {
      setRemarks(event.target.value);
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Value should be less than 140 characters.",
      }));
    }
  };

  const validateForm = () => {
    const amountError = validateAmount();
    const amountReceiveError = validateReceiveAmount();
    const remarksError = validateRemarks();

    if (amountError || remarksError || amountReceiveError) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
        amountReceiveError: amountReceiveError,
      });

      return false;
    }

    handleSubmit();
    return true;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const payLoad = {
        content: {
          EnterAmount: "",
          ReceiveAmount: accountDetails.ReceiveAmount,
          ReceiveCurrency: accountDetails.ReceiveCurrency,
          ReceiversCountryName: accountDetails.ReceiversCountryName,
          SendAmount: accountDetails.SendAmount,
          ConfirmAcknowledgement: checkbox.ReviewConfirmAck,
          PayAllCharges: checkbox.PayChargeCmtIndicator,
          Remarks: remarks,
          UserAcceptanceFlag: radioValue === "Yes" ? true : false,
          Payment_instruction_validation:
            accountDetails?.Payment_instruction_validation,
        },
        pageInstructions: [],
      };
      const response = await payAgainProcessSubmit(
        payLoad,
        nextAssignmentId,
        eTag,
        actionName
      );
      if (response.status === 200 && radioValue == "Yes") {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          setFinalResponse(res?.data?.data?.caseInfo);
          setIsFinalResponse(true);
        }
      } else {
        const res = await sendPaymentCancelApi(cancelPaymentId, eTag);
        if (res.status === 200) {
          setLoading(false);
          setIsFinalResponse(true);
        }
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && <CurrencyFlag currency={value} />}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  // const handleCancel = () => {
  //   navigate("/internationaltransfer/transactions");
  // };

  return (
    <>
      {loading && <Loader />}

      {!isFinalResponse && (
        <>
          <Box className={classes.rootContainer}>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography
                className={classes.title}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                {actionName === "RepeatTransaction" && "Resend"}
              </Typography>
            </Grid>
            <Box sx={{ padding: "5px 0px 0px 10px" }}>
              {/* <div className={classes.helpIconCont}>
                    <HelpIcon className={classes.helpIcon} />
                  </div> */}
              <Grid container>
                <Grid size={{ md: 6, xs: 12 }}>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <AccountBalanceIcon />
                    <Typography className={classes.Typo1}>
                      {" "}
                      Account details
                    </Typography>
                  </Box>
                  <Grid container spacing={1} sx={{ p: "10px 0px 0px 20px" }}>
                    <Grid size={{ xs: 12, md: 12 }} pl={"5px"}>
                      <Typography className={classes.Typo3}>
                        Debit account
                      </Typography>
                    </Grid>
                    <Box
                      sx={{
                        background: " #faf7f7",
                        borderRadius: "10px",
                        p: "10px",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item size={{ md: 6 }}>
                          Name
                        </Grid>
                        <Grid item size={{ md: 6 }}>
                          Country
                        </Grid>
                        <Grid item size={{ md: 12 }}>
                          {" "}
                          {accounts &&
                            accounts.map((acc) => {
                              if (acc.AccountNumber.includes(account)) {
                                return acc.MaskAccountNumber;
                              }
                              return null;
                            })}
                        </Grid>
                        <Grid item size={{ md: 6 }}>
                          CreditCard
                        </Grid>
                        <Grid item size={{ md: 6 }} sx={{ display: "flex" }}>
                          <Typography variant="body2">
                            Balance &nbsp;
                          </Typography>
                          <Typography variant="body2">
                            {accountDetails.AccountBalance}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                {account && (
                  <>
                    <Grid item size={{ md: 5 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ pt: "30px", display: "block" }}
                      >
                        <Typography
                          className={classes.Typo3}
                          sx={{ pb: "10px" }}
                        >
                          Beneficiary account
                        </Typography>
                        <Box
                          sx={{
                            background: " #faf7f7",
                            borderRadius: "10px",
                            p: "10px",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item size={{ md: 12 }}>
                              {accountDetails?.SelectedBeneDetails?.FullName}
                            </Grid>

                            <Grid item size={{ md: 12 }} mt={"1px"}>
                              {" "}
                              {accountDetails?.SelectedBeneDetails?.CdtrAcctId}
                            </Grid>
                            <Grid
                              item
                              size={{ md: 12 }}
                              mt={"3px"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {accountDetails?.SelectedBeneDetails?.Country}
                              <CurrencyFlag
                                currency={accountDetails?.SendCurrency}
                                style={{
                                  width: "25px  ",
                                  height: "15px  ",
                                  margin: "0px 0px 0px 5px",
                                }}
                                //className={classes.CurrencyFlag}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
                {/* </Grid> */}
              </Grid>

              {account ? (
                <>
                  <Grid container className={classes.spacing}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <LocalAtmOutlinedIcon />
                      <Typography className={classes.Typo1}>
                        {" "}
                        Payment details
                      </Typography>
                    </Box>
                    <Grid container spacing={1} sx={{ pt: "10px" }} pl={"20px"}>
                      <Grid item size={{ md: 3 }} pl={"8px"}>
                        <Typography className={classes.Typo3}>
                          {" "}
                          Amount you want to send
                        </Typography>
                        <Typography className={classes.Typo2}>
                          {" "}
                          {accountDetails?.SendAmount} &nbsp;{" "}
                          {accountDetails?.SendCurrency} &nbsp;
                          <CurrencyFlag
                            currency={accountDetails?.SendCurrency}
                            style={{ height: "11px", width: " 17px" }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item size={{ md: 3 }}>
                        <Typography className={classes.Typo3}>
                          {" "}
                          Amount beneficiary receive
                        </Typography>
                        <Typography className={classes.Typo2}>
                          {" "}
                          {accountDetails?.ReceiveAmount} &nbsp;{" "}
                          {accountDetails?.ReceiveCurrency} &nbsp;
                          <CurrencyFlag
                            currency={accountDetails?.ReceiveCurrency}
                            style={{ height: "11px", width: " 17px" }}
                          />
                        </Typography>
                      </Grid>
                      {purposeCodeList &&
                        accountDetails?.PurposeofPaymentVisibility && (
                          <Grid item size={{ md: 3 }}>
                            <Typography className={classes.Typo3}>
                              {" "}
                              Purpose of payment
                            </Typography>
                            <TextField
                              id="PurposeCode"
                              variant="outlined"
                              value={purposeCode}
                              size="small"
                              onChange={handlePurposeCode}
                              slotProps={{
                                input: {
                                  className: `${classes.purposeCodeDropdown} ${classes.remarks}`,
                                },
                              }}
                              select
                              fullWidth
                            >
                              {purposeCodeList &&
                                purposeCodeList.map((pCode) => (
                                  <MenuItem
                                    key={pCode.PayPurPCodeDesc}
                                    value={pCode.PayPurPCodeDesc}
                                  >
                                    {pCode.PayPurPCode}
                                  </MenuItem>
                                ))}
                            </TextField>
                            {error.purposeCodeError && (
                              <div className="error-text">
                                {error.purposeCodeError}
                              </div>
                            )}
                          </Grid>
                        )}
                      <Grid item size={{ md: 3 }}>
                        <Typography className={classes.Typo3}>
                          {" "}
                          Additional Payment Info
                        </Typography>
                        <TextField
                          id="remarks"
                          size="small"
                          multiline
                          rows={1}
                          value={remarks}
                          onChange={handleRemarksChange}
                          fullWidth
                          slotProps={{
                            input: {
                              maxLength: 140,
                              //className: classes.borderstyle,
                            },
                          }}
                          className={classes.remarks}
                        />
                        {error.remarksError && (
                          <div className="error-text">{error.remarksError}</div>
                        )}
                      </Grid>
                      {loading ? (
                        <Loader />
                      ) : (
                        showExchangeFields && (
                          <Grid
                            size={{ xs: 12, md: 11 }}
                            sx={{
                              background: "#faf7f7",
                              borderRadius: "10px",
                              p: "15px",
                            }}
                            container
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={3}>
                                <Typography className={classes.Typo3}>
                                  Exchange Rate:&nbsp;
                                  <span className={classes.Typo2}>
                                    {accountDetails?.CurrencyConvDesc}
                                  </span>
                                </Typography>
                              </Grid>

                              {/* Divider */}
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ borderLeft: "1px solid", mx: 2 }}
                              />

                              <Grid item xs={3}>
                                <Typography className={classes.Typo3}>
                                  Exchange Rate Valid For:&nbsp;
                                  <span className={classes.Typo2}>
                                    {accountDetails?.FXRateGoodForText}
                                  </span>
                                </Typography>
                              </Grid>

                              {/* Divider */}
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ borderLeft: "1px solid", mx: 2 }}
                              />

                              <Grid item xs={3}>
                                <Typography className={classes.Typo3}>
                                  Estimated Delivery Time:&nbsp;
                                  <span className={classes.Typo2}>
                                    {accountDetails?.EstimatedDeliveryTime}
                                  </span>
                                </Typography>
                              </Grid>

                              {/* Divider */}
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ borderLeft: "1px solid", mx: 2 }}
                              />

                              <Grid item xs={3}>
                                <Typography className={classes.Typo3}>
                                  Transaction Fee:&nbsp;
                                  <span className={classes.Typo2}>
                                    {accountDetails?.TransactionDesc}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>

                  <Box sx={{ display: "flex", gap: "10px", pt: "10px" }}>
                    <LocalAtmOutlinedIcon />
                    <Typography className={classes.Typo1}>
                      {" "}
                      Beneficiary details
                    </Typography>
                  </Box>
                  <div style={{ padding: "0px 0px 0px 20px" }}>
                    <CountrySpecificComponent
                      editableBeneDetails={editableBeneDetails}
                      payAgainOrRepeatTransaction={true}
                      flag={true}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              {accountDetails?.Payment_instruction_validation &&
                accountDetails?.Payment_instruction_validation === "ERROR" &&
                checkbox_Details.map((data, index) => {
                  if (data?.type === "RadioButtons") {
                    return (
                      <Grid
                        container
                        className={classes.spacing}
                        spacing={0.5}
                        sx={{ pt: "10px" }}
                      >
                        <Grid
                          size={{ md: 12 }}
                          className={classes.flexCenterAlign}
                          sx={{
                            display: "flex",
                            ml: 1.3,
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={warningIcon}
                            width={20}
                            height={20}
                            style={{}}
                          />
                          &nbsp;&nbsp;
                          <Typography className={classes.message}>
                            {data.config.label?.replace(/@L\s*/, "")}
                          </Typography>
                          &nbsp;&nbsp;
                          <FormControl sx={{ display: "contents" }}>
                           

                            <Typography
                              className={classes.message}
                              sx={{ mr: 1 }}
                            >
                              No
                            </Typography>

                            <FormControlLabel
                              value="Yes"
                              onClick={cancelPayment}
                              control={<Switch color="primary" />}
                              label={
                                <Typography className={classes.message}>
                                  Yes
                                </Typography>
                              }
                            />
                            {/* <Switch checked={radioValue} onChange={cancelPayment} /> */}
                          </FormControl>
                        </Grid>
                        {/* <Grid size={{ md: 2 }}></Grid> */}
                      </Grid>
                    );
                  }

                  return null;
                })}

              <div
                style={{
                  display: "flex",
                  marginTop: "10px ",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ color: "var(--primary-color)" }}
                  name="ReviewConfirmAck"
                  checked={checkbox.ReviewConfirmAck}
                  onChange={handleCheckbox}
                />
                <Typography
                  style={{ fontSize: "12px ", fontFamily: "Open sans" }}
                >
                  {editDetails?.ReviewConfirmAck}
                </Typography>
              </div>
              {editDetails?.PayChargeCmtIndicator &&
                editDetails?.IsSwiftGo === false && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      style={{
                        color: "var(--primary-color)",
                        marginTop: "10px",
                      }}
                      name="PayChargeCmtIndicator"
                      checked={checkbox.PayChargeCmtIndicator}
                      onChange={handleCheckbox}
                    />
                    <Typography
                      style={{ fontSize: "12px ", fontFamily: "Open sans" }}
                    >
                      {editDetails?.PayChargeCmtIndicator}
                    </Typography>
                  </div>
                )}
              <Grid container spacing={2} className={classes.spacingCont}>
                <Grid size={{ xs: 12, md: 12 }} className={classes.flexBetween}>
                  <Button className={classes.cancel} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.continue}
                    disabled={
                      // !checkedItems.ConfirmAcknowledgement ||
                      // !checkedItems.PayAllCharges ||
                      // !radioValue
                      checkCount !== 1 && checkCount !== 2
                    }
                    onClick={validateForm}
                  >
                    Review & Pay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
      {isFinalResponse && <FinalResponse finalResponseData={finalResponse} />}
    </>
  );
};

export default RepeatTransaction;
