import React from "react";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: " rgba(0, 0, 0, 0.5)",
        width: "100vw",
        height: " 100vh",
      }}
    >
      <CircularProgress sx={{ color: "var(--primary-color)" }} />
    </div>
  );
};

export default Loader;
