import React, { useState } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import FormControl from "@mui/material/FormControl";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useDispatch, useSelector } from "react-redux";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import AccountNumberFeild from "./CommonFields/AccountNumberFeild";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import NickNameField from "./CommonFields/NickNameField";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import ABARoutingNumberField from "./CommonFields/ABARoutingNumberField";
import AddressMessage from "./CommonFields/AddressMessage";
import { useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CountryFeild from "./CommonFields/CountryFeild";
import InfoIcon from "@mui/icons-material/Info";
import { setClearBICFI as clearBICForAddNewbene } from "../../Redux/Reducers/addNewBeneficiaryReducer";
import { setClearBICForEditBene } from "../../Redux/Reducers/editBeneficiaryReducer";
import { setClearBICForSendPaymentFlow } from "../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";
const Usa = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();
  const [disableField, setDisableField] = useState({
    BICFI: false,
    ClrSysMmbId: false,
  });

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    setInitialDetails,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    handleChange,
    handleRefreshInputBlur,
    //below is for edit beneficiary
    editBene,
    //below is for add beneficiary in send payment flow
    addNewBeneSendPaymentFlow,
  } = props;
  const state = useSelector((state) => state);
  const [localErrors, setLocalErrors] = useState({});
  const dispatch = useDispatch();
  const newBeneData = addNewBeneSendPaymentFlow
    ? state?.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state?.addNewBeneficiary;

  const clearBIC = addNewBeneSendPaymentFlow
    ? setClearBICForSendPaymentFlow
    : editBene
    ? setClearBICForEditBene
    : clearBICForAddNewbene;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const validationRules = {
    ClrSysMmbId: {
      maxLength: 9,
      errorMessage: `ABA Routing number should be exactly 9 digits.`,
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    if (name == "ClrSysMmbId" && value == "") {
      dispatch(clearBIC());
    }
    handleChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name, value } = event.target;
    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    // if (localErrors.ClrSysMmbId == "")
    handleRefreshInputBlur(event);
  };

  const handleLocalValidationEdit = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    if (name == "ClrSysMmbId" && value == "") {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        BICFI: "",
      }));
    }
    handleInputChange(event); // Pass the change to parent
  };

  const handleFieldBlurEdit = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    handleEditChange(event);
  };

  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;
    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;

  const handleEditChange = async (event) => {
    const { name, value } = event.target;

    if (name === "BICFI" || name === "ClrSysMmbId") {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI") {
          setDisableField({
            ClrSysMmbId: true,
          });
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        } else if (name === "ClrSysMmbId") {
          setDisableField({
            BICFI: true,
          });
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            ClrSysMmbId:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.ClrSysMmbId,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <PayToCountryFeild countryCode={SelectedBeneDetails?.CountryCode} />
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb}
              </Typography>
            </Grid>

            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            {/* <Typography variant="body2" className={classes.headerstyle}>
                ABA Routing Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.ClrSysMmbId}
              </Typography> */}
            <ABARoutingNumberField SelectedBeneDetails={SelectedBeneDetails} />
            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankNameField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location?.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  size={{ md: 12 }}
                >
                  <InfoIcon className={classes.infoIcon} />
                  <span>&nbsp;Enter BIC Code or ABA Number.</span>
                </Grid>
                <AccountNumberFeild
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <FormControl fullWidth>
                    <Typography variant="body2" className={classes.headerstyle}>
                      ABA Routing Number
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      name="ClrSysMmbId"
                      value={newBeneData?.formState?.ClrSysMmbId}
                      //error={Boolean(checkErrors("ClrSysMmbId"))}
                      //helperText={checkErrors("ClrSysMmbId")}
                      //onChange={handleChange}
                      disabled={
                        newBeneData?.submitFlag ||
                        newBeneData?.formState?.ClrSysMmbId
                          ? newBeneData?.submitFlag
                          : newBeneData?.formState?.BICFI
                      }
                      //onBlur={handleRefreshInputBlur}
                      onChange={handleLocalValidation}
                      onBlur={handleFieldBlur}
                      error={Boolean(
                        localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                      )}
                      helperText={
                        localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                      }
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment>
                              {newBeneData?.formState?.IsValidABANumberURL && (
                                <img
                                  src={
                                    newBeneData?.formState?.IsValidABANumberURL
                                  }
                                  className={classes.checkMark}
                                />
                              )}
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid container size={{ md: 12 }}> */}
                <BeneficiaryBankBICCodeField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  flag={true}
                  country={newBeneData?.formState?.Country}
                />
                {/* ||newBeneData?.formState?.ClrSysMmbId */}
                {newBeneData?.formState?.BeneBankName && (
                  <BeneficiaryBankNameField
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                )}
                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                    addEditBene={true}
                  />
                )}
                {/* </Grid> */}
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />
                  <NickNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
                handleChange={handleInputChange}
                checkErrors={checkErrors}
              />
              <AccountNumberFeild
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
                handleChange={handleInputChange}
                checkErrors={checkErrors}
              />
              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />
              {isEditable && (
                <Grid
                  sx={{ display: "flex", alignItems: "center" ,pl:1}}
                  size={{ md: 12 }}
                >
                  <InfoIcon className={classes.infoIcon} />
                  <span>&nbsp;Enter BIC Code or ABA Number.</span>
                </Grid>
              )}
              <NickNameField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                checkErrors={checkErrors}
                disableFlag={editableBeneDetails?.ClrSysMmbId}
              />
              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  ABA Routing Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={
                      editableBeneDetails?.submitFlag ||
                      editableBeneDetails?.ClrSysMmbId
                        ? editableBeneDetails?.submitFlag
                        : editableBeneDetails?.BICFI
                    }
                    // disabled={disableField.ClrSysMmbId}
                    value={editableBeneDetails?.ClrSysMmbId}
                    onChange={handleLocalValidationEdit}
                    onBlur={handleFieldBlurEdit}
                    error={Boolean(
                      localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                    )}
                    helperText={
                      localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                    }
                    // onChange={handleInputChange}
                    // onBlur={handleEditChange}
                    name="ClrSysMmbId"
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {flag &&
                              editableBeneDetails?.IsValidBeneBICCodeURL && (
                                <img
                                  src={
                                    editableBeneDetails?.IsValidBeneBICCodeURL
                                  }
                                  className={classes.checkMark}
                                />
                              )}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.ClrSysMmbId
                      ? editableBeneDetails?.ClrSysMmbId
                      : "NA"}{" "}
                    &nbsp;
                    {flag && editableBeneDetails?.ClrSysMmbId && (
                      <img
                        src={editableBeneDetails?.IsValidABANumberURL}
                        className={classes.checkMark}
                        alt="checkmark"
                      />
                    )}
                  </span>
                )}
              </Grid>
              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}
              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
              />
              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Usa;
