import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpIcon from "@mui/icons-material/Help";
import {
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataForReceiversCountryName,
  payAgainProcessSubmit,
  sendPaymentFinalSubmit,
  debouncedGetDataForSendAmount,
  debouncedGetDataForReceiverAmount,
} from "../../CommonService/sendPaymentService";
import { getPurposeCodeListApi } from "../../CommonService/commonAppService";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import FinalResponse from "../MobileSendPayment/FinalResponse";
import warningIcon from "../../../assets/images/warningicon.svg";
import CountrySpecificComponent from "../MobileSendPayment/CountrySpecificComponent";
import Loader from "../Loader";
import { sendPaymentCancelApi } from "../../CommonService/commonAppService";
import Flag from "react-world-flags";

const PayAgain = () => {
  const classes = useStyles();
  const location = useLocation();

  const { state } = location;
  const [accounts, setAccounts] = useState([]);
  const [payAgainData] = useState(state);
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [setValidateCurrency] = useState("");
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [payAgainEtag] = useState(state?.etag);
  const [editableBeneDetails] = useState(
    state?.response?.data.caseInfo?.content?.SelectedBeneDetails
  );
  const [editDetails] = useState(state?.response?.data.caseInfo?.content);
  const [PayChargeCmtIndicator, setPayChargeCmtIndicator] = useState(
    state?.response?.data.caseInfo?.content?.PayChargeCmtIndicator
  );
  const [actionName, setActionName] = useState(
    state?.response?.data.caseInfo?.content?.pyViewName
  );

  const [finalResponse, setFinalResponse] = useState();
  const [isFinalResponse, setIsFinalResponse] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visible, setvisibile] = useState(true);
  const [transaction, settransaction] = useState(true);
  const [accDetails, setaccDetails] = useState(true);
  const [icon, seticon] = useState(false);
  const [removeicon, setremoveicon] = useState(false);
  const [accIcon, setaccIcon] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [checkbox, setcheckbox] = useState({ check1: false, check2: false });
  const [checkCount, setcheckCount] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [cancelPaymentId, setCancelPaymentId] = useState();

  const cancelPayment = async (evt) => {
    setRadioValue(evt.target.value);
  };
  useEffect(() => {
    if ("RepeatTransaction" === actionName) {
      setDisable(true);
    }
  }, [actionName]);

  const [checkbox_Details] = useState(
    state?.response?.uiResources.resources.views?.[actionName]?.[0]?.children[0]
      .children
  );

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const matchCountries = (countryName) => {
    for (const [code, fullCountryName] of Object.entries(countriesShow)) {
      if (countryName === countriesShow[code]) {
        return code;
      }
    }
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        acc[data.config.value.replace(/^@P \./, "")] = false;
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  function getAccounts(response) {
    const list = response.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const getCountryCode = (countryName) => {
    const normalizedCountry = countryName.trim().toLowerCase();
    let countryObj = iso3166.all().find((item) => {
      const isoCountryName = item.country.toLowerCase();

      return isoCountryName === normalizedCountry;
    });

    if (!countryObj) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.trim().toLowerCase();
        return isoCountryName.includes(normalizedCountry);
      });
    }

    return countryObj.alpha2;
  };

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setcheckbox((check) => ({
      ...check,
      [name]: checked,
    }));
    setcheckCount((prev) => (checked ? prev + 1 : prev - 1));
  };

  useEffect(() => {
    setnextAssignmentId(payAgainData?.response?.nextAssignmentInfo?.ID);
    setCaseId(payAgainData?.response?.data.caseInfo?.ID.split(" ")[1]);
    setAvailableToPayCountryNamesList(
      getCountryNamesList(payAgainData?.response?.data)
    );
    let pxResults = getAccounts(payAgainData?.response?.data);
    setAccounts(pxResults);
    setAvailableToPayCurrencyList(
      payAgainData?.response?.data.shared.D_AvailabletoPayCurrencyListAPIList
        .D_AvailabletoPayCurrencyListAPIList.pxResults
    );
    setEtag(payAgainEtag);

    setAccount(payAgainData?.response?.data.caseInfo?.content?.FromAccount);
    matchCountries(
      payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
    );
    setflagselect(
      matchCountries(
        payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
      )
    );
    const option = {
      label: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
      value: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
    };
    setSelectedCurrency(option);
    setShowExchangeFields(true);
    if (payAgainData?.response?.data?.shared?.D_PurposeCodeList) {
      setPurposeCodeList(
        getPurposeCodeList(payAgainData?.response.data.shared)
      );
    } else {
      setPurposeCodeList("");
    }
    if (payAgainData?.response?.data.caseInfo?.content?.POPVisibility) {
      getPurposeCodeListApi(
        payAgainData?.response?.data.caseInfo?.content?.PaymentInfo?.CountryCode
      ).then((res) => {
        setPurposeCodeList(res?.data?.data);
      });
    }
    const newDetails = payAgainData?.response?.data.caseInfo?.content;
    setCancelPaymentId(payAgainData?.response?.data.caseInfo?.ID);
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag, true)
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
          SendAmount: "",
          ReceiveAmount: "",
        });
      })
      .catch((error) => {
        if (error.status != "401") {
        } else {
          navigate("/login");
        }
      });
  };

  function updatePurposeCodeListForJapan(
    resData,
    setPurposeCodeList,
    getPurposeCodeList,
    response
  ) {
    if ("Japan" === resData.ReceiversCountryName) {
      setPurposeCodeList(
        resData.ReceiveAmount > 1000000
          ? getPurposeCodeList(response?.data?.data?.shared)
          : ""
      );
    }
  }

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoadingAccounts(true);
    let formattedValue;
    if (value && !value?.includes(".")) {
      formattedValue = `${value}.00`;
    }
    const amount = formattedValue || value;
    if (!isNaN(amount) && !error?.amountYouSendError) {
      debouncedGetDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
            EstimatedDeliveryTime: resData?.EstimatedDeliveryTime,
            POPVisibility: resData.POPVisibility,
            Payment_instruction_validation:
              resData?.Payment_instruction_validation,
          });
          setPayChargeCmtIndicator(resData.PayChargeCmtIndicator);
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        })
        .finally(() => {
          setLoadingAccounts(false);
        });
    }
  };

  // const handleAmountInputBlur = () => {
  //   const amount = parseFloat(accountDetails.SendAmount);
  //   if (!isNaN(amount) && !error?.amountYouSendError) {
  //     getDataForSendAmount(
  //       account,
  //       amount,
  //       accountDetails.ReceiveCurrency,
  //       accountDetails.ReceiversCountryName,
  //       flagselect,
  //       nextAssignmentId,
  //       eTag,
  //       actionName
  //     )
  //       .then((response) => {
  //         const resData = response.data.data.caseInfo.content;
  //         setAccountDetails({
  //           ...accountDetails,
  //           CurrencyConvDesc: resData.CurrencyConvDesc,
  //           FXRateGoodForText: resData.FXRateGoodForText,
  //           TransactionDesc: resData.TransactionDesc,
  //           SendAmount: resData.SendAmount,
  //           ReceiveAmount: resData.ReceiveAmount,
  //         });
  //         setShowExchangeFields(true);
  //       })
  //       .catch((error) => {
  //         if (error.status == "401") {
  //           navigate("/login");
  //         } else {
  //           console.log("Promise rejected with error:", error.message);
  //         }
  //       });
  //   }
  // };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoadingAccounts(true);
    if (parsedValue === 0 || value === 0) {
      setLoading(false);
    }
    if ((!isNaN(value) && parsedValue) || value === "") {
      debouncedGetDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        value,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            ReceiveAmount: resData.ReceiveAmount,
            SendAmount: resData.SendAmount,
            EstimatedDeliveryTime: resData?.EstimatedDeliveryTime,
            POPVisibility: resData.POPVisibility,
            Payment_instruction_validation:
              resData?.Payment_instruction_validation,
          });
          setPayChargeCmtIndicator(resData.PayChargeCmtIndicator);
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          console.error("Error fetching receiver amount:", error);
        })
        .finally(() => {
          setLoading(false);
          setLoadingAccounts(false);
        });
    }
  };

  // const handleReceiveAmountInputBlur = () => {
  //   const amount = parseFloat(accountDetails.ReceiveAmount);
  //   if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
  //     getDataForReceiverAmount(
  //       account,
  //       accountDetails.SendAmount,
  //       amount,
  //       accountDetails.ReceiveCurrency,
  //       accountDetails.ReceiversCountryName,
  //       flagselect,
  //       nextAssignmentId,
  //       eTag,
  //       actionName
  //     ).then((response) => {
  //       const resData = response.data.data.caseInfo.content;
  //       setAccountDetails({
  //         ...accountDetails,
  //         CurrencyConvDesc: resData.CurrencyConvDesc,
  //         FXRateGoodForText: resData.FXRateGoodForText,
  //         TransactionDesc: resData.TransactionDesc,
  //         ReceiveAmount: resData.ReceiveAmount,
  //         SendAmount: resData.SendAmount,
  //       });
  //       setShowExchangeFields(true);
  //     });
  //   }
  // };

  const handleReceiverCurrencyChange = (option) => {
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    }
    if (!visible) {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };

  const TransactioinVisible = () => {
    if (transaction) {
      settransaction(false);
    } else {
      settransaction(true);
    }
    setremoveicon((prev) => !prev);
  };

  const accountVisible = () => {
    if (accDetails) {
      setaccDetails(false);
    } else {
      setaccDetails(true);
    }
    setaccIcon((prev) => !prev);
  };

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    let amountError = "";
    if (sendAmount <= 0) {
      amountError = "Send Amount must be a valid number greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };

  const validatePurposeCode = () => {
    if (accountDetails?.POPVisibility) {
      return purposeCode === "" ? "Value cannot be blank." : "";
    } else {
      return false;
    }
  };

  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount must be a valid number greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
      // } else if (receiveAmount > accountDetails?.AccountBalance) {
      //   amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const validateForm = () => {
    const amountError = validateAmount();
    const amountReceiveError = validateReceiveAmount();
    const remarksError = validateRemarks();
    const purposeCodeError = validatePurposeCode();

    if (amountError || remarksError || amountReceiveError) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
        amountReceiveError: amountReceiveError,
        purposeCodeError: purposeCodeError,
      });

      return false;
    }

    handleSubmit();
    return true;
  };

  const handleSubmit = async () => {
    try {
      const payLoad = {
        content: {
          EnterAmount: "",
          ReceiveAmount: accountDetails.ReceiveAmount,
          ReceiveCurrency: accountDetails.ReceiveCurrency,
          ReceiversCountryName: accountDetails.ReceiversCountryName,
          SendAmount: accountDetails.SendAmount,
          ConfirmAcknowledgement:
            /* checkedItems.ConfirmAcknowledgement */ checkbox.check1,
          PayAllCharges: /* checkedItems.PayAllCharges */ checkbox.check2,
          Remarks: remarks,
        },
        pageInstructions: [],
      };
      const response = await payAgainProcessSubmit(
        payLoad,
        nextAssignmentId,
        eTag,
        actionName
      );
      if (response.status === 200 && radioValue == "Yes") {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          navigate("/moneysent", {
            state: {
              response: res?.data?.data?.caseInfo,
            },
          });
          // setFinalResponse(res?.data?.data?.caseInfo);
          setIsFinalResponse(true);
        }
      } else {
        const res = await sendPaymentCancelApi(cancelPaymentId, eTag);
        if (res.status === 200) {
          setLoading(false);
          navigate("/moneysent");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && (
        <CurrencyFlag currency={value} className={classes.CurrencyFlag} />
      )}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  const handleCancel = () => {
    navigate("/recentpayments");
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!isFinalResponse && (
            <>
              <Box className={classes.rootContainer}>
                <Grid container>
                  <Grid size={{ md: 12, xs: 12 }}>
                    <Typography
                      className={classes.title}
                      variant="h6"
                      color="textPrimary"
                      gutterBottom
                    >
                      {actionName === "PayAgain" && "Pay Again"}
                    </Typography>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={`${classes.header} ${classes.headerDetails}`}
                >
                  <Typography variant="body2" className={classes.headerBene}>
                    Account Details
                  </Typography>
                  {!accIcon && (
                    <RemoveCircleRoundedIcon
                      onClick={accountVisible}
                      fontSize="small"
                    />
                  )}
                  {accIcon && (
                    <AddCircleRoundedIcon
                      onClick={accountVisible}
                      fontSize="small"
                    />
                  )}
                </Grid>
                {accDetails && (
                  <Box className={classes.headerContent}>
                    <div className={classes.helpIconCont}>
                      <HelpIcon className={classes.helpIcon} />
                    </div>
                    <Grid container>
                      <Grid size={{ xs: 12 }}>
                        <FormControl fullWidth variant="standard">
                          <InputLabel className={classes.inputLabel}>
                            Select Account To Make Payment From:
                          </InputLabel>
                          <Select
                            id="Account"
                            value={account}
                            onChange={handleAccountChange}
                            inputProps={{ className: classes.acctInput }}
                            label="Select Account To Make Payment From"
                            disableUnderline
                            displayEmpty
                          >
                            {accounts &&
                              accounts.map((acc) => (
                                <MenuItem
                                  key={acc.AccountNumber}
                                  value={acc.AccountNumber}
                                >
                                  {acc.MaskAccountNumber}{" "}
                                  {/* Masked account number shown */}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid size={{ xs: 12 }} className={classes.spacing}>
                        {account && (
                          <>
                            <Typography className={classes.headerstyle}>
                              Available Balance
                            </Typography>
                            <Typography
                              className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                            >
                              {accountDetails.AccountBalance}
                              &nbsp;
                              <CurrencyFlag
                                currency={accountDetails?.SendCurrency}
                                className={classes.CurrencyFlag}
                              />
                              &nbsp;
                              <span className={classes.Sendcurrency}>
                                {accountDetails?.SendCurrency}
                              </span>
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={`${classes.header} ${classes.headerDetails}`}
                >
                  <Typography variant="body2" className={classes.headerBene}>
                    Transaction Details
                  </Typography>
                  {!removeicon && (
                    <RemoveCircleRoundedIcon
                      onClick={TransactioinVisible}
                      fontSize="small"
                    />
                  )}
                  {removeicon && (
                    <AddCircleRoundedIcon
                      onClick={TransactioinVisible}
                      fontSize="small"
                    />
                  )}
                </Grid>

                {account ? (
                  <>
                    {transaction && (
                      <Box>
                        <Grid container className={classes.spacing}>
                          <Grid size={{ md: 5, xs: 12 }}>
                            <Typography className={classes.headerstyle}>
                              Pay to Country
                            </Typography>
                            <Typography
                              className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                            >
                              {accountDetails.ReceiversCountryName}
                              &nbsp;
                              <Flag
                                code={getCountryCode(
                                  accountDetails.ReceiversCountryName
                                )}
                                className={classes.CountryFlag}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.spacingCont}>
                          <Grid size={{ md: 12, xs: 12 }}>
                            <Typography
                              variant="body2"
                              className={classes.info}
                            >
                              Enter either "Amount You Send" or "Beneficiary
                              Gets". The other amount field will be
                              automatically calculated.
                            </Typography>
                          </Grid>
                          <Grid className={classes.flexCenterAlign}>
                            <Typography
                              variant="body2"
                              className={classes.headerstyle}
                            >
                              Amount You Send
                            </Typography>
                            &nbsp;
                            <Tooltip title="Enter the amount that you want to send in the currency of your account. The beneficiary will receive the equivalent of this amount.">
                              <HelpOutlineOutlinedIcon
                                className={classes.tooltip}
                                fontSize="small"
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.spacingCont}>
                          <Grid size={{ xs: 12 }}>
                            <TextField
                              variant="outlined"
                              size="small"
                              type="number"
                              fullWidth
                              onChange={handleAmountInputChange}
                              // onBlur={handleAmountInputBlur}
                              value={accountDetails.SendAmount}
                              className={classes.numberField}
                              slotProps={{
                                input: {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CurrencyFlag
                                        currency={accountDetails.SendCurrency}
                                        className={classes.CurrencyFlag}
                                      />
                                      <span className={classes.send}>
                                        &nbsp;{accountDetails.SendCurrency}
                                      </span>
                                    </InputAdornment>
                                  ),
                                  className: classes.textfeild,
                                },
                              }}
                            />
                            {error.amountYouSendError && (
                              <div className="error-text">
                                {error.amountYouSendError}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container className={classes.spacingCont}>
                          <Grid className={classes.flexCenterAlign}>
                            <Typography
                              variant="body2"
                              className={classes.headerstyle}
                            >
                              Beneficiary Gets
                            </Typography>
                            &nbsp;
                            <Tooltip title="Enter the amount that you want the beneficiary to receive. Your account will be debited for the equivalent amount, plus the transaction fee.">
                              <HelpOutlineOutlinedIcon
                                className={classes.tooltip}
                                fontSize="small"
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid size={{ xs: 12 }}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              type="number"
                              onChange={handleReceiveAmountInputChange}
                              // onBlur={handleReceiveAmountInputBlur}
                              className={classes.numberField}
                              value={
                                accountDetails.ReceiveCurrency.includes("JPY")
                                  ? accountDetails.ReceiveAmount &&
                                    !isNaN(
                                      parseFloat(accountDetails.ReceiveAmount)
                                    )
                                    ? parseFloat(
                                        accountDetails.ReceiveAmount
                                      ).toFixed(0)
                                    : ""
                                  : accountDetails.ReceiveAmount
                              }
                              slotProps={{
                                input: {
                                  className: classes.textfeild,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <FormControl fullwidth variant="standard">
                                        <SelectFeild
                                          options={options}
                                          value={selectedCurrency}
                                          onChange={
                                            handleReceiverCurrencyChange
                                          }
                                          formatOptionLabel={formatOptionLabel}
                                          isSearchable={true}
                                          isDisabled={true}
                                          placeholder="Select Currency"
                                          className={classes.currencyCodeSelect}
                                          classNamePrefix="custom"
                                          styles={{
                                            control: (provided) => ({
                                              ...provided,
                                              width: "130px",
                                              height: "6vh",
                                              backgroundColor: "transparent",
                                              borderRadius: "8px",
                                              border: "none",
                                              boxShadow: "none",
                                              "&:hover": {
                                                border: "none",
                                              },
                                            }),
                                          }}
                                        />
                                      </FormControl>
                                    </InputAdornment>
                                  ),
                                },
                              }}
                            />
                          </Grid>
                          {error.amountReceiveError && (
                            <div className="error-text">
                              {error.amountReceiveError}
                            </div>
                          )}
                        </Grid>
                        {loadingAccounts ? (
                          <Loader />
                        ) : (
                          showExchangeFields && (
                            <Grid
                              container
                              className={`${classes.spacing} ${classes.exchangefields}`}
                            >
                              <Grid
                                size={{ xs: 12 }}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Transaction Fee
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputBase}>
                                    &nbsp;&nbsp;
                                    {accountDetails?.TransactionDesc}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                size={{ xs: 12 }}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Exchange Rate
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputBase}>
                                    &nbsp;&nbsp;
                                    {accountDetails?.CurrencyConvDesc}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                size={{ xs: 12 }}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Exchange Rate Valid For
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputBase}>
                                    &nbsp;&nbsp;
                                    {accountDetails?.FXRateGoodForText}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                size={{ xs: 12 }}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Estimated Delivery Time
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputBase}>
                                    &nbsp;&nbsp;
                                    {accountDetails.EstimatedDeliveryTime}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                        <Grid container spacing={1}>
                          {purposeCodeList && accountDetails?.POPVisibility && (
                            <>
                              <Grid
                                size={{ xs: 12, md: 5 }}
                                className={classes.purpose}
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.headerstyle}
                                >
                                  Purpose of Payment
                                </Typography>

                                <TextField
                                  id="PurposeCode"
                                  variant="outlined"
                                  value={purposeCode}
                                  size="small"
                                  onChange={handlePurposeCode}
                                  slotProps={{
                                    input: {
                                      className: `${classes.purposeCodeDropdown} ${classes.remarks}`,
                                    },
                                  }}
                                  //label="Select Pay Purpose"
                                  select
                                  fullWidth
                                >
                                  {purposeCodeList &&
                                    purposeCodeList.map((pCode) => (
                                      <MenuItem
                                        key={pCode.PayPurPCodeDesc}
                                        value={pCode.PayPurPCodeDesc}
                                      >
                                        {pCode.PayPurPCode}
                                      </MenuItem>
                                    ))}
                                </TextField>
                                {error.purposeCodeError && (
                                  <div className="error-text">
                                    {error.purposeCodeError}
                                  </div>
                                )}
                              </Grid>
                              <Grid siz={{ md: 2 }}></Grid>
                            </>
                          )}
                          <Grid
                            size={{ md: 6, xs: 12 }}
                            className={classes.purpose}
                          >
                            <Typography
                              variant="body2"
                              className={classes.headerstyle}
                            >
                              Additional Payment Info
                            </Typography>
                            <TextField
                              id="remarks"
                              size="small"
                              fullwidth
                              value={remarks}
                              className={classes.remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                              slotProps={{
                                input: {
                                  className: `${classes.borderstyle} ${classes.remarksText}`,
                                },
                              }}
                            />
                            {error.remarksError && (
                              <div className="error-text">
                                {error.remarksError}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Grid
                      className={`${classes.header} ${classes.headerDetails}`}
                    >
                      <Typography
                        variant="body2"
                        className={classes.headerBene}
                      >
                        Beneficiary and Bank Details
                      </Typography>
                      {!icon && (
                        <RemoveCircleRoundedIcon
                          onClick={handlevisibility}
                          fontSize="small"
                        />
                      )}
                      {icon && (
                        <AddCircleRoundedIcon
                          onClick={handlevisibility}
                          fontSize="small"
                        />
                      )}
                    </Grid>
                    {visible && (
                      <Box>
                        <CountrySpecificComponent
                          editableBeneDetails={editableBeneDetails}
                          payAgainOrRepeatTransaction={true}
                          flag={true}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Box>
                  {accountDetails?.Payment_instruction_validation &&
                    accountDetails?.Payment_instruction_validation ===
                      "ERROR" &&
                    checkbox_Details.map((data, index) => {
                      if (data?.type === "RadioButtons") {
                        return (
                          <Grid
                            container
                            className={classes.spacing}
                            spacing={0.5}
                          >
                            <Grid
                              size={{ md: 12 }}
                              className={classes.flexCenterAlign}
                            >
                              <span>
                                <img
                                  src={warningIcon}
                                  className={classes.warningIcon}
                                />
                              </span>
                              &nbsp;&nbsp;
                              <Typography className={classes.message}>
                                {data.config.label?.replace(/@L\s*/, "")}
                              </Typography>
                            </Grid>
                            <Grid size={{ md: 12 }} ml={5} pr={30}>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                >
                                  <div style={{ display: "flex" }}>
                                    <FormControlLabel
                                      value="Yes"
                                      control={<Radio size="small" />}
                                      onClick={cancelPayment}
                                      label={
                                        <Typography
                                          className={classes.contentstyle}
                                        >
                                          Yes
                                        </Typography>
                                      }
                                    />
                                    <FormControlLabel
                                      value="No"
                                      control={<Radio size="small" />}
                                      onClick={cancelPayment}
                                      label={
                                        <Typography
                                          className={classes.contentstyle}
                                        >
                                          No
                                        </Typography>
                                      }
                                    />
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        );
                      }
                      // if (data?.type === "Checkbox") {
                      //   const checkboxName = data?.config?.value.replace(
                      //     /^@P \./,
                      //     ""
                      //   );
                      //   return (
                      //     <Box key={data.id} className={classes.spacing}>
                      //       <Grid className={classes.flex}>
                      //         <Checkbox
                      //           name={checkboxName}
                      //           className={classes.checkboxStyles}
                      //           checked={!!checkedItems[checkboxName]}
                      //           onChange={handleCheckbox}
                      //           style={{ color: "var(--primary-color) " }}
                      //         />
                      //         <Typography
                      //           variant="body2"
                      //           className={classes.textFont}
                      //         >
                      //           {data.config.caption.replace(/@L\s*/, "")}
                      //         </Typography>
                      //       </Grid>
                      //     </Box>
                      //   );
                      // }
                      return null;
                    })}
                </Box>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    style={{ color: "var(--primary-color)" }}
                    name="check1"
                    checked={checkbox.check1}
                    onChange={handleCheckbox}
                  />
                  <Typography
                    style={{
                      fontSize: "0.7rem ",
                      fontFamily: "Open sans",
                    }}
                  >
                    {editDetails?.ReviewConfirmAck}
                  </Typography>
                </div>

                {PayChargeCmtIndicator && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px ",
                      }}
                    >
                      <Checkbox
                        style={{ color: "var(--primary-color)" }}
                        name="check2"
                        checked={checkbox.check2}
                        onChange={handleCheckbox}
                      />
                      <Typography
                        style={{ fontSize: "0.7rem ", fontFamily: "Open sans" }}
                      >
                        {PayChargeCmtIndicator}
                      </Typography>
                    </div>
                  </>
                )}

                <Grid container spacing={2} className={classes.spacingCont}>
                  <Grid
                    size={{ xs: 12, md: 12 }}
                    className={classes.flexBetween}
                  >
                    <Button className={classes.cancel} onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.continue}
                      disabled={checkCount !== 1 && checkCount !== 2}
                      onClick={validateForm}
                    >
                      Review & Pay
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {/* {isFinalResponse && <FinalResponse finalResponse={finalResponse} />} */}
        </>
      )}
    </>
  );
};

export default PayAgain;
