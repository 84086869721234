import React from "react";
import Grid from "@mui/material/Grid2";
import { TextareaAutosize, Typography } from "@mui/material";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { useSelector } from "react-redux";
import { formattedAddress } from "../../CommonUtils/CommonUtils";

const BeneficiaryAddressReadOnly = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { reviewDetails, review } = props;
  return (
    <>
      {/* <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Address
        </Typography>
        <>
          {reviewDetails?.RecipentAddress ? (
            <span className={classes.contentstyle}>
              {formattedAddress(reviewDetails?.RecipentAddress)}
            </span>
          ) : (
            <div>
              {(reviewDetails?.SelectedBeneDetails?.BuildingNumber ||
                reviewDetails?.SelectedBeneDetails?.BuildingName ||
                reviewDetails?.BuildingNumber ||
                reviewDetails?.BuildingName) && (
                <span className={classes.contentstyle}>
                  {review
                    ? reviewDetails?.SelectedBeneDetails?.BuildingNumber
                    : reviewDetails?.BuildingNumber}

                  {review
                    ? reviewDetails?.SelectedBeneDetails?.BuildingName
                      ? ` - ${reviewDetails?.SelectedBeneDetails?.BuildingName}`
                      : ""
                    : reviewDetails?.BuildingName
                    ? ` - ${reviewDetails?.BuildingName}`
                    : ""}
                  <br />
                </span>
              )}

              {(reviewDetails?.SelectedBeneDetails?.StreetName ||
                reviewDetails?.SelectedBeneDetails?.TownName ||
                reviewDetails?.StreetName ||
                reviewDetails?.TownName) && (
                <span className={classes.contentstyle}>
                  {review
                    ? reviewDetails?.SelectedBeneDetails?.StreetName
                    : reviewDetails?.StreetName}

                  {review
                    ? reviewDetails?.SelectedBeneDetails?.TownName
                      ? `, ${reviewDetails?.SelectedBeneDetails?.TownName}`
                      : ""
                    : reviewDetails?.TownName
                    ? `, ${reviewDetails?.TownName}`
                    : ""}
                  <br />
                </span>
              )}

              {(reviewDetails?.SelectedBeneDetails?.Country ||
                reviewDetails?.SelectedBeneDetails?.PostCode ||
                reviewDetails?.ReceiversCountryName ||
                reviewDetails?.PostCode) && (
                <span className={classes.contentstyle}>
                  {review
                    ? reviewDetails?.SelectedBeneDetails?.Country
                    : reviewDetails?.Country}

                  {review
                    ? reviewDetails?.SelectedBeneDetails?.PostCode
                      ? ` - ${reviewDetails?.SelectedBeneDetails?.PostCode}`
                      : ""
                    : reviewDetails?.PostCode
                    ? ` - ${reviewDetails?.PostCode}`
                    : ""}
                  <br />
                </span>
              )}
            </div>
          )}
        </>
      </Grid> */}
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Building Number
        </Typography>
        <Typography className={classes.textFont}>
          {reviewDetails?.SelectedBeneDetails?.BuildingNumber
            ? reviewDetails?.SelectedBeneDetails?.BuildingNumber
            : "NA"}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Building Name
        </Typography>
        <Typography className={classes.textFont}>
          {reviewDetails?.SelectedBeneDetails?.BuildingName
            ? reviewDetails?.SelectedBeneDetails?.BuildingName
            : "NA"}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Street name
        </Typography>
        <Typography className={classes.textFont}>
          {reviewDetails?.SelectedBeneDetails?.StreetName
            ? reviewDetails?.SelectedBeneDetails?.StreetName
            : "NA"}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Town
        </Typography>
        <Typography className={classes.textFont}>
          {reviewDetails?.SelectedBeneDetails?.TownName
            ? reviewDetails?.SelectedBeneDetails?.TownName
            : "NA"}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Zip Code / Postal Code
        </Typography>
        <Typography className={classes.textFont}>
          {reviewDetails?.SelectedBeneDetails?.PostCode
            ? reviewDetails?.SelectedBeneDetails?.PostCode
            : "NA"}
        </Typography>
      </Grid>
    </>
  );
};

export default BeneficiaryAddressReadOnly;
