import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import endPoints, { BASE_URL } from "../../../../config/Api";
import axios from "axios";
import CurrencyFlag from "react-currency-flags";
import FilterListIcon from "@mui/icons-material/FilterList";
import RepeatIcon from "@mui/icons-material/Repeat";
import Alert from "@mui/material/Alert";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  checkBeneficiaryExists,
  createPayAgainOrRepeatTransactionCase,
} from "../../../CommonService/commonAppService";
import {
  refreshAccessToken,
  getAccessToken,
  isTokenExpired,
} from "../../../Authorization/Services/authService";
import { useNavigate } from "react-router-dom";
import ModalPopup from "./Dialog";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  CardActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Loader from "../../Loader";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Payagain from "../PayAgain/index";
import RepeatTransaction from "../../RepeatTransaction";

const colors = [
  "#0b8953",
  "#c23824",
  "#2c97de",
  "#00657c",
  "#00aa66",
  "#667700",
  "#e27100",
  "var(--primary-color)",
];

const RecentPayments = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState(null);
  const token = getAccessToken();
  const [open, setOpen] = useState(false);
  const [isPayAgainAlert, setIsPayAgainAlert] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(true);
  const [collapse, setCollapse] = useState(null);
  const [icon, setIcon] = useState(false);
  const [load, setLoad] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    try {
      let token = getAccessToken();

      if (isTokenExpired(token)) {
        token = await refreshAccessToken();
      }
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.recentPayments.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data);
      setSearchData(response?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      localStorage.clear();
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchRowClickData = async (row) => {
    setOpen(!open);
    setIsPayAgainAlert(false);

    const payload = {
      dataContext: "D_TransactionDetails",
      dataContextParameters: {
        TRN: row.TRN,
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/pages/PaymentTCContent`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const content = response?.data?.data?.dataInfo?.content;
      setSelectedRow(content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const _handleClose = () => {
    setOpen(false);
  };

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);

    if (searchValue === "") {
      fetchData();
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.BeneficiaryName &&
        row.BeneficiaryName?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setData(SearchedData);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dateConvert = (isoString) => {
    const date = new Date(isoString);
    // Define options for date and time formatting
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    // Format the date and time
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };
  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);
    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const [openAlertMessage, setOpenAlertMessage] = useState("");

  const handleActions = (row, actionName) => {
    try {
      checkBeneficiaryExists(row?.TRN).then((res) => {
        if (res.status === 200 && res?.data?.message?.includes("Success")) {
          createPayAgainOrRepeatTransactionCase(row?.TRN, actionName)
            .then((res) => {
              // actionName === "PayAgain"
              //   ? navigate("/payagain", {
              //       state: {
              //         response: res?.data,
              //         PayAgain: true,
              //         etag: res.headers["etag"],
              //       },
              //     })
              //   : navigate("/repeattransaction", {
              //       state: {
              //         response: res?.data,
              //         PayAgain: true,
              //         etag: res.headers["etag"],
              //       },
              //     });
              actionName === "PayAgain"
                ? navigate("/internationaltransfer/transfer", {
                    state: {
                      response: res?.data,
                      PayAgain: true,
                      etag: res.headers["etag"],
                    },
                  })
                : navigate("/internationaltransfer/transfer", {
                    state: {
                      response: res?.data,
                      PayAgain: true,
                      etag: res.headers["etag"],
                    },
                  });
            })
            .catch((error) => {
              console.log(error);
              if (error.status !== "401") {
                setApiError(error?.response?.data);
              } else {
                navigate("/login");
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setOpen(!open);
          setIsPayAgainAlert(true);
          setOpenAlertMessage(res?.data?.message);
        }
      });
    } catch (error) {
      console.error("Error checking beneficiary:", error);
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSendClick = () => {
    navigate("/sendpayment");
  };

  const handleClick = (index) => {
    setCollapse((prevIndex) => (prevIndex === index ? null : index));
    setIcon(false);
  };

  const checkStatus = async (row) => {
    setLoad(true);
    const payload = {
      dataViewParameters: {
        UETR: row?.UETR,
      },
    };
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // return \
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/D_CheckTranStatus`,
        payload,
        {
          headers: headers,
        }
      );
      setStatus(response?.data?.data[0]);
      setIcon(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <Grid container spacing={1} className={classes.rootContainer}>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography
                className={classes.title}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                <ArrowBackIcon
                  onClick={() => {
                    navigate("/internationaltransfer/dashboard");
                  }}
                  className={classes.backArrow}
                />{" "}
                All transactions
              </Typography>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={classes.searchContainer}
                >
                  <input
                    placeholder="Search for beneficiary by name"
                    className={classes.searchInput}
                    onChange={searchHandler}
                    onClick={() => {
                      setCollapse(null);
                    }}
                    value={searchText}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              {apiError && (
                <Alert
                  severity="error"
                  onClose={() => {
                    setApiError("");
                  }}
                >
                  {apiError}
                </Alert>
              )}

              {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const initials = getInitials(row.BeneficiaryName);
                  const backgroundColor = getColor(initials);
                  const formatted_amount = parseFloat(
                    row.ReceivedAmount
                  ).toFixed(2);
                  const formatted_Payment_amount = parseFloat(
                    row.PaymentAmount
                  ).toFixed(2);
                  return (
                    <Card
                      key={index}
                      className={classes.transactionCard}
                      //onClick={() => fetchRowClickData(row)}
                    >
                      <CardContent
                        className={`${classes.flexAlignCenter} ${classes.transactionContent}`}
                      >
                        <Grid size={{ md: 1 }}>
                          <Avatar
                            style={{ backgroundColor }}
                            className={classes.avatar}
                          >
                            {initials}
                          </Avatar>
                        </Grid>
                        <Grid size={{ md: 2 }} className={classes.flexColumn}>
                          <Typography className={classes.transactionDetail}>
                            {row?.BeneficiaryName}
                          </Typography>
                          <Typography className={classes.transactionGreyData}>
                            {dateConvert(row?.DateOfPayment)}
                          </Typography>
                        </Grid>
                        <Grid size={{ md: 2 }} className={classes.flexColumn}>
                          <div className={classes.flexAlignCenter}>
                            {row?.PaymentCurrency && (
                              <CurrencyFlag
                                currency={row?.PaymentCurrency}
                                className={classes.currencyFlag}
                              />
                            )}
                            &nbsp;&nbsp;
                            <Typography className={classes.transactionDetail}>
                              {row?.PaymentCurrency?.includes("JPY")
                                ? parseFloat(row.PaymentAmount).toFixed(0)
                                : formatted_Payment_amount}
                              &nbsp;&nbsp;{row?.PaymentCurrency}
                            </Typography>
                          </div>
                          <Typography className={classes.statusText}>
                            &nbsp;&nbsp;&nbsp;Debit amount
                          </Typography>
                        </Grid>
                        <Grid size={{ md: 2 }} className={classes.flexColumn}>
                          <div className={classes.flexAlignCenter}>
                            {row?.ReceivedAmountCurrency && (
                              <CurrencyFlag
                                currency={row?.ReceivedAmountCurrency}
                                className={classes.currencyFlag}
                              />
                            )}
                            &nbsp;&nbsp;
                            <Typography className={classes.transactionDetail}>
                              {row?.ReceivedAmountCurrency?.includes("JPY")
                                ? parseFloat(row?.ReceivedAmount).toFixed(0)
                                : formatted_amount}
                              &nbsp;&nbsp;{row?.ReceivedAmountCurrency}
                            </Typography>
                          </div>
                          <Typography className={classes.statusText}>
                            &nbsp;&nbsp;&nbsp;Credit amount
                          </Typography>
                        </Grid>
                        <Grid size={{ md: 1.3 }} className={classes.flexColumn}>
                          <div className={classes.flexAlignCenter}>
                            <img
                              src={row?.pyImage}
                              alt="img"
                              className={classes.imageIcon}
                              title={row?.ImageHover}
                            />
                            <Typography className={classes.transactionDetail}>
                              &nbsp;&nbsp;
                              {row?.Status == "New-InProcess" && "Initiated"}
                              {row?.Status == "Pending-InTransit" &&
                                "In Progress"}
                              {row?.Status == "Pending-PastDueBank" &&
                                "To Be Sent"}
                              {row?.Status == "Pending-PastDueSWIFT" &&
                                "Payment Delayed"}
                              {row?.Status == "Resolved-RejectedBank" &&
                                "Rejected by bank"}
                              {row?.Status == "Resolved-RejectedSWIFT" &&
                                "Rejected by Swift"}
                              {row?.Status == "Resolved-Delivered" && "Sent"}
                            </Typography>
                          </div>
                          <Typography className={classes.statusText}>
                            Status
                          </Typography>
                        </Grid>
                        {(row.Status === "Resolved-Delivered" ||
                          row.Status === "Completed") && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "13%",
                                textWrap: "nowrap",
                              }}
                            >
                              <Grid
                                size={{ md: 1.5 }}
                                className={`${classes.flexAlignCenter} ${classes.clickActions}`}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleActions(row, "PayAgain");
                                  setLoading(true);
                                }}
                              >
                                <SendOutlinedIcon
                                  className={classes.transactionIcons}
                                />
                                <Typography
                                  className={classes.transactionColorData}
                                >
                                  &nbsp;&nbsp;Send amount
                                </Typography>
                              </Grid>

                              <Grid
                                size={{ md: 3 }}
                                className={`${classes.flexAlignCenter} ${classes.clickActions}`}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleActions(row, "RepeatTransaction");
                                  setLoading(true);
                                }}
                              >
                                <RepeatIcon
                                  className={classes.transactionIcons}
                                />
                                <Typography
                                  className={classes.transactionColorData}
                                >
                                  &nbsp;&nbsp;Resend &nbsp;&nbsp;
                                  {row?.CalculationInitiatedField ===
                                  "ReceiveAmount" ? (
                                    <>
                                      {row?.ReceivedAmountCurrency?.includes(
                                        "JPY"
                                      )
                                        ? parseFloat(
                                            row?.ReceivedAmount
                                          ).toFixed(0)
                                        : formatted_amount}
                                      &nbsp;&nbsp;
                                      {row?.ReceivedAmountCurrency}
                                      {row?.ReceivedAmountCurrency && (
                                        <CurrencyFlag
                                          currency={row?.ReceivedAmountCurrency}
                                          className={classes.currencyFlag}
                                        />
                                      )}
                                    </>
                                  ) : row?.CalculationInitiatedField ===
                                    "SendAmount" ? (
                                    <>
                                      {row?.PaymentCurrency?.includes("JPY")
                                        ? parseFloat(row.PaymentAmount).toFixed(
                                            0
                                          )
                                        : formatted_Payment_amount}
                                      &nbsp;&nbsp;{row?.PaymentCurrency}
                                      &nbsp;&nbsp;
                                      {row?.PaymentCurrency && (
                                        <CurrencyFlag
                                          currency={row?.PaymentCurrency}
                                          className={classes.currencyFlag}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                              </Grid>
                            </div>
                          </>
                        )}

                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClick(index);
                          }}
                          style={{ marginLeft: "auto" }}
                        >
                          {collapse === index ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => fetchRowClickData(row)}
                            />
                          )}
                        </IconButton>
                      </CardContent>
                      <Collapse in={collapse === index}>
                        <Box
                          sx={{
                            padding: "0px 40px 13px 40px",
                          }}
                        >
                          <Divider />

                          <Grid
                            container
                            size={{ xs: 12, md: 12, xl: 12 }}
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="outlined"
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              Transaction details
                            </Typography>
                            {load ? <Loader /> : ""}
                            <Button
                              variant="oulined"
                              sx={{
                                color: "var(--secondary-color)",
                                outline: " 1px solid var(--secondary-color)",
                                borderRadius: "25px",
                                textTransform: "none",
                                fontSize: "12px",
                              }}
                              size="small"
                              onClick={() => {
                                checkStatus(selectedRow);
                              }}
                            >
                              Check Status
                            </Button>
                          </Grid>
                          <Grid container size={{ xs: 12, md: 12, xl: 12 }}>
                            <Grid size={{ xs: 12, md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                Beneficiary account
                              </Typography>
                              <Typography className={classes.values}>
                                {selectedRow?.BeneficiaryAcct
                                  ? selectedRow?.BeneficiaryAcct
                                  : "NA"}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 12, md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                UETR
                              </Typography>
                              <Typography className={classes.values}>
                                {selectedRow?.UETR ? selectedRow?.UETR : "NA"}
                              </Typography>
                            </Grid>

                            <Grid size={{ md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                Beneficiary bank name
                              </Typography>
                              <Typography className={classes.values}>
                                {selectedRow?.BeneficiaryBkNm
                                  ? selectedRow?.BeneficiaryBkNm
                                  : "NA"}
                              </Typography>
                            </Grid>
                            <Grid size={{ md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                Reference id
                              </Typography>
                              <Typography className={classes.values}>
                                {selectedRow?.TRN ? selectedRow?.TRN : "NA"}
                              </Typography>
                            </Grid>
                            <Grid size={{ md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                Date of payment
                              </Typography>
                              <Typography className={classes.values}>
                                {dateConvert(selectedRow?.DateofPayment)}
                              </Typography>
                            </Grid>
                            <Grid size={{ md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                Staus
                              </Typography>
                              <Typography className={classes.values}>
                                {icon
                                  ? status?.TransactionStatus
                                    ? status?.TransactionStatus
                                    : "NA"
                                  : selectedRow?.Status
                                  ? selectedRow?.Status
                                  : "NA"}
                              </Typography>
                            </Grid>
                            <Grid size={{ md: 3, xl: 3 }}>
                              <Typography
                                variant="subtitle1"
                                className={classes.labels}
                              >
                                Last updated date
                              </Typography>
                              <Typography className={classes.values}>
                                {icon
                                  ? status?.LastCheckedTime
                                    ? status?.LastCheckedTime
                                    : "NA"
                                  : selectedRow?.LastUpdatedDate
                                  ? selectedRow?.LastUpdatedDate
                                  : "NA"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapse>
                    </Card>
                  );
                })}
              {data && data.length > 0 && (
                <TablePagination
                  id="pagination"
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default RecentPayments;
