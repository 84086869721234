import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import React, { useState } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useLocation } from "react-router-dom";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LeavePage from "./LeavePage";
import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import Dashboard from "./Dashboard";
import TransferAmount from "./TransferAmount";
import AllTransactions from "./AllTransactions";
import Beneficiaries from "./Beneficiaries";
import PayAgain from "./PayAgain";

const InternationalTransfer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [unsaved, setUnsaved] = useState(true); // Simulate unsaved changes
  const [openModal, setOpenModal] = useState(false);
  const [nextTab, setNextTab] = useState(null);
const [pageMessage,setPageMessage]=useState()
const [activetab, setActiveTab] = useState(0);
  const paths = [
    "/internationaltransfer/dashboard",
    "/internationaltransfer/transfer",
    "/internationaltransfer/beneficiaries",
    "/internationaltransfer/transactions",
  ];

  const activeTab = paths.findIndex((path) =>
    location.pathname.startsWith(path)
  );


  const handleTabChange = (event, newValue) => {
    // Check if there are unsaved changes and the user is not moving from tab 0
    if (unsaved && activeTab !== 0 && (newValue !== activeTab)) {
      let message = "";
  
      // Show message only for tabs 1 and 2
      switch (activeTab) {
        case 1:
          message = " Your payment has not been submitted and details will be lost if you proceed.";
          break;
        case 2:
          message = " Any changes made to the beneficiary details will not be saved. Do you wish to continue?";
          break;
        default:
          // No message for other tabs
          message = "";
      }
  
      if (message) {
        // Set the dynamic message and open the modal if a message is defined
        setPageMessage(message);
        setNextTab(newValue);
        setOpenModal(true); // Open the modal to confirm if the user wants to leave
      } else {
        // If no message is set (for tab 3 or other cases), directly navigate
        setActiveTab(newValue);
        navigate(paths[newValue]);
      }
    } else {
      // If no unsaved changes or user is moving to/from tab 0, directly navigate
      setActiveTab(newValue);
      navigate(paths[newValue]);
    }
  };
  

  const handleStay = () => {
    setOpenModal(false); 
  };
  const handleLeave = () => {
    setOpenModal(false); 
    navigate(paths[nextTab]); 
   
  };
  return (
    <>
      <div className={classes.tabStyles}>
        <Tabs
          value={activeTab === -1 ? 0 : activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="payment steps"
        >
          <Tab
            icon={<DashboardOutlinedIcon />}
            label="Dashboard"
            iconPosition="start"
            className={classes.tabHeading}
            // component={Link}
          />
          <Tab
            label="Transfer amount "
            icon={<SendOutlinedIcon />}
            iconPosition="start"
            // component={Link}
            className={classes.tabHeading}
          />
          <Tab
            label="Beneficiaries "
            icon={<PersonOutlineOutlinedIcon />}
            iconPosition="start"
            className={classes.tabHeading}
            // component={Link}
          />
          <Tab
            label="All transactions "
            icon={<PaymentsOutlinedIcon />}
            iconPosition="start"
            // component={Link}
            className={classes.tabHeading}
          />
        </Tabs>

        <div></div>
      </div>

      <Box className={classes.content}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/transfer" element={<TransferAmount />} />
          <Route path="/beneficiaries" element={<Beneficiaries />} />
          <Route path="/transactions" element={<AllTransactions />} />
          <Route
            path="*"
            element={<Navigate to="/internationaltransfer/dashboard" replace />}
          />
        </Routes>
      </Box>
      {/* unsave changes */}
      <LeavePage open={openModal} onClose={handleStay} onLeave={handleLeave} message={pageMessage} />

    </>
  );
};
export default InternationalTransfer;
