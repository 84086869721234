import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import endPoints, { BASE_URL } from "../../../config/Api";
import axios from "axios";
import CurrencyFlag from "react-currency-flags";
import { getAccessTokenForMobile } from "../../Authorization/Services/authService";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { format, formatDistanceToNow, differenceInDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  TablePagination,
  MenuItem,
  Menu,
  Button,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import RepeatIcon from "@mui/icons-material/Repeat";
import ReplayIcon from "@mui/icons-material/Replay";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  checkBeneficiaryExists,
  createPayAgainOrRepeatTransactionCase,
} from "../../CommonService/commonAppService";
import ModalPopup from "./Dialog";
import Loader from "../Loader";
import Alert from "@mui/material/Alert";
import {
  refreshAccessToken,
  isTokenExpired,
} from "../../Authorization/Services/authService";

const colors = [
  "#0b8953",
  "#c23824",
  "#2c97de",
  "#00657c",
  " #00aa66",
  "#667700",
  "#e27100",
  "var(--primary-color)",
];

const RecentPayments = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isPayAgainAlert, setIsPayAgainAlert] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(true);
  const token = getAccessTokenForMobile();

  const [openAlertMessage, setOpenAlertMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let token = await getAccessTokenForMobile();

      if (isTokenExpired(token)) {
        token = await refreshAccessToken();
      }
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.recentPayments.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data);
      setSearchData(response?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      localStorage.clear();
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchRowClickData = async (row) => {
    setOpen(!open);
    setIsPayAgainAlert(false);
    const tokenFor = await token.then((res) => res);
    const payload = {
      dataContext: "D_TransactionDetails",
      dataContextParameters: {
        TRN: row.TRN,
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/pages/PaymentTCContent`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${tokenFor}`,
          },
        }
      );
      const content = response?.data?.data?.dataInfo?.content;
      setSelectedRow(content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const _handleClose = () => {
    setOpen(false);
  };
  const handleActions = (row, actionName) => {
    setAnchorEl(null);
    try {
      checkBeneficiaryExists(row?.TRN).then((res) => {
        if (res.status === 200 && res?.data?.message?.includes("Success")) {
          createPayAgainOrRepeatTransactionCase(row?.TRN, actionName)
            .then((res) => {
              actionName === "PayAgain"
                ? navigate("/payagain", {
                    state: {
                      response: res?.data,
                      payAgainOrRepeatTransaction: true,
                      etag: res.headers["etag"],
                    },
                  })
                : navigate("/repeattransaction", {
                    state: {
                      response: res?.data,
                      payAgainOrRepeatTransaction: true,
                      etag: res.headers["etag"],
                    },
                  });
            })
            .catch((error) => {
              console.log(error);
              if (error.status !== "401") {
                setApiError(error?.response?.data);
              } else {
                navigate("/login");
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setOpen(!open);
          setIsPayAgainAlert(true);
          setOpenAlertMessage(res?.data?.message);
        }
      });
    } catch (error) {
      console.error("Error checking beneficiary:", error);
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    "Beneficiary",
    "Date",
    "Receive Amount/Currency",
    "Status",
    "StatusIcon",
  ];

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      fetchData();
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.BeneficiaryName &&
        row.BeneficiaryName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setData(SearchedData);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon className={classes.arrowIcon} />
        </IconButton>

        {/* Page count in the center */}
        <Typography
          variant="body2"
          sx={{ margin: "0 16px" }}
          className={classes.arrowContent}
        >
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </IconButton>
      </Box>
    );
  }

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);
    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 1).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    // Define options for date and time formatting
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    // Format the date and time
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container style={{ padding: "16px" }}>
            {/* Header */}
            <Grid size={{ xs: 12 }} className={classes.recentPaymentsGrid}>
              <h2 className={classes.recentPaymentsHeader}>Recent Payments</h2>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }} style={{ marginTop: "20px" }}>
              <Grid
                size={{ md: 12, xs: 12 }}
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <input
                  placeholder="Search for beneficiary by name"
                  className={classes.inputInput}
                  onChange={searchHandler}
                  value={searchText}
                />
              </Grid>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              {apiError && (
                <Alert
                  severity="error"
                  onClose={() => {
                    setApiError("");
                  }}
                >
                  {apiError}
                </Alert>
              )}
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid container size={{ xs: 12 }}>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const initials = getInitials(row?.BeneficiaryName);
                    const backgroundColor = getColor(initials);
                    return (
                      <Grid size={{ xs: 12 }}>
                        <Card
                          key={row.id}
                          className={classes.accountCard}
                          onClick={() => fetchRowClickData(row)}
                        >
                          <CardContent className={classes.acContent}>
                            <Grid
                              container
                              size={{ xs: 12 }}
                              alignItems="center"
                            >
                              <Grid size={{ xs: 6 }} direction="column">
                                <div className={classes.flexAlignCenter}>
                                  <Avatar
                                    style={{
                                      backgroundColor,
                                    }}
                                    className={classes.avatarStyle}
                                  >
                                    <span className={classes.avatarInitial}>
                                      {initials}
                                    </span>
                                  </Avatar>
                                  <span className={classes.avatarName}>
                                    {row?.BeneficiaryName}
                                  </span>
                                </div>
                                <span className={classes.avatarDate}>
                                  {formatDate(row?.DateOfPayment)}
                                </span>
                              </Grid>
                              <Grid
                                size={{ xs: 6 }}
                                className={classes.amountStatusCont}
                              >
                                <div className={classes.flexAlignCenter}>
                                  <span className={classes.avatarPayment}>
                                    {row?.PaymentAmount?.toFixed(2)}
                                  </span>
                                  <div
                                    className={classes.avatarPaymentCurrency}
                                  >
                                    {row?.PaymentCurrency}
                                  </div>
                                  <CurrencyFlag
                                    currency={
                                      row?.PaymentCurrency != null
                                        ? row?.PaymentCurrency
                                        : ""
                                    }
                                    className={classes.CurrencyFlags}
                                  />
                                </div>
                                <div
                                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                                >
                                  <img
                                    title={row?.ImageOver}
                                    src={row?.pyImage}
                                    alt="image"
                                    className={classes.StatusIcon}
                                  />

                                  <span className={classes.avatarStatus}>
                                    {row?.Status}
                                  </span>
                                </div>
                              </Grid>
                              <Divider className={classes.divider} />
                              <Grid
                                size={{ xs: 12 }}
                                className={`${classes.flexCenterCenter} ${classes.spacing}`}
                              >
                                <Button
                                  className={classes.repeatPmtBtn}
                                  titleAccess="Repeat Payment"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleActions(row, "RepeatTransaction");
                                  }}
                                >
                                  Repeat Payment
                                </Button>
                                <Button
                                  className={classes.newAmtBtn}
                                  titleAccess="Pay Again"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleActions(row, "PayAgain");
                                  }}
                                >
                                  Quick pay
                                </Button>
                              </Grid>
                            </Grid>
                          </CardContent>

                          {/* <CardContent className={classes.acContent}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div className={classes.avatarMargin}>
                                <Avatar
                                  style={{
                                    backgroundColor,
                                  }}
                                  className={classes.avatarStyle}
                                >
                                  <span className={classes.avatarInitial}>
                                    {initials}
                                  </span>
                                </Avatar>
                              </div>
                              <Grid className={classes.avatarNameGrid}>
                                <span className={classes.avatarName}>
                                  {row?.BeneficiaryName}
                                </span>
                                <span className={classes.avatarDate}>
                                  {formatDate(row?.DateOfPayment)}
                                </span>
                              </Grid>
                            </div>
                            <div className={classes.avatarPaymentDiv}>
                              <span className={classes.avatarPayment}>
                                {row?.PaymentAmount?.toFixed(2)}
                              </span>
                              <div className={classes.avatarPaymentCurrency}>
                                {row?.PaymentCurrency}
                              </div>
                              <CurrencyFlag
                                currency={
                                  row?.PaymentCurrency != null
                                    ? row?.PaymentCurrency
                                    : ""
                                }
                                className={classes.CurrencyFlags}
                              />
                              <img
                                title={row?.ImageOver}
                                src={row?.pyImage}
                                alt="image"
                                className={classes.StatusIcon}
                              /> */}
                          {/* <Box>
                                <IconButton
                                  aria-label="more"
                                  aria-controls={
                                    openMenu ? "more-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={handleClickMenu}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="more-menu"
                                  anchorEl={anchorEl}
                                  open={openMenu}
                                  onClose={() => setAnchorEl(null)}
                                  MenuListProps={{
                                    "aria-labelledby": "more-button",
                                  }}
                                >
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleActions(row, "PayAgain");
                                    }}
                                  >
                                    Pay Again
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleActions(row, "RepeatTransaction");
                                    }}
                                  >
                                    Repeat Trasaction
                                  </MenuItem>
                                </Menu>
                              </Box> */}
                          {/* <RepeatIcon
                                className={classes.repeatIcon}
                                titleAccess="Pay Again"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleActions(row, "PayAgain");
                                }}
                              />
                              <ReplayIcon
                                className={classes.repeatIcon}
                                titleAccess="Repeat Payment"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleActions(row, "RepeatTransaction");
                                }}
                              />
                            </div>
                          </CardContent> */}
                        </Card>
                        <ModalPopup
                          open={open}
                          handleClose={_handleClose}
                          rowData={selectedRow}
                          colors={colors}
                          isPayAgainAlert={isPayAgainAlert}
                          openAlertMessage={openAlertMessage}
                        />
                      </Grid>
                    );
                  })}

                {data && data.length > 0 && (
                  <Grid container>
                    <CustomTablePagination
                      count={data.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default RecentPayments;
