import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, Box, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

const BeneficiaryBankNameField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { beneDetails, flag, addEditBene, isEditable } = props;

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Bank Name
        </Typography>
        {addEditBene ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={beneDetails?.BeneBankName}
            name="BICFI"
            disabled
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneAddressURL && (
                      <img
                        src={beneDetails?.IsValidBeneAddressURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
          />
        ) : (
          <Box className={classes.flagAlignments}>
            {/* <Typography className={classes.BankAddress}>
              <span className={classes.contentstyle}>
                {beneDetails?.BeneBankName ? beneDetails?.BeneBankName : "NA"}
              </span>
            </Typography>
            <span className={classes.addressFlag}>
              {flag && beneDetails?.IsValidBeneAddressURL && (
                <img
                  src={beneDetails?.IsValidBeneAddressURL}
                  className={classes.checkMark}
                  alt="checkmark"
                />
              )}
            </span> */}
            {isEditable ? (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={
                  beneDetails?.BeneBankName ? beneDetails?.BeneBankName : "NA"
                }
                disabled
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment>
                        {flag && beneDetails?.IsValidBeneAddressURL && (
                          <img
                            src={beneDetails?.IsValidBeneAddressURL}
                            className={classes.checkMark}
                            alt="checkmark"
                          />
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            ) : (
              <>
                <Typography className={classes.BankAddress}>
                  <span className={classes.contentstyle}>
                    {beneDetails?.BeneBankName
                      ? beneDetails?.BeneBankName
                      : "NA"}
                  </span>
                </Typography>
                <span className={classes.addressFlag}>
                  {flag &&
                    beneDetails?.IsValidBeneAddressURL &&
                    beneDetails?.BeneBankName && (
                      <img
                        src={beneDetails?.IsValidBeneAddressURL}
                        className={classes.checkMark}
                        alt="checkmark"
                      />
                    )}
                </span>
              </>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryBankNameField;
