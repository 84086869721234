import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  getListOfBeneficiaries,
  getDataForReviewBeneficiary,
  getDataForFirstPreviousPage,
} from "../../../CommonService/sendPaymentService";
import Loader from "../Loader";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import CountrySpecificComponent from "./CountrySpecificComponent";
import Grid from "@mui/material/Grid2";
import { getCountryCode } from "../../../CommonUtils/CommonUtils";
import Flag from "react-world-flags";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewBeneficiarySendPaymentFlow,
  validateBeneficiarySendPaymentFlow,
} from "../../../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";
import { getPurposeCodeList } from "../../../CommonUtils/CommonUtils";
import {
  setCaseKey,
  resetInitialState,
  setFormState,
  setSubmitFlag,
  submitNewBeneficiarySendPaymentFlow,
} from "../../../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Circle as CircleIcon,
} from "@mui/icons-material";
import { getVisibleIndicators } from "../../../CommonUtils/CommonUtils";

const Beneficiaries = ({
  onContinue,
  receiversCountryName,
  nextAssignmentId,
  eTag,
  caseKey,
  setEtag,
  setSelectedBeneDetails,
  setPurposeCodeList,
  onPrevious,
  setPreviousData,
  previousData,
  setIsSecondAccordionComplete,
  setExpanded,
  setEditableBeneDetails,
  editableBeneDetails,
  beneficiariesPreviousData,
  setAmountPreviousData,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage] = useState(6);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const dispatch = useDispatch();

  const addNewBeneStoreData = useSelector(
    (state) => state?.addNewBeneficiarySendPaymentFlowReducer
  );

  useEffect(() => {
    dispatch(setCaseKey(caseKey));
    const fetchData = async () => {
      try {
        const response = await getListOfBeneficiaries(receiversCountryName);
        setData(response.data.data);
        setSearchData(response.data.data);
      } catch (error) {
        console.error("Error fetching Beneficiaries data:", error);
        localStorage.clear();
        navigate("/login");
      }
    };
    fetchData();
  }, [
    navigate,
    receiversCountryName,
    addNewBeneStoreData?.enableNewBeneFormFlag,
  ]);
  useEffect(() => {
    dispatch(resetInitialState());
  }, []);

  // useEffect(async () => {
  //   try {
  //     const response = await getDataForReviewBeneficiary(
  //       selectedBeneficiary,
  //       nextAssignmentId,
  //       eTag
  //     );
  //     const newSelectedBeneDetails =
  //       response.data.data.caseInfo.content.SelectedBeneDetails;
  //     setSelectedBeneDetails(newSelectedBeneDetails);
  //     if (response?.data?.data?.shared) {
  //       setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
  //     }
  //     setEtag(response.headers["etag"]);
  //   } catch {}
  // }, [selectedBeneficiary]);

  useEffect(() => {
    if (beneficiariesPreviousData) {
      setSelectedBeneficiary(
        beneficiariesPreviousData?.caseInfo?.content?.AvailableRecipients
          ?.pyGUID
      );
    }
  }, [beneficiariesPreviousData]);

  useEffect(() => {
    if (previousData) {
      setSelectedBeneficiary(
        previousData?.caseInfo?.content?.AvailableRecipients?.pyGUID
      );
    }
  }, [previousData]);

  const handlePrevious = async () => {
    try {
      setLoading(true);
      const response = await getDataForFirstPreviousPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      if (response?.data?.data?.shared) {
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
      setAmountPreviousData(resourceData);
      if (resourceData) {
        setLoading(false);
        setExpanded("panel1");
      }
    } catch {}
  };

  const handle_row = async (rowid) => {
    setSelectedBeneficiary(rowid);
  };
  const handleContinue = async () => {
    try {
      setLoading(true);
      const response = await getDataForReviewBeneficiary(
        selectedBeneficiary,
        nextAssignmentId,
        eTag
      );

      const newSelectedBeneDetails =
        response.data.data.caseInfo.content.SelectedBeneDetails;
      if (newSelectedBeneDetails) {
        setIsSecondAccordionComplete(true);
        setExpanded("panel3");
      }

      setSelectedBeneDetails(newSelectedBeneDetails);
      setEditableBeneDetails(newSelectedBeneDetails);
      if (response?.data?.data?.shared) {
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
      setEtag(response.headers["etag"]);
      onContinue(nextAssignmentId);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const isRowSelected = (rowId) => {
    return selectedBeneficiary === rowId; // Check if the row is selected
  };

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handleNextPage = () => {
    if ((page + 1) * rowsPerPage < data.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box className={classes.pageNation}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Page count in the center */}
        <Typography variant="body2" className={classes.pageCount}>
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }

  const handleAddNewBene = () => {
    setLoading(true);
    dispatch(
      fetchNewBeneficiarySendPaymentFlow({ caseKey: caseKey, eTag: eTag })
    )
      .then((res) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRefreshInputBlur = (event) => {
    const { name, value } = event.target;

    const bicfiRefreshCountries = [
      "Argentina",
      "Ghana",
      "Hong Kong",
      "Bangladesh",
      "India",
      "China",
      "Malaysia",
      "Mexico",
      "Morocco",
      "Singapore",
      "Philippines",
      "South Africa",
      "Sri Lanka",
      "Japan",
      "Kenya",
      "Chile",
      "Indonesia",
      "New Zealand",
      "Korea, Republic of", //south korea
      "Tanzania",
      "Uganda",
      "Vietnam",
      "Uruguay",
      "Zambia",
      "Nepal",
      "Thailand",
      "Colombia",
    ];

    let refreshFor;

    if (
      addNewBeneStoreData?.formState?.Country === "Brazil" ||
      addNewBeneStoreData?.formState?.Country === "United Arab Emirates" ||
      addNewBeneStoreData?.formState?.Country === "Poland" ||
      addNewBeneStoreData?.formState?.Country === "Costa Rica" ||
      addNewBeneStoreData?.formState?.Country === "Pakistan" ||
      addNewBeneStoreData?.formState?.Country === "Romania" ||
      addNewBeneStoreData?.formState?.Country === "Ukraine"
    ) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneficiary.BICFI";
      } else if (name === "CdtrAcctIdIBAN") {
        refreshFor = ".NewBeneficiary.CdtrAcctIdIBAN";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneficiary.ClrSysMmbId";
      }
    } else if (
      addNewBeneStoreData?.formState?.Country === "United States" ||
      addNewBeneStoreData?.formState?.Country === "Australia" ||
      addNewBeneStoreData?.formState?.Country === "Canada" ||
      addNewBeneStoreData?.formState?.Country === "India"
    ) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneficiary.BICFI";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneficiary.ClrSysMmbId";
      }
    } else {
      refreshFor = bicfiRefreshCountries.includes(
        addNewBeneStoreData?.formState?.Country
      )
        ? ".NewBeneficiary.BICFI"
        : ".NewBeneficiary.CdtrAcctIdIBAN";
    }

    dispatch(
      validateBeneficiarySendPaymentFlow({
        caseKey: addNewBeneStoreData?.caseKey,
        formState: addNewBeneStoreData?.formState,
        etag: addNewBeneStoreData?.etag,
        refreshFor: refreshFor,
      })
    );
  };
  const handleValidate = () => {
    try {
      setLoading(true);
      const { Edit, ...state } = addNewBeneStoreData?.formState;
      const refreshFor = ".NewBeneficiary.ValidateAndSave";
      dispatch(
        validateBeneficiarySendPaymentFlow({
          caseKey: addNewBeneStoreData?.caseKey,
          formState: addNewBeneStoreData?.formState,
          etag: addNewBeneStoreData?.etag,
          refreshFor: refreshFor,
        })
      )
        .then((res) => {
          if (res.payload && res?.meta.requestStatus == "fulfilled") {
            dispatch(setSubmitFlag(true));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleDateChange = (value, name) => {
    const formattedDate = value ? dayjs(value).format("MM/DD/YYYY") : "";
    dispatch(
      setFormState({
        [name]: formattedDate, // Update the specific field
      })
    );
  };
  // const handleChange = (evt) => {
  //   dispatch(
  //     setFormState({
  //       [evt.target.name]: evt.target.value,
  //     })
  //   );
  // };
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    // Prepare the updated value
    const updatedValue =
      name === "CdtrAcctIdIBAN" ||
      name === "BICFI" ||
      name === "PostCode" ||
      (addNewBeneStoreData?.formState?.Country === "India" &&
        name === "ClrSysMmbId") ||
      (addNewBeneStoreData?.formState?.Country === "South Africa" &&
        name === "ClrSysMmbId")
        ? value.toUpperCase()
        : value;
    dispatch(
      setFormState({
        [name]: updatedValue, // Update the specific field
      })
    );
  };
  const handleCloseForm = (evt) => {
    dispatch(resetInitialState());
  };
  const handleBeneFormSubmit = () => {
    dispatch(
      submitNewBeneficiarySendPaymentFlow({
        caseKey: addNewBeneStoreData?.caseKey,
        eTag: addNewBeneStoreData?.etag,
        payload: addNewBeneStoreData?.formState,
      })
    )
      .then((res) => {
        if (res.payload?.data?.confirmationNote) {
          setEtag(res.payload?.etag);
          dispatch(resetInitialState());
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {});
  };
  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      setData(searchData);
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.FullName &&
        row.FullName?.toLowerCase().includes(event.target.value?.toLowerCase())
    );
    setData(SearchedData);
    setPage(0);
  };
  return (
    <>
      {loading && <Loader />}
      {!addNewBeneStoreData?.enableNewBeneFormFlag ? (
        <>
          <Grid container spacing={2} style={{ padding: "5px 35px 5px  35px" }}>
            <Grid size={{ md: 12, xs: 12 }}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid size={{ md: 9, xs: 12 }} className={classes.searchBar}>
                  <Box>
                    <Button className={classes.button}>Recent</Button>
                    <Button className={classes.button}>Favourite</Button>
                    <Button className={classes.allButton}>All</Button>
                  </Box>
                  <Box sx={{ width: "28vw" }}>
                    <input
                      placeholder="Search by beneficiary name"
                      className={classes.inputInput}
                      // onChange={_onSearch}
                      onChange={searchHandler}
                      value={searchText}
                    />
                  </Box>
                </Grid>

                <Grid size={{ md: 3, xs: 12 }} sx={{ pl: 1 }}>
                  <div
                    className={classes.BeneficiaryItems}
                    onClick={handleAddNewBene}
                  >
                    <AddOutlinedIcon className={classes.circleIcon} />
                    <Typography variant="h6" className={classes.addtxt}>
                      Add New Beneficiary
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              size={{ md: 12 }}
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "10px" }}
            >
              <Grid size={{ md: 0.5 }}>
                <IconButton onClick={handlePreviousPage} disabled={page === 0}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid size={{ md: 11, xs: 12 }}>
                <Grid container spacing={2}>
                  {data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <Grid key={index} size={{ md: 4, xs: 6 }}>
                          <Card
                            style={{ height: "110px" }}
                            key={row.pxRefObjectInsName || row?.pyID}
                            className={`${classes.accountCard} ${
                              isRowSelected(row.pyID)
                                ? classes.selectedRow
                                : " "
                            }`}
                            onClick={() => handle_row(row?.pyID)}
                          >
                            <CardContent className={classes.acContent}>
                              <Grid
                                container
                                spacing={1}
                                className={classes.infoContainer}
                              >
                                <Grid size={{ xs: 12, md: 12 }}>
                                  <Typography
                                    variant="body1"
                                    className={classes.Fullname}
                                  >
                                    {row?.FullName}
                                  </Typography>
                                </Grid>
                                <Grid
                                  size={{ xs: 12, md: 12 }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    className={classes.Accountnumber}
                                  >
                                    {row.MaskAccNumb
                                      ? row.MaskAccNumb
                                      : row.MaskIBANNumb}
                                  </Typography>
                                  {isRowSelected(row.pyID) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <TaskAltOutlinedIcon />
                                    </div>
                                  )}
                                </Grid>

                                <Grid size={{ xs: 12, md: 12 }}>
                                  <Typography className={classes.country}>
                                    {row?.Country}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  {data && data.length > 0 && (
                    <Grid
                      container
                      size={{ md: 12 }}
                      sx={{ marginTop: "15px" }}
                    >
                      <Grid size={{ md: 12 }} className={classes.flexCenter}>
                        {getVisibleIndicators(4, page, totalPages)?.map(
                          (indicator) => {
                            return (
                              <CircleIcon
                                sx={{
                                  color: indicator === page ? "#000" : "#ccc",
                                }}
                                className={classes.carouselIcons}
                              />
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid size={{ md: 0.5 }}>
                <IconButton
                  onClick={handleNextPage}
                  disabled={(page + 1) * rowsPerPage >= data.length}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <>
            <CountrySpecificComponent
              addNewBene={true}
              handleChange={handleChange}
              newCountryName={addNewBeneStoreData?.formState?.Country}
              handleRefreshInputBlur={handleRefreshInputBlur}
              handleValidate={handleValidate}
              handleDateChange={handleDateChange}
              addNewBeneSendPaymentFlow={true}
            />

            <Grid container spacing={2}>
              <Grid
                size={{ md: 12, xs: 12 }}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.secondaryBtn}
                    onClick={handleCloseForm}
                  >
                    Cancel
                  </Button>
                </div>
                {!addNewBeneStoreData?.submitFlag && (
                  <Button
                    variant="contained"
                    onClick={handleValidate}
                    sx={{
                      // background: "#7A70F0",
                      background: "var(--secondary-color)",
                      color: "#fffff",
                      borderRadius: "27px",
                      textTransform: "none",
                    }}
                    size="small"
                  >
                    Validate
                  </Button>
                )}
                {addNewBeneStoreData?.submitFlag && (
                  <Button
                    variant="contained"
                    onClick={handleBeneFormSubmit}
                    sx={{
                      // background: "#7A70F0",
                      background: "var(--secondary-color)",
                      color: "#fffff",
                      borderRadius: "27px",
                      textTransform: "none",
                    }}
                    size="small"
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </>
        </>
      )}

      {!addNewBeneStoreData?.enableNewBeneFormFlag && (
        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid sx={{ display: "flex" }} size={{ md: 6 }}>
            <InfoIcon className={classes.infoIcon} />
            <span>&nbsp;Please select a beneficiary to continue further.</span>
          </Grid>
          <Grid
            item
            size={{ md: 6 }}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <Button
              variant="outlined"
              size="small"
              className={classes.secondaryBtn}
              onClick={handlePrevious}
            >
              Previous &nbsp;
              <ArrowUpwardIcon
                sx={{ fill: "var(--secondary-color)", height: "18px" }}
              />
            </Button>
            <Button
              onClick={handleContinue}
              variant="contained"
              sx={{
                // background: "#7A70F0",
                background: "var(--secondary-color)",
                color: "#fffff",
                borderRadius: "27px",
                textTransform: "none",
              }}
              size="small"
              disabled={!selectedBeneficiary}
            >
              Next &nbsp;
              <ArrowDownwardIcon sx={{ fill: "#fffff", height: "18px" }} />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Beneficiaries;
