import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Switch from "@mui/material/Switch";
import { sendPaymentCancelApi } from "../../../CommonService/commonAppService";
import FinalResponse from "../TransferAmount/Finalresponse";
import {
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  payAgainProcessSubmit,
  sendPaymentFinalSubmit,
  debouncedGetDataForSendAmount,
  debouncedGetDataForReceiverAmount,
} from "../../../CommonService/sendPaymentService";
import { getPurposeCodeListApi } from "../../../CommonService/commonAppService";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import warningIcon from "../../../../assets/images/warningicon.svg";
import HelpIcon from "@mui/icons-material/Help";
import CountrySpecificComponent from "../../SendPayment/CountrySpecificComponent";
import Loader from "../Loader";
import Flag from "react-world-flags";
import { getAccounts } from "../../../CommonUtils/CommonUtils";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const PayAgain = ({ paydata, handleCancel }) => {
  const classes = useStyles();
  const location = useLocation();

  //const { state } = location;
  const [accounts, setAccounts] = useState([]);
  const [payAgainData] = useState(paydata);
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [validate, setValidate] = useState();
  const [check, setCheck] = useState(true);
  const [selectedOption, setSelectedOption] = useState("send");

  const [setValidateCurrency] = useState("");
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [payAgainEtag] = useState(paydata?.etag);
  const [editableBeneDetails] = useState(
    paydata?.response?.data.caseInfo?.content?.SelectedBeneDetails
  );
  const [editDetails, setEditDetails] = useState(
    paydata?.response?.data.caseInfo?.content
  );
  const [PayChargeCmtIndicator, setPayChargeCmtIndicator] = useState(
    paydata?.response?.data.caseInfo?.content?.PayChargeCmtIndicator
  );
  const [actionName, setActionName] = useState(
    paydata?.response?.data.caseInfo?.content?.pyViewName
  );
  const [finalResponse, setFinalResponse] = useState();
  const [isFinalResponse, setIsFinalResponse] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [cancelPaymentId, setCancelPaymentId] = useState();

  const [checkbox, setcheckbox] = useState({
    PayChargeCmtIndicator: false,
    ReviewConfirmAck: false,
  });
  const [checkCount, setcheckCount] = useState(0);

  const cancelPayment = async (evt) => {
    setRadioValue(evt.target.value);
  };
  useEffect(() => {
    if ("RepeatTransaction" === actionName) {
      setDisable(true);
    }
  }, [actionName]);

  const [checkbox_Details] = useState(
    paydata?.response?.uiResources.resources.views?.[actionName]?.[0]
      ?.children[0].children
  );

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const matchCountries = (countryName) => {
    for (const [code, fullCountryName] of Object.entries(countriesShow)) {
      if (countryName === countriesShow[code]) {
        return code;
      }
    }
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        acc[data.config.value.replace(/^@P \./, "")] = false;
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }
  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setCheck(checked);
  };

  const handleCheckBox = (event) => {
    const { name, checked } = event.target;
    setcheckbox((check) => ({
      ...check,
      [name]: checked,
    }));
    setcheckCount((prev) => (checked ? prev + 1 : prev - 1));
  };
  useEffect(() => {
    setnextAssignmentId(payAgainData?.response?.nextAssignmentInfo?.ID);
    setCaseId(payAgainData?.response?.data.caseInfo?.ID.split(" ")[1]);
    setAvailableToPayCountryNamesList(
      getCountryNamesList(payAgainData?.response?.data)
    );
    let pxResults = getAccounts(payAgainData?.response?.data);
    setAccounts(pxResults);
    setAvailableToPayCurrencyList(
      payAgainData?.response?.data.shared.D_AvailabletoPayCurrencyListAPIList
        .D_AvailabletoPayCurrencyListAPIList.pxResults
    );
    setEtag(payAgainEtag);

    setAccount(payAgainData?.response?.data.caseInfo?.content?.FromAccount);
    matchCountries(
      payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
    );
    setflagselect(
      matchCountries(
        payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
      )
    );
    const option = {
      label: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
      value: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
    };
    setSelectedCurrency(option);
    setShowExchangeFields(true);
    if (payAgainData?.response?.data?.shared?.D_PurposeCodeList) {
      setPurposeCodeList(
        getPurposeCodeList(payAgainData?.response.data.shared)
      );
    } else {
      setPurposeCodeList("");
    }
    if (payAgainData?.response?.data.caseInfo?.content?.POPVisibility) {
      getPurposeCodeListApi(
        payAgainData?.response?.data.caseInfo?.content?.PaymentInfo?.CountryCode
      ).then((res) => {
        setPurposeCodeList(res?.data?.data);
      });
    }

    const newDetails = payAgainData?.response?.data.caseInfo?.content;
    setCancelPaymentId(payAgainData?.response?.data.caseInfo?.ID);

    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag, true)
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
          SendAmount: "",
          ReceiveAmount: "",
        });
      })
      .catch((error) => {
        if (error.status !== "401") {
        } else {
          navigate("/login");
        }
      });
  };

  function updatePurposeCodeListForJapan(
    resData,
    setPurposeCodeList,
    getPurposeCodeList,
    response
  ) {
    if ("Japan" === resData.ReceiversCountryName) {
      setPurposeCodeList(
        resData.ReceiveAmount > 1000000
          ? getPurposeCodeList(response?.data?.data?.shared)
          : ""
      );
    }
  }
  const handleRadioChange = (evt) => {
    setSelectedOption(evt.target.value);
    // setAccountDetails({
    //   ...accountDetails,
    //   transferMoneyFlag:evt.target.value
    // });
  };
  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    // let formattedValue ;
    // if (value && !value.includes('.')) {
    //    formattedValue = `${value}.00`;
    // }
    const parsedValue = value;
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount should not greater than available balance",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoadingAccounts(true);
    let formattedValue;
    if (value && !value?.includes(".")) {
      formattedValue = `${value}.00`;
    }
    const amount = formattedValue || value;
    if (!isNaN(amount) && !error?.amountYouSendError) {
      debouncedGetDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
            EstimatedDeliveryTime: resData?.EstimatedDeliveryTime,
            Payment_instruction_validation:
              resData?.Payment_instruction_validation,
            POPVisibility: resData.POPVisibility,
          });
          setEditDetails({
            ...editDetails,
            IsSwiftGo: resData?.IsSwiftGo,
          });
          setPayChargeCmtIndicator(resData.PayChargeCmtIndicator);
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        })
        .finally(() => {
          setLoadingAccounts(false);
        });
    }
  };

  

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoadingAccounts(true);
    if (parsedValue === 0 || value === 0) {
      setLoading(false);
    }
    if ((!isNaN(value) && parsedValue) || value === "") {
      debouncedGetDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        value,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            ReceiveAmount: resData.ReceiveAmount,
            SendAmount: resData?.SendAmount,
            EstimatedDeliveryTime: resData?.EstimatedDeliveryTime,
            Payment_instruction_validation:
              resData?.Payment_instruction_validation,
            POPVisibility: resData.POPVisibility,
          });
          setEditDetails({
            ...editDetails,
            IsSwiftGo: resData?.IsSwiftGo,
          });
          setPayChargeCmtIndicator(resData.PayChargeCmtIndicator);
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          console.error("Error fetching receiver amount:", error);
        })
        .finally(() => {
          setLoading(false);
          setLoadingAccounts(false);
        });
    }
  };

  

  const handleReceiverCurrencyChange = (option) => {
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const getCountryCode = (countryName) => {
    const normalizedCountry = countryName.trim().toLowerCase();
    let countryObj = iso3166.all().find((item) => {
      const isoCountryName = item.country.toLowerCase();

      return isoCountryName === normalizedCountry;
    });

    if (!countryObj) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.trim().toLowerCase();
        return isoCountryName.includes(normalizedCountry);
      });
    }

    return countryObj.alpha2;
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    let amountError = "";
    if (sendAmount <= 0) {
      amountError = "Send Amount must be a valid number greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };
  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount must be a valid number greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
      // } else if (receiveAmount > accountDetails?.AccountBalance) {
      //   amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const handleRemarksChange = (event) => {
    //setRemarks(event.target.value);
    if (event.target.value.length <= 140) {
      setRemarks(event.target.value);
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Value should be less than 140 characters.",
      }));
    }
  };

  const validateForm = () => {
    const amountError = validateAmount();
    const amountReceiveError = validateReceiveAmount();
    const remarksError = validateRemarks();

    if (amountError || remarksError || amountReceiveError) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
        amountReceiveError: amountReceiveError,
      });

      return false;
    }

    handleSubmit();
    return true;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const payLoad = {
        content: {
          EnterAmount: "",
          ReceiveAmount: accountDetails.ReceiveAmount,
          ReceiveCurrency: accountDetails.ReceiveCurrency,
          ReceiversCountryName: accountDetails.ReceiversCountryName,
          SendAmount: accountDetails.SendAmount,
          ConfirmAcknowledgement: checkbox.ReviewConfirmAck,
          PayAllCharges: checkbox.PayChargeCmtIndicator,
          Remarks: remarks,
          UserAcceptanceFlag: radioValue === "Yes" ? true : false,
          Payment_instruction_validation:
            accountDetails?.Payment_instruction_validation,
        },
        pageInstructions: [],
      };
      const response = await payAgainProcessSubmit(
        payLoad,
        nextAssignmentId,
        eTag,
        actionName
      );
      if (response.status === 200 && radioValue == "Yes") {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          setLoading(false)

          
          setFinalResponse(res?.data?.data?.caseInfo);
          setIsFinalResponse(true);
        }
      } else {
        const res = await sendPaymentCancelApi(cancelPaymentId, eTag);
        if (res.status === 200) {
          setLoading(false);
          setIsFinalResponse(true);
        }
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const checkErrors = (value) => {
    if (!validate) return null;

    const errorDetail = validate.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && (
        <CurrencyFlag currency={value} className={classes.CurrencyFlag} />
      )}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  // const handleCancel = () => {
  //   navigate("/internationaltransfer/transactions");
  // };

  return (
    <>
      {loading && <Loader />}

      {!isFinalResponse && (
        <>
          <Box className={classes.rootContainer}>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography
                className={classes.title}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                {actionName === "PayAgain" && "Send amount"}
              </Typography>
              <Divider />
            </Grid>

            <Box sx={{ padding: "5px 20px 0px 20px" }}>
              <Grid container size={{ md: 12 }} justifyContent="space-between">
                <Grid
                  className={`${classes.spacingCont} ${classes.flexCenterAlign}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.icons} />
                  <Typography className={classes.textstyle}>
                    Account and payment details
                  </Typography>
                </Grid>
                <Grid>
                  <HelpIcon className={classes.helpIcon} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item size={{ md: 7 }}>
                  <Typography className={classes.Typo2} sx={{ pb: "10px" }}>
                    Select account to make payment from
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid #c0c0c0",
                      borderRadius: "10px",
                      p: "10px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item size={{ md: 6 }}>
                        Name
                      </Grid>
                      <Grid item size={{ md: 6 }}>
                        Country
                      </Grid>
                      <Grid item size={{ md: 12 }}>
                        <FormControl
                          fullWidth
                          error={Boolean(checkErrors(".FromAccount"))}
                        >
                          {/* <InputLabel className={classes.inputLable}>
                                                      Select an account
                                                    </InputLabel> */}
                          <Select
                            id="Account"
                            variant="standard"
                            value={account}
                            onChange={handleAccountChange}
                            inputProps={{ className: classes.acctInput }}
                            label="Select an account"
                            error={Boolean(checkErrors(".FromAccount"))}
                            helperText={checkErrors(".FromAccount")}
                            disableUnderline
                            displayEmpty
                            required
                          >
                            <MenuItem
                              value=""
                              disabled
                              className={classes.placeholder}
                            >
                              Select an account
                            </MenuItem>
                            {accounts &&
                              accounts.map((acc) => (
                                <MenuItem
                                  key={acc.AccountNumber}
                                  value={acc.AccountNumber}
                                >
                                  {acc.AccountNumber}{" "}
                                  {/* Masked account number shown */}
                                </MenuItem>
                              ))}
                          </Select>
                          {checkErrors(".FromAccount") && (
                            <FormHelperText>
                              {checkErrors(".FromAccount")}
                            </FormHelperText>
                          )}
                          {error?.accountError && (
                            <span className={classes.errorMsg}>
                              {error.accountError}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item size={{ md: 6 }}>
                        CreditCard
                      </Grid>
                      <Grid item size={{ md: 6 }} sx={{ display: "flex" }}>
                        <Typography variant="body2">Balance</Typography>
                        <Typography variant="body2">
                          &nbsp;
                          {accountDetails.AccountBalance.toLocaleString()}
                          &nbsp;
                          <CurrencyFlag
                            currency={accountDetails?.SendCurrency}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item size={{ md: 5 }}>
                  <Typography
                    variant="body2"
                    className={classes.Typo2}
                    // sx={{ pb: "10px" }}
                  >
                    What country are the funds being sent to ?&nbsp;
                  </Typography>
                  <TextField
                    size="small"
                    value={accountDetails?.ReceiversCountryName}
                    fullWidth
                    disabled
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Flag
                              code={getCountryCode(
                                accountDetails?.ReceiversCountryName
                              )}
                              className={classes.CountryFlag}
                            />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  {error?.sendCountry && (
                    <span className={classes.errorMsg}>
                      {error?.sendCountry}
                    </span>
                  )}
                  <Box sx={{ display: "flex", pt: "8px" }}>
                    <Checkbox
                      style={{ color: "var(--primary-color)" }}
                      name="checkBox"
                      checked={check}
                      onChange={handleCheck}
                    />
                    <Box>
                      <Typography className={classes.Typo2}>
                        Default beneficiary currency type as per country is
                        selected
                      </Typography>
                      <Typography sx={{ fontSize: "10px" }}>
                        You can change beneficiary currency type by uncheck
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {account ? (
                <>
                  <Grid
                    container
                    className={classes.spacingCont}
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item size={{ md: 7 }}>
                      <Grid container>
                        <Grid size={{ md: 12 }}>
                          <Typography className={classes.Typo2}>
                            Please select send money equal to
                          </Typography>
                          {/* <Box>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  className={classes.radioCSS}
                                  value={selectedOption}
                                  onChange={(evt) =>
                                    setSelectedOption(evt.target.value)
                                  } 
                                >*/}
                          <Grid container spacing={2} sx={{ fontSize: "12px" }}>
                            <Grid
                              item
                              size={{ md: 6 }}
                              className={classes.radioCSS}
                            >
                              <FormControlLabel
                                value="send"
                                control={<Radio />}
                                label="Amount I want to send"
                                checked={selectedOption === "send"}
                                onChange={handleRadioChange}
                              />
                            </Grid>
                            <Grid
                              item
                              size={{ md: 6 }}
                              className={classes.radioCSS}
                            >
                              <FormControlLabel
                                value="receive"
                                control={<Radio />}
                                label="Amount beneficiary receive"
                                checked={selectedOption === "receive"}
                                onChange={handleRadioChange}
                              />
                            </Grid>
                          </Grid>
                          {/* </RadioGroup>
                              </Box> */}
                        </Grid>
                        <Grid size={{ md: 12 }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid size={{ md: 5 }}>
                              <Typography
                                className={classes.Typo2}
                                sx={{ pb: "5px" }}
                              >
                                Amount you want to send
                              </Typography>
                              <TextField
                                variant="outlined"
                                size="small"
                                type="text"
                                disabled={selectedOption != "send"}
                                sx={{
                                  background:
                                    selectedOption !== "send"
                                      ? "rgba(0,0,0,0.12)"
                                      : "",
                                }}
                                onChange={handleAmountInputChange}
                                // onBlur={handleAmountInputBlur}
                                value={
                                  accountDetails.SendCurrency.includes("JPY")
                                    ? accountDetails.SendAmount &&
                                      !isNaN(
                                        parseFloat(accountDetails.SendAmount)
                                      )
                                      ? parseFloat(
                                          accountDetails.SendAmount
                                        ).toFixed(0)
                                      : ""
                                    : accountDetails.SendAmount
                                }
                                // value={accountDetails.SendAmount}
                                slotProps={{
                                  input: {
                                    // className: classes.borderstyle,
                                    inputMode: "decimal",
                                    pattern: "\\d*([\\.,]\\d{0,7})?",
                                    step: accountDetails?.SendCurrency?.includes(
                                      "JPY"
                                    )
                                      ? "1"
                                      : "0.0000001",
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <CurrencyFlag
                                          currency={
                                            accountDetails?.SendCurrency
                                          }
                                          className={classes.CurrencyFlag}
                                        />
                                        &nbsp; {accountDetails?.SendCurrency}
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            </Grid>
                            <Grid size={{ md: 1 }}>
                              <SwapHorizIcon
                                sx={{ mt: "25px", fill: "#44318D" }}
                              />
                            </Grid>
                            <Grid size={{ md: 6 }}>
                              <Typography
                                className={classes.Typo2}
                                sx={{ pb: "5px" }}
                              >
                                Amount beneficiary receive
                              </Typography>
                              <TextField
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                disabled={selectedOption !== "receive"}
                                onChange={handleReceiveAmountInputChange}
                                // onBlur={handleReceiveAmountInputBlur}
                                value={
                                  accountDetails.ReceiveCurrency.includes("JPY")
                                    ? accountDetails.ReceiveAmount &&
                                      !isNaN(
                                        parseFloat(accountDetails.ReceiveAmount)
                                      )
                                      ? parseFloat(
                                          accountDetails.ReceiveAmount
                                        ).toFixed(0)
                                      : ""
                                    : accountDetails.ReceiveAmount
                                }
                                // value={accountDetails?.ReceiveAmount}
                                className={`${classes.boxItem} ${
                                  check ? classes.disabledTextField : ""
                                }`}
                                sx={{
                                  background:
                                    selectedOption !== "receive"
                                      ? "rgba(0,0,0,0.12)"
                                      : "",
                                  borderRadius: "5px",
                                }}
                                slotProps={{
                                  input: {
                                    className: classes.borderstyle,
                                    inputMode: "decimal",
                                    pattern: "\\d*([\\.,]\\d{0,7})?",
                                    step: accountDetails?.ReceiveCurrency?.includes(
                                      "JPY"
                                    )
                                      ? "1"
                                      : "0.0000001",
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <FormControl fullWidth>
                                          <SelectFeild
                                            options={options}
                                            value={selectedCurrency}
                                            onChange={
                                              handleReceiverCurrencyChange
                                            }
                                            formatOptionLabel={
                                              formatOptionLabel
                                            }
                                            isSearchable={true}
                                            isDisabled={check}
                                            placeholder="Select Currency"
                                            className={
                                              classes.currencyCodeSelect
                                            }
                                            classNamePrefix="custom"
                                          />
                                        </FormControl>
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      size={{ md: 5 }}
                      sx={{
                        border: "2px dashed #44318D",
                        borderRadius: "2px",
                        p: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "auto auto 1fr",
                          gap: "10px",
                        }}
                      >
                        <Typography variant="body2" className={classes.label}>
                          Exchange Rate
                        </Typography>
                        <Typography variant="body2">:</Typography>
                        <Typography variant="body2" className={classes.label}>
                          {accountDetails.CurrencyConvDesc}
                        </Typography>

                        <Typography variant="body2" className={classes.label}>
                          Exchange Rate Valid For
                        </Typography>
                        <Typography variant="body2">:</Typography>
                        <Typography variant="body2" className={classes.label}>
                          {accountDetails.FXRateGoodForText}
                        </Typography>

                        <Typography variant="body2" className={classes.label}>
                          Transaction Fee
                        </Typography>
                        <Typography variant="body2">:</Typography>
                        <Typography variant="body2" className={classes.label}>
                          {accountDetails.TransactionDesc}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    {purposeCodeList && accountDetails?.POPVisibility && (
                      <Grid
                        size={{ xs: 12, md: 6 }}
                        className={classes.purpose}
                      >
                        <Typography variant="body2" className={classes.Typo2}>
                          Purpose of Payment
                        </Typography>

                        <TextField
                          id="PurposeCode"
                          variant="outlined"
                          value={purposeCode}
                          size="small"
                          onChange={handlePurposeCode}
                          slotProps={{
                            input: {
                              className: classes.remarks,
                            },
                          }}
                          select
                          fullWidth
                        >
                          {purposeCodeList &&
                            purposeCodeList.map((pCode) => (
                              <MenuItem
                                key={pCode.PayPurPCodeDesc}
                                value={pCode.PayPurPCodeDesc}
                              >
                                {pCode.PayPurPCode}
                              </MenuItem>
                            ))}
                        </TextField>
                        {error.purposeCodeError && (
                          <div className="error-text">
                            {error.purposeCodeError}
                          </div>
                        )}
                      </Grid>
                    )}
                    <Grid size={{ md: 6, xs: 12 }} className={classes.purpose}>
                      <Typography variant="body2" className={classes.Typo2}>
                        Additional Payment Info
                      </Typography>
                      <TextField
                        id="remarks"
                        //label="Remarks"
                        size="small"
                        multiline
                        rows={1}
                        value={remarks}
                        onChange={handleRemarksChange}
                        fullWidth
                        slotProps={{
                          input: {
                            maxLength: 140,
                          },
                        }}
                        className={classes.remarks}
                      />
                      {error.remarksError && (
                        <div className="error-text">{error.remarksError}</div>
                      )}
                    </Grid>
                    {/* )} */}
                  </Grid>

                  <Divider sx={{ marginTop: "24px" }} />
                  <Grid
                    size={{ md: 12 }}
                    className={`${classes.spacingCont} ${classes.flexCenterAlign}`}
                    sx={{ gap: "10px" }}
                  >
                    <FactCheckOutlinedIcon className={classes.icons} />
                    <Typography className={classes.textstyle}>
                      Beneficiary details
                    </Typography>
                  </Grid>

                  <CountrySpecificComponent
                    editableBeneDetails={editableBeneDetails}
                    payAgainOrRepeatTransaction={true}
                    flag={true}
                  />
                </>
              ) : (
                <></>
              )}

              {accountDetails?.Payment_instruction_validation &&
                accountDetails?.Payment_instruction_validation === "ERROR" &&
                checkbox_Details.map((data, index) => {
                  if (data?.type === "RadioButtons") {
                    return (
                      <Grid
                        container
                        className={classes.spacing}
                        spacing={0.5}
                        sx={{ pt: "10px" }}
                      >
                        <Grid
                          size={{ md: 12 }}
                          className={classes.flexCenterAlign}
                          sx={{
                            display: "flex",
                            ml: 1.3,
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={warningIcon}
                            width={20}
                            height={20}
                            style={{}}
                          />
                          &nbsp;&nbsp;
                          <Typography className={classes.message}>
                            {data.config.label?.replace(/@L\s*/, "")}
                          </Typography>
                          &nbsp;&nbsp;
                          <FormControl sx={{ display: "contents" }}>
                            <Typography
                              className={classes.message}
                              sx={{ mr: 1 }}
                            >
                              No
                            </Typography>

                            <FormControlLabel
                              value="Yes"
                              onClick={cancelPayment}
                              control={<Switch color="primary" />}
                              label={
                                <Typography className={classes.message}>
                                  Yes
                                </Typography>
                              }
                            />
                            {/* <Switch checked={radioValue} onChange={cancelPayment} /> */}
                          </FormControl>
                        </Grid>
                        {/* <Grid size={{ md: 2 }}></Grid> */}
                      </Grid>
                    );
                  }

                  return null;
                })}

              <div
                style={{
                  display: "flex",
                  marginTop: "10px ",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ color: "var(--primary-color)" }}
                  name="ReviewConfirmAck"
                  checked={checkbox.ReviewConfirmAck}
                  //onChange={handleCheckbox}
                  onChange={handleCheckBox}
                />
                <Typography
                  style={{ fontSize: "12px ", fontFamily: "Open sans" }}
                >
                  {editDetails?.ReviewConfirmAck}
                </Typography>
              </div>
              {editDetails?.PayChargeCmtIndicator &&
                editDetails?.IsSwiftGo === false && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      style={{
                        color: "var(--primary-color)",
                        marginTop: "10px",
                      }}
                      name="PayChargeCmtIndicator"
                      checked={checkbox.PayChargeCmtIndicator}
                      //onChange={handleCheckbox}
                      onChange={handleCheckBox}
                    />
                    <Typography
                      style={{ fontSize: "12px ", fontFamily: "Open sans" }}
                    >
                      {editDetails?.PayChargeCmtIndicator}
                    </Typography>
                  </div>
                )}

              <Grid container spacing={2} className={classes.spacingCont}>
                <Grid size={{ xs: 12, md: 12 }} className={classes.flexBetween}>
                  <Button className={classes.cancel} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.continue}
                    disabled={
                      // !checkedItems.ConfirmAcknowledgement ||
                      // !checkedItems.PayAllCharges  ||
                      // !radioValue
                      checkCount !== 1 && checkCount !== 2
                    }
                    onClick={validateForm}
                  >
                    Review & Pay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
      {isFinalResponse && <FinalResponse finalResponseData={finalResponse} />}
    </>
  );
};

export default PayAgain;
