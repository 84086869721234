import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const NickNameField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    disableFlag,
    handleChange,
    payAgainOrRepeatTransaction,
    addEditBene,
  } = props;
  return (
    <>
      {(payAgainOrRepeatTransaction && beneDetails?.NickName !== "") ||
        (addEditBene && (
          <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
            <Typography variant="body2" className={classes.headerstyle}>
              Nickname (Optional)
            </Typography>
            {isEditable ? (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                // className={classes.textWidth}
                value={beneDetails?.NickName}
                onChange={handleChange}
                name="NickName"
                disabled={disableFlag}
              />
            ) : beneDetails?.NickName ? (
              <span className={classes.contentstyle}>
                {beneDetails?.NickName}
              </span>
            ) : (
              "NA"
            )}
          </Grid>
        ))}

      {!payAgainOrRepeatTransaction ||
        (!addEditBene && (
          <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
            <Typography variant="body2" className={classes.headerstyle}>
              Nickname (Optional)
            </Typography>
            {isEditable ? (
              <TextField
                variant="outlined"
                size="small"
                value={beneDetails?.NickName}
                onChange={handleChange}
                // onBlur={handleBlurChange}
                name="NickName"
                disabled={disableFlag}
              />
            ) : beneDetails?.NickName ? (
              <span className={classes.contentstyle}>
                {beneDetails?.NickName}
              </span>
            ) : (
              "NA"
            )}
          </Grid>
        ))}
    </>
  );
};

export default NickNameField;
