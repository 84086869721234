import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import {
  Typography,
  TextField,
  Box,
  TextareaAutosize,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";

const BeneficiaryBankAddressField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    flag,
    review,
    payAgainOrRepeatTransaction,
    addEditBene,
    isEditable,
  } = props;

  const formattedAddress = (address) => {
    return address.replace(/^\n/, "");
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Bank Address
        </Typography>
        {addEditBene ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={formattedAddress(beneDetails?.BeneBankAddress)}
            name="BICFI"
            disabled
            multiline
            rows={2}
            sx={{
              "& .MuiInputBase-input": {
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                },
              },
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneAddressURL && (
                      <img
                        src={beneDetails?.IsValidBeneAddressURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
          />
        ) : (
          <Box className={classes.flagAlignments}>
            {isEditable ? (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={
                  beneDetails?.BeneBankAddress
                    ? beneDetails?.BeneBankAddress
                    : "NA"
                }
                disabled
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment>
                        {flag && beneDetails?.IsValidBeneAddressURL && (
                          <img
                            src={beneDetails?.IsValidBeneAddressURL}
                            className={classes.checkMark}
                            alt="checkmark"
                          />
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            ) : (
              <>
                <Typography className={classes.BankAddress}>
                  <span className={classes.contentstyle}>
                    {beneDetails?.BeneBankAddress
                      ? beneDetails?.BeneBankAddress
                      : "NA"}
                  </span>
                </Typography>
                <span className={classes.addressFlag}>
                  {flag &&
                    beneDetails?.IsValidBeneAddressURL &&
                    beneDetails?.BeneBankAddress && (
                      <img
                        src={beneDetails?.IsValidBeneAddressURL}
                        className={classes.checkMark}
                        alt="checkmark"
                      />
                    )}
                </span>
              </>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryBankAddressField;
