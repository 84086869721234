import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

const AccountNumberFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();

  const {
    handleChange,
    checkErrors,
    beneDetails,
    isEditable,
    flag,
    handleBlurChange,
    disableFlag,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const accountNumberValidationRule = {
    7: ["Japan"],
    9: ["Australia"],
    11: ["South Africa", "Colombia"],
    12: ["Canada", "China", "Hong Kong"],
    13: ["Ghana"],
    14: ["Singapore", "Vietnam"],
    15: ["Sri Lanka"],
    16: ["New Zealand", "South Korea", "Korea, Republic of", "Thailand"],
    17: ["Bangladesh", "United States", "Chile"],
    18: ["India", "Mexico", "Philippines", "Indonesia"],
    20: [
      "Malaysia",
      "Kenya",
      "Nepal",
      "Tanzania",
      "Uganda",
      "Uruguay",
      "Zambia",
    ],
    22: ["Argentina"],
    24: ["Morocco"],
  };

  const getMaxIBANLength = (country) => {
    for (const [maxLength, countries] of Object.entries(
      accountNumberValidationRule
    )) {
      if (countries.includes(country)) {
        return parseInt(maxLength, 10);
      }
    }
    return 10; // Default max length if country not listed
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    const maxLength = getMaxIBANLength(beneDetails?.Country);

    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: ` Account Number should not exceed ${maxLength} digits.`,
      }));
      return; // Prevent further input
    } else {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    handleChange(event); // Pass changes to the parent component
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    //handleBlurChange(event);
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          {(beneDetails?.Country === "Argentina" && "Account Number (CBU)") ||
            (beneDetails?.Country === "Mexico" && "CLABE Number") ||
            (beneDetails?.Country !== "Argentina" &&
              beneDetails?.Country !== "Mexico" &&
              "Account Number")}
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={beneDetails?.CdtrAcctId}
            //onChange={handleChange}
            //onBlur={handleBlurChange}
            name="CdtrAcctId"
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(localErrors.CdtrAcctId || checkErrors("CdtrAcctId"))}
            helperText={localErrors.CdtrAcctId || checkErrors("CdtrAcctId")}
            // error={Boolean(checkErrors("CdtrAcctId"))}
            // helperText={checkErrors("CdtrAcctId")}
            disabled={disableFlag}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneIBANURL && (
                      <img
                        src={beneDetails?.IsValidBeneIBANURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.CdtrAcctId ? beneDetails?.CdtrAcctId : "NA"}
            &nbsp;
            {flag && beneDetails?.IsValidBeneIBANURL && (
              <img
                src={beneDetails?.IsValidBeneIBANURL}
                className={classes.checkMark}
                alt="checkmark"
              />
            )}
          </span>
        )}
      </Grid>
    </>
  );
};

export default AccountNumberFeild;
