import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Button } from "@mui/material";
import useStyles from "./styles";
import EditIcon from "@mui/icons-material/Edit";
import {
  validateBeneficiaryDetailsReviewScreen,
  getDataForReviewBenefiToAddBenefiPage,
  validateAndSaveDetails,
  validateBeneficiaryDetails,
} from "../../../CommonService/sendPaymentService";
import CloseIcon from "@mui/icons-material/Close";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  resetInitialState,
  validateAndSaveDetailsForEditBeneInSendPaymentFlow,
} from "../../../../Redux/Reducers/SendPaymentReducer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CountrySpecificComponent from "./CountrySpecificComponent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Loader from "../Loader";
import dayjs from "dayjs";
import { setFormState } from "../../../../Redux/Reducers/addNewBeneficiaryReducer";
import LeavePage from "../LeavePage";

const ReviewBeneficiaryDetails = ({
  onContinue,
  selectedBeneDetails,
  purposeCodeList,
  nextAssignmentId,
  eTag,
  setEtag,
  setReviewDetails,
  onPrevious,
  setPreviousData,
  previousData,
  handleStep,
  editableBeneDetails,
  setEditableBeneDetails,
  setExpanded,
  setBeneficiariesPreviousData,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  // const [editableBeneDetails, setEditableBeneDetails] =
  //   useState(selectedBeneDetails);
  const [initialDetails, setInitialDetails] = useState(editableBeneDetails);
  const [flag, setFlag] = useState(true);
  const [submit, setSubmit] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  // useEffect(() => {
  //   console.log("useeffect")
  //   setInitialDetails(editableBeneDetails); // Store initial details whenever `editableBeneDetails` changes  
  // }, [editableBeneDetails]);

  const handleCancel = () => {
    navigate("/");
    dispatch(resetInitialState());
  };
  const handleDateChange = (value, name) => {
    const formattedDate = value ? value.format("MM/DD/YYYY") : "";
    // dispatch(
    //   setFormState({
    //     [name]: formattedDate, // Update the specific field
    //   })
    // );
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: formattedDate,
    }));
  };
  useEffect(() => {
    if (previousData && !selectedBeneDetails) {
      setEditableBeneDetails(
        previousData?.caseInfo?.content?.SelectedBeneDetails
      );
    }
  }, [previousData]);

  const handleContinue = async () => {
    dispatch(resetInitialState());
    try {
      const response = await validateBeneficiaryDetailsReviewScreen(
        nextAssignmentId,
        eTag,
        editableBeneDetails
      );
      if (response.data) {
        handleStep();
      }
      setReviewDetails(response.data);
      setEtag(response.headers.etag);
      // onContinue(nextAssignmentId, eTag, setReviewDetails);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePrevious = async () => {
    dispatch(resetInitialState());
    try {
      setLoading(true);
      const response = await getDataForReviewBenefiToAddBenefiPage(
        editableBeneDetails.BeneBankAddress,
        nextAssignmentId,
        eTag
      ).catch((err) => {
        setLoading(false);
        console.log(err.response.data.errorDetails[0].message);
      });
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      if (resourceData) {
        setLoading(false);
        setExpanded("panel2");
        handleClose();
      }
      setBeneficiariesPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleValidateAndSave = async () => {
    setLoading(true);
    const refreshFor = ".SelectedBeneDetails.ValidateAndSave";
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      ValidateAndSave: true,
    }));

    // try {
    // const response = await validateAndSaveDetails(
    //   editableBeneDetails,
    //   nextAssignmentId,
    //   eTag,
    //   refreshFor
    // );
    dispatch(
      validateAndSaveDetailsForEditBeneInSendPaymentFlow({
        editableBeneDetails: editableBeneDetails,
        nextAssignmentId: nextAssignmentId,
        eTag: eTag,
        refreshFor: refreshFor,
      })
    ).then((res) => {
      setLoading(false);
      if (res.payload.status !== 422) {
        setEditableBeneDetails(res.payload);
        setFlag(true);
        setSubmit(false);
        setIsEditable(false);
      }
    });
    // setEditableBeneDetails(
    //   response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
    // );
    // setFlag(true);
    // setIsEditable(false);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const handleSave = async () => {
    setLoading(true);
    dispatch(resetInitialState());
    // setEditableBeneDetails((prevDetails) => ({
    //   ...prevDetails,
    //   ValidateAndSave: true,
    // }));
    try {
      const refreshFor = ".SelectedBeneDetails.Save";
      const response = await validateAndSaveDetails(
        editableBeneDetails,
        nextAssignmentId,
        eTag,
        refreshFor
      );
      setEditableBeneDetails(
        response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
      );
      if (response?.data?.data?.caseInfo?.content?.SelectedBeneDetails) {
        setLoading(false);
      }
      setFlag(true);
      setSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
    setFlag(!flag);
    setSubmit(!submit);
    dispatch(resetInitialState());
  };
  const handleClose = () => {
    setIsEditable(false);
    setEditableBeneDetails(initialDetails); 
    setFlag(!flag);
    setSubmit(true);
    dispatch(resetInitialState());
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedValue =
      name === "CdtrAcctIdIBAN" ||
      name === "BICFI" ||
      name === "PostCode" ||
      (editableBeneDetails?.Country === "India" && name === "ClrSysMmbId") ||
      (editableBeneDetails?.Country === "South Africa" &&
        name === "ClrSysMmbId")
        ? value.toUpperCase()
        : value;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: updatedValue, //value,
    }));
  };
  const [openModal, setOpenModal] = useState(false);
  const handleStay = () => {
    setOpenModal(false);
  };
  const handleLeave = () => {
    navigate("/internationaltransfer/dashboard");
  };
  const [message, setMessage] = useState("");
  const handleBack = () => {
    const message =
      "Your payment has not been submitted and details will be lost if you proceed.";
    setMessage(message);
    setOpenModal(true);
  };
  return (
    <>
      {loading && <Loader />}
      <Grid container>
        <Grid item size={{ md: 12 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={classes.Typo1}>
              Beneficiary details
            </Typography>

            {!isEditable ? (
              <Typography
                className={classes.Typo1}
                sx={{ color: "var(--secondary-color)", cursor: "pointer" }}
                onClick={handleEdit}
              >
                <EditIcon
                  sx={{ height: "15px", fill: "var(--secondary-color)" }}
                />{" "}
                Edit beneficiary
              </Typography>
            ) : (
              <Button
                variant="outlined"
                className={classes.secondaryBtn}
                size="small"
                onClick={handleClose}
              >
                Close &nbsp;
                <CloseIcon
                  sx={{ fill: "var(--secondary-color)", height: "18px" }}
                />
              </Button>
            )}
          </Box>
        </Grid>
        <CountrySpecificComponent
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          isEditable={isEditable}
          setInitialDetails={setInitialDetails}
          handleDateChange={handleDateChange}
          handleInputChange={handleInputChange}
          flag={flag}
          review={false}
          nextAssignmentId={nextAssignmentId}
          etag={eTag}
        />
        <LeavePage
          open={openModal}
          onClose={handleStay}
          onLeave={handleLeave}
          message={message}
        />
        <Grid
          item
          size={{ md: 12 }}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <Link to="/internationaltransfer/dashboard"> */}
          <Button
            variant="outlined"
            className={classes.secondaryBtn}
            size="small"
            onClick={handleBack}
          >
            Back to dashboard &nbsp;
            <DashboardOutlinedIcon
              sx={{ fill: "var(--secondary-color)", height: "18px" }}
            />
          </Button>
          {/* </Link> */}

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="outlined"
              size="small"
              className={classes.secondaryBtn}
              onClick={handlePrevious}
            >
              Previous &nbsp;
              <ArrowUpwardIcon
                sx={{ fill: "var(--secondary-color)", height: "18px" }}
              />
            </Button>
            {submit && !isEditable && (
              <Button
                variant="contained"
                className={classes.primaryButton}
                onClick={handleStep}
                size="small"
                // disabled={activeStep === steps.length - 1}
              >
                Continue &nbsp;
                <ArrowForwardIcon sx={{ fill: "#ffff", height: "18px" }} />
              </Button>
            )}
            {isEditable && (
              <Button
                variant="contained"
                className={classes.primaryButton}
                onClick={handleValidateAndSave}
                size="small"
              >
                Validate
              </Button>
            )}
           
            {editableBeneDetails?.HideValidateButton &&
              !submit &&
              !isEditable && (
                <Button
                  className={classes.primaryButton}
                  onClick={handleSave}
                  variant="contained"
                  disabled={!editableBeneDetails?.EnableAPIAddress}
                  size="small"
                >
                  Save
                </Button>
              )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewBeneficiaryDetails;
