import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageHeader: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "0.6",
    fontFamily: "Open Sans",
  },
  rootContainer: {
    // width: "70vw !important ",
    marginTop: "10px",
    padding: "10px 30px 30px 30px",
  },
  mainHeading: {
    fontSize: "16px !important",
    lineHeight: "1 !important",
    fontWeight: "600 !important ",
  },
  subHeading: {
    fontSize: "14px !important",
    lineHeight: "0.6 !important",
    // fontWeight: "600 !important ",
  },
  flexAlignCenter: {
    display: "flex !important ",
    alignItems: "center !important",
  },
  flexColumnGap: {
    display: "flex !important ",
    flexDirection: "column",
    marginLeft: "10px",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  quickActionsCard: {
    marginTop: "10px !important",
    // width: "90% !important",
  },
  quickActionsCardContent: {
    border: "1px solid #e0e0e0 !important",
    borderRadius: "5px !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    padding: "10px 16px !important",
    "&:hover": {
      border: "1px solid var(--primary-color) !important",
    },
  },
  flexCenterGap: {
    display: "flex !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  recentPayments: {
    position: "relative",
    border: "1px solid #e0e0e0 !important",
    borderRadius: "5px !important",
  },
  statusCardContent: {
    padding: "12px !important",
  },
  futurePayments: {
    border: "1px solid #e0e0e0 !important",
    borderRadius: "5px !important",
    padding: "10px !important",
  },
  avatar: {
    width: "24px !important",
    height: "24px !important",
    backgroundColor: "var(--secondary-color) !important",
  },
  avatarInitial: {
    fontSize: "12px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "180px !important",
  },
  spacing: { marginTop: "20px !important" },
  flexCenter: {
    display: "flex !important ",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "3px !important",
  },
  stepperStyle: {
    "& .MuiStep-root": {
      "& .MuiStepIcon-root": {
        color: "gray",
        fontSize: "16px !important",
      },
    },
    "& .MuiStep-root.Mui-completed .MuiStepIcon-root": {
      color: "var(--primary-color)",
      fontSize: "16px !important",
    },
  },
  icons: {
    cursor: "pointer",
    color: "#000 !important",
  },
  carouselIcons: {
    width: "8px !important ",
    height: "8px !important ",
  },
  carouselLeftBtn: {
    position: "absolute !important",
    top: "47%",
    left: "0 ",
    transform: "translateY(-50%)",
    zIndex: 2,
    // "@media screen and (min-width: 1440px) and (max-width: 1600px)": {
    //   position: "absolute !important",
    //   top: "46% !important",
    //   left: "0 !important",
    //   transform: "translateY(-50%)",
    //   zIndex: 2,
    // },
  },
  carouselRightBtn: {
    position: "absolute !important",
    top: "47% !important ",
    right: "0",
    transform: "translateY(-50%)",
    zIndex: 2,
    // "@media screen and (min-width: 1440px) and (max-width: 1600px)": {
    //   position: "absolute !important",
    //   top: "46% !important",
    //   right: "0 !important",
    //   transform: "translateY(-50%)",
    //   zIndex: 2,
    // },
  },
}));
export default useStyles;
