import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { TextField, Typography } from "@mui/material";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { useSelector } from "react-redux";

const BeneficiaryAddress = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    handleChange,
    isEditable,
    checkErrors,
    disableFlag,
    Country,
  } = props;

  const country = beneDetails?.Country;
  const [localErrors, setLocalErrors] = useState({});

  const zipValidationRules = {
    4: [
      "Denmark",
      "Luxembourg",
      "Norway",
      "Australia",
      "Bangladesh",
      "Georgia",
      "Hungary",
      "Liechtenstein",
      "Switzerland",
      "Bulgaria",
      "Ghana",
      "Philippines",
      "South Africa",
      "Austria",
      "Belgium",
      "Slovenia",
      "Cyprus",
      "New Zealand",
      "South Korea",
      "Korea, Republic of",
    ],
    5: [
      "Egypt",
      "Guatemala",
      "Sweden",
      "Mexico",
      "Turkey",
      "Malaysia",
      "Morocco",
      "Sri Lanka",
      "Finland",
      "Greece",
      "Italy",
      "Spain",
      "Germany",
      "Croatia",
      "Estonia",
      "France",
      "Qatar",
      "Indonesia",
      "Kenya",
      "Costa Rica",
      "Nepal",
      "Pakistan",
      "Tanzania",
      "Uganda",
      "Thailand",
      "Ukraine",
      "Uruguay",
      "Zambia",
    ],
    6: [
      "India",
      "Canada",
      "United Arab Emirates",
      "Czech Republic",
      "Netherlands",
      "China",
      "Poland",
      "Singapore",
      "Colombia",
      "Vietnam",
      "Romania",
      "Slovakia",
    ],
    7: ["Israel", "Malta", "Latvia", "Lithuania"],
    8: ["Argentina", "Chile", "Japan", "Ireland", "Portugal", "United Kingdom"],
    9: ["Brazil"],
    10: ["United States"],
  };

  const validationRules = {
    BuildingNumber: {
      maxLength: 16,
      errorMessage:
        "Flat Number / Building Number cannot exceed 16 characters.",
    },
    BuildingName: {
      maxLength: 35,
      errorMessage: "Flat Name / Building Name cannot exceed 35 characters.",
    },
    StreetName: {
      maxLength: 70,
      errorMessage: "Street Name / Area Name cannot exceed 70 characters.",
    },
    TownName: {
      maxLength: 35,
      errorMessage: "Town / City cannot exceed 35 characters.",
    },
  };

  const getMaxZipLength = (country) => {
    for (const [maxLength, countries] of Object.entries(zipValidationRules)) {
      if (countries.includes(country)) {
        return parseInt(maxLength, 10);
      }
    }
    return 10; // Default max length if country not listed
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;

    if (name === "PostCode") {
      // Get the country-specific maximum ZIP length
      const maxLength = getMaxZipLength(country);
      if (value.length > maxLength) {
        if (countryNames.includes(country)) {
          setLocalErrors((prev) => ({
            ...prev,
            [name]: `P.O Box Number cannot exceed ${maxLength} digits.`,
          }));
          return;
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            [name]: `Zip Code / Postal Code cannot exceed ${maxLength} characters.`,
          }));
          return;
        } // Prevent further input beyond maxLength
      } else {
        // Clear the error if input is within the valid limit
        setLocalErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      // Validate other fields
      const maxLength = validationRules[name]?.maxLength;

      if (maxLength && value.length > maxLength) {
        setLocalErrors((prev) => ({
          ...prev,
          [name]: validationRules[name].errorMessage,
        }));
        return;
      } else {
        // Clear the error if input is within the valid limit
        setLocalErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }

    handleChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear the error message when moving to another field
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const countryNames = ["United Arab Emirates", "Qatar"];

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Flat Number / Building Number
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            // className={classes.textWidth}
            value={beneDetails?.BuildingNumber}
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(
              localErrors.BuildingNumber || checkErrors("BuildingNumber")
            )}
            helperText={
              localErrors.BuildingNumber || checkErrors("BuildingNumber")
            }
            // onChange={handleChange}
            // error={Boolean(checkErrors("BuildingNumber"))}
            // helperText={checkErrors("BuildingNumber")}
            disabled={disableFlag}
            name="BuildingNumber"
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.BuildingNumber ? beneDetails?.BuildingNumber : "NA"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Flat Name / Building Name (Optional)
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            // className={classes.textWidth}
            value={beneDetails?.BuildingName}
            // onChange={handleChange}
            name="BuildingName"
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(
              localErrors.BuildingName || checkErrors("BuildingName")
            )}
            helperText={localErrors.BuildingName || checkErrors("BuildingName")}
            // error={Boolean(checkErrors("BuildingName"))}
            // helperText={checkErrors("BuildingName")}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.BuildingName ? beneDetails?.BuildingName : "NA"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Street Name / Area Name
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            // className={classes.textWidth}
            value={beneDetails?.StreetName}
            //onChange={handleChange}
            name="StreetName"
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(localErrors.StreetName || checkErrors("StreetName"))}
            helperText={localErrors.StreetName || checkErrors("StreetName")}
            // error={Boolean(checkErrors("StreetName"))}
            // helperText={checkErrors("StreetName")}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.StreetName ? beneDetails?.StreetName : "NA"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Town / City
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            // className={classes.textWidth}
            value={beneDetails?.TownName}
            //onChange={handleChange}
            name="TownName"
            onChange={handleLocalValidation}
            onBlur={handleFieldBlur}
            error={Boolean(localErrors.TownName || checkErrors("TownName"))}
            helperText={localErrors.TownName || checkErrors("TownName")}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.TownName ? beneDetails?.TownName : "NA"}
          </span>
        )}
      </Grid>
      {/*  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Country
        </Typography>
        <span className={classes.contentstyle}>
          {beneDetails?.Country || Country}
        </span>
      </Grid> */}
      {beneDetails?.Country === "Hong Kong" ? (
        <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            Zip Code / Postal Code
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              // className={classes.textWidth}
              value={beneDetails?.PostCode}
              onChange={handleChange}
              name="PostCode"
              error={Boolean(checkErrors("PostCode"))}
              helperText={checkErrors("PostCode")}
              disabled={true}
            />
          ) : (
            <span className={classes.contentstyle}>
              {beneDetails?.PostCode ? beneDetails?.PostCode : "NA"}
            </span>
          )}
        </Grid>
      ) : (
        <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            {countryNames.includes(beneDetails?.Country)
              ? `P.O Box Number`
              : `Zip Code / Postal Code`}
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              // className={classes.textWidth}
              value={beneDetails?.PostCode}
              // onChange={handleChange}
              name="PostCode"
              // error={Boolean(checkErrors("PostCode"))}
              // helperText={checkErrors("PostCode")}
              onChange={handleLocalValidation}
              onBlur={handleFieldBlur}
              error={Boolean(localErrors.PostCode || checkErrors("PostCode"))}
              helperText={localErrors.PostCode || checkErrors("PostCode")}
              disabled={disableFlag}
            />
          ) : (
            <span className={classes.contentstyle}>
              {beneDetails?.PostCode ? beneDetails?.PostCode : "NA"}
            </span>
          )}
        </Grid>
      )}
    </>
  );
};

export default BeneficiaryAddress;
