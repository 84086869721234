import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const AgenciaNumberField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { editableBeneDetails, SelectedBeneDetails, flag } = props;

  return (
    <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
      <Typography variant="body2" className={classes.headerstyle}>
        Agencia Number
      </Typography>
      {editableBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {editableBeneDetails?.ClrSysMmbId} &nbsp;
          {flag && editableBeneDetails?.IsValidBeneAddressURL && (
            <img
              src={editableBeneDetails?.IsValidBeneAddressURL}
              alt="checkmark"
              className={classes.checkMark}
            />
          )}
        </span>
      ) : SelectedBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {SelectedBeneDetails?.ClrSysMmbId}&nbsp;
          {flag && SelectedBeneDetails?.IsValidBeneAddressURL && (
            <img
              src={SelectedBeneDetails?.IsValidBeneAddressURL}
              alt="checkmark"
              className={classes.checkMark}
            />
          )}
        </span>
      ) : (
        "NA"
      )}
    </Grid>
  );
};

export default AgenciaNumberField;
