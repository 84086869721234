import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAccountsData,
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverAmount,
  getDataForReceiverCurrency,
  sendPaymentFinalSubmit,
  sendPaymentProcessSubmit,sendPaymentCancelApi
} from "../../components/CommonService/sendPaymentService";
// apicalls
export const fetchAccounts_Data = createAsyncThunk(
  "sendPaymentDataFlow/fetchAccountsData",
  async () => {
    const res = await fetchAccountsData();
    return {
      etag: res.headers["etag"],
      data: res.data,
    };
  }
);
export const fetchDataFromAccount = createAsyncThunk(
  "sendPaymentDataFlow/getDataForFromAccount",
  async ({ value, nextAssignmentId, eTag }) => {
    const res = await getDataForFromAccount(value, nextAssignmentId, eTag);
    return {
      data: res.data.data.caseInfo.content,
    };
  }
);
export const fetchDataForSendAmount = createAsyncThunk(
  "sendPaymentDataFlow/getDataForSendAmount",
  async ({
    account,
    amount,
    ReceiveCurrency,
    ReceiversCountryName,
    flagselect,
    nextAssignmentId,
    eTag,
    actionName,
  }) => {
    const res = await getDataForSendAmount(
      account,
      amount,
      ReceiveCurrency,
      ReceiversCountryName,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    );
    return {
      data: res.data.data.caseInfo.content,
    };
  }
);

export const fetchDataForReceiverAmount = createAsyncThunk(
  "sendPaymentDataFlow/getDataForReceiverAmount",
  async ({
    account,
    SendAmount,
    amount,
    ReceiveCurrency,
    ReceiversCountryName,
    flagselect,
    nextAssignmentId,
    eTag,
    actionName,
  }) => {
    const res = await getDataForReceiverAmount(
      account,
      SendAmount,
      amount,
      ReceiveCurrency,
      ReceiversCountryName,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    );
    return {
      data: res.data.data.caseInfo.content,
    };
  }
);
export const fetchDataForReceiverCurrency = createAsyncThunk(
  "sendPaymentDataFlow/getDataForReceiverCurrency",
  async ({
    account,
    SendAmount,
    ReceiveAmount,
    ReceiversCountryName,
    newCurrency,
    flagselect,
    nextAssignmentId,
    eTag,
    actionName,
  }) => {
    const res = await getDataForReceiverCurrency(
      account,
      SendAmount,
      ReceiveAmount,
      ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    );
    return {
      data: res.data.data.caseInfo.content,
    };
  }
);

export const SendPaymentProcessSubmitAPI = createAsyncThunk(
  "sendPaymentDataFlow/sendPaymentProcessSubmit",
  async ({
    nextAssignmentId,
    eTag,
    actionName,
    radioValue,
    Payment_instruction_validation,
  }) => {
    const response = await sendPaymentProcessSubmit(
      nextAssignmentId,
      eTag,
      actionName,
      radioValue,
      // review_Details?.Payment_instruction_validation
    );
  }
);
