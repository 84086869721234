import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import RoutingCodeField from "./CommonFields/RoutingCodeField";
import AddressMessage from "./CommonFields/AddressMessage";
import NickNameField from "./CommonFields/NickNameField";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import PhoneNumberFeild from "./CommonFields/PhoneNumberFeild";
import EmailIDField from "./CommonFields/EmailIDField";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountNumberFeild from "./CommonFields/AccountNumberFeild";
import CountryFeild from "./CommonFields/CountryFeild";
import AccountTypeFeild from "./CommonFields/AccountTypeFeild";
import { useDispatch } from "react-redux";
import { setFormState as setFormStateForAddNewbene } from "../../Redux/Reducers/addNewBeneficiaryReducer";
import { setFormState as setFormStateForEditBene } from "../../Redux/Reducers/editBeneficiaryReducer";
import { setFormState as setFormStateForSendPaymentFlow } from "../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";

const Colombia = (props) => {
  const dispatch = useDispatch();

  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    setInitialDetails,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    addNewBeneSendPaymentFlow,
    //below is for edit beneficiary
    editBene,
  } = props;

  const [localErrors, setLocalErrors] = useState({});
  const [TaxIDType, setTaxIDType] = useState("");

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);
  const newBeneData = addNewBeneSendPaymentFlow
    ? state.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const setFormState = addNewBeneSendPaymentFlow
    ? setFormStateForSendPaymentFlow
    : editBene
    ? setFormStateForEditBene
    : setFormStateForAddNewbene;

  const handleTaxIDTypeChange = (event) => {
    const { name, value } = event.target;
    setTaxIDType(value);
    handleInputChange(event);
    if (editableBeneDetails) {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,

        TaxID: "",
      }));
    }
  };
  
  const handleTaxIDTypeChangeV1 = (event) => {
    const { name, value } = event.target;
    setTaxIDType(value);
    if (newBeneData?.formState) {
      dispatch(
        setFormState({
          ["TaxID"]: "",
        })
      );
    }
    handleChange(event);
  };
  const validationRules = {
    "Cedulas Card Number": {
      maxLength: 10,
      errorMessage: `Cedulas card number should not exceed 10 digits.`,
    },

    "National Tax ID (NIT)": {
      maxLength: 11,
      errorMessage: `National  Tax ID  number should be  exactly 11 characters.  Example format is 123456789-5.`,
    },
  };
  const handleEmailValidation = (event) => {
    const { name, value } = event.target;
    const maxLength = 254;
    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "Email ID cannot exceed 254 characters.",
      }));
      return;
    }
    handleChange(event);
  };
  const handleEmailValidationV1 = (event) => {
    const { name, value } = event.target;
    const maxLength = 254;
    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "Email ID cannot exceed 254 characters.",
      }));
      return;
    }
    handleInputChange(event);
  };
  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const rule = validationRules[TaxIDType];
    const maxLength = validationRules[TaxIDType]?.maxLength;
    if (rule) {
      if (maxLength && value.length > maxLength) {
        setLocalErrors((prev) => ({
          ...prev,
          [name]: validationRules[TaxIDType].errorMessage,
        }));
        return;
      } else {
        // Clear the error if input is within the valid limit
        setLocalErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
    handleInputChange(event);
  };
  const handleLocalValidationV1 = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const rule = validationRules[TaxIDType];
    const maxLength = validationRules[TaxIDType]?.maxLength;
    if (rule) {
      if (maxLength && value.length > maxLength) {
        setLocalErrors((prev) => ({
          ...prev,
          [name]: validationRules[TaxIDType].errorMessage,
        }));
        return;
      } else {
        // Clear the error if input is within the valid limit
        setLocalErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
    handleChange(event);
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    if (name === "BICFI" || name === "ClrSysMmbId") {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        } else if (name === "ClrSysMmbId") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            ClrSysMmbId:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.ClrSysMmbId,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <PayToCountryFeild countryCode={SelectedBeneDetails?.CountryCode} />
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb
                  ? SelectedBeneDetails?.MaskAccNumb
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Account Type
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Phone Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.PhoneNumber
                  ? `+57 ${reviewDetails?.SelectedBeneDetails?.PhoneNumber}`
                  : "--"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Tax ID Type
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxIDTypeColombia
                  ? SelectedBeneDetails?.TaxIDTypeColombia
                  : "NA"}
              </Typography>
            </Grid>
            {/* <RoutingCodeField SelectedBeneDetails={SelectedBeneDetails} /> */}
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                {SelectedBeneDetails?.TaxIDTypeColombia
                  ? SelectedBeneDetails?.TaxIDTypeColombia
                  : "Cedulas Card Number / National Tax ID (NIT)"}
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxID ? SelectedBeneDetails?.TaxID : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Email ID
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.EmailID
                  ? SelectedBeneDetails?.EmailID
                  : "NA"}
              </Typography>
            </Grid>
            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />
            <BeneficiaryBankNameField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>

                <AccountNumberFeild
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  flag={flag}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Beneficiary Account Type
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="CdtrAcctTpPrtry"
                    value={newBeneData?.formState?.CdtrAcctTpPrtry}
                    error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                    helperText={checkErrors("CdtrAcctTpPrtry")}
                    disabled={newBeneData?.submitFlag}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment>
                            {newBeneData?.formState?.IsValidBeneIBANURL && (
                              <img
                                src={newBeneData?.formState?.IsValidBeneIBANURL}
                                className={classes.checkMark}
                              />
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                  >
                    <MenuItem value={"Savings/General"}>
                      Savings/General
                    </MenuItem>
                    {/* <MenuItem value={"Savings/Deposit"}>
                          Savings/Deposit
                        </MenuItem> */}
                    <MenuItem value={"Checking/Current"}>
                      Checking/Current
                    </MenuItem>
                  </Select>
                  {checkErrors("CdtrAcctTpPrtry") && (
                    <FormHelperText style={{ color: "red" }}>
                      {checkErrors("CdtrAcctTpPrtry")}
                    </FormHelperText>
                  )}

                  {/* {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )} */}
                </Grid>
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Tax ID Type
                  </Typography>
                  <Select
                    labelId="TaxIDTypeColombia"
                    id="TaxIDTypeColombia"
                    name="TaxIDTypeColombia"
                    error={Boolean(checkErrors("TaxIDTypeColombia"))}
                    value={newBeneData?.formState?.TaxIDTypeColombia}
                    helperText={checkErrors("TaxIDTypeColombia")}
                    disabled={newBeneData?.submitFlag}
                    onChange={handleTaxIDTypeChangeV1}
                    size="small"
                    fullWidth
                  >
                    <MenuItem value={"Cedulas Card Number"}>
                      Cedulas Card Number
                    </MenuItem>
                    <MenuItem value={"National Tax ID (NIT)"}>
                      National Tax ID (NIT)
                    </MenuItem>
                  </Select>
                  {checkErrors("TaxIDTypeColombia") && (
                    <FormHelperText style={{ color: "red" }}>
                      {checkErrors("TaxIDTypeColombia")}
                    </FormHelperText>
                  )}
                </Grid>
                {newBeneData?.formState?.TaxIDTypeColombia && (
                  <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        {newBeneData?.formState?.TaxIDTypeColombia}
                      </Typography>

                      <TextField
                        variant="outlined"
                        size="small"
                        name="TaxID"
                        value={newBeneData?.formState?.TaxID}
                        // error={Boolean(checkErrors("TaxID"))}
                        // helperText={checkErrors("TaxID")}
                        onChange={handleLocalValidationV1}
                        onBlur={handleFieldBlur}
                        error={Boolean(
                          localErrors.TaxID || checkErrors("TaxID")
                        )}
                        helperText={localErrors.TaxID || checkErrors("TaxID")}
                        disabled={newBeneData?.submitFlag}
                        //onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                )}
                <BeneficiaryBankBICCodeField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />

                {newBeneData?.formState?.BeneBankName && (
                  <BeneficiaryBankNameField
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                )}
                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                    addEditBene={true}
                  />
                )}
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />
                  <NickNameField
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />

                <PhoneNumberFeild
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  handleBlurChange={handleRefreshInputBlur}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                  country={newBeneData?.formState?.Country}
                />

                {/* <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Email ID
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newBeneData?.formState?.EmailID}
                    onChange={handleEmailValidation}
                    error={Boolean(
                      localErrors.EmailID || checkErrors("EmailID")
                    )}
                    onBlur={handleFieldBlur}
                    helperText={localErrors.EmailID || checkErrors("EmailID")}
                    name="EmailID"
                    disabled={newBeneData?.submitFlag}
                  />
                </Grid> */}
                <EmailIDField
                  editableBeneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleInputChange={handleChange}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                  checkErrors={checkErrors}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <AccountNumberFeild
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                checkErrors={checkErrors}
                flag={flag}
              />
              <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Account Type
                </Typography>
                {isEditable ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="CdtrAcctTpPrtry"
                    value={editableBeneDetails?.CdtrAcctTpPrtry}
                    error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                    helperText={checkErrors("CdtrAcctTpPrtry")}
                    disabled={newBeneData?.submitFlag}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment>
                            {editableBeneDetails?.IsValidBeneIBANURL && (
                              <img
                                src={editableBeneDetails?.IsValidBeneIBANURL}
                                className={classes.checkMark}
                              />
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                  >
                    <MenuItem value={"Savings/General"}>
                      Savings/General
                    </MenuItem>
                    <MenuItem value={"Checking/Current"}>
                      Checking/Current
                    </MenuItem>
                  </Select>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctTpPrtry
                      ? editableBeneDetails?.CdtrAcctTpPrtry
                      : "NA"}
                  </span>
                )}
                {checkErrors("CdtrAcctTpPrtry") && (
                  <FormHelperText style={{ color: "red" }}>
                    {checkErrors("CdtrAcctTpPrtry")}
                  </FormHelperText>
                )}
              </Grid>
              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}
              <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Tax ID Type
                </Typography>
                {isEditable ? (
                  <Select
                    labelId="TaxIDTypeColombia"
                    id="TaxIDTypeColombia"
                    name="TaxIDTypeColombia"
                    error={Boolean(checkErrors("TaxIDTypeColombia"))}
                    value={editableBeneDetails?.TaxIDTypeColombia || ""}
                    helperText={checkErrors("TaxIDTypeColombia")}
                    onChange={handleTaxIDTypeChange}
                    size="small"
                    fullWidth
                  >
                    <MenuItem value={"Cedulas Card Number"}>
                      Cedulas Card Number
                    </MenuItem>
                    <MenuItem value={"National Tax ID (NIT)"}>
                      National Tax ID (NIT)
                    </MenuItem>
                  </Select>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxIDTypeColombia
                      ? editableBeneDetails?.TaxIDTypeColombia
                      : "NA"}
                  </span>
                )}
                {checkErrors("TaxIDTypeColombia") && (
                  <FormHelperText style={{ color: "red" }}>
                    {checkErrors("TaxIDTypeColombia")}
                  </FormHelperText>
                )}
              </Grid>
              {/* <RoutingCodeField
                editableBeneDetails={editableBeneDetails}
                handleInputChange={handleInputChange}
                handleBlurChange={handleEditChange}
                isEditable={isEditable}
                checkErrors={checkErrors}
                Country={editableBeneDetails?.Country}
              /> */}
              <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                <FormControl fullWidth>
                  <Typography variant="body2" className={classes.headerstyle}>
                    {editableBeneDetails?.TaxIDTypeColombia
                      ? editableBeneDetails?.TaxIDTypeColombia
                      : "Cedulas Card Number / National Tax ID (NIT)"}
                  </Typography>
                  {isEditable ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      name="TaxID"
                      value={editableBeneDetails?.TaxID || " "}
                      // error={Boolean(checkErrors("TaxID"))}
                      // helperText={checkErrors("TaxID")}
                      onChange={handleLocalValidation}
                      onBlur={handleFieldBlur}
                      error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                      helperText={localErrors.TaxID || checkErrors("TaxID")}

                      //onChange={handleChange}
                    />
                  ) : (
                    <span className={classes.contentstyle}>
                      {editableBeneDetails.TaxID
                        ? editableBeneDetails.TaxID
                        : "NA"}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <EmailIDField
                editableBeneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleInputChange={handleInputChange}
                checkErrors={checkErrors}
                flag={flag}
              />
              <PhoneNumberFeild
                beneDetails={editableBeneDetails}
                checkErrors={checkErrors}
                handleChange={handleInputChange}
                isEditable={isEditable}
                country={editableBeneDetails?.Country}
                disableFlag={editableBeneDetails?.submitFlag}
              />
              {/* <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Email ID
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={editableBeneDetails?.EmailID}
                    onChange={handleEmailValidationV1}
                    error={Boolean(
                      localErrors.EmailID || checkErrors("EmailID")
                    )}
                    onBlur={handleFieldBlur}
                    helperText={localErrors.EmailID || checkErrors("EmailID")}
                    name="EmailID"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.EmailID
                      ? editableBeneDetails.EmailID
                      : "NA"}
                  </span>
                )}
              </Grid>

              <PhoneNumberFeild
                beneDetails={editableBeneDetails}
                checkErrors={checkErrors}
                handleChange={handleInputChange}
                isEditable={isEditable}
                country={editableBeneDetails?.Country}
                disableFlag={editableBeneDetails?.submitFlag}
              />
              {/* <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Phone Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails.PhoneNumber}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="PhoneNumber"
                    fullWidth
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment>
                            <Typography>+57 &nbsp;</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.PhoneNumber
                      ? `+57 ${editableBeneDetails.PhoneNumber}`
                      : "NA"}
                  </span>
                )}
              </Grid> */}

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}

              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
              />
              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Colombia;
