import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import { useSelector } from "react-redux";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import NickNameField from "./CommonFields/NickNameField";
import AddressMessage from "./CommonFields/AddressMessage";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import PhoneNumberFeild from "./CommonFields/PhoneNumberFeild";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountNumberFeild from "./CommonFields/AccountNumberFeild";
import CountryFeild from "./CommonFields/CountryFeild";
const Chile = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    setInitialDetails,
    // below is for  add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    formFlag,
    formState,
    setFormState,
    handleRefreshInputBlur,
    handleValidate,
    addNewBeneSendPaymentFlow,
    //below is for edit beneficiary
    editBene,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const validationRules = {
    TaxID: {
      maxLength: 10,
      errorMessage: `RUT number should be exactly 10 Characters.  Example format is 12345678-9.`,
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleLocalValidationV1 = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleInputChange(event); // Pass the change to parent
  };

  const handleFieldBlurV1 = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const state = useSelector((state) => state);
  const newBeneData = addNewBeneSendPaymentFlow
    ? state.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      Edit: isEditable,
    }));

    const refereshFor = ".SelectedBeneDetails.BICFI";
    try {
      const payLoad = {
        content: {
          SelectedBeneDetails: {
            [name]: value,
            Edit: isEditable,
          },
        },
        pageInstructions: [],
      };
      const response = await validateEditBeneficiaryFields(
        nextAssignmentId,
        payLoad,
        etag,
        refereshFor
      );
      if (name === "BICFI") {
        setEditableBeneDetails((prevDetails) => ({
          ...prevDetails,
          BICFI:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails?.BICFI,
          BeneBankAddress:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankAddress,
          BeneBankName:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankName,
          Edit: isEditable,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={reviewDetails?.SelectedBeneDetails}
              isEditable={false}
            />
            <PayToCountryFeild
              countryCode={reviewDetails?.SelectedBeneDetails?.CountryCode}
            />
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.MaskAccNumb
                  ? reviewDetails?.SelectedBeneDetails?.MaskAccNumb
                  : "NA"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Account Type
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? reviewDetails?.SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "NA"}
              </Typography>
            </Grid>

            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Phone Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.PhoneNumber
                  ? `+56 ${reviewDetails?.SelectedBeneDetails?.PhoneNumber}`
                  : "--"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                RUT Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.TaxID
                  ? reviewDetails?.SelectedBeneDetails?.TaxID
                  : "--"}
              </Typography>
            </Grid>

            <BeneficiaryBankBICCodeField
              beneDetails={reviewDetails?.SelectedBeneDetails}
              isEditable={false}
            />

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankNameField
              beneDetails={reviewDetails?.SelectedBeneDetails}
              review={review}
            />
            <BeneficiaryBankAddressField
              beneDetails={reviewDetails?.SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>
                <AccountNumberFeild
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  flag={flag}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Beneficiary Account Type
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="CdtrAcctTpPrtry"
                    value={newBeneData?.formState?.CdtrAcctTpPrtry}
                    error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                    helperText={checkErrors("CdtrAcctTpPrtry")}
                    disabled={newBeneData?.submitFlag}
                    onChange={handleChange}
                    size="small"
                    style={{ width: "100%" }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment>
                            {newBeneData?.formState?.IsValidBeneIBANURL && (
                              <img
                                src={newBeneData?.formState?.IsValidBeneIBANURL}
                                className={classes.checkMark}
                              />
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                  >
                    <MenuItem value={"Savings"}>
                      Savings
                    </MenuItem>
                    <MenuItem value={"Checkings"}>
                      Checkings
                    </MenuItem>
                    <MenuItem value={"Demands"}>
                      Demands
                    </MenuItem>
                  </Select>
                  {checkErrors("CdtrAcctTpPrtry") && (
                    <FormHelperText style={{ color: "red" }}>
                      {checkErrors("CdtrAcctTpPrtry")}
                    </FormHelperText>
                  )}

                  {/* {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )} */}
                </Grid>
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    RUT Number
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="TaxID"
                    fullWidth
                    value={newBeneData?.formState?.TaxID}
                    //error={Boolean(checkErrors("TaxID"))}
                    //helperText={checkErrors("TaxID")}
                    disabled={newBeneData?.submitFlag}
                    //onChange={handleChange}
                    onChange={handleLocalValidation}
                    onBlur={handleFieldBlur}
                    error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                    helperText={localErrors.TaxID || checkErrors("TaxID")}
                  />
                </Grid>
                <Grid container size={{ md: 12 }}>
                  <BeneficiaryBankBICCodeField
                    beneDetails={newBeneData?.formState}
                    isEditable={true}
                    handleChange={handleChange}
                    handleBlurChange={handleRefreshInputBlur}
                    disableFlag={newBeneData?.submitFlag}
                    checkErrors={checkErrors}
                    flag={true}
                  />

                  {newBeneData?.formState?.BeneBankName && (
                    <BeneficiaryBankNameField
                      beneDetails={newBeneData?.formState}
                      addEditBene={true}
                    />
                  )}

                  {newBeneData?.formState?.BeneBankAddress && (
                    <BeneficiaryBankAddressField
                      beneDetails={newBeneData?.formState}
                      flag={true}
                      addEditBene={true}
                    />
                  )}
                </Grid>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />
                  <NickNameField
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <PhoneNumberFeild
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  handleBlurChange={handleRefreshInputBlur}
                  isEditable={true}
                  country={newBeneData?.formState?.Country}
                  disableFlag={newBeneData?.submitFlag}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <AccountNumberFeild
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                checkErrors={checkErrors}
                flag={flag}
              />

              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Account Type
                </Typography>
                {isEditable ? (
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      value={editableBeneDetails?.CdtrAcctTpPrtry}
                      onChange={handleInputChange}
                      size="small"
                    >
                    <MenuItem value={"Savings"}>
                      Savings
                    </MenuItem>
                    <MenuItem value={"Checkings"}>
                      Checkings
                    </MenuItem>
                    <MenuItem value={"Demands"}>
                      Demands
                    </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctTpPrtry
                      ? editableBeneDetails?.CdtrAcctTpPrtry
                      : "NA"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                  <img
                    src={editableBeneDetails?.IsValidAccountTypeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>

              <PhoneNumberFeild
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                checkErrors={checkErrors}
                isEditable={isEditable}
                country={editableBeneDetails?.Country}
                disableFlag={editableBeneDetails?.submitFlag}
              />
              <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  RUT Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    name="TaxID"
                    value={editableBeneDetails?.TaxID}
                    error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                    helperText={localErrors.TaxID || checkErrors("TaxID")}
                    onChange={handleLocalValidationV1}
                    onBlur={handleFieldBlurV1}
                    fullWidth
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.TaxID
                      ? editableBeneDetails.TaxID
                      : "NA"}
                  </span>
                )}
              </Grid>

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}

              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
              />
              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Chile;
