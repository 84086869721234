import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import axios from "axios";
import endPoints from "../../../../config/Api";
import {
  refreshAccessToken,
  getAccessToken,
  isTokenExpired,
} from "../../../Authorization/Services/authService";
import {
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Circle as CircleIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ModalPopup from "./Dialog";
import { Link, useNavigate } from "react-router-dom";
import {
  resetInitialState,
  fetchCountries,
} from "../../../../Redux/Reducers/addNewBeneficiaryReducer";
import {
  ToggleButton,
  Card,
  CardContent,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Loader from "../Loader";
import { fetchEditBeneficiary } from "../../../../Redux/Reducers/editBeneficiaryReducer";
import { useDispatch, useSelector } from "react-redux";
import { getVisibleIndicators } from "../../../CommonUtils/CommonUtils";
import AddNewBeneficiary from "../../AddNewBene/AddNewBeneficiary";
import EditBeneficiary from "../../AddNewBene/EditBeneficiary";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AddModifyBeneficiary = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [newFormFlag, setNewFormFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addnewbene, setAddnewbene] = useState(true);
  const [editbene, setEditbene] = useState(false);

  const addNewBeneficiaryData = useSelector((data) => data?.addNewBeneficiary);
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [selected, setSelected] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    try {
      let token = getAccessToken();
      if (isTokenExpired(token)) {
        token = await refreshAccessToken(); // Refresh the token
      }
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.addModifyBeneficiary.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data);
      setFilteredData(response?.data?.data);
      setSearchData(response?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      localStorage.clear();
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetInitialState());
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(fetchCountries());
    // setCountries(addNewBeneficiaryData?.countries);
  }, [editbene,addnewbene]);

  const handleSelectedCountry = (e) => {
    setSelectedCountry(e.target.value);
    let selectedCountry = e.target.value;
    // Filter data whenever selectedCountry changes
    let filtered =
      selectedCountry && selectedCountry !== "All"
        ? data.filter((item) => item.Country === selectedCountry)
        : data; // Show all data if no country is selected
    setFilteredData(filtered);
    setPage(0); // Reset to the first page on filter change
  };

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      // fetchData();
      setData(searchData);
      setFilteredData(searchData);
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.FullName &&
        row.FullName?.toLowerCase().includes(event.target.value?.toLowerCase())
    );
    setData(SearchedData);
    setFilteredData(SearchedData);

    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleNewBeneficiary = () => {
    /* setNewFormFlag(true);
      setOpen(!open); */
    // navigate("/addnewbeneficiary");
    setAddnewbene(!addnewbene);
    dispatch(resetInitialState());

    setEditbene(false);
  };

  const _handleClose = () => {
    fetchData();
    dispatch(resetInitialState());
    setOpen(false);
  };

  const _handleClickAndNavigate = (row) => {
    // _handleClick(row);
    setLoading(true);
    dispatch(fetchEditBeneficiary({ pyGUID: row?.pyGUID })).then((res) => {
      // navigate("/editbeneficiary");
      setLoading(false);
    });
    setEditbene(!editbene);
    setAddnewbene(!addnewbene);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleNextPage = () => {
    if ((page + 1) * rowsPerPage < data.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {/* <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            <KeyboardArrowLeftIcon className={classes.arrowIcon} />
          </IconButton> */}

        {/* Page count in the center */}
        <Typography
          variant="body2"
          sx={{ margin: "0 16px" }}
          className={classes.arrowContent}
        >
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        {/* <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            <KeyboardArrowRightIcon className={classes.arrowIcon} />
          </IconButton> */}
      </Box>
    );
  }

  return (
    <>
      {loading && <Loader />}
      <>
        {addnewbene ? (
          <>
            <Grid className={classes.rootContainer}>
              <Grid container spacing={2}>
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={classes.flexAlignCenter}
                >
                  <Link to="internationaltransfer/dashboard">
                    <ArrowBackIcon className={classes.icons} />
                  </Link>
                  <Typography className={classes.title}>
                    Beneficiaries
                  </Typography>
                </Grid>
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={classes.addBeneficiary}
                  onClick={handleNewBeneficiary}
                >
                  <AddIcon fontSize="small" className={classes.circleIcon} />
                  <Typography variant="h6" className={classes.addtxt}>
                    Add New Beneficiary
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className={classes.search}
              >
                <Grid size={{ md: 12, xs: 12 }} className={classes.searchbar}>
                  <input
                    placeholder="Search for beneficiary by name"
                    className={classes.SearchInput}
                    onChange={searchHandler}
                    value={searchText}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  size={{ md: 6 }}
                  className={classes.flexAlignCenter}
                  sx={{ gap: "10px" }}
                >
                  <ToggleButton
                    value="Favorite"
                    onClick={() => setSelected(!selected)}
                    className={classes.beneListTypeBtn}
                  >
                    Favorite
                  </ToggleButton>
                  <ToggleButton
                    value="All"
                    selected={selected}
                    onClick={() => setSelected(!selected)}
                    className={classes.beneListTypeBtn}
                  >
                    All
                  </ToggleButton>
                </Grid>
                <Grid
                  size={{ md: 6 }}
                  className={classes.flexEndCenter}
                  sx={{ gap: "10px" }}
                >
                  <Typography className={classes.FullName}>Country:</Typography>
                  <Select
                    value={selectedCountry}
                    defaultValue="All"
                    onChange={handleSelectedCountry}
                    className={classes.countryDropDown}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflow: "auto",
                        },
                      },
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {addNewBeneficiaryData?.countries?.map((row, index) => {
                      return (
                        <MenuItem value={row?.Country}>{row?.Country}</MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                container
                size={{ md: 12 }}
                alignItems="center"
                spacing={1}
                sx={{ marginTop: "10px" }}
              >
                <Grid size={{ md: 0.5 }}>
                  <IconButton
                    onClick={handlePreviousPage}
                    disabled={page === 0}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
                <Grid
                  container
                  size={{ md: 11 }}
                  spacing={2}
                  sx={{ marginTop: "10px" }}
                >
                  {filteredData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      return (
                        <>
                          <Grid
                            size={{ md: 4 }}
                            key={row.pxRefObjectInsName || row.pyID}
                          >
                            <Card className={classes.accountCard}>
                              <CardContent className={classes.cardContent}>
                                <Grid
                                  container
                                  size={{ md: 12 }}
                                  className={classes.accountContent}
                                >
                                  <Grid
                                    size={{ md: 10 }}
                                    className={classes.infoContainer}
                                  >
                                    <Typography
                                      variant="body1"
                                      className={classes.FullName}
                                    >
                                      {row?.FullName}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.subDetails}
                                    >
                                      {row?.MaskAccNumb
                                        ? row?.MaskAccNumb
                                        : row?.MaskIBANNumb}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.subDetails}
                                    >
                                      {row?.Country}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    size={{ md: 2 }}
                                    className={classes.iconContainer}
                                  >
                                    <FavoriteBorderOutlinedIcon
                                      fontSize="medium"
                                      className={classes.icons}
                                    />
                                    <EditOutlinedIcon
                                      fontSize="medium"
                                      className={classes.icons}
                                      onClick={() => {
                                        _handleClickAndNavigate(row);
                                      }}
                                    />
                                    <DeleteOutlineOutlinedIcon
                                      fontSize="medium"
                                      className={classes.icons}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
                <Grid size={{ md: 0.5 }}>
                  <IconButton
                    onClick={handleNextPage}
                    disabled={(page + 1) * rowsPerPage >= filteredData.length}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {filteredData && filteredData.length > 0 && (
                <Grid container size={{ md: 12 }} sx={{ marginTop: "15px" }}>
                  <Grid size={{ md: 10 }} className={classes.flexCenter}>
                    {getVisibleIndicators(6, page, totalPages)?.map(
                      (indicator) => {
                        return (
                          <CircleIcon
                            sx={{
                              color: indicator === page ? "#000" : "#ccc",
                            }}
                            className={classes.carouselIcons}
                          />
                        );
                      }
                    )}
                  </Grid>
                  <Grid size={{ md: 2 }} justifyContent="flex-end">
                    <CustomTablePagination
                      count={filteredData.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : editbene ? (
          <EditBeneficiary handleNewBeneficiary={handleNewBeneficiary} />
        ) : (
          <AddNewBeneficiary handleNewBeneficiary={handleNewBeneficiary} />
        )}
        <ModalPopup
          open={open}
          close={_handleClose}
          rowData={rowData}
          newFormFlag={newFormFlag}
        />
      </>
    </>
  );
};

export default AddModifyBeneficiary;
