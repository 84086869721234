import React, { useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import { enableAPIAddress } from "../../CommonService/commonAppService";

const AddressMessage = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    etag,
    nextAssignmentId,
    setInitialDetails
  } = props;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const [checkedItems, setCheckedItems] = useState(
    editableBeneDetails?.EnableAPIAddress
  );

  const handleCheckbox = async (event) => {
    const { name, checked } = event.target;
    // setCheckedItems((prevState) => ({
    //   ...prevState,
    //   [name]: checked,
    // }));
    const response = await enableAPIAddress(
      nextAssignmentId,
      editableBeneDetails,
      etag
    );
    const updatedDetails =
      response?.data?.data?.caseInfo?.content?.SelectedBeneDetails;
    if (response?.data?.data?.caseInfo?.content?.SelectedBeneDetails) {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        PostCode: updatedDetails?.PostCode,
        StreetName: updatedDetails?.StreetName,
        BuildingNumber: updatedDetails?.BuildingNumber,
        FullName: updatedDetails?.FullName,
      }));
      setInitialDetails((prevDetails) => ({
        ...prevDetails,
        PostCode: updatedDetails?.PostCode,
        StreetName: updatedDetails?.StreetName,
        BuildingNumber: updatedDetails?.BuildingNumber,
        FullName: updatedDetails?.FullName,
      }));
      
    }
    setCheckedItems(checked);
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked,
    }));
    setInitialDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked,
    }));
  };

  return (
    <Grid size={{ xs: 12, md: 12 }} className={classes.spacing}>
      {editableBeneDetails?.AddressMessage && (
        <>
          <Typography variant="body2" className={classes.contentstyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                name="EnableAPIAddress"
                className={classes.checkboxStyles}
                checked={checkedItems}
                onChange={handleCheckbox}
                style={{
                  color: "var(--primary-color)",
                }}
              />{" "}
              {editableBeneDetails?.AddressMessage &&
                editableBeneDetails?.AddressMessage}
            </div>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              fontSize: "12px ",
              fontFamily: "Open Sans",
            }}
          >
            {" "}
            <InfoIcon className={classes.infoIcon} />
            <span>&nbsp;Please confirm to proceed with save.</span>
          </div>
        </>
      )}
    </Grid>
  );
};

export default AddressMessage;
