import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

const SortCodeField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    editableBeneDetails,
    SelectedBeneDetails,
    isEditable,
    flag,
    handleRefreshInputBlur,
    handleEditChange,
    handleInputChange,
    checkErrors,
    beneDetails,
    disableFlag,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const validationRules = {
    ClrSysMmbId: {
      maxLength: 8,
      errorMessage: `Sort Code should be exactly ZA+6 digits.`,
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleInputChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    handleRefreshInputBlur(event);
  };

  return (
    <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
      <Typography variant="body2" className={classes.headerstyle}>
        Sort Code
      </Typography>
      {isEditable ? (
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={editableBeneDetails?.ClrSysMmbId || beneDetails?.ClrSysMmbId}
          //onChange={handleInputChange}
          //onBlur={handleEditChange}
          onChange={handleLocalValidation}
          onBlur={handleFieldBlur}
          disabled={disableFlag}
          error={Boolean(localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId"))}
          helperText={localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")}
          name="ClrSysMmbId"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                    <img
                      src={editableBeneDetails?.IsValidAccountTypeURL}
                      className={classes.checkMark}
                    />
                  )}
                </InputAdornment>
              ),
            },
          }}
        />
      ) : editableBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {editableBeneDetails?.ClrSysMmbId} &nbsp;
          {flag && editableBeneDetails?.IsValidAccountTypeURL && (
            <img
              src={editableBeneDetails?.IsValidAccountTypeURL}
              alt="checkmark"
              className={classes.checkMark}
            />
          )}
        </span>
      ) : SelectedBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {SelectedBeneDetails?.ClrSysMmbId}&nbsp;
          {flag && SelectedBeneDetails?.IsValidAccountTypeURL && (
            <img
              src={SelectedBeneDetails?.IsValidAccountTypeURL}
              alt="checkmark"
              className={classes.checkMark}
            />
          )}
        </span>
      ) : (
        "NA"
      )}
    </Grid>
  );
};

export default SortCodeField;
