import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "../TransferAmount/styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const LeavePage = (props) => {
  const { message, openDialogFlag, setOpenDialogFlag, open, onClose, onLeave } =
    props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(openDialogFlag);
  useEffect(() => {
    setOpenDialog(openDialogFlag);
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{ width: "100vw", height: "110vh" }}
      >
        <DialogTitle sx={{ mb: 3 }}>
          {/* <span style={{ paddingTop: "2px",display:"flex" }}>
            {" "}
            <WarningAmberIcon fill="rgb(202, 149, 92)" />{" "}
            <Typography sx={{fontWeight:"bold",pt:0.5}}>
             Warning
            </Typography>
          </span>{" "} */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 16,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="body1" className={classes.Typo1} sx={{fontSize:"18px !important"}}>
            Are you sure you want to leave this page?
          </Typography>
          <Typography variant="body1" className={classes.Typo2} sx={{fontSize:"16px !important"}}>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", mb: 9 }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            className={classes.secondaryBtn}
            size="small"
          >
            Stay
          </Button>
          <Button
            onClick={onLeave}
            variant="contained"
            size="small"
            className={classes.primaryButton}
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default LeavePage;
