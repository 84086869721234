import { Box, Typography, Button, Divider } from "@mui/material";
import useStyles from "./styles";
import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid2";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import warningIcon from "../../../../assets/images/warningicon.svg";
import CurrencyFlag from "react-currency-flags";
import CountrySpecificComponent from "./CountrySpecificComponent";
import Loader from "../Loader";
import { sendPaymentCancelApi } from "../../../CommonService/commonAppService";
import LeavePage from "../LeavePage";
import {
  sendPaymentFinalSubmit,
  sendPaymentProcessSubmit,
  getDataForFirstPreviousPage,
} from "../../../CommonService/sendPaymentService";
import {
  getDataForReviewToReviewBeneficiaryPage,
  getDataForReviewBenefiToAddBenefiPage,
} from "../../../CommonService/sendPaymentService";
import { getPurposeCodeList } from "../../../CommonUtils/CommonUtils";
import { Link, useLocation } from "react-router-dom";

const Review = ({
  onContinue,
  nextAssignmentId,
  eTag,
  setEtag,
  reviewDetails,
  onPrevious,
  setPreviousData,
  setFinalResponse,
  handleStep,
  handleBack,
  setBeneficiariesPreviousData,
  setAmountPreviousData,
  setPurposeCodeList,
}) => {
  const classes = useStyles();
  const [review_Details, setReviewDetails] = useState(
    reviewDetails?.data?.caseInfo?.content
  );
  const [checkedItems, setCheckedItems] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const actionName = "ActionSendPayment";
  const [loading, setLoading] = useState(false);
 const [checkbox, setcheckbox] = useState({
    PayChargeCmtIndicator: false,
    ReviewConfirmAck: false,
  });  const [checkCount, setcheckCount] = useState(0);
  const [radioValue, setRadioValue] = useState(false);
  const [checkbox_Details, setCheckboxDetails] = useState(
    reviewDetails?.uiResources?.resources.views?.SendPayment[0]?.children[0]
      .children
  );
  const handleCancel = () => {
    navigate("/");
  };
  const handlePrevious = async () => {
    try {
      setLoading(true);
      const response = await getDataForReviewToReviewBeneficiaryPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      if (resourceData) {
        setLoading(false);

        handleBack();

       
      }
    } catch {}
  };
  useEffect(() => {
    const initialCheckedState = checkbox_Details?.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        // Assuming initial checked state can be based on some condition (e.g., if value starts with '@P .')
        acc[data.config.value.replace(/^@P \./, "")] = false; // Or true if you want it checked initially
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);
  const cancelPayment = async (evt) => {
    setRadioValue(evt.target.checked);
  };
  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setcheckbox((check) => ({
      ...check,
      [name]: checked,
    }));
    setcheckCount((prev) => (checked ? prev + 1 : prev - 1));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await sendPaymentProcessSubmit(
        nextAssignmentId,
        eTag,
        actionName,
        radioValue,
        review_Details?.Payment_instruction_validation
      );
      if (response.status === 200 && radioValue) {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          setLoading(false);
          setFinalResponse(res?.data?.data?.caseInfo);
          handleStep();
        }
      } else {
        const res = await sendPaymentCancelApi(
          reviewDetails.data.caseInfo?.ID,
          eTag
        );
        if (res.status === 200) {
          setLoading(false);
          handleStep();
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [openModal, setOpenModal] = useState(false);
    const handleStay = () => {
      setOpenModal(false);
    };
    const handleLeave = () => {
      navigate("/internationaltransfer/dashboard");
    };
    const [message, setMessage] = useState("");
    const handleBackModal = () => {
      const message =
        "Your payment has not been submitted and details will be lost if you proceed.";
      setMessage(message);
      setOpenModal(true);
    };
  return (
    <>
   
      <Grid container spacing={1} sx={{ m: 2, padding: "5px 0px 0px 10px" }}>
        <Grid item size={{ md: 12 }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <AccountBalanceIcon />
            <Typography className={classes.Typo1}> Account details</Typography>
          </Box>
        </Grid>
        <Grid item size={{ md: 6 }}>
          <Grid
            container
            spacing={1}
            sx={{
              p: "10px 0px 0px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography className={classes.Typo3} pl={"5px"}>
              Debit account
            </Typography>
            <Box
              sx={{
                background: " #faf7f7",
                borderRadius: "10px",
                p: "10px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item size={{ md: 6 }}>
                  {review_Details?.FullName ? review_Details?.FullName : "Name"}{" "}
                  &nbsp;
                </Grid>
                <Grid item size={{ md: 6 }}>
                  {review_Details?.DebtorCountry
                    ? review_Details?.DebtorCountry
                    : " Debitor Country"}{" "}
                  &nbsp;
                </Grid>
                <Grid item size={{ md: 12 }}>
                  {" "}
                  {review_Details?.MaskAccountNumber}
                </Grid>
                <Grid item size={{ md: 6 }}>
                  {review_Details?.AccountType
                    ? review_Details?.AccountType
                    : "Account type"}{" "}
                </Grid>
                <Grid item size={{ md: 6 }} sx={{ display: "flex" }}>
                  <Typography variant="body2">Balance &nbsp;</Typography>
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {review_Details?.AccountBalance}
                    <CurrencyFlag currency={review_Details?.SendCurrency} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item size={{ md: 6 }}>
          <Grid container spacing={1} sx={{ pt: "10px", display: "block" }}>
            <Typography className={classes.Typo3} sx={{ pb: "10px" }}>
              Beneficiary account
            </Typography>
            <Box
              sx={{
                background: " #faf7f7",
                borderRadius: "10px",
                p: "10px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item size={{ md: 12 }}>
                  {review_Details?.SelectedBeneDetails?.FullName}
                </Grid>

                <Grid item size={{ md: 12 }}>
                  {" "}
                  {review_Details?.SelectedBeneDetails?.MaskAccNumb}
                </Grid>
                <Grid item size={{ md: 12 }}>
                  {review_Details?.ReceiversCountryName}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item size={{ md: 12 }} sx={{ pt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <LocalAtmOutlinedIcon />
            <Typography className={classes.Typo1}> Payment details</Typography>
          </Box>
          <Grid container spacing={1} sx={{ pt: "10px" }} pl={"20px"}>
            <Grid item size={{ md: 3 }} pl={"6px"}>
              <Typography className={classes.Typo3}>
                {" "}
                Amount you want to send
              </Typography>
              <Typography className={classes.Typo2}>
                {review_Details?.SendCurrency?.includes("JPY")
                  ? `${parseFloat(review_Details?.SendAmount).toFixed(0)}  ${
                      review_Details?.SendCurrency
                    }`
                  : `${review_Details?.SendAmount}  ${review_Details?.SendCurrency}`}
                &nbsp;
                <CurrencyFlag
                  currency={review_Details?.SendCurrency}
                  style={{ height: "11px", width: " 17px" }}
                />
              </Typography>
            </Grid>
            <Grid item size={{ md: 3 }}>
              <Typography className={classes.Typo3}>
                {" "}
                Amount beneficiary receive
              </Typography>
              <Typography className={classes.Typo2}>
                {" "}
                {review_Details?.ReceiveCurrency?.includes("JPY")
                  ? `${parseFloat(review_Details?.ReceiveAmount).toFixed(0)}  ${
                      review_Details?.ReceiveCurrency
                    }`
                  : `${review_Details?.ReceiveAmount}  ${review_Details?.ReceiveCurrency}`}
                &nbsp;
                <CurrencyFlag
                  currency={review_Details?.ReceiveCurrency}
                  style={{ height: "11px", width: " 17px" }}
                />
              </Typography>
            </Grid>
            {review_Details?.PaymentPurposeCode_RVW && (
              <Grid item size={{ md: 3 }}>
                <Typography className={classes.Typo3}>
                  {" "}
                  Purpose of payment
                </Typography>
                <Typography className={classes.Typo2}>
                  {review_Details?.PaymentPurposeCode_RVW}
                </Typography>
              </Grid>
            )}
            <Grid item size={{ md: 3 }}>
              <Typography className={classes.Typo3}>
                {" "}
                Additional payment info
              </Typography>
              <Typography className={classes.Typo2}>
                {review_Details?.Remarks}
              </Typography>
            </Grid>
            <Grid
              size={{ md: 12 }}
              sx={{
                background: "#faf7f7",
                borderRadius: "10px",
                p: "10px",
              }}
              item
            >
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography className={classes.Typo3}>
                    Exchange Rate:&nbsp;
                    <span className={classes.Typo2}>
                      {review_Details?.CurrencyConvDesc}
                    </span>
                  </Typography>
                </Grid>

                {/* Divider */}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderLeft: "1px solid", mx: 2 }}
                />

                <Grid item xs={3}>
                  <Typography className={classes.Typo3}>
                    Exchange Rate Valid For:&nbsp;
                    <span className={classes.Typo2}>
                      {review_Details?.FXRateGoodForText}
                    </span>
                  </Typography>
                </Grid>

                {/* Divider */}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderLeft: "1px solid", mx: 2 }}
                />

                <Grid item xs={3}>
                  <Typography className={classes.Typo3}>
                    Estimated Delivery Time:&nbsp;
                    <span className={classes.Typo2}>
                      {review_Details?.EstimatedDeliveryTime}
                    </span>
                  </Typography>
                </Grid>

                {/* Divider */}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderLeft: "1px solid", mx: 2 }}
                />

                <Grid item xs={3}>
                  <Typography className={classes.Typo3}>
                    Transaction Fee:&nbsp;
                    <span className={classes.Typo2}>
                      {review_Details?.TransactionDesc}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item size={{ md: 12 }} sx={{ pt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <FactCheckOutlinedIcon />
            <Typography className={classes.Typo1}>
              {" "}
              Beneficiary details
            </Typography>
          </Box>
          <div style={{ padding: "0px 0px 0px 20px" }}>
            <CountrySpecificComponent
              reviewDetails={review_Details}
              review={true}
              flag={true}
            />
          </div>

          {review_Details?.Payment_instruction_validation &&
            review_Details?.Payment_instruction_validation === "ERROR" &&
            checkbox_Details.map((data, index) => {
              if (data?.type === "RadioButtons") {
                return (
                  <Grid
                    container
                    className={classes.spacing}
                    spacing={0.5}
                    sx={{ pt: "10px" }}
                  >
                    <Grid
                      size={{ md: 12 }}
                      className={classes.flexCenterAlign}
                      sx={{ display: "flex", ml: 1.3, alignItems: "center" }}
                    >
                      <img
                        src={warningIcon}
                        width={20}
                        height={20}
                        style={{}}
                      />
                      &nbsp;&nbsp;
                      <Typography className={classes.message}>
                        {data.config.label?.replace(/@L\s*/, "")}
                      </Typography>
                      &nbsp;&nbsp;
                      <FormControl sx={{ display: "contents" }}>
                        
                        <Typography className={classes.message} sx={{ mr: 1 }}>
                          No
                        </Typography>

                        <FormControlLabel
                          value="Yes"
                          onClick={cancelPayment}
                          control={<Switch color="primary" />}
                          label={
                            <Typography className={classes.message}>
                              Yes
                            </Typography>
                          }
                        />
                        {/* <Switch checked={radioValue} onChange={cancelPayment} /> */}
                      </FormControl>
                    </Grid>
                    {/* <Grid size={{ md: 2 }}></Grid> */}
                  </Grid>
                );
              }
              
              return null;
            })}

          <div
            style={{
              display: "flex",
              marginTop: "10px ",
              alignItems: "center",
            }}
          >
            <Checkbox
              style={{ color: "var(--primary-color)" }}
              name="ReviewConfirmAck"
              checked={checkbox.ReviewConfirmAck}
              onChange={handleCheckbox}
            />
            <Typography style={{ fontSize: "12px ", fontFamily: "Open Sans" }}>
              {review_Details?.ReviewConfirmAck}
            </Typography>
          </div>
          {review_Details?.PayChargeCmtIndicator &&
            review_Details?.IsSwiftGo === false && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  style={{ color: "var(--primary-color)", marginTop: "10px " }}
                  name="PayChargeCmtIndicator"
                  checked={checkbox.PayChargeCmtIndicator}
                  onChange={handleCheckbox}
                />
                <Typography
                  style={{ fontSize: "12px ", fontFamily: "Open sans" }}
                >
                  {review_Details?.PayChargeCmtIndicator}
                </Typography>
              </div>
            )}
          {loading && <Loader />}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.spacing}
            sx={{ mt: 2 }}
          >
          <Button
            variant="outlined"
            className={classes.secondaryBtn}
            size="small"
            onClick={handleBackModal}
          >
            Back to dashboard &nbsp;
            <DashboardOutlinedIcon
              sx={{ fill: "var(--secondary-color)", height: "18px" }}
            />
          </Button>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="outlined"
                // className={classes.primaryButton}
                onClick={handlePrevious}
                sx={{
                  color: "#fffff",
                  color: "#7A70F0",
                  borderRadius: "27px",
                  textTransform: "none",
                }}
                size="small"
              >
                Previous &nbsp;
                <ArrowBackIcon
                  sx={{ fill: "var(--secondary-color)", height: "18px" }}
                />
              </Button>

              {checkbox.ReviewConfirmAck && (
                <Button
                  variant="outlined"
                  className={classes.primaryButton}
                  sx={{ color: "#ffff" }}
                  onClick={handleSubmit}
                  size="small"
                  // disabled={checkCount > 0}
                >
                  Submit Payment
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <LeavePage
          open={openModal}
          onClose={handleStay}
          onLeave={handleLeave}
          message={message}
        />
    </>
  );
};

export default Review;
