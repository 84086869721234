import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  accord: {
    height: "100vh",
    overflow: "scroll",
    padding: "12px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  txAmountTitle: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    lineHeight: "0.6 !important",
    marginBottom: "10px !important",
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  steps: {
    "& .MuiStepLabel-label": {
      fontSize: "14px !important",
    },
    "& .css-3hpdci-MuiSvgIcon-root-MuiStepIcon-root.Mui-active ": {
      color: "var(--primary-color) !important",
    },
    "& .css-p8xe3-MuiStepLabel-labelContainer": {
      width: "14vw !important",
      [theme.breakpoints.down("xl")]: {
        width: "14vw !important",
      },
    },
  },
  headerstyle: {
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "2em !important",
  },
  countryCodeSelect: {
    "& .custom__control": {
      marginTop: "17px",
      borderRadius: "8px",
      border: "1px solid #d5d5d5",
      //background: "#fff",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #d5d5d5",
      },
    },
    "& .custom__placeholder": {
      fontSize: "11px !important",
    },
    "& .custom__indicator-separator ": {
      display: "none",
    },
    "& .custom__menu-list": {
      maxHeight: "160px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primary-color)",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },
  currencyCodeSelect: {
    "& .custom__control": {
      width: "130px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    },
    "& .custom__placeholder": {
      fontSize: "11px !important",
    },
    "& .custom__indicator-separator ": {
      display: "none",
    },
    "& .custom__menu-list": {
      maxHeight: "160px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primary-color)",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },
  waringICon: {
    width: "1em !important",
    height: "1em !important",
  },
  acctHeading: {
    // marginTop: "10px !important",
    // fontWeight: "600 !important",
  },
  accordionSummary: {
    borderRadius: "4px !important",
    paddingLeft: "0px !important",
    height: "80px !important",
    border: "1px solid #44318D !important",
    borderLeft: "40px solid #44318D !important",
    "::before": {
      content: "......... !important",
    },
  },
  boxItem: {
    "& .css-1n04w30-MuiInputBase-root-MuiOutlinedInput-root": {
      paddingRight: "0px !important",
    },
  },
  textCenter: {
    textAlign: "-webkit-center !important ",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "flex-start",
  },
  flexCenterAlign: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flex: {
    display: "flex",
  },
  searchbar: {
    position: "relative",
    marginTop: "10px",
  },
  inputInput: {
    border: "1px solid #ddd !important",
    color: "#4F4F4F",
    fontSize: "14px",
    borderRadius: "15px !important",
    lineHeight: "38px",
    height: "25px !important",
    fontWeight: "normal",
    textDecoration: "inherit",
    textTransform: "none",
    // margin: "10px 0px",
    padding: "0 32px 0 16px",
    width: " -webkit-fill-available",
    background: " #FFF !important",
    "&:focus": {
      boxShadow:
        "0 0 0 0.11rem #fff, 0 0 0 0.18rem #295ED9, 0 0 0 0.3rem #295ED91a",
      outline: "none",
    },
  }, infoIcon: {
    fill: "#6b5eff !important",
    height: "20px !important",
  },
  Typo1: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: "Open Sans !important",
  },
  Typo2: {
    fontSize: "12px !important",
    fontFamily: "Open Sans !important",
  },
  Typo3: {
    fontSize: "12px !important",
    fontFamily: "Open Sans !important",
    fontWeight: "600 !important",
  },
  message: {
    fontSize: "12px !important",
  },
  radioCSS: {
    gap: "91px !important",
    "& .MuiTypography-root": {
      fontSize: "12px !important",
    },
    "& .css-rizt0-MuiTypography-root": {
      fontSize: "12px !important",
    },
  },
  Infotext: {
    lineHeight: "1.5em !important ",

    fontSize: "11px !important",
  },
  disabledTextField: {
    // pointerEvents: "none !important",
    // opacity: 0.5,
  },
  accountCard: {
    border: "1px solid #DDDDDD !important",
    borderRadius: "10px !important",
    //paddingBottom: "20px",
    display: "flex !important ",
    flexDirection: "row !important ",
    cursor: "pointer !important ",
  },
  secondaryBtn: {
    color: "#7A70F0 !important",
    borderRadius: "27px !important",
    textTransform: "none !important",
  },
  selectedRow: {
    backgroundColor: "var(--secondary-color) !important ",
    color: "#fff !important ",
  },
  acContent: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent !important", // Ensure no conflicting background color
    //padding: "22px 16px 3px 16px !important",
    "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  },
  cardContent: {
    display: "flex",
  },
  Fullname: {
    fontWeight: "600 !important ",
    fontSize: "12px !important ",
  },
  Accountnumber: {
    fontSize: "12px !important ",
    fontWeight: "400 !important ",
  },
  country: {
    fontSize: "12px !important ",
    fontWeight: "400 !important ",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  BeneficiaryItems: {
    display: "flex",
    gap: "10px",
    cursor: "pointer",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px",
  },
  carouselIcons: {
    width: "12px !important ",
    height: "12px !important ",
  },
  circleIcon: {
    fill: "var(--primary-color) !important",
    cursor: "pointer",
  },
  addtxt: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    color: "var(--primary-color) !important",
  },
  searchBar: {
    display: "flex !important ",
    alignItems: "center !important ",
    gap: "15px",
  },
  button: {
    fontSize: "12px !important",
    backgroundColor: "#d5d5d5 !important",
    border: "none !important ",
    borderRadius: "50px !important ",
    textTransform: "capitalize !important ",
    padding: "0px 10px 1px 10px !important ",
    marginLeft: "10px !important ",
    "&:hover": {
      color: "#fff !important ",
      backgroundColor: "var(--secondary-color) !important",
    },
  },
  allButton: {
    color: "#fff !important ",
    backgroundColor: "var(--secondary-color) !important",
    fontSize: "12px !important",
    border: "none !important ",
    borderRadius: "50px !important ",
    textTransform: "capitalize !important ",
    padding: "0px 10px 1px 10px !important ",
    marginLeft: "10px !important ",
  },
  primaryButton: {
    background: "#7A70F0 !important",
    borderRadius: "27px !important",
    textTransform: "none !important",
    color: "#fffff !important",
  },
}));

export default useStyles;
