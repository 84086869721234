import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getCountryCode } from "../../CommonUtils/CommonUtils";
import { getCountryName } from "../../CommonUtils/CommonUtils";
import Flag from "react-world-flags";

const PayToCountryFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { Country, isEditable, countryCode } = props;
  const countryName = getCountryName(countryCode);
  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Country
        </Typography>
        <Box className={classes.flagAlignments}>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={Country ? Country : "NA"}
              disabled
              style={{ color: "black" }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment>
                      <Flag
                        code={getCountryCode(Country)}
                        className={classes.countryFlag}
                      />
                    </InputAdornment>
                  ),
                },
              }}
            />
          ) : (
            <>
              <Typography className={classes.BankAddress}>
                <span className={classes.contentstyle}>
                  {Country ? Country : countryName ? countryName : "NA"}
                </span>
              </Typography>
              &nbsp;
              <Flag
                code={getCountryCode(Country || countryName)}
                // style={{ width: "16px", height: "17px" }}
                className={classes.countryFlag}
              />{" "}
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default PayToCountryFeild;
