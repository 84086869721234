import React, { useState } from "react";
import warningIcon from "../../../../assets/images/warningicon.svg";
import useStyles from "./styles";
import { Box, Typography, Divider, Button } from "@mui/material";
import CurrencyFlag from "react-currency-flags";
import checkMark from "../../../../assets/images/check_mark_green.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CountrySpecificComponent from "./CountrySpecificComponent";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import Grid from "@mui/material/Grid2";
import { Home } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
const FinalResponse = ({ finalResponseData }) => {
  const classes = useStyles();
  const location = useLocation();

  const { state } = location;
  const [finalResponse] = useState(finalResponseData);
  return (
    <>
      <Box>
        {finalResponse?.content && (
          <>
            <div className={classes.textCenter}>
              <img src={checkMark} width={50} alt="checkmark" height={50} />
              <Typography variant="body1" sx={{ fontSize: "18px", pt: "10px" }}>
                Successfully sent
              </Typography>
              <Typography variant="body1">
                Amount&nbsp;{finalResponse?.content?.ReceiveAmount}&nbsp;{" "}
                {finalResponse?.content?.ReceiveCurrency}{" "}
                <CurrencyFlag
                  currency={finalResponse?.content?.ReceiveCurrency}
                  style={{ height: "11px", width: " 17px" }}
                />
                &nbsp; sent to{" "}
                {finalResponse?.content?.SelectedBeneDetails?.FullName}
              </Typography>
              <Typography variant="body2">
                Transaction Id: {finalResponse?.content?.UETR}
              </Typography>
            </div>
            <Accordion
              sx={{
                borderRadius: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  m: 1,
                  borderRadius: 1,
                  height: "80px",
                  border: "1px solid !important",
                  borderRadius: "4px !important",
                }}
              >
                <Grid container>
                  <Grid item md={0.5} sx={{ pt: 1, pr: 2 }}>
                    <LanguageIcon />
                  </Grid>
                  <Grid item md={11} sx={{ pt: 1 }}>
                    <Box sx={{ display: "block" }}>
                      <Typography className={classes.Typo1}>
                        Transaction details
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ padding: "5px 0px 0px 10px" }}>
                  <Grid container className={classes.finalScreen} spacing={1}>
                    <Grid item size={{ md: 12 }}>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <AccountBalanceIcon />
                        <Typography className={classes.Typo1}>
                          {" "}
                          Account details
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item size={{ md: 6 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          p: "10px 0px 0px 20px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography className={classes.Typo3} pl={"5px"}>
                          Debit account
                        </Typography>
                        <Box
                          sx={{
                            background: " #faf7f7",
                            borderRadius: "10px",
                            p: "10px",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item size={{ md: 6 }}>
                              Name
                            </Grid>
                            <Grid item size={{ md: 6 }}>
                              Country
                            </Grid>
                            <Grid item size={{ md: 12 }}>
                              {" "}
                              {finalResponse?.content?.MaskAccountNumber}
                            </Grid>
                            <Grid item size={{ md: 6 }}>
                              CreditCard
                            </Grid>
                            <Grid
                              item
                              size={{ md: 6 }}
                              sx={{ display: "flex" }}
                            >
                              <Typography variant="body2">
                                Balance &nbsp;
                              </Typography>
                              <Typography variant="body2">
                                {finalResponse?.content?.AccountBalance}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item size={{ md: 6 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ pt: "10px", display: "block" }}
                      >
                        <Typography
                          className={classes.Typo3}
                          sx={{ pb: "10px" }}
                        >
                          Beneficiary account
                        </Typography>
                        <Box
                          sx={{
                            background: " #faf7f7",
                            borderRadius: "10px",
                            p: "10px",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item size={{ md: 12 }}>
                              {
                                finalResponse?.content?.SelectedBeneDetails
                                  ?.FullName
                              }
                            </Grid>
                            <Grid item size={{ md: 12 }}>
                              {" "}
                              {
                                finalResponse?.content?.SelectedBeneDetails
                                  ?.MaskAccNumb
                              }
                            </Grid>
                            <Grid item size={{ md: 12 }}>
                              {finalResponse?.content?.ReceiversCountryName}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item size={{ md: 12 }} sx={{ pt: "10px" }}>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <LocalAtmOutlinedIcon />
                        <Typography className={classes.Typo1}>
                          {" "}
                          Payment details
                        </Typography>
                      </Box>
                      <Grid
                        container
                        spacing={1}
                        sx={{ pt: "10px" }}
                        pl={"20px"}
                      >
                        <Grid item size={{ md: 3 }} pl={"8px"}>
                          <Typography className={classes.Typo3}>
                            {" "}
                            Amount you want to send
                          </Typography>
                          <Typography className={classes.Typo2}>
                            {finalResponse?.content?.SendCurrency?.includes(
                              "JPY"
                            )
                              ? `${parseFloat(
                                  finalResponse?.content?.SendAmount
                                ).toFixed(0)}  ${
                                  finalResponse?.content?.SendCurrency
                                }`
                              : `${finalResponse?.content?.SendAmount}  ${finalResponse?.content?.SendCurrency}`}
                            &nbsp;
                            <CurrencyFlag
                              currency={finalResponse?.content?.SendCurrency}
                              style={{ height: "11px", width: " 17px" }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item size={{ md: 3 }}>
                          <Typography className={classes.Typo3}>
                            {" "}
                            Amount beneficiary receive
                          </Typography>
                          <Typography className={classes.Typo2}>
                            {" "}
                            {finalResponse?.content?.ReceiveCurrency?.includes(
                              "JPY"
                            )
                              ? `${parseFloat(
                                  finalResponse?.content?.ReceiveAmount
                                ).toFixed(0)} ${" "} ${
                                  finalResponse?.content?.ReceiveCurrency
                                }`
                              : `${
                                  finalResponse?.content?.ReceiveAmount
                                } ${" "} ${
                                  finalResponse?.content?.ReceiveCurrency
                                }`}
                            &nbsp;
                            <CurrencyFlag
                              currency={finalResponse?.content?.ReceiveCurrency}
                              style={{ height: "11px", width: " 17px" }}
                            />
                          </Typography>
                        </Grid>
                        {finalResponse?.content?.PaymentPurposeCode_RVW && (
                          <Grid item size={{ md: 3 }}>
                            <Typography className={classes.Typo3}>
                              {" "}
                              Purpose of payment
                            </Typography>
                            <Typography className={classes.Typo2}>
                              {finalResponse?.content?.PaymentPurposeCode_RVW}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item size={{ md: 3 }}>
                          <Typography className={classes.Typo3}>
                            {" "}
                            Additional payment info
                          </Typography>
                          <Typography className={classes.Typo2}>
                            {finalResponse?.content?.Remarks}
                          </Typography>
                        </Grid>
                        <Grid
                          size={{ xs: 12, md: 12 }}
                          sx={{
                            background: "#faf7f7",
                            borderRadius: "10px",
                            p: "15px",
                          }}
                          container
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={3}>
                              <Typography className={classes.Typo3}>
                                Exchange Rate:&nbsp;
                                <span className={classes.Typo2}>
                                  {finalResponse?.content?.CurrencyConvDesc}
                                </span>
                              </Typography>
                            </Grid>

                            {/* Divider */}
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{ borderLeft: "1px solid", mx: 2 }}
                            />

                            <Grid item xs={3}>
                              <Typography className={classes.Typo3}>
                                Exchange Rate Valid For:&nbsp;
                                <span className={classes.Typo2}>
                                  {finalResponse?.content?.FXRateGoodForText}
                                </span>
                              </Typography>
                            </Grid>

                            {/* Divider */}
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{ borderLeft: "1px solid", mx: 2 }}
                            />

                            <Grid item xs={3}>
                              <Typography className={classes.Typo3}>
                                Estimated Delivery Time:&nbsp;
                                <span className={classes.Typo2}>
                                  {
                                    finalResponse?.content
                                      ?.EstimatedDeliveryTime
                                  }
                                </span>
                              </Typography>
                            </Grid>

                            {/* Divider */}
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{ borderLeft: "1px solid", mx: 2 }}
                            />

                            <Grid item xs={3}>
                              <Typography className={classes.Typo3}>
                                Transaction Fee:&nbsp;
                                <span className={classes.Typo2}>
                                  {finalResponse?.content?.TransactionDesc}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid size={{ xs: 12, md: 12 }}>
                      <Box sx={{ display: "flex", gap: "10px", pt: "10px" }}>
                        <FactCheckOutlinedIcon />
                        <Typography className={classes.Typo1}>
                          {" "}
                          Beneficiary details
                        </Typography>
                      </Box>
                      <div style={{ padding: "0px 0px 0px 20px" }}>
                        <CountrySpecificComponent
                          review={true}
                          reviewDetails={finalResponse?.content}
                          flag={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {!finalResponse && (
          <>
            <div className={classes.textCenter}>
              <img src={warningIcon} width={50} alt="checkmark" height={50} />
              <Typography className={classes.onlyBold}>
                Payment Cancelled
              </Typography>
            </div>
          </>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          size={{ md: 6 }}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Link to="/internationaltransfer/dashboard">
            {" "}
            <Button
              variant="outlined"
              sx={{
                color: "#7A70F0",
                borderRadius: "27px",
                textTransform: "none",
              }}
            >
              Back to dashboard &nbsp;
              <DashboardOutlinedIcon
                sx={{ fill: "var(--secondary-color)", height: "18px" }}
              />
            </Button>
          </Link>
        </Grid>
        <Grid item size={{ md: 6 }}>
          <Link to="/internationaltransfer/transactions">
            <Button variant="contained" className={classes.primaryButton}>
              View all transactions &nbsp;
              <PaymentsOutlinedIcon sx={{ fill: "#ffff", height: "18px" }} />
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
export default FinalResponse;
