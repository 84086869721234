import React, { useState } from "react";
import hamburger from "../../../assets/images/hamburger.svg";
import companyLogo from "../../../assets/images/EPP-logo.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import notificationsIcon from "../../../assets/images/notification.svg";
import useStyles from "./styles";
import DrawerMenu from "./drawerMenu";
import { Link } from "react-router-dom";
import { getUserName } from "../../Authorization/Services/authService";
import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useLocation } from "react-router-dom";

const Navigation = ({ showtabs }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const colors = [
    "#0b8953",
    "#c23824",
    "#2c97de",
    "#00657c",
    "#00aa66",
    "#667700",
    "#e27100",
    "var(--primary-color)",
  ];

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0];
  };
  const initials = getInitials(getUserName());
  const backgroundColor = getColor(initials);
  const getActiveTab = () => {
    switch (location.pathname) {
      case "/dashboard":
        return 0;
      case "/sendpayment":
        return 1;
      case "/recentpayments":
        return 2;
      case "/addnewbene":
        return 3;
      default:
        return 0;
    }
  };
  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to={"/"}>
            <img
              src={companyLogo}
              alt="portalLogo"
              className={classes.appListLogo}
            />
          </Link>
          {/* <div className={classes.tabStyles}>
            <Tabs
              value={getActiveTab()}
              indicatorColor="primary"
              textColor="primary"
              aria-label="payment steps"
            >
              <Tab
                icon={<DashboardOutlinedIcon />}
                label="Dashboard"
                iconPosition="start"
                className={classes.tabHeading}
                component={Link}
                to="/dashboard"
              />
              <Tab
                label="Transfer Amount "
                icon={<SendOutlinedIcon />}
                iconPosition="start"
                component={Link}
                to="/sendpayment"
                className={classes.tabHeading}
              />
              <Tab
                label="Beneficiaries "
                icon={<PersonOutlineOutlinedIcon />}
                iconPosition="start"
                className={classes.tabHeading}
                component={Link}
                to="/recentpayments"
              />
              <Tab
                label="All transactions "
                icon={<PaymentsIcon />}
                iconPosition="start"
                component={Link}
                to="/addnewbene"
                className={classes.tabHeading}
              />
            </Tabs>
          </div> */}
          <div className={classes.flexgrow}></div>

          <div className={classes.user}>
            <Typography variant="body2" className={classes.userText}>
              {getUserName()}
            </Typography>
            <Avatar className={classes.avatar}>
              {getInitials(getUserName())}
            </Avatar>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Navigation;
