import React, { useState, useEffect, useCallback } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import InfoIcon from "@mui/icons-material/Info";
import flagsCode from "../../CommonService/flagServies";
import {
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataAfterFirstContinue,
  getDataForReceiversCountryName,
  debouncedGetDataForReceiverAmount,
  debouncedGetDataForSendAmount,
} from "../../CommonService/sendPaymentService";
import ReactFlagsSelect from "react-flags-select";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import EuroCountries from "../../CommonService/EuroCountries";
import waringICon from "../../../assets/images/warningicon.svg";
import Selected from "react-select";
import countryList from "react-select-country-list";
const AmountSection = ({
  onContinue,
  accounts,
  eTag,
  nextAssignmentId,
  avaiableToPayCurrencyList,
  availableToPayCountryNamesList,
  setEtag,
  setReceiversCountryName,
  previousData,
}) => {
  const classes = useStyles();
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [validateCurrency, setValidateCurrency] = useState("");
  const [showAmountFields, setShowAmountFields] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagCode, setFlagCode] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const actionName = "ActionInitiatePayment";
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  useEffect(() => {
    if (accounts && accounts.length === 1) {
      setAccount(accounts[0].AccountNumber);
    }
  }, [accounts]);

  useEffect(() => {
    if (previousData) {
      const fmAccount = previousData.caseInfo.content.FromAccount;
      const pxresults = getAccountsList(previousData.shared.D_GetAccountList);
      const maskNumber = pxresults.find(
        (item) => item.AccountNumber === fmAccount
      );
      if (maskNumber) {
        setAccount(maskNumber.AccountNumber);
      }
      setSelectedCountryName(
        previousData.caseInfo.content.ReceiversCountryName
      );
      setFlagCode(
        flagsCode[previousData?.caseInfo?.content?.ReceiversCountryName]
      );
      /* setflagselect(previousData?.caseInfo?.content?.PaymentInfo?.CountryCode); */
      const Options = {
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`https://flagcdn.com/w40/${previousData?.caseInfo?.content?.PaymentInfo?.CountryCode.toLowerCase()}.png`}
              alt=""
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                borderRadius: "50px",
                objectFit: "cover",
              }}
            />
            {previousData?.caseInfo?.content?.ReceiversCountryName}
          </div>
        ),
        value: previousData?.caseInfo?.content?.PaymentInfo?.CountryCode,
      };
      setSelectedCountry(Options);

      if (previousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode) {
        const list = getPurposeCodeList(previousData?.shared);
        setPurposeCodeList(list);
        setPurposeCode(
          previousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode
        );
      }
      if (previousData?.shared) {
        const list = getPurposeCodeList(previousData?.shared);
        setPurposeCodeList(list);
        setPurposeCode(
          previousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode
        );
      }
      const option = {
        label: previousData?.caseInfo?.content?.ReceiveCurrency,
        value: previousData?.caseInfo?.content?.ReceiveCurrency,
      };
      setSelectedCurrency(option);
      setRemarks(previousData?.caseInfo?.content?.Remarks);
      const newDetails = previousData.caseInfo.content;
      setShowAmountFields(true);
      setShowExchangeFields(true);
      setAccountDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    }
  }, [previousData]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (/* flagselect */ selectedCountry && selectedvalue) {
      if (selectedvalue === "EU") {
        setShowWarning(
          !EuroCountries.includes(/* flagselect */ selectedCountry?.value)
        );
      } else {
        const isMismatch =
          /* flagselect */ selectedCountry?.value !== selectedvalue;
        setShowWarning(isMismatch);
      }
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (selectedCountry && selectedvalue) {
      if (selectedvalue === "EU") {
        setShowWarning(!EuroCountries.includes(selectedCountry?.value));
      } else {
        const isMismatch = selectedCountry?.value !== selectedvalue;
        setShowWarning(isMismatch);
      }
    } else {
      setShowWarning(false);
    }
  }, [selectedCountry, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  useEffect(() => {
    if (accountDetails.ReceiveAmount) {
      setError((prevState) => ({
        ...prevState,
        amountReceiveError: "",
      }));
    }
  }, [accountDetails.ReceiveAmount]);

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
    if (purposeCode) {
      setError((prevState) => ({
        ...prevState,
        purposeCodeError: "",
      }));
    }
  };

  const handleRemarksChange = (event) => {
    //setRemarks(event.target.value);
    if (event.target.value.length <= 140) {
      setRemarks(event.target.value);
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Value should be less than 140 characters.",
      }));
    }
  };

  function updatePurposeCodeListForJapan(
    resData,
    setPurposeCodeList,
    getPurposeCodeList,
    response
  ) {
    if ("Japan" === resData.ReceiversCountryName && resData.POPVisibility) {
      // setPurposeCodeList(
      //   resData.ReceiveAmount > 1000000
      //     ? getPurposeCodeList(response?.data?.data?.shared)
      //     : ""
      // );
      setPurposeCodeList(getPurposeCodeList(response?.data?.data?.shared));
    }
  }

  function getAccountsList(list) {
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    if (accountValue) {
      setError((prev) => ({
        ...prev,
        accountError: "",
        sendCountry: "",
      }));
    }
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag, false)
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
          POPVisibility: resData.POPVisibility,
        });
        /* setflagselect(""); */
        setSelectedCountry(null);
      })
      .catch((error) => {
        if (error.status !== "401") {
        } else {
          navigate("/login");
        }
      });
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;

    // Allow only numeric input and a single decimal point
    const regex = /^[0-9]*\.?[0-9]*$/; // Allow digits and a single decimal point
    if (!regex.test(value)) {
      return; // Prevent any invalid characters from being entered
    }

    // let formattedValue ;
    // if (value && !value.includes('.')) {
    //    formattedValue = `${value}.00`;
    // }
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
        remarksError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount should not greater than available balance",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoading(true);
    let formattedValue;
    if (value && !value?.includes(".")) {
      formattedValue = `${value}.00`;
    }
    const amount = formattedValue || value;

    if (!isNaN(amount) && !error?.amountYouSendError) {
      debouncedGetDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
            POPVisibility: resData.POPVisibility,
          });
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // const handleAmountInputBlur = () => {
  //   setLoading(true);
  //   let formattedValue;
  //   if (
  //     accountDetails.SendAmount &&
  //     !accountDetails?.SendAmount?.includes(".")
  //   ) {
  //     formattedValue = `${accountDetails?.SendAmount}.00`;
  //   }
  //   const amount = formattedValue || accountDetails.SendAmount;
  //   if (!isNaN(amount) && !error?.amountYouSendError) {
  //     getDataForSendAmount(
  //       account,
  //       amount,
  //       accountDetails.ReceiveCurrency,
  //       accountDetails.ReceiversCountryName,
  //       flagselect,
  //       nextAssignmentId,
  //       eTag,
  //       actionName
  //     )
  //       .then((response) => {
  //         const resData = response.data.data.caseInfo.content;
  //         setAccountDetails({
  //           ...accountDetails,
  //           CurrencyConvDesc: resData.CurrencyConvDesc,
  //           FXRateGoodForText: resData.FXRateGoodForText,
  //           TransactionDesc: resData.TransactionDesc,
  //           SendAmount: resData.SendAmount,
  //           ReceiveAmount: resData.ReceiveAmount,
  //           POPVisibility: resData.POPVisibility,
  //         });
  //         updatePurposeCodeListForJapan(
  //           resData,
  //           setPurposeCodeList,
  //           getPurposeCodeList,
  //           response
  //         );
  //       })
  //       .catch((error) => {
  //         if (error.status == "401") {
  //           navigate("/login");
  //         } else {
  //           console.log("Promise rejected with error:", error.message);
  //         }
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;

    // Allow only numeric input and a single decimal point
    const regex = /^[0-9]*\.?[0-9]*$/; // Allow digits and a single decimal point
    if (!regex.test(value)) {
      return; // Prevent any invalid characters from being entered
    }

    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not greater than available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoading(true);
    if (parsedValue === 0 || value === 0) {
      setLoading(false);
    }
    if ((!isNaN(value) && parsedValue) || value === "") {
      debouncedGetDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        value,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            ReceiveAmount: resData.ReceiveAmount,
            SendAmount: resData.SendAmount,
            POPVisibility: resData.POPVisibility,
          });
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          console.error("Error fetching receiver amount:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleReceiverCountryName = (value) => {
    setSelectedCountryName(value);
    setShowWarning(
      value !== accountDetails.ReceiversCountryName ? true : false
    );
  };

  // const handleReceiveAmountInputBlur = () => {
  //   const amount = parseFloat(accountDetails.ReceiveAmount);
  //   if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
  //     getDataForReceiverAmount(
  //       account,
  //       accountDetails.SendAmount,
  //       amount,
  //       accountDetails.ReceiveCurrency,
  //       accountDetails.ReceiversCountryName,
  //       flagselect,
  //       nextAssignmentId,
  //       eTag,
  //       actionName
  //     ).then((response) => {
  //       const resData = response.data.data.caseInfo.content;
  //       setAccountDetails({
  //         ...accountDetails,
  //         CurrencyConvDesc: resData.CurrencyConvDesc,
  //         FXRateGoodForText: resData.FXRateGoodForText,
  //         TransactionDesc: resData.TransactionDesc,
  //         ReceiveAmount: resData.ReceiveAmount,
  //         SendAmount: resData.SendAmount,
  //         POPVisibility: resData.POPVisibility,
  //       });
  //       updatePurposeCodeListForJapan(
  //         resData,
  //         setPurposeCodeList,
  //         getPurposeCodeList,
  //         response
  //       );
  //     });
  //   }
  //   const parsedValue = parseFloat(accountDetails.SendAmount);
  //   if (parsedValue < 0) {
  //     setError((prevState) => ({
  //       ...prevState,
  //       amountEnteredError: "Send Amount must be greater than zero.",
  //     }));
  //   } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
  //     setError((prevState) => ({
  //       ...prevState,
  //       amountYouSendError:
  //         "Send Amount should not greater than available balance",
  //     }));
  //   }
  // };

  const handleReceiverCurrencyChange = (option) => {
    setLoading(true);
    const newCurrency = option.value;

    setError((prev) => ({
      ...prev,
      accountCurrency: " ",
      remarksError: "",
    }));

    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData.POPVisibility,
        });
        updatePurposeCodeListForJapan(
          resData,
          setPurposeCodeList,
          getPurposeCodeList,
          response
        );
        setShowExchangeFields(true);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
  /*   const handleReceiverCountrySelect = (code) => {
    setflagselect(code);
    setSelectedCurrency(null);
    const countryName = countriesShow[code];
    setShowExchangeFields(false);
    setRemarks("");
    setPurposeCode("");
    setError((prev) => ({
      amountYouSendError: "",
      remarksError: "",
      amountReceiveError: "",
      purposeCodeError: "",
      sendCountry: "",
      accountCurrency: "",
    }));
    setAccountDetails((prev) => ({
      ...prev,
      ReceiversCountryName: countryName,
      SendAmount: "",
      ReceiveAmount: "",
      CurrencyConvDesc: "",
      FXRateGoodForText: "",
      TransactionDesc: "",
      ReceiveCurrency: "",
    }));

    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      countryName,
      code,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData?.POPVisibility,
        });
        if (response?.data?.data?.shared?.D_PurposeCodeList) {
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        } else {
          setPurposeCodeList("");
        }
      })
      .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  }; */

  const handleReceiverCountrySelect = (country) => {
    setSelectedCountry(country);
    setSelectedCurrency(null);
    setShowExchangeFields(false);
    setRemarks("");
    setPurposeCode("");
    const countryName = country?.label?.props?.children[1];
    setError((prev) => ({
      amountYouSendError: "",
      remarksError: "",
      amountReceiveError: "",
      purposeCodeError: "",
      sendCountry: "",
      accountCurrency: "",
    }));
    setAccountDetails((prev) => ({
      ...prev,
      ReceiversCountryName: countryName,
      SendAmount: "",
      ReceiveAmount: "",
      CurrencyConvDesc: "",
      FXRateGoodForText: "",
      TransactionDesc: "",
      ReceiveCurrency: "",
    }));
    // Fetch data for the selected country
    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      countryName,
      country?.value,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        // Update account details with fetched data
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData?.POPVisibility,
        });
        // Handle purpose code list
        if (response?.data?.data?.shared?.D_PurposeCodeList) {
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        } else {
          setPurposeCodeList("");
        }
      })
      .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const validateCountry = () => {
    if (/* flagselect */ selectedCountry === null) {
      return selectedCountry === null ? "Select a country" : "";
    } else {
      return false;
    }
  };

  const validateBeneficiary = () => {
    if (selectedCurrency === null) {
      return selectedCurrency === null ? "Select a Currency" : "";
    } else {
      return false;
    }
  };

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    let amountError = "";
    if (sendAmount <= 0) {
      amountError = " Amount entered must be greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };
  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount entered must be greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
      // } else if (receiveAmount > accountDetails?.AccountBalance) {
      //   amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const validatePurposeCode = () => {
    if (accountDetails?.POPVisibility) {
      return purposeCode === "" ? "Value cannot be blank." : "";
    } else {
      return false;
    }
  };
  const validateAccount = () => {
    if (account === "") {
      return account === "" ? "Select an account to continue." : "";
    } else {
      return false;
    }
  };

  // Main form validation
  const validateForm = () => {
    const amountError = validateAmount();
    const amountReceiveError = validateReceiveAmount();
    const remarksError = validateRemarks();
    const purposeCodeError = validatePurposeCode();
    const accountError = validateAccount();
    const sendCountry = validateCountry();
    const accountCurrency = validateBeneficiary();

    if (
      amountError ||
      remarksError ||
      amountReceiveError ||
      purposeCodeError ||
      accountError ||
      sendCountry ||
      accountCurrency
    ) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
        amountReceiveError: amountReceiveError,
        purposeCodeError: purposeCodeError,
        accountError: accountError,
        sendCountry: sendCountry,
        accountCurrency: accountCurrency,
      });
      return false;
    }
    handleContinue();
    return true;
  };
  const checkErrors = (value) => {
    if (!validate) return null;

    const errorDetail = validate.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const handleContinue = async () => {
    try {
      // setLoading(true);
      const response = await getDataAfterFirstContinue(
        accountDetails.FromAccount,
        accountDetails.SendAmount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiveAmount,
        accountDetails.ReceiversCountryName,
        /* flagselect, */
        selectedCountry?.value,
        purposeCode,
        remarks,
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const receiversCountryName = accountDetails.ReceiversCountryName;
      setReceiversCountryName(receiversCountryName);
      onContinue(nextAssignmentId, eTag, setEtag);
    } catch (error) {
      // console.log("error,", error.response?.data?.errorDetails);
      setValidate(error.response?.data?.errorDetails);
    } finally {
      // setLoading(false);
    }
  };

  const uniqueCurrencies = avaiableToPayCurrencyList.filter(
    (currency, index, self) =>
      index ===
      self.findIndex(
        (element) => element.CurrencyName === currency.CurrencyName
      )
  );

  const options = uniqueCurrencies.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && (
        <CurrencyFlag currency={value} className={classes.CurrencyFlag} />
      )}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );
  const Options = countryList().getData();
  const newOptions = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();
      let countryObj = Options.find((item) => {
        const isoCountryName = item.label.trim().toLowerCase();
        if (
          normalizedCountry === "czech republic" &&
          isoCountryName === "czechia"
        ) {
          return true;
        } else if (
          normalizedCountry === "tanzania" &&
          isoCountryName === "tanzania, united republic of"
        ) {
          return true;
        }
        return isoCountryName === normalizedCountry;
      });
      if (!countryObj) {
        countryObj = Options.find((item) => {
          const isoCountryName = item.label.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }
      if (!countryObj) {
        return null;
      }
      if (countryObj.label.toLowerCase() === "czechia") {
        countryObj.label = "Czech Republic";
      } else if (
        countryObj.label.toLowerCase() === "tanzania, united republic of"
      ) {
        countryObj.label = "Tanzania";
      }
      return countryObj;
    });

  // Add flags to the options
  const formattedOptions = newOptions.map((country) => ({
    value: country?.value,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={`https://flagcdn.com/w40/${country?.value.toLowerCase()}.png`}
          alt=""
          style={{
            marginRight: "10px",
            width: "20px",
            height: "20px",
            borderRadius: "50px",
            objectFit: "cover",
          }}
        />
        {country?.label}
      </div>
    ),
  }));
  const customFilterOption = (option, searchText) => {
    const labelText = option.label.props.children[1];
    return labelText?.toLowerCase().includes(searchText?.toLowerCase());
  };

  const handleCancel = () => {
    navigate("/");
  };
  return (
    <>
      <Grid container>
        <Grid size={{ md: 5, xs: 12 }}>
          <Typography variant="body2" className={classes.acctHeading}>
            Select account to make payment from
          </Typography>
          <FormControl fullWidth error={Boolean(checkErrors(".FromAccount"))}>
            {/* <InputLabel className={classes.inputLable}>
              Select an account
            </InputLabel> */}
            <Select
              id="Account"
              variant="standard"
              value={account}
              onChange={handleAccountChange}
              inputProps={{ className: classes.acctInput }}
              label="Select an account"
              error={Boolean(checkErrors(".FromAccount"))}
              helperText={checkErrors(".FromAccount")}
              disableUnderline
              displayEmpty
              required
            >
              <MenuItem value="" disabled className={classes.placeholder}>
                Select an account
              </MenuItem>
              {accounts &&
                accounts.map((acc) => (
                  <MenuItem key={acc.AccountNumber} value={acc.AccountNumber}>
                    {acc.MaskAccountNumber} {/* Masked account number shown */}
                  </MenuItem>
                ))}
            </Select>
            {checkErrors(".FromAccount") && (
              <FormHelperText>{checkErrors(".FromAccount")}</FormHelperText>
            )}
            {error?.accountError && (
              <span className={classes.errorMsg}>{error.accountError}</span>
            )}
          </FormControl>
        </Grid>
        <Grid size={{ md: 2 }}></Grid>
        {account && (
          <Grid size={{ md: 5, xs: 12 }}>
            <Typography variant="body2" className={classes.acctHeading}>
              What country are the funds being sent to ?&nbsp;
            </Typography>
            {/*   <ReactFlagsSelect
                selected={flagselect}
                onSelect={handleReceiverCountrySelect}
                countries={countriesToShow}
                searchable
                searchPlaceholder="Search by Country Name"
                className={classes.sent}
              /> */}
            <SelectFeild
              options={formattedOptions}
              value={selectedCountry}
              onChange={handleReceiverCountrySelect}
              placeholder="Search by country name"
              isSearchable={true}
              filterOption={customFilterOption}
              className={classes.countryCodeSelect}
              classNamePrefix="custom"
            />

            {error?.sendCountry && (
              <span className={classes.errorMsg}>{error?.sendCountry}</span>
            )}
          </Grid>
        )}
      </Grid>
      {account ? (
        <>
          <Grid container className={classes.spacingCont}>
            <Grid
              size={{ md: 12, xs: 12 }}
              className={`${classes.flex} ${classes.Amountcolor}`}
            >
              <Typography className={classes.Availablebal}>
                Available Balance :
              </Typography>
              <Typography variant="body2">
                &nbsp;{accountDetails.AccountBalance.toLocaleString()}
                {/* {accountDetails.SendCurrency} */}
              </Typography>
              &nbsp;
              <CurrencyFlag
                currency={accountDetails?.SendCurrency}
                className={classes.CurrencyFlag}
              />
              <span className={classes.Sendcurrency}>
                {accountDetails?.SendCurrency}
              </span>
            </Grid>
          </Grid>
          <Divider />

          {
            /* flagselect */ selectedCountry && (
              <>
                <Grid
                  container
                  className={classes.spacingCont}
                  alignItems="center"
                >
                  <Grid
                    size={{ md: 12, xs: 12 }}
                    className={classes.flexCenterAlign}
                  >
                    <InfoIcon className={classes.InfoOutlinedIcon} /> &nbsp;
                    <Typography variant="body2" className={classes.info}>
                      Enter either "Amount You Send" or "Beneficiary Gets". The
                      other amount field will be automatically calculated.
                    </Typography>
                  </Grid>
                  <Grid size={{ md: 5, xs: 12 }}>
                    <Grid container className={classes.spacingCont}>
                      <Grid className={classes.flexCenterAlign}>
                        <Typography
                          variant="body2"
                          className={classes.textstyle}
                        >
                          Amount You Send
                        </Typography>
                        &nbsp;
                        <Tooltip title="Enter the amount that you want to send in the currency of your account.The beneficiary will receive the equivalent of this amount.">
                          <HelpOutlineOutlinedIcon
                            className={classes.InfoOutlinedIcon}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container /* className={classes.spacingCont} */>
                      <Grid className={classes.flex}>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          className={`${classes.sendAmountTextField} ${classes.itemWidth}`}
                          onChange={handleAmountInputChange}
                          // onBlur={handleAmountInputBlur}
                          value={
                            accountDetails.SendCurrency.includes("JPY")
                              ? accountDetails.SendAmount &&
                                !isNaN(parseFloat(accountDetails.SendAmount))
                                ? parseFloat(accountDetails.SendAmount).toFixed(
                                    0
                                  )
                                : ""
                              : accountDetails.SendAmount
                          }
                          // value={accountDetails.SendAmount}
                          slotProps={{
                            input: {
                              className: classes.borderstyle,
                              inputMode: "decimal",
                              pattern: "\\d*([\\.,]\\d{0,7})?",
                              step: accountDetails?.SendCurrency?.includes(
                                "JPY"
                              )
                                ? "1"
                                : "0.0000001",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CurrencyFlag
                                    currency={accountDetails?.SendCurrency}
                                    className={classes.CurrencyFlag}
                                  />
                                  &nbsp; {accountDetails?.SendCurrency}
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </Grid>
                      {error.amountYouSendError && (
                        <div className="error-text">
                          {error.amountYouSendError}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid size={{ md: 2 }} className={classes.flexCenter}>
                    <SwapHorizIcon className={classes.Swaphorizicon} />
                  </Grid>
                  <Grid size={{ md: 5, xs: 12 }}>
                    <Grid container className={classes.spacingCont}>
                      <Grid className={classes.flexCenterAlign}>
                        <Typography
                          variant="body2"
                          className={classes.Fontweight}
                        >
                          Beneficiary Gets
                        </Typography>
                        &nbsp;
                        <Tooltip title="Enter the amount that you want beneficiary to receive.Your account will be debited for the equivalent amount, plus transaction fee.">
                          <HelpOutlineOutlinedIcon
                            className={classes.InfoOutlinedIcon}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid size={{ xs: 12, md: 5 }}>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          onChange={handleReceiveAmountInputChange}
                          // onBlur={handleReceiveAmountInputBlur}
                          value={
                            accountDetails.ReceiveCurrency.includes("JPY")
                              ? accountDetails.ReceiveAmount &&
                                !isNaN(parseFloat(accountDetails.ReceiveAmount))
                                ? parseFloat(
                                    accountDetails.ReceiveAmount
                                  ).toFixed(0)
                                : ""
                              : accountDetails.ReceiveAmount
                          }
                          // value={accountDetails?.ReceiveAmount}
                          className={classes.itemWidth}
                          slotProps={{
                            input: {
                              className: classes.borderstyle,
                              inputMode: "decimal",
                              pattern: "\\d*([\\.,]\\d{0,7})?",
                              step: accountDetails?.ReceiveCurrency?.includes(
                                "JPY"
                              )
                                ? "1"
                                : "0.0000001",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FormControl fullWidth>
                                    <SelectFeild
                                      options={options}
                                      value={selectedCurrency}
                                      onChange={handleReceiverCurrencyChange}
                                      formatOptionLabel={formatOptionLabel}
                                      isSearchable={true}
                                      placeholder="Select Currency"
                                      className={classes.currencyCodeSelect}
                                      classNamePrefix="custom"
                                    />
                                  </FormControl>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid size={{ md: 8 }}>
                        {error.amountReceiveError && (
                          <div className="error-text">
                            {error.amountReceiveError}
                          </div>
                        )}
                      </Grid>

                      {error.accountCurrency && (
                        <Grid size={{ md: 4 }}>
                          <div className="error-text">
                            {error.accountCurrency}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.flexBetween}>
                  <Grid size={{ md: 12, xs: 12 }} className={classes.spacing}>
                    {isShowWarning ? (
                      <Box className={classes.flexCenterAlign}>
                        <img src={waringICon} className={classes.waringICon} />
                        <Typography
                          variant="body2"
                          className={classes.Infotext}
                        >
                          Paying funds to a country in a currency other than its
                          domestic currency may result in the funds being
                          converted by the beneficiary bank before they are
                          applied to the beneficiary's account if that account
                          is not held in the currency of the payment. Please be
                          sure you want to proceed before continuing.
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </>
            )
          }
          {loading ? (
            <Loader />
          ) : (
            /* flagselect */ selectedCountry &&
            showExchangeFields && (
              <Grid
                container
                spacing={2}
                className={`${classes.spacing} ${classes.exchangefields}`}
              >
                <Grid size={{ md: 5 }}>
                  <Grid container>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2" className={classes.label}>
                        Exchange Rate
                      </Typography>
                    </Grid>
                    <Grid size={{ md: 6 }}>
                      <Typography
                        variant="body2"
                        className={classes.exchangeFeild}
                      >
                        {accountDetails.CurrencyConvDesc}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
                <Grid size={{ md: 5 }}>
                  <Grid container>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2" className={classes.label}>
                        Exchange Rate Valid For
                      </Typography>
                    </Grid>
                    <Grid size={{ md: 6 }}>
                      <Typography
                        variant="body2"
                        className={classes.exchangeFeild}
                      >
                        {accountDetails.FXRateGoodForText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ md: 5 }}>
                  <Grid container>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2" className={classes.label}>
                        Transaction Fee
                      </Typography>
                    </Grid>
                    <Grid size={{ md: 6 }}>
                      <Typography
                        variant="body2"
                        className={classes.exchangeFeild}
                      >
                        {accountDetails.TransactionDesc}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
                <Grid size={{ md: 5 }}></Grid>
              </Grid>
            )
          )}

          <Grid container spacing={1} className={classes.flexBetween}>
            {purposeCodeList &&
              accountDetails?.POPVisibility &&
              selectedCountry && (
                <>
                  <Grid size={{ xs: 12, md: 5 }} className={classes.purpose}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Purpose of Payment
                      {/*  */}
                    </Typography>

                    <TextField
                      id="PurposeCode"
                      variant="outlined"
                      value={purposeCode}
                      size="small"
                      onChange={handlePurposeCode}
                      slotProps={{
                        input: {
                          className: `${classes.purposeCodeDropdown} ${classes.remarks}`,
                        },
                      }}
                      //label="Select Pay Purpose"
                      select
                      fullWidth
                    >
                      {purposeCodeList &&
                        purposeCodeList.map((pCode) => (
                          <MenuItem
                            key={pCode.PayPurPCodeDesc}
                            value={pCode.PayPurPCodeDesc}
                          >
                            {pCode.PayPurPCode}
                          </MenuItem>
                        ))}
                    </TextField>
                    {error.purposeCodeError && (
                      <div className="error-text">{error.purposeCodeError}</div>
                    )}
                  </Grid>
                  <Grid siz={{ md: 2 }}></Grid>
                </>
              )}
            {
              /* flagselect */ selectedCountry && showExchangeFields && (
                <Grid size={{ md: 5, xs: 12 }} className={classes.purpose}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Additional Payment Info
                    {/*  */}
                  </Typography>
                  <TextField
                    id="remarks"
                    //label="Remarks"
                    size="small"
                    //multiline
                    rows={1}
                    value={remarks}
                    onChange={handleRemarksChange}
                    fullWidth
                    slotProps={{
                      input: {
                        maxLength: 140,
                        className: classes.borderstyle,
                      },
                    }}
                    className={classes.remarks}
                  />
                  {error.remarksError && (
                    <div className="error-text">{error.remarksError}</div>
                  )}
                </Grid>
              )
            }
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid container spacing={2} className={classes.spacingCont}>
        <Grid size={{ xs: 12, md: 12 }} className={classes.flexBetween}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.continue}
            // onClick={handleContinue}
            onClick={validateForm}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AmountSection;
