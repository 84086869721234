import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import companyLogo from "../../../assets/images/EPP-logo.png";
const BeneficiaryIbanField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    flag,
    handleChange,
    handleBlurChange,
    review,
    disableFlag,
    checkErrors,
  } = props;
  const [localErrors, setLocalErrors] = useState({});

  const IBANValidationRule = {
    15: ["Norway"],
    16: ["Belgium"],
    18: ["Denmark", "Netherlands", "Finland"],
    19: ["Slovenia"],
    20: ["Luxembourg", "Austria", "Lithuania", "Estonia"],
    21: ["Liechtenstein", "Switzerland", "Latvia", "Croatia"],
    22: [
      "Georgia",
      "Bulgaria",
      "United Kingdom",
      "Ireland",
      "Germany",
      "Costa Rica",
    ],
    23: ["United Arab Emirates",  "Israel"],
    24: ["Czech Republic","Sweden", "Romania", "Slovakia", "Spain","Pakistan"],
    25: ["Portugal"],
    26: ["Turkey"],
    27: ["Greece", "Italy", "France"],
    28: ["Hungary", "Guatemala","Poland","Cyprus"],
    29: ["Egypt", "Brazil", "Qatar", "Ukraine"],
    31: ["Malta"],
  };
  const validationMessage = {
    Guatemala: "GT82TRAJ01020000001210029690",
    Brazil: "BR1234567890123456789012345A6",
    Georgia: "GE12OR1234567890123456",
    Liechtenstein: "LI12345678901234567EU",
    Malta: "MT84MALT011000012345MTLCAST001S",
    Netherlands: "NL91ABNA0417164300",
    Bulgaria: "BG80BNBG96611020345678",
    // "Qatar":"GT82TRAJ01020000001210029690",
    Romania: "RO49AAAA1B31007593840000",
    "United Kingdom": "GB29NWBK60161331926819",
    Ireland: "IE29AIBK93115212345678",
    Latvia: "LV80BANK0000435195001",
    France: "FR1420041010050500013M02606",
    Pakistan: "PK36SCBL0000001123456702",
  };
  const getMaxIBANLength = (country) => {
    for (const [maxLength, countries] of Object.entries(IBANValidationRule)) {
      if (countries.includes(country)) {
        return parseInt(maxLength, 10);
      }
    }
    return 10; // Default max length if country not listed
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    const maxLength = getMaxIBANLength(beneDetails?.Country);
    // const maxLength = getMaxIBANLength(beneDetails?.CdtrAcctIdIBAN);
    const message = validationMessage[beneDetails?.Country];
    if (value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: `Exceeded length. IBAN Number should be ${maxLength} characters, Format is ${message || beneDetails.CountryCode + '+' + (maxLength - 2) + ' digits'}.`,

      }));
      return; // Prevent further input
    } else {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    handleChange(event); // Pass changes to the parent component
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (localErrors.CdtrAcctIdIBAN == "") handleBlurChange(event);
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary IBAN
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            // className={classes.textWidth}
            value={beneDetails?.CdtrAcctIdIBAN}
            onBlur={handleFieldBlur}
            // onChange={handleChange}
            // error={Boolean(checkErrors("CdtrAcctIdIBAN"))}
            // helperText={checkErrors("CdtrAcctIdIBAN")}
            onChange={handleLocalValidation}
            error={Boolean(
              localErrors.CdtrAcctIdIBAN || checkErrors("CdtrAcctIdIBAN")
            )}
            helperText={
              localErrors.CdtrAcctIdIBAN || checkErrors("CdtrAcctIdIBAN")
            }
            name="CdtrAcctIdIBAN"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {flag && beneDetails?.IsValidBeneNameURL && (
                      <img
                        src={beneDetails?.IsValidBeneNameURL}
                        className={classes.checkMark}
                      />
                    )}
                  </InputAdornment>
                ),
              },
            }}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {review ? beneDetails?.MaskIBANNumb : beneDetails?.CdtrAcctIdIBAN}
            &nbsp;
            {flag && beneDetails?.IsValidBeneNameURL && (
              <img
                src={beneDetails?.IsValidBeneNameURL}
                className={classes.checkMark}
              />
            )}
          </span>
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryIbanField;
