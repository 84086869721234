import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editBeneficiary } from "../../components/CommonService/commonAppService";
import {
  editValidateBeneficiaryRefresh,
  editBeneficiaryDetailsFinalSubmit,
} from "../../components/CommonService/commonAppService";

export const fetchEditBeneficiary = createAsyncThunk(
  "beneficiary/fetchEditBeneficiary",
  async ({ pyGUID }) => {
    const res = await editBeneficiary(pyGUID);
    return {
      etag: res.headers["etag"],
      assignmentID: res?.data?.nextAssignmentInfo?.ID,
      data: res.data?.data?.caseInfo?.content?.NewBeneDetails,
    };
  }
);

export const validateEditBeneficiary = createAsyncThunk(
  "beneficiary/validateEditBeneficiary",
  async (
    { assignmentID, formState, etag, refreshFor },
    { rejectWithValue }
  ) => {
    try {
      const response = await editValidateBeneficiaryRefresh(
        assignmentID,
        formState,
        etag,
        refreshFor
      );

      // Check for a valid response (e.g., HTTP 2xx status)
      if (response.status >= 200 && response.status < 300) {
        return response.data?.data?.caseInfo?.content?.NewBeneDetails; 
      }
    } catch (error) {
      // Handle unexpected errors
      return rejectWithValue({
        status: error.status,
        message: error,
      });
    }
  }
  // async ({ assignmentID, formState, etag, refreshFor }) => {
  //   const res = await editValidateBeneficiaryRefresh(
  //     assignmentID,
  //     formState,
  //     etag,
  //     refreshFor
  //   );
  //   return res?.data?.data?.caseInfo?.content?.NewBeneDetails;
  // }
);
export const editSubmitBeneficiaryDetails = createAsyncThunk(
  "beneficiary/submitBeneficiaryDetails",
  async ({ assignmentID, formState, etag }) => {
    const res = await editBeneficiaryDetailsFinalSubmit(
      assignmentID,
      formState,
      etag
    );
    return res.data;
  }
);

const initialState = {
  countries: [],
  etag: "",
  assignmentID: "",
  formState: {},
  formFlag: false,
  submitFlag: false,
  countryName: "",
  loading: false,
  error: null,
};

const editBeneficiarySlice = createSlice({
  name: "editBeneficiary",
  initialState,
  reducers: {
    setFormState: (state, action) => {
      state.formState = { ...state.formState, ...action.payload };
    },
    setCountryNameFor: (state, action) => {
      state.countryName = action.payload;
      state.formFlag = action.payload !== "";
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEditSubmitFlag: (state, action) => {
      state.submitFlag = action.payload;
    },
    resetInitialState: (state, action) => {
      return initialState;
    },
    setClearBICForEditBene:(state,action)=>{
      if (state.formState) {
        
        state.formState.BICFI = "";
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditBeneficiary.fulfilled, (state, action) => {
        state.formState = action.payload?.data;
        state.etag = action.payload?.etag;
        state.assignmentID = action.payload?.assignmentID;
        state.formFlag = action.payload.data !== "";
      })
      .addCase(validateEditBeneficiary.fulfilled, (state, action) => {
        state.NewBeneDetails = { ...state.NewBeneDetails, ...action.payload };
        state.formState = { ...state.formState, ...action.payload };
      })
      .addCase(validateEditBeneficiary.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(editSubmitBeneficiaryDetails.fulfilled, (state, action) => {
        state.error=null
      });
  },
});

export const {
  setFormState,
  setError,
  setEditSubmitFlag,
  setCountryNameFor,
  resetInitialState,setClearBICForEditBene
} = editBeneficiarySlice.actions;

export default editBeneficiarySlice.reducer;
