import React from "react";
import Usa from "../../../CountrySpecificFields/Usa";
import Japan from "../../../CountrySpecificFields/Japan";
import Qatar from "../../../CountrySpecificFields/Qatar";
import Europe from "../../../CountrySpecificFields/Europe";
import Australia from "../../../CountrySpecificFields/Australia";
import Bangladesh from "../../../CountrySpecificFields/Bangladesh";
import Brazil from "../../../CountrySpecificFields/Brazil";
import Canada from "../../../CountrySpecificFields/Canada";
import China from "../../../CountrySpecificFields/China";
import Ghana from "../../../CountrySpecificFields/Ghana";
import HongKong from "../../../CountrySpecificFields/HongKong";
import India from "../../../CountrySpecificFields/India";
import Malaysia from "../../../CountrySpecificFields/Malaysia";
import Mexico from "../../../CountrySpecificFields/Mexico";
import Morocco from "../../../CountrySpecificFields/Morocco";
import Philippines from "../../../CountrySpecificFields/Philippines";
import Singapore from "../../../CountrySpecificFields/Singapore";
import SouthAfrica from "../../../CountrySpecificFields/SouthAfrica";
import SriLanka from "../../../CountrySpecificFields/SriLanka";
import UnitedArabEmirates from "../../../CountrySpecificFields/UnitedArabEmirates";
import Argentina from "../../../CountrySpecificFields/Argentina";
import Egypt from "../../../CountrySpecificFields/Egypt";
import Georgia from "../../../CountrySpecificFields/Georgia";
import Guatemala from "../../../CountrySpecificFields/Guatemala";
import Israel from "../../../CountrySpecificFields/Israel";
import Turkey from "../../../CountrySpecificFields/Turkey";
import UnitedKingdom from "../../../CountrySpecificFields/UnitedKingdom";
import NewZealand from "../../../CountrySpecificFields/Newzealand";
import Chile from "../../../CountrySpecificFields/Chile";
import Colombia from "../../../CountrySpecificFields/Colombia";
import CostaRica from "../../../CountrySpecificFields/CostaRica";
import Indonesia from "../../../CountrySpecificFields/Indonesia";
import Kenya from "../../../CountrySpecificFields/Kenya";
import Pakistan from "../../../CountrySpecificFields/Pakistan";
import SouthKorea from "../../../CountrySpecificFields/SouthKorea";
import Tazania from "../../../CountrySpecificFields/Tanzania";
import Uganda from "../../../CountrySpecificFields/Uganda";
import Uruguay from "../../../CountrySpecificFields/Uruguay";
import Vietnam from "../../../CountrySpecificFields/Vietnam";
import Zambia from "../../../CountrySpecificFields/Zambia";
import { useSelector } from "react-redux";
import Nepal from "../../../CountrySpecificFields/Nepal";
import Thailand from "../../../CountrySpecificFields/Thailand";

const CountrySpecificComponent = (props) => {
  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    setInitialDetails,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    // newBeneCountryName,
    handleDateChange,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    newCountryName = "",
    row = {},
    addNewBeneSendPaymentFlow = false,
    editBene = false,
  } = props;

  const newBeneData = useSelector((data) => data?.addNewBeneficiary);
  let newBeneCountryName =
    newBeneData?.countryName || newCountryName || row?.Country;

  let countryName = review
    ? reviewDetails?.ReceiversCountryName
    : editableBeneDetails?.Country;

  const europeCountryNames = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Ukraine",
  ];

  countryName = europeCountryNames.includes(countryName)
    ? "Europe"
    : countryName;
  newBeneCountryName = europeCountryNames.includes(newBeneCountryName)
    ? "Europe"
    : newBeneCountryName;

  return (
    <>
      {(countryName === "United States" ||
        newBeneCountryName === "United States") && (
        <Usa
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          setInitialDetails={setInitialDetails}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Japan" || newBeneCountryName === "Japan") && (
        <Japan
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          setInitialDetails={setInitialDetails}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Qatar" || newBeneCountryName === "Qatar") && (
        <Qatar
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          setInitialDetails={setInitialDetails}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Europe" || newBeneCountryName === "Europe") && (
        <Europe
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          setInitialDetails={setInitialDetails}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Australia" || newBeneCountryName === "Australia") && (
        <Australia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          setInitialDetails={setInitialDetails}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          // send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Bangladesh" ||
        newBeneCountryName === "Bangladesh") && (
        <Bangladesh
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Brazil" || newBeneCountryName === "Brazil") && (
        <Brazil
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}      
              setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Canada" || newBeneCountryName === "Canada") && (
        <Canada
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          setInitialDetails={setInitialDetails}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "China" || newBeneCountryName === "China") && (
        <China
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          setInitialDetails={setInitialDetails}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Ghana" || newBeneCountryName === "Ghana") && (
        <Ghana
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Hong Kong" || newBeneCountryName === "Hong Kong") && (
        <HongKong
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          setInitialDetails={setInitialDetails}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "India" || newBeneCountryName === "India") && (
        <India
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          setInitialDetails={setInitialDetails}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Malaysia" || newBeneCountryName === "Malaysia") && (
        <Malaysia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Mexico" || newBeneCountryName === "Mexico") && (
        <Mexico
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          setInitialDetails={setInitialDetails}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Morocco" || newBeneCountryName === "Morocco") && (
        <Morocco
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Philippines" ||
        newBeneCountryName === "Philippines") && (
        <Philippines
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          setInitialDetails={setInitialDetails}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Singapore" || newBeneCountryName === "Singapore") && (
        <Singapore
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "South Africa" ||
        newBeneCountryName === "South Africa") && (
        <SouthAfrica
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Sri Lanka" || newBeneCountryName === "Sri Lanka") && (
        <SriLanka
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          setInitialDetails={setInitialDetails}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "United Arab Emirates" ||
        newBeneCountryName === "United Arab Emirates") && (
        <UnitedArabEmirates
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Argentina" || newBeneCountryName === "Argentina") && (
        <Argentina
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Egypt" || newBeneCountryName === "Egypt") && (
        <Egypt
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Georgia" || newBeneCountryName === "Georgia") && (
        <Georgia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Guatemala" || newBeneCountryName === "Guatemala") && (
        <Guatemala
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Israel" || newBeneCountryName === "Israel") && (
        <Israel
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Turkey" || newBeneCountryName === "Turkey") && (
        <Turkey
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "United Kingdom" ||
        newBeneCountryName === "United Kingdom") && (
        <UnitedKingdom
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "New Zealand" ||
        newBeneCountryName === "New Zealand") && (
        <NewZealand
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Colombia" || newBeneCountryName === "Colombia") && (
        <Colombia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Zambia" || newBeneCountryName === "Zambia") && (
        <Zambia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Uruguay" || newBeneCountryName === "Uruguay") && (
        <Uruguay
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Vietnam" || newBeneCountryName === "Vietnam") && (
        <Vietnam
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Uganda" || newBeneCountryName === "Uganda") && (
        <Uganda
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Tanzania" || newBeneCountryName === "Tanzania") && (
        <Tazania
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Korea, Republic of" ||
        newBeneCountryName === "Korea, Republic of") && (
        <SouthKorea
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Pakistan" || newBeneCountryName === "Pakistan") && (
        <Pakistan
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Kenya" || newBeneCountryName === "Kenya") && (
        <Kenya
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Costa Rica" ||
        newBeneCountryName === "Costa Rica") && (
        <CostaRica
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Indonesia" || newBeneCountryName === "Indonesia") && (
        <Indonesia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Chile" || newBeneCountryName === "Chile") && (
        <Chile
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Nepal" || newBeneCountryName === "Nepal") && (
        <Nepal
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
      {(countryName === "Thailand" || newBeneCountryName === "Thailand") && (
        <Thailand
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          setInitialDetails={setInitialDetails}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
          //Edit beneficiary
          row={row}
          editBene={editBene}
          //send payment flow
          addNewBeneSendPaymentFlow={addNewBeneSendPaymentFlow}
        />
      )}
    </>
  );
};

export default CountrySpecificComponent;
