import iso3166 from "iso-3166-1";

export const getCountryCode = (countryName) => {
  const normalizedCountry = countryName?.trim()?.toLowerCase();
  let countryObj = iso3166?.all()?.find((item) => {
    const isoCountryName = item?.country?.toLowerCase()?.trim();

    return isoCountryName === normalizedCountry;
  });

  if (!countryObj) {
    countryObj = iso3166?.all()?.find((item) => {
      const isoCountryName = item?.country?.trim()?.toLowerCase();
      return isoCountryName?.includes(normalizedCountry);
    });
  }
  if (!countryObj) {
    if (
      normalizedCountry === "Korea, Republic of" ||
      normalizedCountry?.includes("korea")
    ) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item?.country?.toLowerCase()?.trim();
        // Checking for variations such as "Republic of Korea"
        return isoCountryName === "republic of korea";
      });
    } else if (normalizedCountry === "vietnam") {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item?.country?.toLowerCase()?.trim();
        console.log(isoCountryName);
        return isoCountryName === "viet nam";
      });
    }
  }
  if (!countryObj) {
    return null;
  }
  return countryObj?.alpha2;
};

export const formattedAddress = (input) => {
  let result = [];
  const parts = input?.replace(/^\n/, "")?.split("\n");

  for (let i = 0; i <= parts?.length; i++) {
    if (i === 0) {
      // FlatNo and FlatName combined
      result?.push(parts[i] + " - " + parts[i + 1] + ",");
      i++;
    } else if (i === parts?.length - 2) {
      // Combine country and pincode on the same line
      result?.push(parts[i] + " - " + parts[i + 1]);
      break;
    } else {
      // Other parts (street name, town, etc.)
      result?.push(parts[i] + " , " + parts[i + 1] + ",");
      i++;
    }
  }

  return result?.map((line, index) => (
    <span key={index}>
      {line}
      {index !== result?.length - 1 && <br />}
    </span>
  ));
};

export function getAccounts(response) {
  const list = response.shared.D_GetAccountList;
  const keys = Object.keys(list);
  let pxResults = [];

  for (const key of keys) {
    if (key.startsWith("D_GetAccountList_")) {
      pxResults = list[key].pxResults;
      break;
    }
  }
  return pxResults;
}

export function getCountryNamesList(response) {
  const list = response.shared.D_GetCountryName;
  const keys = Object.keys(list);
  let pxResults = [];

  for (const key of keys) {
    if (key.startsWith("D_GetCountryName")) {
      pxResults = list[key].pxResults;
      break;
    }
  }
  return pxResults;
}

// export const checkErrors = (value) => {
//   if (!validate) return null;

//   const errorDetail = validate.find((error) =>
//     error?.erroneousInputOutputIdentifier.includes(value)
//   );

//   return errorDetail?.message || null;
// };
export function getPurposeCodeList(response) {
  const list = response.D_PurposeCodeList;
  const keys = Object.keys(list);
  let pxResults = [];
  for (const key of keys) {
    if (key.startsWith("D_PurposeCodeList")) {
      pxResults = list[key].pxResults;
      break;
    }
  }
  return pxResults;
}
export const countryCurrencyList = [
  { countryCode: "AR", currencyCode: "ARS" }, // Argentina
  { countryCode: "AU", currencyCode: "AUD" }, // Australia
  { countryCode: "AT", currencyCode: "EUR" }, // Austria
  { countryCode: "BD", currencyCode: "BDT" }, // Bangladesh
  { countryCode: "BE", currencyCode: "EUR" }, // Belgium
  { countryCode: "BR", currencyCode: "BRL" }, // Brazil
  { countryCode: "BG", currencyCode: "BGN" }, // Bulgaria
  { countryCode: "CA", currencyCode: "CAD" }, // Canada
  { countryCode: "CL", currencyCode: "CLP" }, // Chile
  { countryCode: "CN", currencyCode: "CNY" }, // China
  { countryCode: "CO", currencyCode: "COP" }, // Colombia
  { countryCode: "CR", currencyCode: "CRC" }, // Costa Rica
  { countryCode: "HR", currencyCode: "HRK" }, // Croatia
  { countryCode: "CY", currencyCode: "EUR" }, // Cyprus
  { countryCode: "CZ", currencyCode: "CZK" }, // Czech Republic
  { countryCode: "DK", currencyCode: "DKK" }, // Denmark
  { countryCode: "EG", currencyCode: "EGP" }, // Egypt
  { countryCode: "EE", currencyCode: "EUR" }, // Estonia
  { countryCode: "FI", currencyCode: "EUR" }, // Finland
  { countryCode: "FR", currencyCode: "EUR" }, // France
  { countryCode: "GE", currencyCode: "GEL" }, // Georgia
  { countryCode: "DE", currencyCode: "EUR" }, // Germany
  { countryCode: "GH", currencyCode: "GHS" }, // Ghana
  { countryCode: "GR", currencyCode: "EUR" }, // Greece
  { countryCode: "GT", currencyCode: "GTQ" }, // Guatemala
  { countryCode: "HK", currencyCode: "HKD" }, // Hong Kong
  { countryCode: "HU", currencyCode: "HUF" }, // Hungary
  { countryCode: "IN", currencyCode: "INR" }, // India
  { countryCode: "ID", currencyCode: "IDR" }, // Indonesia
  { countryCode: "IE", currencyCode: "EUR" }, // Ireland
  { countryCode: "IL", currencyCode: "ILS" }, // Israel
  { countryCode: "IT", currencyCode: "EUR" }, // Italy
  { countryCode: "JP", currencyCode: "JPY" }, // Japan
  { countryCode: "KE", currencyCode: "KES" }, // Kenya
  { countryCode: "KR", currencyCode: "KRW" }, // Korea, Republic of
  { countryCode: "LV", currencyCode: "EUR" }, // Latvia
  { countryCode: "LI", currencyCode: "CHF" }, // Liechtenstein
  { countryCode: "LT", currencyCode: "EUR" }, // Lithuania
  { countryCode: "LU", currencyCode: "EUR" }, // Luxembourg
  { countryCode: "MY", currencyCode: "MYR" }, // Malaysia
  { countryCode: "MT", currencyCode: "EUR" }, // Malta
  { countryCode: "MX", currencyCode: "MXN" }, // Mexico
  { countryCode: "MA", currencyCode: "MAD" }, // Morocco
  { countryCode: "NP", currencyCode: "NPR" }, // Nepal
  { countryCode: "NL", currencyCode: "EUR" }, // Netherlands
  { countryCode: "NZ", currencyCode: "NZD" }, // New Zealand
  { countryCode: "NO", currencyCode: "NOK" }, // Norway
  { countryCode: "PK", currencyCode: "PKR" }, // Pakistan
  { countryCode: "PH", currencyCode: "PHP" }, // Philippines
  { countryCode: "PL", currencyCode: "PLN" }, // Poland
  { countryCode: "PT", currencyCode: "EUR" }, // Portugal
  { countryCode: "QA", currencyCode: "QAR" }, // Qatar
  { countryCode: "RO", currencyCode: "RON" }, // Romania
  { countryCode: "SG", currencyCode: "SGD" }, // Singapore
  { countryCode: "SK", currencyCode: "EUR" }, // Slovakia
  { countryCode: "SI", currencyCode: "EUR" }, // Slovenia
  { countryCode: "ZA", currencyCode: "ZAR" }, // South Africa
  { countryCode: "ES", currencyCode: "EUR" }, // Spain
  { countryCode: "LK", currencyCode: "LKR" }, // Sri Lanka
  { countryCode: "SE", currencyCode: "SEK" }, // Sweden
  { countryCode: "CH", currencyCode: "CHF" }, // Switzerland
  { countryCode: "TZ", currencyCode: "TZS" }, // Tanzania
  { countryCode: "TH", currencyCode: "THB" }, // Thailand
  { countryCode: "TR", currencyCode: "TRY" }, // Turkey
  { countryCode: "UG", currencyCode: "UGX" }, // Uganda
  { countryCode: "UA", currencyCode: "UAH" }, // Ukraine
  { countryCode: "AE", currencyCode: "AED" }, // United Arab Emirates
  { countryCode: "GB", currencyCode: "GBP" }, // United Kingdom
  { countryCode: "US", currencyCode: "USD" }, // United States
  { countryCode: "UY", currencyCode: "UYU" }, // Uruguay
  { countryCode: "VN", currencyCode: "VND" }, // Vietnam
  { countryCode: "ZM", currencyCode: "ZMW" }, // Zambia
];

export const bicfiRefreshCountries = [
  "Argentina",
  "Ghana",
  "Hong Kong",
  "Bangladesh",
  "India",
  "China",
  "Malaysia",
  "Mexico",
  "Morocco",
  "Singapore",
  "Philippines",
  "South Africa",
  "Sri Lanka",
  "Japan",
  "Kenya",
  "Chile",
  "Indonesia",
  "New Zealand",
  "Korea, Republic of", //south korea
  "Tanzania",
  "Uganda",
  "Vietnam",
  "Uruguay",
  "Zambia",
  "Nepal",
  "Thailand",
  "Colombia",
];

export const countryGroup1 = [
  "Brazil",
  "United Arab Emirates",
  "Poland",
  "Costa Rica",
  "Pakistan",
  "Romania",
  "Ukraine",
];

export const countryGroup2 = [
  "United States",
  "Australia",
  "Canada",
  "India",
  "New Zealand",
  "South Africa",
  "Zambia",
];

export const getVisibleIndicators = (
  maxVisibleIndicators,
  page,
  totalPages
) => {
  if (totalPages <= maxVisibleIndicators) {
    // All indicators fit within the visible range
    return Array.from({ length: totalPages }, (_, i) => i);
  }

  let start = Math.max(0, page - Math.floor(maxVisibleIndicators / 2));
  let end = start + maxVisibleIndicators;

  // Adjust range when near the end
  if (end > totalPages) {
    end = totalPages;
    start = totalPages - maxVisibleIndicators;
  }

  // Generate the visible range
  return Array.from({ length: maxVisibleIndicators }, (_, i) => start + i);
};

export const getCountryName = (countryCode) => {
  const normalizedCode = countryCode?.trim()?.toLowerCase();
  let countryObj = iso3166.all().find((item) => {
    const isoCountryCode = item?.alpha2?.toLowerCase()?.trim();
    return isoCountryCode === normalizedCode;
  });
  return countryObj?.country ===
    "United Kingdom of Great Britain and Northern Ireland"
    ? "United Kingdom"
    : countryObj?.country === "United States of America"
    ? "United States"
    : countryObj?.country === "Republic of Korea"
    ? "Korea, Republic of"
    : countryObj?.country;
};
