import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createNewBeneficiary,
  initiateNewBeneficiary,
  validateBeneficiaryRefresh,
  enterBeneficiaryDetailsFinalSubmit,
} from "../../components/CommonService/commonAppService";
import { getListOfCountries } from "../../components/CommonService/sendPaymentService";

// Thunks/apis for async actions
export const fetchNewBeneficiary = createAsyncThunk(
  "beneficiary/fetchNewBeneficiary",
  async () => {
    const res = await createNewBeneficiary();
    return {
      etag: res.headers["etag"],
      assignmentID: res.data?.data?.caseInfo?.assignments[0]?.ID,
    };
  }
);

export const fetchCountries = createAsyncThunk(
  "beneficiary/fetchCountries",
  async () => {
    const res = await getListOfCountries();
    return res.data?.data;
  }
);

export const initiateBeneficiary = createAsyncThunk(
  "beneficiary/initiateBeneficiary",
  async ({ assignmentID, countryName, etag }) => {
    const res = await initiateNewBeneficiary(assignmentID, countryName, etag);
    return {
      etag: res.headers["etag"],
      NewBeneDetails: res.data?.data?.caseInfo?.content?.NewBeneDetails,
    };
  }
);

export const validateBeneficiary = createAsyncThunk(
  "beneficiary/validateBeneficiary",
  async (
    { assignmentID, formState, etag, refreshFor },
    { rejectWithValue }
  ) => {
    try {
      const response = await validateBeneficiaryRefresh(
        assignmentID,
        formState,
        etag,
        refreshFor
      );

      // Check for a valid response (e.g., HTTP 2xx status)
      if (response.status >= 200 && response.status < 300) {
        return response.data?.data?.caseInfo?.content?.NewBeneDetails;
      }
    } catch (error) {
      // Handle unexpected errors
      return rejectWithValue({
        status: error.status,
        message: error,
      });
    }
  }
);

export const submitBeneficiaryDetails = createAsyncThunk(
  "beneficiary/submitBeneficiaryDetails",
  async ({ assignmentID, formState, etag }) => {
    const res = await enterBeneficiaryDetailsFinalSubmit(
      assignmentID,
      formState,
      etag
    );
    return res.data;
  }
);
const initialState = {
  countries: [],
  etag: "",
  assignmentID: "",
  formState: {},
  formFlag: false,
  submitFlag: false,
  countryName: "",
  loading: false,
  error: null,
};
const addNewBeneficiarySlice = createSlice({
  name: "addNewBeneficiary",
  initialState,
  reducers: {
    setFormState: (state, action) => {
      state.formState = { ...state.formState, ...action.payload };
    },
    setCountryNameFor: (state, action) => {
      state.countryName = action.payload;
      state.formFlag = action.payload !== "";
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSubmitFlag: (state, action) => {
      state.submitFlag = action.payload;
    },
    resetInitialState: (state, action) => {
      return initialState;
    },
    setClearBICFI:(state,action)=>{
      if (state.formState) {
        state.formState.BICFI = "";
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewBeneficiary.fulfilled, (state, action) => {
        state.etag = action.payload.etag;
        state.assignmentID = action.payload.assignmentID;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(initiateBeneficiary.fulfilled, (state, action) => {
        state.etag = action.payload?.etag;
        state.formState = {
          ...state.formState,
          ...action.payload?.NewBeneDetails,
        };
      })
      .addCase(validateBeneficiary.fulfilled, (state, action) => {
        state.formState = { ...state.formState, ...action.payload };
        state.error = null;
      })
      .addCase(validateBeneficiary.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(submitBeneficiaryDetails.fulfilled, (state, action) => {
        state.error = null;
      });
  },
});

export const {
  setFormState,
  setCountryNameFor,
  setError,
  resetInitialState,
  setSubmitFlag,
  setFormFlag,setClearBICFI
} = addNewBeneficiarySlice.actions;
export default addNewBeneficiarySlice.reducer;
