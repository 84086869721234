import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import warningIcon from "../../../assets/images/warningicon.svg";
import {
  sendPaymentProcessSubmit,
  getDataForReviewToReviewBeneficiaryPage,
  sendPaymentFinalSubmit,
} from "../../CommonService/sendPaymentService";
import checkMark from "../../../assets/images/check_mark_green.svg";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import {
  Box,
  Button,
  //Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import CountrySpecificComponent from "./CountrySpecificComponent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { sendPaymentCancelApi } from "../../CommonService/commonAppService";

const Review = ({
  onContinue,
  nextAssignmentId,
  eTag,
  setEtag,
  reviewDetails,
  onPrevious,
  setPreviousData,
  setFinalResponse,
}) => {
  const classes = useStyles();

  const [review_Details, setReviewDetails] = useState(
    reviewDetails.data.caseInfo.content
  );
  const [checkedItems, setCheckedItems] = useState({});
  const [checkbox_Details, setCheckboxDetails] = useState(
    reviewDetails.uiResources.resources.views?.SendPayment[0]?.children[0]
      .children
  );
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const actionName = "ActionSendPayment";
  const [loading, setLoading] = useState(false);
  const [checkbox, setcheckbox] = useState({ check1: false, check2: false });
  const [checkCount, setcheckCount] = useState(0);
  const [radioValue, setRadioValue] = useState("");

  const handleCancel = () => {
    navigate("/");
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        // Assuming initial checked state can be based on some condition (e.g., if value starts with '@P .')
        acc[data.config.value.replace(/^@P \./, "")] = false; // Or true if you want it checked initially
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);
  const cancelPayment = async (evt) => {
    setRadioValue(evt.target.value);
  };
  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setcheckbox((check) => ({
      ...check,
      [name]: checked,
    }));
    setcheckCount((prev) => (checked ? prev + 1 : prev - 1));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForReviewToReviewBeneficiaryPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await sendPaymentProcessSubmit(
        nextAssignmentId,
        eTag,
        actionName,
        radioValue,
        review_Details?.Payment_instruction_validation
      );
      if (response.status === 200 && radioValue === "Yes") {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          setLoading(false);
          navigate("/moneysent", {
            state: {
              response: res?.data?.data?.caseInfo,
            },
          });
          // setFinalResponse(res?.data?.data?.caseInfo);
        }
      } else {
        const res = await sendPaymentCancelApi(
          reviewDetails.data.caseInfo?.ID,
          eTag
        );
        if (res.status === 200) {
          setLoading(false);
          navigate("/moneysent");
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // const handleCheckBoxes = () => {
  //   setcheckbox((check) => ({
  //     ...check,
  //     [event.target.name]: event.target.checked,
  //   }));
  //   const handleCheckBoxes = () => {
  //     setcheckbox((check) => ({
  //       ...check,
  //       [event.target.name]: event.target.checked,
  //     }));
  //     setcheckCount((prev) => (event.target.checked ? prev + 1 : prev - 1));
  //   };
  // };

  return (
    <div>
      <Box className={classes.spacing}>
        <Typography variant="body2" className={classes.Reviewheader}>
          Review Transfer Details
        </Typography>
      </Box>
      <Box className={classes.spacing}>
        <Grid className={classes.flex}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Account to be Debited
            </Typography>
            <Box>
              <Typography variant="body2" className={classes.contentstyle}>
                {review_Details?.MaskAccountNumber}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Available Balance
            </Typography>
            <Box className={classes.flex}>
              <Typography variant=" body2" className={classes.contentstyle}>
                {review_Details?.AccountBalance}
              </Typography>
              <Box className={classes.optionValue}>
                {review_Details?.SendCurrency}
              </Box>
              <CurrencyFlag
                currency={review_Details?.SendCurrency}
                className={classes.currencyStyle}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider className={classes.spacing} />

      <Box className={classes.spacing}>
        <Grid container className={classes.flex}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Amount to be Sent
            </Typography>
            <Box className={classes.flexCenterAlign}>
              <Typography className={classes.contentstyle}>
                {review_Details?.SendAmount}
              </Typography>
              <Box className={classes.Sendcurrency}>
                {review_Details?.SendCurrency}
              </Box>
              <CurrencyFlag
                currency={review_Details?.SendCurrency}
                className={classes.currencyStyle}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Amount to be Received
            </Typography>
            <Box className={classes.flexCenterAlign}>
              <Typography className={classes.contentstyle}>
                {review_Details?.ReceiveCurrency === "JPY"
                  ? (review_Details?.ReceiveAmount).toFixed(0)
                  : review_Details?.ReceiveAmount}
              </Typography>
              <Box className={classes.Sendcurrency}>
                {review_Details?.ReceiveCurrency}
              </Box>
              <CurrencyFlag
                currency={review_Details?.ReceiveCurrency}
                className={classes.currencyStyle}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container md={12} xs={12} className={classes.flex}>
        <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            Transaction Fee
          </Typography>
          <Box className={classes.flexCenterAlign}>
            <Typography variant="body2" className={classes.textFont}>
              {review_Details?.TransactionDesc}
            </Typography>
            <CurrencyFlag
              currency={review_Details?.SendCurrency}
              className={classes.currencyStyle}
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
          <Typography
            variant="body2"
            className={`${classes.headerstyle} ${classes.flexCenterAlign}`}
          >
            Estimated Delivery Time
            <Tooltip title="This is an estimated delivery time frame only based on how long similar payments have taken in the past. It is not a guarantee that your payment will be delivered within this time frame, which could take more or less time than this estimate.">
              <InfoOutlinedIcon className={classes.InfoOutlinedIcon} />
            </Tooltip>
          </Typography>
          <Typography className={classes.textFont}>
            {review_Details?.EstimatedDeliveryTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid container size={{ md: 12 }} className={classes.spacing}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="body2" className={classes.headerstyle}>
            Foreign Exchange Rate
          </Typography>
          <Typography className={classes.textFont}>
            {review_Details?.CurrencyConvDesc}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="body2" className={classes.headerstyle}>
            Exchange Rate Valid For
          </Typography>
          <Typography variant="small">
            {review_Details?.FXRateGoodForText}
          </Typography>
        </Grid>
        <Grid container size={{ md: 12 }} className={classes.spacing}>
          {review_Details?.PaymentPurposeCode_RVW && (
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Purpose of Payment
              </Typography>
              <Typography variant="small">
                {review_Details?.PaymentPurposeCode_RVW}
              </Typography>
            </Grid>
          )}
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Additional Payment Info
            </Typography>
            <Typography variant="small">{review_Details?.Remarks}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.spacing} />

      <CountrySpecificComponent
        reviewDetails={review_Details}
        review={true}
        flag={true}
      />

      {review_Details?.Payment_instruction_validation &&
        review_Details?.Payment_instruction_validation === "ERROR" &&
        checkbox_Details.map((data, index) => {
          if (data?.type === "RadioButtons") {
            return (
              <Grid container className={classes.spacing} spacing={0.5}>
                <Grid size={{ md: 12 }} className={classes.flexCenterAlign}>
                  <img src={warningIcon} width={20} height={20} />
                  &nbsp;&nbsp;
                  <Typography className={classes.message}>
                    {data.config.label?.replace(/@L\s*/, "")}
                  </Typography>
                </Grid>
                <Grid size={{ md: 12 }} ml={5} pr={30}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      row
                      style={{ display: "flex" }}
                    >
                      <FormControlLabel
                        value="Yes"
                        onClick={cancelPayment}
                        control={<Radio size="small" />}
                        label={
                          <Typography className={classes.message}>
                            Yes
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        onClick={cancelPayment}
                        label={
                          <Typography className={classes.message}>
                            No
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            );
          }
          // if (data?.type === "Checkbox") {
          //   const checkboxName = data?.config?.value.replace(/^@P \./, "");
          //   return (
          //     <Box key={data.id} className={classes.spacing}>
          //       <Grid className={classes.flex}>
          //         <Checkbox
          //           name={checkboxName}
          //           className={classes.checkboxStyles}
          //           checked={!!checkedItems[checkboxName]}
          //           onChange={handleCheckbox}
          //           style={{ color: "var(--primary-color)" }}
          //         />
          //         <Typography variant="body2" className={classes.textFont}>
          //           {data.config.caption.replace(/@L\s*/, "")}
          //         </Typography>
          //       </Grid>
          //     </Box>
          //   );
          // }
          return null;
        })}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          style={{ color: "var(--primary-color)" }}
          name="check1"
          checked={checkbox.check1}
          onChange={handleCheckbox}
        />
        <Typography style={{ fontSize: "14px ", fontFamily: "Open sans" }}>
          {review_Details?.PayChargeCmtIndicator}
        </Typography>
      </div>
      <div
        style={{ display: "flex", marginTop: "10px ", alignItems: "center" }}
      >
        <Checkbox
          style={{ color: "var(--primary-color)" }}
          name="check2"
          checked={checkbox.check2}
          onChange={handleCheckbox}
        />
        <Typography style={{ fontSize: "14px ", fontFamily: "Open sans" }}>
          {review_Details?.ReviewConfirmAck}
        </Typography>
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={`${classes.cancel} ${classes.optionValue}`}
            onClick={handlePrevious}
          >
            Back
          </Button>
        </Box>
        {loading && <Loader />}
        <Grid>
          <Button
            className={classes.continue}
            onClick={handleSubmit}
            disabled={checkCount !== 2}
          >
            Send Payment
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.successDialog}
      >
        <DialogTitle id="alert-dialog-title">
          <Link to={"/recentpayments"}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              className={classes.closeiconPosition} // Position the close icon
            >
              <CloseIcon color="error" />
            </IconButton>
          </Link>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.textCenter}>
              <img src={checkMark} width={100} alt="checkmark" height={100} />
              <Typography className={classes.txt1}>Money Sent</Typography>
              {/* <Typography className={classes.txt2}>Your transfer will be processed in next 30 minutes</Typography> */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Home</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Review;
