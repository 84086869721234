import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  fetchNewBeneficiary,
  fetchCountries,
  submitBeneficiaryDetails,
  validateBeneficiary,
  initiateBeneficiary,
  setCountryNameFor,
  setFormState,
  setSubmitFlag,
  setError,
  resetInitialState,
  setFormFlag,
} from "../../../Redux/Reducers/addNewBeneficiaryReducer";
import CountrySpecificComponent from "../MobileSendPayment/CountrySpecificComponent";
import useStyles from "./styles";
import Flag from "react-world-flags";
import Loader from "../Loader";
import { getCountryCode } from "../../CommonUtils/CommonUtils";
const MobileAddNewBeneficiary = () => {
  const classes = useStyles();
  //const [submitFlag, setSubmitFlag] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const addNewBeneficiaryData = useSelector((data) => data?.addNewBeneficiary);
  const storeData = useSelector((data) => data?.addNewBeneficiary);

  const { state } = location;
  const [data] = useState(state);

  const country = storeData?.countryName;

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    // Prepare the updated value
    const updatedValue =
      name === "CdtrAcctIdIBAN" ||
      name === "BICFI" ||
      name === "PostCode" ||
      (country === "India" && name === "ClrSysMmbId")
        ? value.toUpperCase()
        : value;

    dispatch(
      setFormState({
        [name]: updatedValue, // Update the specific field
      })
    );
  };

  useEffect(() => {
    dispatch(setCountryNameFor(data?.country));
  }, [data?.country]);

  const handleRefreshInputBlur = (event) => {
    const { name, value } = event.target;

    const bicfiRefreshCountries = [
      "Argentina",
      "Ghana",
      "Hong Kong",
      "Bangladesh",
      "India",
      "China",
      "Malaysia",
      "Mexico",
      "Morocco",
      "Singapore",
      "Philippines",
      "South Africa",
      "Sri Lanka",
      "Japan",
      "Kenya",
      "Chile",
      "Indonesia",
      "New Zealand",
      "Korea, Republic of", //south korea
      "Tanzania",
      "Uganda",
      "Vietnam",
      "Uruguay",
      "Zambia",
      "Nepal",
      "Thailand",
      "Colombia",
    ];

    const countryGroup1 = [
      "Brazil",
      "United Arab Emirates",
      "Poland",
      "Costa Rica",
      "Pakistan",
      "Romania",
      "Ukraine",
    ];

    const countryGroup2 = ["United States", "Australia", "Canada", "India"];

    let refreshFor;

    if (countryGroup1.includes(country)) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "CdtrAcctIdIBAN") {
        refreshFor = ".NewBeneDetails.CdtrAcctIdIBAN";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
      }
    } else if (countryGroup2.includes(country)) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
      }
    } else {
      refreshFor = bicfiRefreshCountries.includes(storeData?.countryName)
        ? ".NewBeneDetails.BICFI"
        : ".NewBeneDetails.CdtrAcctIdIBAN";
    }

    dispatch(
      validateBeneficiary({
        assignmentID: storeData?.assignmentID,
        formState: storeData?.formState,
        etag: storeData?.etag,
        refreshFor: refreshFor,
      })
    );
  };

  const handleValidate = () => {
    const { Edit, ...state } = addNewBeneficiaryData?.formState;
    const refreshFor = ".NewBeneDetails.Validate";
    setLoading(true);
    try {
      dispatch(
        validateBeneficiary({
          assignmentID: addNewBeneficiaryData?.assignmentID,
          formState: addNewBeneficiaryData?.formState,
          etag: addNewBeneficiaryData?.etag,
          refreshFor: refreshFor,
        })
      )
        .then((res) => {
          if (res.payload && res?.meta.requestStatus == "fulfilled") {
            dispatch(setSubmitFlag(true));
            dispatch(setError(""));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = () => {
    const { Edit, message, pyImage, classID, EnterBICCode, ...state } =
      addNewBeneficiaryData?.formState;
    setLoading(true);
    dispatch(
      submitBeneficiaryDetails({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        formState: state,
        etag: addNewBeneficiaryData?.etag,
      })
    )
      .then((res) => {
        if (res.payload?.confirmationNote) {
          handleClose();
          navigate("/addnewbene");
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    dispatch(resetInitialState());
    navigate("/addnewbene");
  };

  return (
    <div>
      <Box className={classes.rootContainer}>
        <Grid>
          <Typography className={classes.title}>Add New Beneficiary</Typography>
        </Grid>
        <Divider className={classes.divider} />

        <Grid className={classes.PayCountry}>
          <Typography variant="body2" className={classes.textstyle}>
            Pay - to Country :{" "}
          </Typography>
          <TextField
            value={data?.country}
            className={classes.textField}
            disabled
            size="small"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Flag
                      code={getCountryCode(data?.country)}
                      className={classes.countryFlag}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        {data?.country && (
          <>
            <CountrySpecificComponent
              addNewBene={true}
              handleChange={handleChange}
              newCountryName={data?.country}
              handleRefreshInputBlur={handleRefreshInputBlur}
              handleValidate={handleValidate}
            />
          </>
        )}
        {loading && <Loader />}
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "5%",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid md={6}>
            <Button className={classes.cancel} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>

          {!addNewBeneficiaryData?.submitFlag && (
            <Grid md={6}>
              <Button className={classes.submit} onClick={handleValidate}>
                Validate
              </Button>
            </Grid>
          )}
          {addNewBeneficiaryData?.submitFlag && (
            <Grid md={6}>
              <Button className={classes.submit} onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default MobileAddNewBeneficiary;
