import { makeStyles } from "@mui/styles";
import bgImage from "../../../assets/images/bgimg.png";
import bgImageAccounts from "../../../assets/images/bgimg_beni.png";

const drawerWidth = "22%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundSize: "cover",
    width: "100vw",
    height: "auto",
    minHeight: "100vh",
    backgroundColor: "#EDEBF4 !important ",
  },
  navigationMenu: {
    position: "fixed !important ",
    height: "100vh !important",
    backgroundColor: "#7A70F0 !important",
    padding: "20px !important",
    minWidth: "275px !important",
    display: "flex !important",
    flexDirection: "column !important",

    color: "#EDEBF4 !important",
  },
  outletContainer: {
    flexGrow: 1,
    padding: "20px !important",
    boxSizing: "border-box !important",
    marginLeft: "275px !important",
    marginTop: "11px !important",
  },
  internationalTransfer: {
    whiteSpace: "nowrap !important ",
    marginTop: "2px !important ",
    borderRadius: "8px !important ",
    minWidth: "220px !important ",
  },

  listItem: {
    display: "flex !important ",
    gap: "10px !important ",
    padding: "10px !important ",
    whiteSpace: "nowrap !important ",
    "&:hover": {
      backgroundColor: " #44318D !important  ",
      borderRadius: "10px !important ",
      // width: "12vw !important ",
      minWidth: "200px",
    },
  },
  logoutBtn: {
    color: "#EDEBF4 !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
    width: "100%",
    cursor: "pointer !important",
    border: "1px solid #EDEBF4!important ",
    borderRadius: "20px !important",
    marginTop: "5%!important",
    textTransform: "none !important",
  },
  linkStyle: {
    display: "flex",
    gap: "10px",
    color: "#EDEBF4 !important ",
    textDecoration: "none",
  },

  HighLight: {
    display: "flex !important ",
    gap: "10px !important ",
    marginBottom: "2px ",
    padding: "10px !important ",
    whiteSpace: "nowrap !important ",
    borderRadius: "10px !important ",
    // width: "12vw !important ",
    minWidth: "200px",
    backgroundColor: "#44318D !important ",
  },
}));

export default useStyles;
