import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import AgenciaNumberField from "./CommonFields/AgenciaNumberField";
import AddressMessage from "./CommonFields/AddressMessage";
import NickNameField from "./CommonFields/NickNameField";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import BeneficiaryIbanField from "./CommonFields/BeneficiaryIbanField";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CountryFeild from "./CommonFields/CountryFeild";
const Brazil = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    setInitialDetails,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    addNewBeneSendPaymentFlow,
    //below is for edit beneficiary
    editBene,
  } = props;

  const [localErrors, setLocalErrors] = useState({});

  const validationRules = {
    TaxID: {
      maxLength: 11,
      errorMessage: `CPF number should be exactly 11 digits.`,
    },
    ClrSysMmbId: {
      maxLength: 5,
      errorMessage: `Agencia Number should be exactly 4 or 5 digits.`,
    },
  };

  const handleLocalValidation = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleChange(event); // Pass the change to parent
  };

  const handleFieldBlur = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "ClrSysMmbId") {
      handleRefreshInputBlur(event);
    }
  };

  const handleLocalValidationEdit = (event) => {
    const { name, value } = event.target;
    // Validate other fields
    const maxLength = validationRules[name]?.maxLength;

    if (maxLength && value.length > maxLength) {
      setLocalErrors((prev) => ({
        ...prev,
        [name]: validationRules[name].errorMessage,
      }));
      return;
    } else {
      // Clear the error if input is within the valid limit
      setLocalErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    handleInputChange(event); // Pass the change to parent
  };

  const handleFieldBlurEdit = (event) => {
    const { name } = event.target;

    // Clear errors on field blur
    setLocalErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    //if (name === "ClrSysMmbId") {
    handleEditChange(event);
    //}
  };

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);
  const newBeneData = addNewBeneSendPaymentFlow
    ? state.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    if (
      name === "BICFI" ||
      name === "CdtrAcctIdIBAN" ||
      name === "ClrSysMmbId"
    ) {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            Edit: isEditable,
          }));
        } else if (name === "CdtrAcctIdIBAN") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            CdtrAcctIdIBAN:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.CdtrAcctIdIBAN,
            Edit: isEditable,
          }));
        } else if (name === "ClrSysMmbId") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            ClrSysMmbId:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.ClrSysMmbId,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <PayToCountryFeild countryCode={SelectedBeneDetails?.CountryCode} />
            <BeneficiaryIbanField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
              review={review}
              flag={false}
            />

            <AgenciaNumberField
              editableBeneDetails={SelectedBeneDetails}
              flag={flag}
            />

            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Account Type
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "NA"}
              </Typography>
            </Grid>

            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />

            <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                CPF / Tax ID
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxID ? SelectedBeneDetails?.TaxID : "NA"}
              </Typography>
            </Grid>

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankNameField
              beneDetails={SelectedBeneDetails}
              review={review}
            />

            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <AccountBalanceIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary bank details
                  </Typography>
                </Grid>
                <BeneficiaryIbanField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  flag={true}
                />
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <FormControl fullWidth>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Beneficiary Account Type
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      value={newBeneData?.formState?.CdtrAcctTpPrtry}
                      error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                      helperText={checkErrors("CdtrAcctTpPrtry")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleChange}
                      size="small"
                      style={{ width: "100%" }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment>
                              {newBeneData?.formState?.IsValidBeneIBANURL && (
                                <img
                                  src={
                                    newBeneData?.formState?.IsValidBeneIBANURL
                                  }
                                  className={classes.checkMark}
                                />
                              )}
                            </InputAdornment>
                          ),
                        },
                      }}
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>

                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                    {checkErrors("CdtrAcctTpPrtry") && (
                      <FormHelperText style={{ color: "red" }}>
                        {checkErrors("CdtrAcctTpPrtry")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    CPF / Tax ID
                  </Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="TaxID"
                    // error={Boolean(checkErrors("TaxID"))}
                    // helperText={checkErrors("TaxID")}
                    disabled={newBeneData?.submitFlag}
                    //onChange={handleChange}
                    value={newBeneData?.formState?.TaxID}
                    onChange={handleLocalValidation}
                    onBlur={handleFieldBlur}
                    error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                    helperText={localErrors.TaxID || checkErrors("TaxID")}
                  />
                </Grid>

                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Agencia Number
                  </Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="ClrSysMmbId"
                    // error={Boolean(checkErrors("ClrSysMmbId"))}
                    // helperText={checkErrors("ClrSysMmbId")}
                    // onBlur={handleRefreshInputBlur}
                    // onChange={handleChange}
                    disabled={newBeneData?.submitFlag}
                    onChange={handleLocalValidation}
                    onBlur={handleFieldBlur}
                    error={Boolean(
                      localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                    )}
                    helperText={
                      localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                    }
                    value={newBeneData?.formState?.ClrSysMmbId}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment>
                            {newBeneData?.formState?.IsValidBeneIBANURL && (
                              <img
                                src={newBeneData?.formState?.IsValidBeneIBANURL}
                                className={classes.checkMark}
                              />
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>
                {/* <Grid container size={{ md: 12 }}> */}
                {newBeneData?.formState?.BICFI && (
                  <BeneficiaryBankBICCodeField
                    beneDetails={newBeneData?.formState}
                    isEditable={false}
                    flag={true}
                    addEditBene={true}
                  />
                )}
                {newBeneData?.formState?.BeneBankName && (
                  <BeneficiaryBankNameField
                    beneDetails={newBeneData?.formState}
                    addEditBene={true}
                  />
                )}

                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                    addEditBene={true}
                  />
                )}
                {/* </Grid> */}
                <Grid
                  size={{ md: 12 }}
                  className={`${classes.flexAlignCenter} ${classes.spacing}`}
                  sx={{ gap: "10px" }}
                >
                  <FactCheckOutlinedIcon className={classes.colorIcons} />
                  <Typography className={classes.subHeading}>
                    Beneficiary details
                  </Typography>
                </Grid>
                <Grid container size={{ md: 12 }}>
                  <BeneficiaryNameField
                    beneDetails={newBeneData?.formState}
                    handleChange={handleChange}
                    isEditable={true}
                    checkErrors={checkErrors}
                    flag={true}
                    disableFlag={newBeneData?.submitFlag}
                  />

                  <NickNameField
                    handleChange={handleChange}
                    disableFlag={newBeneData?.submitFlag}
                    isEditable={true}
                    addEditBene={true}
                  />
                  <CountryFeild Country={newBeneData?.formState?.Country} />
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />

              <BeneficiaryIbanField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />
              <PayToCountryFeild
                Country={editableBeneDetails?.Country}
                isEditable={isEditable}
              />

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  handleBlurChange={handleEditChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Agencia Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.ClrSysMmbId}
                    onChange={handleLocalValidationEdit}
                    onBlur={handleFieldBlurEdit}
                    error={Boolean(
                      localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                    )}
                    helperText={
                      localErrors.ClrSysMmbId || checkErrors("ClrSysMmbId")
                    }
                    // onChange={handleInputChange}
                    // onBlur={handleEditChange}
                    // error={Boolean(checkErrors("ClrSysMmbId"))}
                    // helperText={checkErrors("ClrSysMmbId")}
                    name="ClrSysMmbId"
                    fullWidth
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.ClrSysMmbId
                      ? editableBeneDetails?.ClrSysMmbId
                      : "NA"}
                  </span>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Account Type
                </Typography>
                {isEditable ? (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    //style={{ width: "14.5vw" }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      onChange={handleInputChange}
                      onBlur={handleEditChange}
                      value={editableBeneDetails?.CdtrAcctTpPrtry}
                      error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                      helperText={checkErrors("CdtrAcctTpPrtry")}
                      size="small"
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>
                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctTpPrtry
                      ? editableBeneDetails?.CdtrAcctTpPrtry
                      : "NA"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                  <img
                    src={editableBeneDetails?.IsValidAccountTypeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>

              <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  CPF / Tax ID
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.TaxID}
                    onChange={handleLocalValidationEdit}
                    onBlur={handleFieldBlurEdit}
                    error={Boolean(localErrors.TaxID || checkErrors("TaxID"))}
                    helperText={localErrors.TaxID || checkErrors("TaxID")}
                    // onChange={handleInputChange}
                    // onBlur={handleEditChange}
                    // error={Boolean(checkErrors("TaxID"))}
                    // helperText={checkErrors("TaxID")}
                    name="TaxID"
                    fullWidth
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxID
                      ? editableBeneDetails?.TaxID
                      : "NA"}
                  </span>
                )}
              </Grid>
              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                //handleChange={handleInputChange}
                //handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
                disableFlag={true}
              />

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}
              <BeneficiaryBankNameField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
              />
              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                isEditable={isEditable}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              setInitialDetails={setInitialDetails}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Brazil;
