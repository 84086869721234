import React, { useState } from "react";
import useStyles from "./styles";
import rewards from "../../../assets/images/badge-reward-svgrepo-com.svg";
import budget from "../../../assets/images/money-svgrepo-com.svg";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SocialDistanceOutlinedIcon from "@mui/icons-material/SocialDistanceOutlined";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SettingsInputComponentOutlinedIcon from "@mui/icons-material/SettingsInputComponentOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import CircleIcon from "@mui/icons-material/Circle";
import { useLocation } from "react-router-dom";

const Dataview = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [showtabs, setShowtabs] = useState(false);
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Grid
        container
        //style={{ display: "flex", height: "100vh" }}
        className={classes.root}
      >
        <Grid className={classes.navigationMenu}>
          <div>
            <div
              className={
                location?.pathname === "/accounts"
                  ? classes.HighLight
                  : classes.listItem
              }
            >
              <CreditCardOutlinedIcon />
              <Link
                to="accounts"
                style={{ textDecoration: "none", color: "#EDEBF4" }}
              >
                <Typography>Accounts</Typography>
              </Link>
            </div>
            <div className={classes.listItem}>
              <LocalAtmOutlinedIcon />
              <Typography>Pay a bill</Typography>
            </div>
            <div className={classes.listItem}>
              <SocialDistanceOutlinedIcon />
              <Typography>Domestic Transfer</Typography>
            </div>
            <div className={classes.internationalTransfer}>
              <div
                className={
                  location?.pathname === "/recentpayments" ||
                  location?.pathname === "/addnewbene" ||
                  location?.pathname === "/sendpayment" ||
                  location?.pathname === "/addnewbeneficiary" ||
                  location?.pathname === "/internationaltransfer/dashboard" ||
                  location?.pathname ===
                    "/internationaltransfer/transactions" ||
                  location?.pathname === "/internationaltransfer/transfer" ||
                  location?.pathname === "/internationaltransfer/beneficiaries"
                    ? classes.HighLight
                    : classes.listItem
                }
              >
                <SwapHorizontalCircleOutlinedIcon />
                <Link
                  to="internationaltransfer"
                  style={{ textDecoration: "none", color: "#EDEBF4" }}
                >
                  <Typography>International Transfer</Typography>
                </Link>
              </div>
            </div>
            <div className={classes.listItem}>
              <MilitaryTechOutlinedIcon />
              <Typography>Rewards & Deals</Typography>
            </div>
            <div className={classes.listItem}>
              <SettingsInputComponentOutlinedIcon />
              <Typography>Tools & Investing</Typography>
            </div>
            <div className={classes.listItem}>
              <SecurityOutlinedIcon />
              <Typography>Security Center</Typography>
            </div>
            <div className={classes.listItem}>
              <AccountBalanceOutlinedIcon />
              <Typography>Open an Account</Typography>
            </div>
            <div className={classes.listItem}>
              <MonetizationOnOutlinedIcon />
              <Typography>Expense Tracker</Typography>
            </div>
            <div className={classes.listItem}>
              <HelpOutlineOutlinedIcon />
              <Typography>Help & Support</Typography>
            </div>
          </div>

          <Button
            variant="outlined"
            className={classes.logoutBtn}
            onClick={logout}
            style={{ marginTop: "20px" }}
          >
            Logout
          </Button>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }} className={classes.outletContainer}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default Dataview;
