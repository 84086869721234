export const AUTH_URL =
  "https://eppdev.evonsys.com.au/prweb/PRRestService/oauth2/v1/authorize";
export const TOKEN_URL =
  "https://eppdev.evonsys.com.au/prweb/PRRestService/oauth2/v1/token";
export const CLIENT_ID = "24142140838303336044";
export const CLIENT_SECRET = "202986B470ECAB9F770975AB22667C57";
// export const REDIRECT_URI = "http://localhost:3000/callback";
export const REDIRECT_URI = "https://eppdevreact.evonsys.com.au/callback";
export const REDIRECT_URI_MOBILE = "com.epp.app://callback";
export const SCOPE = "profile";
export const APP_ALIAS = "evonsys-payment-platform";
export const AUTH_SERVICE = "pega";

// eslint-disable-next-line no-restricted-globals
const state = btoa(location.origin);

const sessionIndex = 0;
const useLocking = "false";

// Function to encode a string to Base64
const encodeToBase64 = (str) => {
  // Convert string to a Uint8Array (UTF-8 encoding)
  const utf8Bytes = new TextEncoder().encode(str);
  // Convert to Base64 string
  const base64String = btoa(String.fromCharCode(...utf8Bytes));
  return base64String;
};

export const getAuthorizeUrl = (userIdentifier, password, isMobile) => {
  const passwordBase64 = encodeToBase64(password);
  let moreAuthArgs =
    (AUTH_SERVICE
      ? `&authentication_service=${encodeURIComponent(AUTH_SERVICE)}`
      : "") +
    (sessionIndex && this.config.sessionIndexAttempts < 3
      ? `&session_index=${sessionIndex}`
      : "") +
    (useLocking ? `&enable_psyncId=true` : "") +
    (userIdentifier
      ? `&UserIdentifier=${encodeURIComponent(userIdentifier)}`
      : "") +
    (userIdentifier && password
      ? `&Password=${encodeURIComponent(window.atob(passwordBase64))}`
      : "");

  if (isMobile) {
    return `${AUTH_URL}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI_MOBILE}&scope=${SCOPE}&state=${state}${moreAuthArgs}`;
  } else {
    return `${AUTH_URL}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&state=${state}${moreAuthArgs}`;
  }

  //return `${AUTH_URL}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&state=${state}&code_challenge_method=S256${moreAuthArgs}`;
};
