import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    // boxShadow: theme.shadows[3],
    borderRadius: "30px",
    overflow: "hidden",
    margin: "3%",
  },
  flex: {
    display: "flex",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "1.25rem !important ",
    fontWeight: "bold !important ",
    color: "#7270a0 !important ",
  },
  selectedRow: {
    backgroundColor: "#51459e20  !important ",
  },
  editIcon: {
    background: "var(--primary-color) !important ",
    color: "#fff !important ",
    padding: "3px !important ",
    borderRadius: "8px !important",
    cursor: "pointer !important",
    //marginLeft: "10vw!important",
  },
  currencyStyle: {
    marginLeft: "5px",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important ",
  },
  InfoOutlinedIcon: {
    color: "var(--primary-color) !important ",
    fontSize: "1.2rem !important ",
    marginLeft: "0px !important ",
  },
  textFont: {
    fontSize: "13px !important ",
  },
  textCenter: {
    textAlign: "-webkit-center !important ",
  },
  finalScreen: {
    position: "relative !important ",
    padding: "20px !important ",
    borderRadius: "8px !important ",
  },
  tooltip: {
    color: "var(--primary-color) !important ",
    padding: "0.1px !important ",
  },
  ScreenStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 1,
  },
  TransactionHeader: {
    fontSize: "16px !important ",
    color: "var(--primary-color)",
    fontWeight: "700 !important ",
  },
  tabHeading: {
    textTransform: "none !important",
    fontSize: "1rem !important ",
    padding: "0px !important ",
    color: "black !important ",
    "&.Mui-selected": {
      color: "var(--primary-color) !important",
    },
  },
  textfield: {
    marginTop: "3px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      /* border: 'none',   */
      borderRadius: "20px",
    },
    "& input": {
      paddingLeft: "0px !important",
    },
  },
  required: {
    color: "red",
    fontWeight: "bold",
  },
  infoIcon: {
    fill: "#6b5eff !important",
    height: "20px !important",
  },
  Infoicon: {
    color: "#ba8e23 !important ",
    fontSize: "1.5rem",
    marginTop: "11px",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "grey !important",
    paddingLeft: "8px !important",
    marginTop: "6px !important ",
  },
  acctInput: {
    padding: "2.5% !important ",
    border: "1px solid #d5d5d5 !important",
    borderRadius: "10px !important",
    fontSize: "14px !important ",
  },
  placeholder: {
    background: "none",
    color: "grey !important",
    fontSize: "0.9em",
  },
  countryName: {
    fontSize: "0.7rem !important ",
    fontWeight: "600 !important",
  },
  previousBtn: {
    marginLeft: "20px !important",
  },
  spacingCont: {
    margin: "10px 0px !important ",
  },
  goodForTextContainer: {
    width: "100%",
    height: "100%",
    borderRadius: "50% !important ",
    background: "var(--primary-color)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fontCurrency: {
    marginLeft: "10px !important ",
    fontSize: "0.8rem !important ",
  },
  moneySentImg: {
    width: "60px !important ",
    height: "60px !important",
  },
  goodForText: {
    color: "#fff",
    padding: "10px",
    width: "60%",
    alignSelf: "center",
    fontSize: "0.8rem !important ",
  },

  text: {
    fontSize: "0.7rem !important ",
    whiteSpace: "nowrap !important",
  },
  availablebalance: {
    fontSize: "14px !important ",
    fontFamily: "Open sans !important",
    fontWeight: "600 !important",
  },
  countryCodeSelect: {
    width: "90vw !important",
    "& .custom__control": {
      marginTop: "17px",
      borderRadius: "8px",
      border: "1px solid #d5d5d5",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #d5d5d5",
      },
    },
    "& .custom__placeholder": {
      fontSize: "11px !important",
    },
    "& .custom__menu-list": {
      maxHeight: "160px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primary-color)",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },
  countrySelect: {
    border: "1px solid #d5d5d5 !important ",
    borderRadius: "8px !important",
    textAlign: "Left",
    "& .MuiSelect-select": {
      display: "flex !important ",
      alignItems: "center !important ",
      //justifyContent: "center !important ",
      marginLeft: "5px !important ",
      padding: "5px  !important ",
    },
  },
  spacing: {
    margin: "17px 0 0 10px",
  },
  space: {
    margin: "10px 0 0 10px !important ",
  },
  header: {
    marginTop: "17px !important",
    background: "var(--primary-color) !important",
    color: "#fff !important ",
    padding: "2% !important ",
  },
  finalheader: {
    marginTop: "17px !important",
    background: "var(--primary-color) !important",
    color: "#fff !important ",
    padding: "4% !important ",
    borderRadius: "50px !important ",
  },
  headertext: {
    marginLeft: "10px !important ",
    fontSize: "0.9rem !important ",
  },
  moneySent: {
    marginTop: "20px !important ",
    fontWeight: "bold !important ",
    fontSize: "1.3rem !important ",
  },
  errorMsg: {
    color: "red !important ",
    fontSize: "0.7rem !important ",
    marginLeft: "5px !important ",
  },
  headerDetails: {
    display: "flex !important ",
    justifyContent: "space-between !important",
    alignItems: "center !important ",
  },
  finalDetails: {
    display: "flex !important ",
    justifyContent: "space-between !important",
    alignItems: "center !important ",
  },
  headerstyle: {
    fontWeight: "600 !important ",
    fontSize: "0.7rem !important",
    lineHeight: "2rem !important",
  },
  flexCenterAlign: {
    display: "flex",
    alignItems: "center",
  },
  InfoOutlined: {
    color: "var(--primary-color)",
    fontSize: "1.2rem !important ",
    marginLeft: "0px !important ",
  },
  checkMark: {
    width: "10px !important",
    paddingLeft: "10px !important",
  },
  contentstyle: {
    fontSize: "0.8rem !important",
  },
  infoStyle: {
    color: "Yellow !important",
    fontSize: "1.5rem !important",
  },
  infoDetails: {
    fontWeight: "600 !important",
    fontSize: "0.9rem !important",

    marginLeft: "5px !important",
  },
  space: {
    margin: "17px 0 0 10px !important",
  },
  inputBase: {
    width: "70% !important ",
    padding: "5px !important ",
    display: "flex",
    alignItems: "center !important ",
    fontWeight: "bold !important ",
    fontSize: "0.7rem !important ",
  },
  avatarAccountnum: {
    color: "var(--primary-color) !important ",
    fontSize: "13px !important ",
    fontWeight: "400 !important ",
  },
  label: {
    marginBottom: "0",
    display: "flex",
    alignItems: "center",
  },
  numberField: {
    "& input[type=number]": {
      /* Remove arrows in Chrome, Safari, Edge, Opera */
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      /* Remove arrows in Firefox */
      "-moz-appearance": "textfield",
    },
  },
  /*   cancel: {
    border: "1px solid #BC64C7 !important  ",
    borderRadius: "40px !important ",
    background: "#ffff !important ",
    padding: "3px 12px 3px 12px !important",
    textTransform: "none !important ",
  }, */
  continue: {
    color: "#ffffff !important",
    padding: "5px 12px 5px 12px !important",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important ",
      color: "#ffffff !important ",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "var(--primary-color)",
  },
  closeIcon: {
    position: "absolute",
    right: "10px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "var(--primary-color)",
  },
  circleIcon: {
    fill: "#51459E !important ",
  },
  avatarFullName: {
    color: "#222 !important ",
    fontWeight: "600 !important ",
    fontSize: "14px !important ",
  },
  addtxt: {
    fontSize: "14px !important ",
    fontWeight: "bold !important",
  },
  inputInput: {
    width: "100%",
    padding: "10px 0px 10px 10px",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    fontSize: "14px",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  b_heading: {
    color: "var(--primary-color) !important",
    padding: "10px !important",
    fontSize: "14px !important ",
    fontWeight: "bold !important",
    backgroundColor: "#dddd !important",
    display: "flex !important",
    alignItems: "center !important",
  },
  avatar: {
    marginRight: "15px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  acountbal: {
    color: "#4f4f4f !important ",
    margin: "2px 0 !important ",
  },
  warningMsg: {
    lineHeight: "1.5 em !important ",
    marginLeft: "7px !important ",
  },
  newBeneficiary: {
    display: "flex !important ",
    gap: "10px !important ",
  },
  accountCard: {
    border: "1px solid #DDDDDD !important ",
    borderRadius: "16px !important ",
    marginTop: "4px !important ",
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
      borderRadius: "16px",
    },
  },
  CardContentDiv: {
    display: "flex !important ",
    width: "50vw !important",
  },
  sent: {
    // border: "1px solid #d5d5d5 !important",
    marginTop: "10px",
    width: "45vw !important",
    "& button": {
      borderRadius: "10px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
    },
    "& input": {
      border: "none !important",
      // borderBottom: '1px solid #000 !important',
    },
    "& ul": {
      position: "unset !important",
      overflowY: "auto !important",

      // WebKit scrollbar styling
      "&::-webkit-scrollbar": {
        width: "5px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primary-color) !important",
        borderRadius: "4px !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555 !important",
      },
    },
    "& .MuiSelect-select": {
      padding: "8px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0%",
    },
  },
  countryCodeSelect: {
    width: "100% !important",
    "& .custom__control": {
      marginTop: "17px",
      borderRadius: "8px",
      border: "1px solid #d5d5d5",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #d5d5d5",
      },
    },

    "& .custom__placeholder": {
      fontSize: "11px !important",
    },
    "& .custom__menu-list": {
      maxHeight: "160px !important ",
      overflowY: "auto !important ",
      position: "unset !important ",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primary-color) !important ",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },

  currencyCodeSelect: {
    "& .custom__control": {
      width: "175px !important",
      borderRadius: "8px !important",
      border: "none !important",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    },
    "& .custom__placeholder": {
      fontSize: "10px !important",
    },
    "& .custom__menu-list": {
      height: "160px !important",
      overflowY: "auto !important",
      "&::-webkit-scrollbar": {
        width: "5px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primary-color) !important",
        borderRadius: "4px !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555 !important",
      },
    },
  },
  Beneficiary: {
    marginTop: "10px !important ",
    fontSize: "14px !important",
    fontFamily: "Open sans !important",
    fontWeight: "600 !important",
  },
  exchangefields: {
    background: "#edecf5 !important ",
    padding: "5px !important",
    marginLeft: "0px !important",
  },
  textfeild: {
    borderRadius: "8px !important",
  },
  tip: {
    color: "var(--primary-color) !important ",
    marginTop: "10px !important",
  },
  CurrencyFlag: {
    borderRadius: "50% !important",
    width: "20px !important",
    height: "20px !important",
  },
  SendCurrency: {
    marginLeft: "5px !important ",
  },
  optionValue: {
    marginLeft: "10px !important ",
  },
  option: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  Currency: {
    marginLeft: "5px !important ",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important",
  },
  mandatory: {
    color: "red !important ",
  },

  purposeCodeDropdown: {
    fontSize: "0.7rem !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important  ",
    borderRadius: "40px !important ",
    background: "#ffff !important ",
    padding: "3px 12px 3px 12px !important",
    fontSize: "0.7rem !important ",
    textTransform: "none !important ",
  },
  continue: {
    color: "#ffffff !important",
    padding: "3px 12px 3px 12px !important",
    fontSize: "0.7rem !important ",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important ",
      color: "#ffffff !important ",
    },
  },
  requiredfeild: {
    fontSize: "0.7rem !important",
  },
  addtxt: {
    fontSize: "0.75rem !important ",
    fontWeight: "bold !important",
  },
  inputInput: {
    width: "100%",
    padding: "10px 0px 10px 10px",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    fontSize: "0.7rem ",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  circleIcon: {
    fill: "#51459E !important ",
    fontSize: "1rem !important ",
    marginTop: "3px !important",
  },
  avatar: {
    marginRight: "15px",
    height: "30px !important",
    width: "30px !important ",
  },
  avatarFont: {
    fontSize: "0.79rem !important ",
  },
  avatarFullName: {
    color: "#222 !important ",
    fontWeight: "600 !important ",
    fontSize: "0.7rem !important ",
  },
  avatarAccountnum: {
    color: "var(--primary-color) !important ",
    fontSize: "0.6rem !important ",
    fontWeight: "400 !important ",
  },
  avatarLaunchIcon: {
    color: "var(--primary-color) !important ",
    marginRight: "14px !important",
    cursor: "pointer !important",
    fontSize: "18px !important ",
  },
  avatarDeleteIcon: {
    color: "var(--primary-color) !important ",
    cursor: "pointer !important ",
    fontSize: "18px !important ",
  },
  Currency: {
    marginLeft: "5px !important ",
    width: "15px !important ",
    height: "15px !important ",
    borderRadius: "50% !important",
  },
  previousBtn: {
    marginLeft: "5px !important",
  },
  RadioBtn: {
    span: {
      fontSize: "13 !important",
    },
  },
  message: {
    fontSize: "0.7rem !important ",
    fontWeight: "600 !important ",
  },
  warningIcon: {
    width: "20px !important ",
    height: "20px !important ",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    inputLabel: {
      fontSize: "12px !important",
      color: "grey !important",
      paddingLeft: "8px !important",
      marginTop: "6px !important ",
    },
    sent: {
      // border: "1px solid #d5d5d5 !important",
      marginTop: "10px",
      width: "100% !important",
      "& button": {
        borderRadius: "10px !important",
        fontSize: "0.75rem !important ",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px !important",
      },
      "& input": {
        border: "none !important",
        fontSize: "0.75rem !important",
        // borderBottom: '1px solid #000 !important',
      },
      "& ul": {
        position: "unset !important",
        overflowY: "auto !important",
        fontSize: "0.75rem !important",

        // WebKit scrollbar styling
        "&::-webkit-scrollbar": {
          width: "5px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "var(--primary-color) !important",
          borderRadius: "4px !important",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555 !important",
        },
      },
      "& .MuiSelect-select": {
        padding: "8px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0%",
      },
    },
    inputBase: {
      width: "70% !important ",
      padding: "3px !important ",
      display: "flex",
      alignItems: "center !important ",
      fontWeight: "bold !important ",
      fontSize: "0.6rem !important ",
    },
    text: {
      fontSize: "0.67rem !important ",
      whiteSpace: "nowrap !important",
    },
    tabHeading: {
      textTransform: "none !important",
      fontSize: "0.8rem !important ",
      padding: "0px !important ",
      color: "black !important ",
      "&.Mui-selected": {
        color: "var(--primary-color) !important",
      },
    },
    b_heading: {
      color: "var(--primary-color) !important",
      padding: "10px !important",
      fontSize: "0.6rem !important ",
      fontWeight: "bold !important",
      backgroundColor: "#dddd !important",
      display: "flex !important",
      alignItems: "center !important",
    },
    title: {
      fontSize: "1rem !important ",
      fontWeight: "bold !important ",
      color: "#7270a0 !important ",
    },
    availablebalance: {
      fontSize: "0.7rem !important ",
      fontFamily: "Open sans !important",
      fontWeight: "600 !important",
    },
    acountbal: {
      color: "#4f4f4f !important ",
      margin: "0px 0 !important ",
      fontSize: "0.65rem !important ",
    },
    acctInput: {
      padding: "2.5% !important ",
      border: "1px solid #d5d5d5 !important",
      borderRadius: "10px !important",
      fontSize: "12px !important ",
    },
    CurrencyFlag: {
      borderRadius: "50% !important",
      width: "15px !important ",
      height: "15px !important",
    },
    textfeild: {
      padding: "0px !important ",
      borderRadius: "8px !important",
      fontSize: "0.75rem !important ",
    },
    SendCurrency: {
      marginLeft: "5px !important ",
      fontSize: "0.6rem !important ",
    },
    send: {
      fontSize: "0.7rem !important ",
      paddingRight: "10px !important ",
    },
    warningMsg: {
      lineHeight: "1 em !important ",
      marginLeft: "7px !important ",
      fontSize: "0.68rem !important ",
    },
    tooltip: {
      color: "var(--primary-color) !important ",
      //padding: "0px !important ",
      fontSize: "0.9rem !important",
    },
    tip: {
      color: "var(--primary-color) !important ",
      marginTop: "5px !important",
      fontSize: "0.9rem !important",
    },
    Beneficiary: {
      marginTop: "5px !important ",
      fontSize: "0.7rem !important",
      fontFamily: "Open sans !important",
      fontWeight: "600 !important",
    },
    currencyCodeSelect: {
      "& .custom__control": {
        width: "130px !important",
        borderRadius: "8px !important",
        border: "none !important",
        boxShadow: "none",
        "&:hover": {
          border: "none",
        },
      },
      "& .custom__menu-list": {
        height: "100px !important",
        overflowY: "auto !important",
        "&::-webkit-scrollbar": {
          width: "5px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "var(--primary-color) !important",
          borderRadius: "4px !important",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555 !important",
        },
      },
    },
    headerstyle: {
      fontWeight: "600 !important ",
      fontSize: "0.7rem !important",
      lineHeight: "2rem !important",
    },
    purposeCodeDropdown: {
      fontSize: "0.7rem !important",
    },
    checkMark: {
      width: "10px !important",
      paddingLeft: "10px !important",
    },
    cancel: {
      border: "1px solid #BC64C7 !important  ",
      borderRadius: "40px !important ",
      background: "#ffff !important ",
      padding: "3px 12px 3px 12px !important",
      fontSize: "0.7rem !important ",
      textTransform: "none !important ",
    },
    continue: {
      color: "#ffffff !important",
      padding: "3px 12px 3px 12px !important",
      fontSize: "0.7rem !important ",
      background: "#51459E !important",
      textTransform: "none !important",
      borderRadius: "40px !important",
      "&:hover": {
        backgroundColor: "black !important ",
        color: "#ffffff !important ",
      },
    },
    requiredfeild: {
      fontSize: "0.7rem !important",
    },
    editIcon: {
      background: "var(--primary-color) !important ",
      color: "#fff !important ",
      padding: "3px !important ",
      borderRadius: "8px !important",
      cursor: "pointer !important",
      fontSize: "16px !important",
      // marginLeft: "10vw!important",
    },
  },
  fontCurrency: {
    marginLeft: "10px !important ",
    fontSize: "0.7rem !important ",
  },
  CountryFlag: {
    marginLeft: "5px !important ",
    width: "15px !important ",
    height: "15px !important ",
    borderRadius: "50% !important ",
    objectFit: "cover !important ",
  },
  textFont: {
    fontSize: "0.7rem !important ",
  },
  warningIcon: {
    width: "15px !important ",
    height: "15px !important ",
    //marginTop: "5px !important "
  },
  moneySent: {
    marginTop: "20px !important ",
    fontWeight: "bold !important ",
    fontSize: "1.3rem !important ",
  },
  optionValue: {
    marginLeft: "5px !important ",
  },
  "@media screen and (min-width:600px) and (max-width:1024px)": {
    title: {
      fontSize: "1.6rem !important ",
      fontWeight: "bold !important ",
      color: "#7270a0 !important ",
    },
    tabHeading: {
      textTransform: "none !important",
      fontSize: "1.5rem !important ",
      padding: "0px !important ",
      color: "black !important ",
      "&.Mui-selected": {
        color: "var(--primary-color) !important",
      },
    },
    inputLabel: {
      fontSize: "0.8rem !important ",
      color: "grey !important",
      paddingLeft: "8px !important",
      marginTop: "8px !important ",
      width: "45vw !important ",
    },
    acctInput: {
      padding: "2.5% !important ",
      border: "1px solid #d5d5d5 !important",
      borderRadius: "10px !important",
      fontSize: "1.3rem !important ",
      width: "45vw !important ",
    },
    fullWidth: {
      width: "45vw !important ",
    },
    availablebalance: {
      fontSize: "1.4rem !important ",
      fontFamily: "Open sans !important",
      fontWeight: "600 !important",
    },
    acountbal: {
      color: "#4f4f4f !important ",
      margin: "2px 0 !important ",
      fontSize: "1.2rem !important ",
    },
    CurrencyFlag: {
      borderRadius: "50% !important",
      width: "25px !important",
      height: "25px !important",
    },
    SendCurrency: {
      marginLeft: "5px !important ",
      fontSize: "1.2rem !important ",
    },
    countryCodeSelect: {
      width: "50% !important",
      "& .custom__control": {
        marginTop: "17px",
        borderRadius: "8px",
        border: "1px solid #d5d5d5",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #d5d5d5",
        },
      },

      "& .custom__placeholder": {
        fontSize: "11px !important",
      },
      "& .custom__menu-list": {
        maxHeight: "160px !important ",
        overflowY: "auto !important ",
        position: "unset !important ",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "var(--primary-color) !important ",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      },
    },

    warningMsg: {
      lineHeight: "1.5 em !important ",
      marginLeft: "7px !important ",
      fontSize: "1.2rem !important ",
    },
    textfeild: {
      borderRadius: "8px !important",
      fontSize: "1.2rem !important ",
    },
    text: {
      fontSize: "1.2rem !important ",
      whiteSpace: "nowrap !important",
    },
    inputBase: {
      width: "70% !important ",
      padding: "5px !important ",
      display: "flex",
      alignItems: "center !important ",
      fontWeight: "bold !important ",
      fontSize: "1rem !important ",
    },
    purposeCodeDropdown: {
      fontSize: "1.2rem !important",
      width: "45vw !important ",
    },
    cancel: {
      border: "1px solid #BC64C7 !important",
      borderRadius: "40px !important",
      background: "#ffff !important",
      color: "#51459E !important",
      fontSize: "1.3rem !important ",
      padding: "8px 20px !important",
      textTransform: "none !important",
      marginTop: "20px !important",
      "&:hover": {
        background: "#ededed !important",
      },
    },
    continue: {
      color: "#ffffff !important",
      padding: "8px 20px !important",
      background: "#51459E !important",
      textTransform: "none !important",
      borderRadius: "40px !important",
      fontSize: "1.3rem !important ",
      "&:hover": {
        backgroundColor: "black !important",
        color: "#ffffff !important",
      },
      marginTop: "20px !important",
    },
    requiredfeild: {
      fontSize: "1.3rem !important",
    },
    inputInput: {
      width: "100%",
      padding: "10px 0px 10px 10px",
      border: "1px solid #dcdcdc",
      borderRadius: "12px",
      fontSize: "1.4rem",
      "&:focus": {
        border: "1px solid #dcdcdc",
        outline: "none",
      },
    },
    Beneficiary: {
      marginTop: "10px !important ",
      fontSize: "1.4rem !important",
      fontFamily: "Open sans !important",
      fontWeight: "600 !important",
    },
    headerstyle: {
      fontWeight: "600 !important ",
      fontSize: "1.4rem !important",
      lineHeight: "2rem !important",
    },
    currencyCodeSelect: {
      "& .custom__control": {
        width: "195px !important",
        borderRadius: "8px !important",
        border: "none !important",
        boxShadow: "none",
        "&:hover": {
          border: "none",
        },
      },
      "& .custom__menu-list": {
        height: "160px !important",
        overflowY: "auto !important",
        "&::-webkit-scrollbar": {
          width: "5px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "var(--primary-color) !important",
          borderRadius: "4px !important",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555 !important",
        },
      },
    },
    addtxt: {
      fontSize: "1.4rem !important ",
      fontWeight: "bold !important",
    },
    circleIcon: {
      fill: "#51459E !important ",
      fontSize: "1.4rem !important",
    },
    newBeneficiary: {
      display: "flex !important ",
      gap: "10px !important ",
      aliginItems: "center !important ",
    },
    b_heading: {
      color: "var(--primary-color) !important",
      padding: "10px !important",
      fontSize: "1.4rem !important ",
      fontWeight: "bold !important",
      backgroundColor: "#dddd !important",
      display: "flex !important",
      alignItems: "center !important",
    },
    CountryFlag: {
      marginLeft: "5px !important ",
      width: "25px !important ",
      height: "25px !important ",
      borderRadius: "50% !important ",
      objectFit: "cover !important ",
    },
    avatarFullName: {
      color: "#222 !important ",
      fontWeight: "600 !important ",
      fontSize: "1.1rem !important ",
    },
    avatarAccountnum: {
      color: "var(--primary-color) !important ",
      fontSize: "1rem !important ",
      fontWeight: "400 !important ",
    },
    avatar: {
      marginRight: "15px",
      width: "100px", // Increase width
      height: "100px ", // Increase height
    },
    avatarFont: {
      fontSize: "1rem !important ",
    },
    avatarDeleteIcon: {
      color: "var(--primary-color) !important ",
      cursor: "pointer !important ",
      fontSize: "25px !important ",
    },
    avatarLaunchIcon: {
      color: "var(--primary-color) !important ",
      marginRight: "14px !important",
      cursor: "pointer !important",
      fontSize: "25px !important ",
    },
    headerBene: {
      fontSize: "1.4rem !important ",
    },
    countryName: {
      fontSize: "1.3rem !important ",
    },
    editIcon: {
      background: "var(--primary-color) !important ",
      color: "#fff !important ",
      padding: "3px !important ",
      borderRadius: "8px !important",
      cursor: "pointer !important",
      fontSize: "25px !important",
      // marginLeft: "10vw!important",
    },
    fontCurrency: {
      marginLeft: "10px !important ",
      fontSize: "1.3rem !important ",
    },
    message: {
      fontSize: "1.4rem !important",
      fontWeight: "600 !important",
    },
    warningIcon: {
      width: "25px !important",
      height: "25px !important",
    },
    textFont: {
      fontSize: "1.5em !important",
    },
    contentstyle: {
      fontSize: "1.3rem !important",
    },
    currencyStyle: {
      marginLeft: "5px",
      width: "25px !important ",
      height: "25px !important ",
      borderRadius: "50% !important ",
    },
    headertext: {
      marginLeft: "10px !important ",
      fontSize: "1.4rem !important ",
    },
    moneySent: {
      marginTop: "10px !important ",
      fontWeight: "bold !important ",
      fontSize: "1.8rem !important ",
    },
    moneySentImg: {
      width: "80px !important ",
      height: "80px !important",
    },
    successMsg: {
      fontSize: "1.3rem !important ",
    },
    TransactionHeader: {
      fontSize: "1.4rem !important ",
      color: "var(--primary-color)",
      fontWeight: "700 !important ",
    },
  },
}));
export default useStyles;
