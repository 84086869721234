import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  fetchNewBeneficiary,
  fetchCountries,
  submitBeneficiaryDetails,
  validateBeneficiary,
  initiateBeneficiary,
  setCountryNameFor,
  setFormState,
  setSubmitFlag,
  setError,
  setFormFlag,
  resetInitialState,
} from "../../../Redux/Reducers/addNewBeneficiaryReducer";
import { fetchEditBeneficiary } from "../../../Redux/Reducers/editBeneficiaryReducer";
import CountrySpecificComponent from "../SendPayment/CountrySpecificComponent";
import useStyles from "./styles";
import Flag from "react-world-flags";
import Loader from "../Loader";
import dayjs from "dayjs";
import { getCountryCode } from "../../CommonUtils/CommonUtils";
import { bicfiRefreshCountries } from "../../CommonUtils/CommonUtils";
import { countryGroup1 } from "../../CommonUtils/CommonUtils";
import { countryGroup2 } from "../../CommonUtils/CommonUtils";
import ClearIcon from "@mui/icons-material/Clear";

const AddNewBeneficiary = ({ handleNewBeneficiary }) => {
  const classes = useStyles();
  //const [submitFlag, setSubmitFlag] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getCountries, setGetCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const addNewBeneficiaryData = useSelector((data) => data?.addNewBeneficiary);
  const [formFlag, setFormFlag] = useState(addNewBeneficiaryData?.formFlag);
  const storeData = useSelector((data) => data?.addNewBeneficiary);

  const { state } = location || {};
  const { row } = location.state || {};
  const [data] = useState(state);

  const country = storeData?.countryName;

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    // Prepare the updated value
    const updatedValue =
      name === "CdtrAcctIdIBAN" ||
      name === "BICFI" ||
      name === "PostCode" ||
      (country === "India" && name === "ClrSysMmbId") ||
      (country === "South Africa" && name === "ClrSysMmbId")
        ? value.toUpperCase()
        : value;

    dispatch(
      setFormState({
        [name]: updatedValue, // Update the specific field
      })
    );
  };
  useEffect(() => {
    dispatch(fetchNewBeneficiary());
    dispatch(fetchCountries());
    dispatch(resetInitialState());
    setGetCountries(addNewBeneficiaryData?.countries);
    setFormFlag(false);
  }, []);

  const handleDateChange = (value, name) => {
    const formattedDate = value ? dayjs(value).format("MM/DD/YYYY") : "";
    dispatch(
      setFormState({
        [name]: formattedDate, // Update the specific field
      })
    );
  };
  useEffect(() => {
    dispatch(setCountryNameFor(data?.country));
    // setIsCountry(false);
    setSelectedCountry("");
  }, [data?.country]);

  const handleRefreshInputBlur = (event) => {
    const { name, value } = event.target;
    let refreshFor;

    if (countryGroup1.includes(country)) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "CdtrAcctIdIBAN") {
        refreshFor = ".NewBeneDetails.CdtrAcctIdIBAN";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
      }
    } else if (countryGroup2.includes(country)) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
      }
    } else {
      refreshFor = bicfiRefreshCountries.includes(storeData?.countryName)
        ? ".NewBeneDetails.BICFI"
        : ".NewBeneDetails.CdtrAcctIdIBAN";
    }

    dispatch(
      validateBeneficiary({
        assignmentID: storeData?.assignmentID,
        formState: storeData?.formState,
        etag: storeData?.etag,
        refreshFor: refreshFor,
      })
    );
  };
  const handleValidate = () => {
    const { Edit, ...state } = addNewBeneficiaryData?.formState;
    const refreshFor = ".NewBeneDetails.Validate";
    setLoading(true);
    try {
      dispatch(
        validateBeneficiary({
          assignmentID: addNewBeneficiaryData?.assignmentID,
          formState: addNewBeneficiaryData?.formState,
          etag: addNewBeneficiaryData?.etag,
          refreshFor: refreshFor,
        })
      )
        .then((res) => {
          if (res.payload && res?.meta.requestStatus === "fulfilled") {
            dispatch(setSubmitFlag(true));
            dispatch(setError(""));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = () => {
    const { Edit, message, pyImage, classID, EnterBICCode, ...state } =
      addNewBeneficiaryData?.formState;
    setLoading(true);
    dispatch(
      submitBeneficiaryDetails({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        formState: state,
        etag: addNewBeneficiaryData?.etag,
      })
    )
      .then((res) => {
        if (res.payload?.confirmationNote) {
          handleClose();
          navigate("/internationaltransfer/*");
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    dispatch(resetInitialState());
    navigate("/internationaltransfer/*");
  };
  const handleCountries = (evt) => {
    const value = evt.target.value;
    const { ReceiversCountryFName, pxObjClass, ...res } = value;
    // navigate("/addnewbeneficiary", { state: { country: value?.Country } });
    setSelectedCountry(value);
    dispatch(setFormState(res));
    dispatch(setCountryNameFor(value?.Country));
    dispatch(
      initiateBeneficiary({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        etag: addNewBeneficiaryData?.etag,
        countryName: value?.Country,
      })
    ).then((res) => {
      // dispatch(setFormState(res?.payload?.NewBeneDetails));
    });
  };

  return (
    <div>
      <Box className={classes.rootContainer}>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.title}>Add New Beneficiary</Typography>
          <ClearIcon
            className={classes.clearIcon}
            onClick={handleNewBeneficiary}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 4 }} p={1}>
            <Typography className={classes.textstyle}>
              Beneficiary country
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleCountries}
                disabled={selectedCountry !== ""}
                placeholder=" Select Country"
                value={selectedCountry}
                style={{
                  marginTop: "10px",
                  height: "40px",
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflow: "auto",
                    },
                  },
                }}
                displayEmpty
              >
                <MenuItem value="">Select Country</MenuItem>
                {addNewBeneficiaryData?.countries &&
                  addNewBeneficiaryData?.countries.map((item) => (
                    <MenuItem value={item} key={item?.CountryCode}>
                      {item?.Country}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {(addNewBeneficiaryData?.countryName || row?.Country) && (
            <>
              <CountrySpecificComponent
                addNewBene={true}
                row={row}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                newCountryName={addNewBeneficiaryData?.countryName}
                handleRefreshInputBlur={handleRefreshInputBlur}
                handleValidate={handleValidate}
              />
            </>
          )}
          {loading && (
            <Grid size={{ md: 12 }} className={classes.loader}>
              <Loader />
            </Grid>
          )}
          <Grid
            container
            spacing={2}
            style={{
              marginTop: "5%",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid md={6}>
              <Button className={classes.cancel} onClick={handleNewBeneficiary}>
                Cancel
              </Button>
            </Grid>

            {!addNewBeneficiaryData?.submitFlag && selectedCountry && (
              <Grid md={6}>
                <Button className={classes.submit} onClick={handleValidate}>
                  Validate
                </Button>
              </Grid>
            )}
            {addNewBeneficiaryData?.submitFlag && (
              <Grid md={6}>
                <Button className={classes.submit} onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AddNewBeneficiary;
