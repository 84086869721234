import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import closeIcon from "../../../assets/images/close.svg";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

const DrawerMenu = (props) => {
  const navigate = useNavigate();

  const { open, onClose } = props;
  const classes = useStyles();
  const [listOpen, setListOpen] = useState(true);
  const [nesMenu, setNesMenu] = useState(true);
  const handleClick = () => {
    setListOpen(!listOpen);
    setNesMenu(false);
  };
  const handleDrawerClose = () => {
    onClose();
    setListOpen(true);
    setNesMenu(true);
  };
  function navPanelButtonClick(pageName) {
    setListOpen(false);
    setNesMenu(false);
  }
  const handleClickSubMenu = () => {
    setNesMenu(!nesMenu);
  };
  const userName = () => {
    const user = localStorage.getItem("userName");
    return user;
  };
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        //className={classes.drawer}
        ModalProps={{
          keepMounted: true,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <AppBar
          style={{ background: "var(--primary-color)", boxShadow: "none" }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {/* <Avatar></Avatar> */}
              <PersonOutlineOutlinedIcon />
              <Typography>{userName()}</Typography>
            </div>
            <CloseIcon style={{ color: "#fff" }} onClick={handleDrawerClose} />
          </Toolbar>
        </AppBar>
        <Box style={{ marginTop: "17%" }}>
          <List className={classes.mainMenu} onClick={handleDrawerClose}>
            <ListItem key="Accounts'">
              <Link
                to="accounts"
                className={classes.linkStyle}
                //style={{ marginTop: "20%" }}
              >
                Accounts
              </Link>
            </ListItem>
            {/* </div> */}
          </List>
          <List style={{ paddingBottom: "0px", marginTop: "-10px" }}>
            <div onClick={handleClick} className={classes.mainMenu}>
              <ListItem style={{ paddingLeft: "15px", color: "#fff" }}>
                <ListItemText
                  primary="Pay & Transfer"
                  className={classes.txt}
                />
              </ListItem>
              <ListItemIcon
                className={classes.menuIcon}
                style={{
                  cursor: "pointer",
                  marginRight: "25px",
                }}
              >
                {listOpen ? (
                  <KeyboardArrowUpIcon
                    style={{ color: "black" }}
                    fontSize="medium"
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{ color: "black" }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
            </div>
            {/* submenu */}
            <Collapse in={listOpen} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingBottom: "0px" }}
              >
                <div style={{ background: "#f0f0f0  " }}>
                  <ListItem className={classes.nestedList}>
                    <ListItemText
                      primary="Pay a Bill"
                      className={classes.txt}
                    />
                  </ListItem>

                  <ListItem className={classes.nestedList}>
                    <ListItemText
                      primary="Domestic Transfer"
                      className={classes.txt}
                    />
                  </ListItem>

                  <ListItem
                    className={classes.nestedList}
                    onClick={handleClickSubMenu}
                  >
                    <ListItemText
                      primary="International Transfer"
                      className={classes.txt}
                    />
                    <span className={classes.nes1}>
                      {nesMenu ? (
                        <KeyboardArrowUpIcon
                          style={{ color: "black", marginLeft: "-6px" }}
                          fontSize="medium"
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          style={{ color: "black", marginLeft: "-6px" }}
                          fontSize="medium"
                        />
                      )}
                    </span>
                  </ListItem>
                </div>

                <Collapse in={nesMenu} timeout="auto" unmountOnExit>
                  <List
                    style={{ paddingBottom: "0px", background: "#e0e0e0 " }}
                    component="div"
                    disablePadding
                    onClick={handleDrawerClose}
                  >
                    <ListItem
                      className={classes.nestedList}
                      key="Recent Payments"
                    >
                      <Link to="recentpayments" className={classes.linkStyle}>
                        <ListItemText
                          primary="Recent Payments"
                          className={classes.txt}
                        />
                      </Link>
                    </ListItem>
                    <ListItem
                      className={classes.nestedList}
                      onClick={() => navPanelButtonClick("Recent Payments")}
                      key="Add/modify"
                    >
                      <Link to="addnewbene" className={classes.linkStyle}>
                        <ListItemText
                          primary="Add/Modify Beneficiary"
                          className={classes.txt}
                        />
                      </Link>
                    </ListItem>
                    <ListItem
                      className={classes.nestedList}
                      onClick={() => navPanelButtonClick("Recent Payments")}
                      key="Send payment"
                    >
                      <Link to="sendpayment" className={classes.linkStyle}>
                        <ListItemText
                          primary="Send Payment"
                          className={classes.txt}
                        />
                      </Link>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </List>

          <List
            style={{ paddingBottom: "0px", color: "black", marginTop: "-10px" }}
          >
            <div className={classes.mainMenu}>
              <ListItem>
                <ListItemText
                  primary="Rewards & Deals"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List
            style={{ paddingBottom: "0px", color: "#fff", marginTop: "-10px" }}
          >
            <div className={classes.mainMenu}>
              <ListItem>
                <ListItemText
                  primary="Tools & Investing"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List
            style={{ paddingBottom: "0px", color: "black", marginTop: "-10px" }}
          >
            <div className={classes.mainMenu}>
              <ListItem>
                <ListItemText
                  primary="Security Center"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List
            style={{ paddingBottom: "0px", color: "black", marginTop: "-10px" }}
          >
            <div className={classes.mainMenu}>
              <ListItem>
                <ListItemText
                  primary="Open an Account"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List
            style={{ paddingBottom: "0px", color: "black", marginTop: "-10px" }}
          >
            <div className={classes.mainMenu}>
              <ListItem>
                <ListItemText
                  primary="Help & Support"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>
          <List style={{ paddingBottom: "15%", color: "black" }}>
            <ListItem className={classes.flexCenter}>
              <Button
                variant="outlined"
                className={classes.logoutBtn}
                onClick={logout}
              >
                Logout
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};
export default DrawerMenu;
