import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

const ABARoutingNumberField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    editableBeneDetails,
    SelectedBeneDetails,
    isEditable,
    flag,
    handleEditChange,
    handleInputChange,
  } = props;

  return (
    <Grid size={{ xs: 12, md: 4 }} className={classes.spacing}>
      <Typography variant="body2" className={classes.headerstyle}>
        ABA Routing Number
      </Typography>
      {isEditable ? (
        <TextField
          variant="outlined"
          size="small"
          disabled={!isEditable}
          value={editableBeneDetails?.ClrSysMmbId}
          onChange={handleInputChange}
          onBlur={handleEditChange}
          name="ClrSysMmbId"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment>
                  {flag && editableBeneDetails?.ClrSysMmbId && (
                    <img
                      src={editableBeneDetails?.IsValidABANumberURL}
                      className={classes.checkMark}
                      alt="checkmark"
                    />
                  )}
                </InputAdornment>
              ),
            },
          }}
        />
      ) : editableBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {editableBeneDetails?.ClrSysMmbId} &nbsp;
          {flag && editableBeneDetails?.ClrSysMmbId && (
            <img
              src={editableBeneDetails?.IsValidABANumberURL}
              className={classes.checkMark}
              alt="checkmark"
            />
          )}
        </span>
      ) : SelectedBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {SelectedBeneDetails?.ClrSysMmbId} &nbsp;
          {flag && editableBeneDetails?.ClrSysMmbId && (
            <img
              src={SelectedBeneDetails?.IsValidABANumberURL}
              className={classes.checkMark}
              alt="checkmark"
            />
          )}
        </span>
      ) : (
        <span className={classes.contentstyle}>NA</span>
      )}
    </Grid>
  );
};

export default ABARoutingNumberField;
