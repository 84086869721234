import zIndex from "@mui/material/styles/zIndex";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  flexCenter: {
    display: "flex !important ",
    justifyContent: "center !important ",
    alignItems: "center !important ",
  },
  drawer: {
    "& .MuiBackdrop-root": {
      background: "none !important",
    },
  },
  drawerPaper: {
    //bottom: "79px !important",
    // height: "calc(100vh - 108px) !important",
    //backgroundColor: "var(--primary-color) !important ",
    color: "#ccc",
    width: "100%",
    //padding: "45px",
    // borderRadius: "65px",
    // top: "56px !important",
    overflowX: "hidden",
    overflowY: "scroll",
    zIndex: "100000",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  middleContainer: {
    height: "calc(100vh - 107px)",
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    //borderBottomLeftRadius: "65px",
    //borderBottomRightRadius: "65px",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "var(--primary-color)",
    padding: "12px",
    zIndex: "9999",
  },
  bgcolor: {
    fontWeight: "800",
  },
  iconText: {
    fontSize: "0.8rem !important ",
    marginLeft: "5px !important",
  },
  footerIcons: {
    color: "#fff",
    cursor: "pointer",
    fontSize: "15px",
    width: "25px !important",
    height: "25px !important",
  },
  contentContainer: {
    background: "#f5f7ff",
  },
  mainMenu: {
    display: "flex",
    // borderBottom: "2px #aca4df80 dotted",
    padding: "0px",
  },
  userName: {
    "& span": {
      fontWeight: "bold",
    },
  },
  linkStyle: {
    color: "black",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "500",
    "&:hover": {
      fontSize: "14.5px !important",
    },
  },
  linkIcons: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "14px",
    display: "flex",
    //flexDirection: "column",
    alignItems: "center",
  },
  txt: {
    color: "black",
    "& span": {
      fontSize: "14px !important",
      fontWeight: "500",
      cursor: "pointer",
      "&:hover": {
        fontSize: "14.5px !important",
      },
    },
  },
  logoutBtn: {
    backgroundColor: "#fff !important ",
    width: "80% !important ",
    color: "var(--primary-color) !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
    padding: "5px 50px 5px 50px !important",
    cursor: "pointer !important",
    border: "1px solid var(--primary-color) !important",
    borderRadius: "20px !important",
    marginTop: "5px !important",
    // marginBottom: "20px !important",
    textTransform: "none !important",
    "& :hover": {
      backgroundColor: "var(--primary-color) !important",
      color: "#fff !important",
      border: "1px solid #ffff !important",
    },
  },
  nes1: {
    // marginLeft: "10px",
    paddingRight: "10px",
  },
  closeIcon: {
    width: "23px",
  },
  menuIcon: {
    minWidth: "10px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "0px !important",
  },
  menuBullet: {
    color: "black",
    fontSize: "10px !important",
  },
  nestedList: {
    color: "#ccc",
    display: "flex",
    //borderBottom: "2px #aca4df80 dotted",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {},
}));

export default useStyles;
