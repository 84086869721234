import { makeStyles } from "@mui/styles";
const drawerWidth = "22%";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    width: drawerWidth,
    alignItems: "center",
    justifyContent: "start",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9),
    },
    height: "100vh",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  mainMenu: {
    display: "flex",
    borderBottom: "2px #ffffff80 dotted",
  },
  userName: {
    "& span": {
      fontWeight: "bold",
    },
  },
  linkStyle: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "bold",
    "&:hover": {
      fontSize: "14.5px !important",
    },
  },
  txt: {
    "& span": {
      fontSize: "14px !important",
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        fontSize: "14.5px !important",
      },
    },
  },
  nested: {
    borderBottom: "2px #ffffff80 dotted",
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    padding: "10px",
    margin: "5px",
  },
  appListItem: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  appListLogo: {
    // marginRight: theme.spacing(2),
    // marginLeft: "10px",
    width: "110px !important",
    height: "2.5rem !important ",
    paddingTop: "5px !important",
    // paddingBottom: "10px",
  },
  appListIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  appListDiv: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoutBtn: {
    backgroundColor: "#fff",
    color: "var(--primary-color)",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "5px 50px 5px 50px",
    cursor: "pointer",
    marginLeft: "30px",
    border: "none",
    borderRadius: "20px",
    marginTop: "30px",
    textTransform: "none",
    "& :hover": {
      backgroundColor: "var(--primary-color) !important",
      color: "#fff",
      border: "1px solid #ffff",
    },
  },
  applicationLabel: {
    whiteSpace: "initial",
  },
  appBar: {
    zIndex: "99999",
    width: "100%",
    background: "#fff",
    borderBottom: " 2px solid #e6eff5",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.01) !important ",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: "none",
  },
  contentPaper: {
    borderRadius: "30px",
    height: "72vh",
    padding: 5,
  },
  accountsPaper: {
    height: "58vh",
    borderRadius: "30px",
    marginTop: "20px",
    padding: "20px",
    backgroundSize: "cover",
  },
  nes1: {
    marginLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "8px",
  },
  hamburgerIcon: {
    width: "40px",
  },
  notificationIcon: {
    width: "22px",
  },
  closeIcon: {
    width: "23px",
  },
  menuBullet: {
    color: "#fff",
    fontSize: "7px !important",
    marginTop: "10px",
  },
}));

export default useStyles;
